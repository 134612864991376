/*{
  "metro_area": "Atlanta",
  "places_type": 1,
  "type": 1,
  "area": "Atlanta",
  "area_pnt_msa": "0.22%",
  "gdp": "$84,107,599",
  "gdp_pnt_msa": "19.17%",
  "area_2": "Atlanta",
  "_type": 1,
  "for_sale_sqft": "38,016,097",
  "multifam_sqft": "41,572,344",
  "office_sqft": "66,318,408",
  "retail_sqft": "36,543,744",
  "industrial_sqft": "95,900",
  "total_sqft": "182,546,493",
  "for_sale_share": "20.80%",
  "multifam_share": "22.80%",
  "office_share": "36.30%",
  "retail_share": "20.00%",
  "industrial_share\r": "0.10%\r"
}*/

import { FaCarAlt, FaWalking } from "react-icons/fa";
import { Icon, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { fuzzySearch } from "../../helpers/fuzzySearch";
import lensdata from "../../data/demo_top_35_inv_shares.json";
import { placeTypes } from "../../assets/theme";
import { useAppState } from "../../context/useAppState";

export const LensMatrixTable = () => {
  const { activeMetro } = useAppState();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!lensdata || !activeMetro) return;
    const area = fuzzySearch(lensdata, activeMetro, "metro_area");
    const _data = lensdata.filter((d) => d.metro_area === area.metro_area);
    _data.forEach((d) => {
      d.type = parseInt(d.places_type);
      // console.log(d);
      for (let k in d) {
        //HACK - this is a hack to fix the data
        !Number(d[k]) && d[k] !== undefined && d[k].length > 4 && (d[k] = d[k].replace("0%", "%"));
      }
    });
    setData(_data);
  }, [activeMetro]);

  /*
  table needs to be 
  multi - percentage for type 1
  office
  retail
  industrial
  */

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Product Type</Th>
          {placeTypes.map((type, i) => (
            <Th
              key={i.toString()}
              alignItems="center"
              background={type.color}
              color={i < 3 ? "white" : "inherit"}
            >
              {type.icon === "walk" ? (
                <Icon as={FaWalking} mr={2} />
              ) : (
                <Icon as={FaCarAlt} mr={2} />
              )}
              {type.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>For Sale Residential</Td>
          {placeTypes.map((type, i) => (
            <Td key={i.toString()} isNumeric background={type.colorMuted}>
              {data.length ? data.find((d) => d.type === type.type)?.for_sale_share : ""}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td>Multi-Family</Td>
          {placeTypes.map((type, i) => (
            <Td key={i.toString()} isNumeric background={type.colorMuted}>
              {data.length ? data.find((d) => d.type === type.type)?.multifam_share : ""}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td>Office</Td>
          {placeTypes.map((type, i) => (
            <Td key={i.toString()} isNumeric background={type.colorMuted}>
              {data.length ? data.find((d) => d.type === type.type)?.office_share : ""}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td>Retail</Td>
          {placeTypes.map((type, i) => (
            <Td key={i.toString()} isNumeric background={type.colorMuted}>
              {data.length ? data.find((d) => d.type === type.type)?.retail_share : ""}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td>Industrial</Td>
          {placeTypes.map((type, i) => (
            <Td key={i.toString()} isNumeric background={type.colorMuted}>
              {data.length ? data.find((d) => d.type === type.type)?.industrial_share : ""}
            </Td>
          ))}
        </Tr>
        {/* {placeTypes.map((type, i) => (
          <Tr background={type.colorMuted} key={i.toString()}>
            <Td display={"flex"} alignItems="center">
              {type.icon === "walk" ? (
                <Icon as={FaWalking} mr={2} />
              ) : (
                <Icon as={FaCarAlt} mr={2} />
              )}
              {type.label}
            </Td>
            <Td isNumeric>
              {data.length ? data.find((d) => d.type === type.type)?.for_sale_share : ""}
            </Td>
            <Td isNumeric>
              {data.length ? data.find((d) => d.type === type.type)?.multifam_share : ""}
            </Td>
            <Td isNumeric>
              {data.length ? data.find((d) => d.type === type.type)?.office_share : ""}
            </Td>
            <Td isNumeric>
              {data.length ? data.find((d) => d.type === type.type)?.retail_share : ""}
            </Td>
            <Td isNumeric>
              {data.length ? data.find((d) => d.type === type.type)?.industrial_share : ""}
            </Td>
          </Tr>
        ))} */}
      </Tbody>
    </Table>
  );
};
