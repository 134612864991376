/**
 * see https://github.com/mapbox/mapbox-react-examples/blob/master/basic/src/Map.js
 * https://codesandbox.io/s/basic-mglmap-525tg?file=/src/Map.js:425-470
 * //https://www.robinwieruch.de/react-hooks-fetch-data/
 */

import "mapbox-gl/dist/mapbox-gl.css";
import "../../../node_modules/@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import "../Map/mapbox-custom.css";
import "../Map/circle.css";

import * as geobuf from "geobuf";
import * as h3 from "h3-js";
import * as turf from "@turf/turf";

import { Box, Flex, Heading, Spinner, useColorMode, useToast } from "@chakra-ui/react";
import { accessToken, apiGateway, mapStyles } from "../../config";
import { clearHighlight, mglPopup } from "../../mapbox-gl-plugins/mapbox-gl-popup";
import { memo, useEffect, useRef, useState } from "react";
import { useMapStateStore, useMapStore } from "../../context/mapStore";

import { ExportCSVControl } from "../../mapbox-gl-plugins/mapbox-gl-export-csv";
import Geocoder from "@mapbox/mapbox-gl-geocoder";
// import Loader from "../Loader/Loader";
import { MapLayerControlModal } from "../Map/MapLayerControl";
import { MapboxGLFullPage } from "../../mapbox-gl-plugins/mapbox-gl-fullpage";
import { MapboxGLLegend } from "../../mapbox-gl-plugins/mapbox-gl-legend";
import Pbf from "pbf";
import { PropertyFilterFormModal } from "../PropertyFilterForm/PropertyFilterForm";
import chroma from "chroma-js";
import { ckmeans } from "simple-statistics";
import { fuzzySearch } from "../../helpers/fuzzySearch.js";
import { getHPACBGColorRamp } from "../Layers/getHPACBGColorRamp.js";
import mapboxgl from "mapbox-gl";
import placesAreaConvexH from "../../data/ppl_areas_convexh.json";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";
import { useLayerStore } from "../../context/layerStore";

// import { useTimeSeriesStore } from "../../context/timeSeriesStore";

// import { listingsAnalytics } from "../Data/ListingsAnalytics";

// import { updateLineCharts } from "../DataViz/UpdateLineCharts.js";

// import axios from "axios";

// import { MapboxGLH3 } from "../../mapbox-gl-plugins/mapbox-gl-h3"

// import "./move-right.css"

mapboxgl.accessToken = accessToken;

const placesChoroplethLegend = new MapboxGLLegend({
  id: "heat-map-legend",
  title: "Value Intensity",
  layer: "places-fill",
});
const placesLegend = new MapboxGLLegend({
  title: "Places Lens™",
  layer: "places-fill",
  coloramp: false,
  html: `
    <div><span class="legend-box" style="background-color: #5d8847">&nbsp;</span>WalkUPs</div>
    <div><span class="legend-box" style="background-color: #689acb">&nbsp;</span>Walkable Neighborhoods</div>
    <div><span class="legend-box" style="background-color: #cb6768">&nbsp;</span>Drivable "Edge-Cities"</div>
    <div><span class="legend-box" style="background-color: #d9d9d9">&nbsp;</span>Drivable Communities</div>
  `,
});

const Map = ({
  buttonFiltersState,
  analyticsChangeMode,
  dateRange,
  setPopupContent,
  placesType,
  activeLayers,
  // setTabIndex,
}) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  let initialLoad = true;

  const [currentMetro, setCurrentMetro] = useState();
  //NOTE metro that is closest to the current map center;

  const queryParams = new URLSearchParams(window.location.search);
  const debug = queryParams.get("debug");
  // console.debug({ debug });

  //getMetros ALLOWS US TO GET THE LIST OF METROS FROM THE STORE, SINCE THE MAP IS RENDERED BEFORE THE STORE IS POPULATED
  const {
    activeMetro,
    setActiveMetro,
    setActiveHPAMetro,
    getMetros,
    setActiveMapPlaceType,
    placesTypes,
    activeLayer,
    setActiveLayer,
  } = useAppState();
  const { layers } = useLayerStore();
  const { apiToken } = useAccountStore();

  const {
    mapState,
    setMapState,
    inFocusMetro,
    setInFocusMetro,
    placesHPABounds,
    setPlacesHPABounds,
  } = useMapStateStore();
  const {
    // placesArea,
    map: mapRef,
    setMap: setMapStore,
    setPlacesArea,
    placesHeatMapToggleActive,
    placesHeatMapToggleValue,
    setActivePlace,
  } = useMapStore();

  const mapStateRef = useRef(null);
  const mapContainerRef = useRef(null);
  const toast = useToast();
  const { colorMode = "light" } = useColorMode();
  const [map, setMap] = useState();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [mapIsLoading, setMapIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [originalFilter, setOriginalFilter] = useState({});
  const [
    statField,
    // setStatField
  ] = useState({
    fs: "median_sold_price",
    ts: "stat_median_sold_price",
    name: "Median Sold Price",
  });
  const [placeState, setPlaceState] = useState();
  // const timeSeriesStore = useTimeSeriesStore();

  const now = Date.now();
  const popupContentId = { id: 1542 };

  //NOTE - does not cause an initial map refresh
  useEffect(() => {
    setActivePlace(60730134192);
    const sampleFeature = {
      type: "Feature",
      properties: {
        id: 60730134192,
        alias: "060730134192",
        place_type: 3,
        geoid: "060730134192",
        area: "San Diego",
        _bbox: [-116.976884, 32.646155, -116.96116, 32.661822],
        acs_hh_med_gross_rent: "2418",
        acs_hh_med_income: "112250",
        acs_hh_med_lor: "7",
        acs_hh_total: "367",
        acs_total_pop: "1152",
        acs_transit_pnt_commute: "0",
        geoid10: "060730134192",
        geoidint: 60730134192,
        hh_avg_lor: "8.40",
        hh_avg_net_worth: 504161,
        hh_ht_ami_80: "20%",
        hh_med_age_head_hh: 48,
        hh_med_gross_rent: 2418,
        hh_med_income: 74057,
        hh_pnt_single_family: null,
        hh_total: 356,
        hh_total_pop: 1181,
        jobs_30min_transit: 9863,
        poi_types:
          '[{"categry":"specialty food store","count":2}, {"categry":"florists","count":1}, {"categry":"pharmacy or drug store","count":1}, {"categry":"restaurant or bar","count":25}, {"categry":"coffee shop","count":6}, {"categry":"super market","count":4}, {"categry":"beer or wine or liquor store","count":1}, {"categry":"pet and pet supplies","count":4}, {"categry":"hospitals and outpatient care","count":7}, {"categry":"childcare","count":3}, {"categry":"fitness","count":16}, {"categry":"beauty salon or barber","count":32}, {"categry":"elementary or secondary school","count":2}, {"categry":"religious institution","count":5}, {"categry":"postal service","count":1}, {"categry":"doctor or dentist","count":40}, {"categry":"hardware store","count":1}]',
        places_type: 1,
        poi_count: 151,
        pptype3: 3,
        stats_average_esi: 15,
        stats_employment_access_index: 17940,
        stats_tot_workers: 471,
        transit_connectivity_index: 2,
        transit_performance_score: 4,
      },
      id: 60730134192,
    };
    setPopupContent({
      title: "places-fill",
      properties: sampleFeature.properties,
      active: true,
      areaType: "Place",
    });
    setPlacesArea({ name: sampleFeature.properties.area, areaType: "Place" });
    setPlaceState(sampleFeature);
    console.debug("[map] -- set place and popup");

    // eslint-disable-next-line
  }, []);

  //TODO move this somewhere else - relies on placesType which will get changed from another component
  //DMEO COMMENTED OUT
  useEffect(() => {
    if (!apiToken) return;
    console.debug("[map] -- place state change");

    // if (placeState && mapRef && placesArea && placesArea.areaType === "Place") {
    //   (async () => {
    //     setShowLoader(true);
    //     console.debug("[map] -- placeState setting store data for charts");
    //     timeSeriesStore.clearDataByPlaceType();
    //     await timeSeriesStore.setData(
    //       placeState,
    //       analyticsChangeMode,
    //       dateRange,
    //       apiToken,
    //       placesArea
    //     );
    //     setShowLoader(false);
    //   })();
    // }

    //   //NOTE removed no more areas in map 2023-02-07 14:19:13
    //   // else if (placeState && placesArea && placesArea.areaType === "Area" && mapRef) {
    //   //   console.debug("[placeState] setting Area store data for charts");
    //   //   (async () => {
    //   //     setShowLoader(true);
    //   //     await timeSeriesStore.setData(null, analyticsChangeMode, dateRange, apiToken, placesArea, placesType);
    //   //     //NOTE if there is no place type selected clear the data by place type so only one place type shows up
    //   //     //TODO why is this done inside the Map?
    //   //     if (placesType) {
    //   //       timeSeriesStore.clearDataByPlaceType();
    //   //     } else {
    //   //       //Else add all place type data to the timeSeriesStore
    //   //       await timeSeriesStore.setDataByPlaceType(analyticsChangeMode, dateRange, apiToken, placesArea);
    //   //     }
    //   //     setShowLoader(false);
    //   //   })();
    //   // }
    //   else {
    // timeSeriesStore.clearData();
    if (mapRef) mapRef.resize();
    //   }
    // eslint-disable-next-line
  }, [placeState, analyticsChangeMode, dateRange, mapRef, placesType, apiToken]);

  useEffect(() => {
    if (!map || !map.getStyle() || !placesChoroplethLegend) return;

    if (activeMetro === currentMetro) {
      return;
    } else {
      if (activeLayer === "metros") {
        setShowLoader(true);

        fetch(
          `${apiGateway}/collections/public.mview_stats_msa/items?&area_name=${activeMetro}&access_token=${apiToken}`
        )
          .then((res) => res.json())
          .then((data) => {
            const { features } = data;
            if (!features || !features.length) return;
            // map.flyTo({
            //   center: features[0]?.properties?.centroid.split(",").map((c) => +c),
            //   zoom: map.getZoom(),
            //   // minZoom: 5,
            // });

            map.flyTo({
              center: features[0]?.properties?.centroid.split(",").map((c) => +c),
              zoom: 9.6,
            });

            setTimeout(() => {
              // map.fire("click", {
              //   latLng: features[0]?.properties?.centroid
              //     .split(",")
              //     .map((c) => +c),
              //   point: map.project(
              //     features[0]?.properties?.centroid.split(",").map((c) => +c)
              //   ),
              //   originalEvent: {},
              // });
              setShowLoader(false);
            }, 3000);
          });
      }

      setCurrentMetro(activeMetro);
    }

    if (placesHeatMapToggleActive) {
      console.debug("[map] -- show heat map legend");
      placesChoroplethLegend.show();
      placesChoroplethLegend.update(null, placesHeatMapToggleValue);
      placesLegend.hide();
    } else {
      if (map.hasControl(placesChoroplethLegend)) placesChoroplethLegend.hide();
      if (
        map &&
        map.getLayer("places-fill") &&
        map.getLayoutProperty("places-fill", "visibility") === "visible"
      )
        placesLegend.show();
    }
    //eslint-disable-next-line
  }, [placesHeatMapToggleActive, map, activeMetro]);

  const clearPopupContent = () => {
    setPopupContent({
      title: null,
      properties: null,
      active: false,
      type: null,
    });
    setPlacesArea({});
    setPlaceState();
    setActivePlace("");
  };

  const mapInitEventHandlers = async (e) => {
    const map = e;
    //  console.debug(map.getStyle().layers)
    map.on("zoomend", () => console.debug(map.getZoom()));
    // setMapIsLoading(true);
    //TOOLTIP FOR ALL LAYERS
    //  const layerTooltip = new mapboxgl.Popup({
    //   closeButton: false,
    //   closeOnClick: false,
    //   trackPointer: true
    //   });

    // console.debug(layerTooltip)

    for (let i = 0; i < layers.length; i++) {
      if (activeLayers) {
        if (activeLayers.includes(layers[i].id)) {
          if (
            layers[i].metadata &&
            layers[i].metadata.visibility &&
            layers[i].metadata.visibility === "none"
          ) {
            layers[i].metadata.visibility = "visible";
          }
        } else {
          //make visibility none
          if (
            layers[i].metadata &&
            layers[i].metadata.visibility &&
            layers[i].metadata.visibility === "visible"
          ) {
            layers[i].metadata.visibility = "none";
          }
        }
      }
      const l = layers[i];
      if (map.getLayer(l.id) && l.remove) {
        map.removeLayer(l.id);
        continue;
      }

      if (!map.getLayer(l.metadata.layers[0])) {
        if (!map.getSource(l.metadata.layers[0]) && l.source && l.source !== "mapbox") {
          if (l._source) {
            if (
              l._source.type === "geojson" &&
              typeof l._source.data === "string" &&
              l._source.data.indexOf(apiGateway) > -1
            ) {
              l._source.data = l._source.data += "&access_token=" + apiToken;
            }
            // console.debug({id: l.id, source: l.source})
            map.addSource(l.source, l._source);
          } else {
            if (l.sourceType && l.sourceType === "geojson") {
              map.addSource(l.id, {
                type: l.sourceType,
                data: l.sourceData,
                cluster: false, //clustes currently disabled, properties below are for reference
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
              });
            } else if (l.sourceType && l.sourceType === "vector") {
              map.addSource(l.id, {
                type: l.sourceType,
                tiles: l.tiles,
                minzoom: l.minzoom,
                maxzoom: l.maxzoom,
              });
            }
          }
        }
      }

      //ADD THE LAYER NOW THAT WE HAVE ADDED THE SOURCE
      if (!map.getLayer(l.id) && l.source !== "mapbox") {
        //  const tiles = l._source?.tiles
        //  if(tiles && tiles[0] && tiles[0].startsWith(apiGateway)){
        //    l._source.tiles[0] += `&access_token=${token}`
        //  }

        const beforeLayer =
          l.metadata && l.metadata.beforeLayer
            ? l.metadata.beforeLayer
            : l.beforeLayer
            ? l.beforeLayer
            : null;
        if (beforeLayer && map.getLayer(beforeLayer)) {
          map.addLayer(l, beforeLayer);
        } else {
          map.addLayer(l);
        }
      }

      //ADD CURSOR CHANGE TO POINTER AS YOU MOVE OVER A FEATURE THAT HAS POPUPS ENABLED
      if (l.popup || l.metadata.popup) {
        map.on("mousemove", l.id, () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseout", l.id, () => {
          map.getCanvas().style.cursor = "";
        });
      }

      // NOTE testing layer tooltip functionality
      //  if (l.metadata.tooltip) {
      //   map.on("mousemove", l.id, (e) => {
      //     layerTooltip.addTo(map)
      //     layerTooltip.setLngLat(e.lngLat).setHTML("test")
      //   });
      //   map.on("mouseout", l.id, () => {
      //     layerTooltip.remove()
      //   });
      //  }

      l.metadata.layers.forEach((layer) => {
        if (map.getLayer(layer)) map.setLayoutProperty(layer, "visibility", l.metadata.visibility);
      });
      const children = l.children ? l.children : [];
      children.forEach((layer) => {
        if (map.getLayer(layer)) map.setLayoutProperty(layer, "visibility", l.metadata.visibility);
      });
      // setMapIsLoading(false);
    }

    /**
     * FUNCTION FOR UPDATING THE GEOJSON CLUSTER FEATURE - LISTINGS
     * NOT CURRENTLY IN USE
     */

    // function getBBox(e) {
    //   const {_ne, _sw} = e.getBounds();
    //   const bbox = [_sw.lng, _sw.lat, _ne.lng, _ne.lat];
    //   return bbox
    // }

    // async function updateListings(e) {
    //   setMapIsLoading(true)
    //   const _map = this || e
    //   if (_map.getZoom() < 12) {
    //     _map.getSource("listings_cluster").setData({
    //       type: "FeatureCollection",
    //       features: []
    //     });
    //     return
    //   }

    //   const bbox = getBBox(_map);
    //   const url = `https://places.geoace.net/featureserv/collections/public.rets_listings/items.json?limit=100000&bbox=${bbox.join(",")}&apiKey=${apiKey}`;
    //   const data = await fetch(url).then(res => res.json());

    //   // const currentIds = listingsFeatures.features.reduce((i, f) => [i, ...f.properties.listingid], []);
    //   // data.features.forEach(f => {
    //   //   if (!currentIds.includes(f.properties.listingid)) listingsFeatures.features.push(f)
    //   // })

    //   // setTimeout(() => {
    //     _map.getSource("listings_cluster").setData(data)
    //     setMapIsLoading(false);
    //   // }, 100)
    // }

    // updateListings(map)

    // map.on("zoomend", updateListings);
    // map.on("moveend", updateListings)

    /**
     * END CLUSTER UPDATE FUNCITON
     */

    /**
     * MAPBOX GEOCODER
     */

    async function extGeocoder(query, features) {
      console.debug(features);

      //TODO add functionality to search within X radius of current map center
      const mapboxFeatures = features && features.length ? [...features] : [];
      const internalFeatures = [];
      const searchURL = `${apiGateway}/functions/postgisftw.map_geocoder_mls/items.json?q=${query}&access_token=${apiToken}`;
      const data = await fetch(searchURL).then((res) => res.json());
      if (debug) console.debug(data);
      data.features.forEach((feature) => {
        feature["place_name"] = `${
          feature.properties.address.split("|")[0]
        },Listing Price: $${Number(feature.properties.address.split("|")[1]).toLocaleString()}`;
        feature["center"] = feature.geometry.coordinates;
        feature["listing"] = true;
        feature["text"] = feature.properties.address;
        internalFeatures.push(feature);
      });
      return await Promise.resolve([...internalFeatures, ...mapboxFeatures]);
    }

    const geocoderIcon = `
          <svg style="float:left;margin-left:-5px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-map-marker" width="32" height="32" viewBox="0 0 24 24" fill="currentColor"><path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"/></svg>
        `;
    const geocoderMarker = new mapboxgl.Marker({
      draggable: false,
      color: "blue",
    });
    const geocoderPopup = new mapboxgl.Popup({
      offset: 25,
      closeButton: false,
    });
    const geocoderResult = [];

    const geocoder = new Geocoder({
      // localGeocoder: extGeocoder,
      // localGeocoderOnly: true,
      externalGeocoder: extGeocoder,
      countries: "us",
      minLength: 3,
      trackProximity: true,
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      collapsed: true,
      limit: 10,
      zoom: 12,
      // clearOnBlur: true,
      placeholder: "Search by Address",
      marker: false,
      render: function (item) {
        // const maki = item.properties.maki || 'marker';
        const array = item.place_name.split(",");
        const title = array[0];
        array.shift();
        return `
          <div class='mapboxgl-ctrl-geocoder--suggestion ${item.listing ? "listing" : ""}'}>
          ${item.listing ? `${geocoderIcon}` : ""}
            <div class="mapboxgl-ctrl-geocoder--suggestion-title" style="font-size:0.8rem;padding-left:2px;">${title}</div>
            <div class="mapboxgl-ctrl-geocoder--suggestion-address">${array}</div>
          </div>`;
      },
    });

    geocoder.on("loading", () => {
      console.debug("geocoder searching...");
      clearHighlight(map);
    });

    function fireClick() {
      console.debug("emulating click event");
      const e = map.getCenter();
      map.fire("click", {
        latLng: [e.lng, e.lat],
        point: map.project([e.lng, e.lat]),
        originalEvent: {},
      });
      map.off("zoomend", fireClick);
    }

    function queryParcels() {
      console.debug("querying parcels");
      const e = map.getCenter();
      const parcel = map.queryRenderedFeatures(map.project([e.lng, e.lat]), {
        layers: ["parcels-demo"],
      });
      const firstscore = parcel.length ? parcel[0].properties.firstscoreX : null;
      geocoderPopup
        .setHTML(
          `<h6>${geocoderResult[0]}</h6>${
            firstscore ? `<strong>First Score: ${firstscore}</strong>` : ""
          }`
        )
        .setLngLat([e.lng, e.lat])
        .addTo(map);
      map.off("zoomend", queryParcels);
    }

    geocoder.on("result", (e) => {
      geocoderMarker.setLngLat(e.result.center).addTo(map);
      if (geocoderPopup.getElement()) geocoderPopup.remove();

      map.off("zoomend", fireClick);
      if (!e.result) return;
      if ((e.result.relevance || e.result.context || e.result.external) && e.result.place_name) {
        if (e.result.place_name && e.result.place_name.split(","))
          geocoderResult[0] = e.result.place_name.split(",")[0];
        map.once("zoomend", queryParcels);
        return;
      } else {
        //TODO add back loading so that the user cannot click on the map when zooming from a geocoder result
        // loader.classList.add("loading")
        if (map.getLayoutProperty("listings", "visibility") === "none")
          document.querySelector("#listings").parentElement.click();
        map.once("zoomend", fireClick);
      }
    });

    map.on("click", async (e) => {
      // console.debug(e);
      setActiveHPAMetro(null);

      const placesScoreFeatures = map.queryRenderedFeatures(e.point, {
        layers: [
          "cbg-place-score-fill-2024",
          "cbg-place-score-fill-2025",
          "cbg-place-score-fill-2026",
          "cbg-place-score-fill-2027",
          "cbg-place-score-fill-2028",
        ],
      });
      if (placesScoreFeatures.length) {
        const _id = placesScoreFeatures[0].properties.geoid;
        setActivePlace(_id);
        return;
      }

      try {
        if (map.getLayer("places-stroke")) {
          map.setFeatureState(
            {
              source: "places-stroke",
              sourceLayer: "default",
              id: 60730134192,
            },
            { highlighted: false }
          );
        }
        if (geocoderMarker.getElement()) geocoderMarker.remove();
        if (geocoderPopup.getElement()) geocoderPopup.remove();

        document.querySelector(".mapboxgl-ctrl-geocoder--input").value = "";
        if (debug) console.debug(map.queryRenderedFeatures(e.point));

        const places = map.queryRenderedFeatures(e.point, {
          layers: ["places-fill"],
        });
        const areas = map.queryRenderedFeatures(e.point, {
          layers: ["stats_areas"],
        });
        const allFeatures = map.queryRenderedFeatures(e.point);

        const firstFeature = allFeatures.length ? allFeatures[0] : null;
        if (firstFeature && firstFeature.layer.id.includes("metro-hpa-fill")) {
          const _metroName = firstFeature?.properties?.area_name?.split(",")[0];
          if (_metroName) {
            setActiveHPAMetro(_metroName.replace(/_/g, " "));
          }
        }

        if (places.length || areas.length) {
          setActiveLayer("places");
          map.flyTo({
            zoom: map.getZoom(),
            center: e.lngLat,
          });
        }
        if (places.length) {
          console.debug("[map] -- found a place");

          //NOTE set tab index to the proper place/metro
          //  setTabIndex(1);
          sessionStorage.setItem("places-map-dashboard--tab-index", 1);
          popupContentId.id = places[0].id;

          const fetchedFeature = await fetch(
            `${apiGateway}/collections/public.mview_ppl_places_props/items?id=${places[0].id}&access_token=${apiToken}`
          ).then((res) => res.json());
          if (!fetchedFeature.features.length) return;

          const fetchedProps = fetchedFeature.features[0].properties;
          const props = Object.assign(places[0].properties, fetchedProps);
          if (props?.area && getMetros().includes(props.area)) setActiveMetro(props.area);
          console.debug({ props });
          if (props?.place_type) setActiveMapPlaceType(+props.place_type);

          if (debug) console.debug("[map]", props);

          if (props["hh_avg_lor"]) props["hh_avg_lor"] = props["hh_avg_lor"].toFixed(2);
          setPopupContent({
            title: places[0].layer.id,
            properties: props,
            // features: features,
            active: true,
            areaType: "Place",
          });
          setPlacesArea({ name: props.area, areaType: "Place" });
          setPlaceState(places[0]);
          setActivePlace(places[0].properties.geoidint);
          return;
        } else if (areas.length) {
          // setActiveLayer("metros");
          setActiveMapPlaceType();
          console.debug({ areas });
          console.debug(areas[0].properties.area_name);
          if (
            areas[0]?.properties?.area_name &&
            getMetros().includes(areas[0].properties.area_name)
          ) {
            setActiveMetro(areas[0].properties.area_name);
          }
          //  setShowLoader(true);
          //  //NOTE set tab index to the proper place/metro
          //  //NOTE prevent moving to the now removed metro tab
          // //  setTabIndex(0);
          // //  sessionStorage.setItem("places-map-dashboard--tab-index", 0)
          //  console.debug("[map]", areas);
          //   const fetchedProps = await fetch(`${apiGateway}/collections/public.mview_stats_msa/items?&area_name=${areas[0].properties.area_name}&access_token=${apiToken}`).then((res) => res.json());
          //   console.debug({fetchedProps});
          //  const props = fetchedProps.features.length ? Object.assign(areas[0].properties, fetchedProps.features[0].properties) : areas[0].properties
          //  props["area"] = props.area_name;
          //  setPopupContent({
          //    title: areas[0].properties.area_name,
          //    properties: props,
          //    active: true,
          //    areaType: "Area"
          //  });
          //  setPlacesArea({name: props.area_name, areaType: "Area"})
          //  setPlaceState(areas[0]);
          //  setShowLoader(false);
          //  setActivePlace(props.area_name)
        } else {
          console.debug("[map] -- clear popup content");
          console.debug(initialLoad);
          if (!initialLoad) clearPopupContent();
          setPlaceState();
          setActivePlace();
          setActiveMapPlaceType();
        }
      } catch (error) {
        console.debug(error);
        return;
      }
    });

    //ADD CONTROLS
    map.addControl(geocoder, "top-right");
    // addHighlightListener(map, layers); //NOTE TEMPORARILY MOVED TO THE MGLPOPUP FUNCTION
    map.addControl(new mapboxgl.NavigationControl());
    //  map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new MapboxGLFullPage());
    //NOTE REMOVED GRID
    // map.addControl(new MapboxGLH3({
    //   source: "places_analytics",
    //   sourceLayer: "default"
    // }), 'top-right')
    map.addControl(new ExportCSVControl(["listings"]), "top-right");
    map.addControl(placesChoroplethLegend, "bottom-left");
    placesChoroplethLegend.hide();
    map.addControl(placesLegend, "bottom-left");
    layers.forEach((l) => {
      if (l.id === "places-fill" && l.metadata.visibility !== "visible") placesLegend.hide();
    });
    mglPopup(map, layers, clearPopupContent);
    // map.on("contextmenu", clearHighlight); //NOTE TEMPORARILY MOVED TO THE MGLPOPUP FUNCTION
    map.on("contextmenu", clearPopupContent);

    /**
     * FUNCTION TO DOWNLOAD MAP
     */

    // map.on("contextmenu", () => {
    //   downloadMap(map)
    // });

    // function downloadMap(map, name) {
    //   const _this = map;
    //   const height = document.querySelector(".mapboxgl-map").style.height;

    //   _this.once("render", () => {
    //     console.debug("done")
    //     const mapCanvas = _this.getCanvas(document.querySelector('.mapboxgl-canvas'));
    //     let link = document.createElement("a");
    //     link.download = name || "react-map-gl.png";
    //     link.href = mapCanvas.toDataURL();
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     _this.preserveDrawingBuffer = false;
    //     document.querySelector(".mapboxgl-map").style.height = height || "100vh"
    //   })

    //   document.querySelector(".mapboxgl-map").style.height = "99vh"
    //   _this.preserveDrawingBuffer = true;
    //   _this.resize();
    // }

    // map.on("click", (e) => {
    //   //NOTE ANALYTICS
    //   const features = map.queryRenderedFeatures(e.point, { layers: ["places-fill"] });
    //   if (features.length) {
    //     popupContentId.id = features[0].id;
    //     const props = features[0].properties;
    //     if (props["hh_avg_lor"]) props["hh_avg_lor"] = props["hh_avg_lor"].toFixed(2);
    //     setPopupContent({
    //       title: features[0].layer.id,
    //       properties: props,
    //       // features: features,
    //       active: true,
    //     });
    //   } else {
    //     console.debug("[map] -- clear popup content");
    //     console.debug(initialLoad);
    //     if (!initialLoad) clearPopupContent();
    //   }
    // });

    // Create a popup, but don't add it to the map yet.
    const tooltip = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      trackPointer: true,
    });

    let tooltipTimeout;
    //  let placesAnalyticsId;

    //  //NOTE TOOLTIP
    //  map.on("mousemove", "places-fill", (e) => {
    //    if (placesAnalyticsId === e.features[0].properties.id) {
    //      tooltip.setLngLat(e.lngLat)
    //    }else{
    //      tooltip.remove()
    //      clearTimeout(tooltipTimeout)
    //      const coordinates = e.lngLat;
    //      placesAnalyticsId = e.features[0].properties.id;
    //      const description = `
    //        <div class="tooltip" style="display:flex">
    //        <div class="circle-outer"><div class="circle-inner">Places Score: 7.5</div></div>
    //        <div class="circle-outer2"><div class="circle-inner">Forward Score: 9.5</div></div>
    //        </div>
    //      `;
    //      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    //        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    //      }
    //      tooltip.setLngLat(coordinates).setHTML(description);
    //      // document.querySelector(".mapboxgl-popup-content h3").style.
    //      tooltipTimeout = setTimeout(() => {
    //        tooltip.addTo(map)
    //      }, 200)
    //    }
    //  });

    map.on("mouseleave", "places-fill", () => {
      //  placesAnalyticsId = null;
      tooltip.remove();
      clearTimeout(tooltipTimeout);
    });

    //FIX MOBILE MAP SIZE WITH URL BAR THEN HIDDEN URL BAR
    window.onload = () => {
      document.querySelector("#root").children[1].style.height = window.innerHeight + "px";
      map.resize();
    };

    window.onresize = () => {
      document.querySelector("#root").children[1].style.height = window.innerHeight + "px";
      map.resize();
    };

    setMapIsLoaded(true);
    if (initialLoad) {
      map.setFeatureState(
        { source: "places-stroke", sourceLayer: "default", id: 60730134192 },
        { highlighted: true }
      );
    }
    console.debug("[map] -- loaded", Date.now() - now);
    setTimeout(() => {
      initialLoad = false;
    }, 4000);
  };

  // useEffect(() => {
  //   //loaded
  //   if (!apiData.length || mapIsLoading || !map || !map.getStyle()) return
  //   console.debug({ apiData });

  //   //TODO CONVERT TO A PARAM
  //   const filtered = apiData.filter(e => e.date_value === 2022)

  //   const avgYearlyDays = filtered.reduce((i, e) => [...i, e[statField.fs]], []);
  //   const _limits = chroma.limits(avgYearlyDays, "q", 9);
  //   const limits = ckmeans(avgYearlyDays, 6).map(function(cluster) {
  //     return cluster[0];
  //   });
  //   console.debug(limits, _limits)
  //   //#fcde9c,#faa476,#f0746e,#e34f6f,#dc3977,#b9257a,#7c1d6f - carto
  //   //#ffffb2 #fecc5c #fd8d3c #f03b20 #bd0026 - color brewer

  //   // const colors = chroma.scale(['#fcde9c','#e34f6f','#7c1d6f']);
  //   // const colors = chroma.scale(["#fee5d9","#a50f15"])
  //   const colors = chroma.scale(["#ffffb2", "#fd8d3c", "#bd0026"]).mode("lch").colors(limits.length);

  //   const paint = [
  //     "case",
  //     ["!", ["has", statField.ts]],
  //     "rgba(0,0,0,0.2)",
  //     ["==", ["get", statField.ts], 0],
  //     "whitesmoke",
  //   ];
  //   limits.forEach((v, i) => {
  //     paint.push(["<", ["get", statField.ts], v], colors[i]);
  //   });
  //   paint.push("whitesmoke");
  //   console.debug(paint)
  //   // map.setPaintProperty("places_analytics", "fill-color", paint);

  //   layers.forEach((l) => {
  //     //VANILLA JS LEGEND FOR PLACES CHOROPLETH
  //     if (l.id === "places_analytics") {
  //       if (l.metadata.visibility === "visible") {
  //         placesChoroplethLegend.show("places_analytics", statField.name);
  //       } else {
  //         placesChoroplethLegend.hide();
  //       }
  //     }
  //   });
  // }, [apiData, map, mapIsLoading, statField, layers]);

  useEffect(() => {
    if (mapStateRef.current || !mapState || !mapState.center || !apiToken) {
      const __types = placesTypes.map((e) => e.toString());
      if (mapStateRef.current) {
        mapStateRef.current.setFilter("places-fill", [
          "in",
          ["get", "place_type"],
          ["literal", __types],
        ]);
      }
      return;
    }

    console.debug("[map] -- init");
    // fetch(`https://api.placesplatform.net/tiles/postgisftw.places/9/89/206.pbf?access_token=${apiToken}`, {})
    //   .then((res) => res.arrayBuffer())
    //   .then(() => {});

    //  fetch(
    //    `https://fs-1en3jhvt.uc.gateway.dev/functions/postgisftw.get_listing_stats_ii/items.json?i_place_type=mls&i_date_type=monthly&i_min_date_value=2021&i_max_date_value=2022&i_places_area_id=1&access_token=${apiToken}`
    //  )
    //    .then((res) => res.json())
    //    .then((data) => console.debug(data));

    const mapboxMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: colorMode === 'light' ? "mapbox://styles/mapbox/dark-v10" : "mapbox://styles/lancasteroh/cl09ro2h6000m15noun89eecx",
      style: colorMode === "light" ? mapStyles.light : mapStyles.dark,
      center: mapState.center,
      zoom: mapState.zoom,
      // minZoom: 4.8,
      hash: false,
      //TODO change this to change this setting then reload the map
      preserveDrawingBuffer: true,
      transformRequest: (url, resourceType) => {
        if (resourceType === "Tile" && url.indexOf(apiGateway) > -1) {
          return {
            url: (url += `&access_token=${apiToken}`),
          };
        }
      },
    });

    mapStateRef.current = mapboxMap;

    //SET MAP STATE IN STORE SO THAT IT IS AVAILABLE ACROSS THE APP AND CAUSES EFFECTS
    mapboxMap.on("zoomend", () => {
      if (debug) console.debug({ mapZoom: mapboxMap.getZoom() });

      setMapState({
        zoom: mapboxMap.getZoom(),
        center: getCenter(mapboxMap),
      });
    });

    mapboxMap.on("moveend", () => {
      const center = getCenter(mapboxMap);
      setMapState({
        zoom: mapboxMap.getZoom(),
        center: center,
      });
      const inside = placesAreaConvexH.features.find((f) =>
        turf.booleanPointInPolygon(turf.point(center), f)
      );
      const closest = placesAreaConvexH.features.reduce((prev, curr) => {
        const prevDist = turf.distance(turf.point(center), turf.center(prev));
        const currDist = turf.distance(turf.point(center), turf.center(curr));
        return prevDist < currDist ? prev : curr;
      });
      console.debug(inside?.properties?.places_area);
      console.debug(closest?.properties?.places_area);

      if (!inside && !closest) return;
      console.debug(`{demo-map} -- closest area: ${closest.properties.places_area}`);
      if (inside) {
        setInFocusMetro(inside?.properties?.places_area);
      } else if (closest) {
        setInFocusMetro(closest?.properties?.places_area);
      }
    });

    /************/
    //MAP LOADER//
    /************/
    mapboxMap.on("render", mapCheckRender);

    function mapCheckRender() {
      setMapIsLoading(true);
      if (!mapboxMap.loaded()) {
        return;
      }
      //NOTE TURN OFF IF YOU JUST WANT IT FOR THE INITIAL LOADING EVENT
      mapboxMap.off("render", mapCheckRender);
      setMapIsLoading(false);
    }
    /************/
    /************/

    mapboxMap.on("load", async () => {
      console.debug("[map] -- load");
      setMapIsLoading(true);
      if (debug) console.debug({ mapStyle: mapboxMap.getStyle().layers });
      // setMapboxMap(() => mapboxMap);
      setMap(mapboxMap);
      setMapStore(mapboxMap);
      // console.debug(apiToken)

      await mapInitEventHandlers(mapboxMap);

      // mapboxMap.on("moveend", (e) => {
      //   //LISTINGS ANALYTICS
      //   // listingsAnalytics(e);
      //   // getListingsByYear(e);
      //   // getDaysOnMarket(e)
      // });

      // setTimeout(() => {
      //   // listingsAnalytics({ target: mapboxMap });
      //   // getListingsByYear({target: mapboxMap})
      //   // getDaysOnMarket({target: mapboxMap})
      // }, 1000);

      //  setTimeout(function () {

      //  }, 50);
      setTimeout(function () {
        // if (initialLoad) {
        //   if (mapState.center[0] === -116.96902) {
        //     mapboxMap.fire("click", {
        //       latLng: [-116.96902, 32.65399],
        //       point: mapboxMap.project([-116.96902, 32.65399]),
        //       originalEvent: {},
        //     });
        //   } else {
        //     mapboxMap.fire("click", {
        //       latLng: getCenter(mapboxMap),
        //       point: mapboxMap.project(getCenter(mapboxMap)),
        //       originalEvent: {},
        //     });
        //   }
        // }
        setMapIsLoading(false);
      }, 1000);
    });
    //  return () => {
    //    if (mapboxMap) {
    //      mapboxMap.remove();
    //      setMap(null);
    //    }
    //  };
    //destroy map on unmount
    //eslint-disable-next-line
    // return () => {
    //   mapboxMap.remove();
    //   //also invalidate ref and state
    //   mapStateRef.current = null;
    //   // setMapState(null);
    // };
  }, [colorMode, apiToken, activeMetro, placesTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchGridData(map) {
    const otherModals = document.querySelectorAll(".chakra-modal__close-btn");
    otherModals.forEach((m) => {
      m.click();
    });

    setTimeout(() => {
      setMapIsLoading(true);
      getData();
    }, 100);

    async function getData() {
      const data = await fetch(
        `${apiGateway}/tiles/postgisftw._ts_geobuf_yardi/0/0/0.pbf?_msa=ALL&access_token=${apiToken}`
      ).then((res) => res.arrayBuffer());
      const yardi = geobuf.decode(new Pbf(data));
      // https://places.geoace.net/featureserv/functions/postgisftw.commercial_h3_stats/items.json?h3_level=8
      // const yardi2 = await fetch("https://places.geoace.net/featureserv/collections/public.vw_test_i/items.json?limit=1000000&apiKey="+apiKey).then(res => res.json());
      // var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(yardi2));
      // const el = document.createElement("a");
      // el.setAttribute("href",     dataStr     );
      // el.setAttribute("download", "data.geojson");
      // document.body.appendChild(el)
      // el.click();

      console.debug(yardi.features.length);
      // console.debug(yardi)
      const h3Grid = {
        type: "FeatureCollection",
        features: [],
      };

      const grids = [];
      const gridValues = {};
      const allValues = [];
      let index = 0;
      yardi.features.forEach((f) => {
        if (!grids.includes(f.properties.h3_7)) {
          index = index + 1;
          h3Grid.features.push({
            type: "Feature",
            id: index,
            geometry: {
              type: "Polygon",
              coordinates: [h3.cellToBoundary(f.properties.h3_7, true)],
            },
            properties: {
              id: index,
              grid: f.properties.h3_7,
              avg_grid_rent: 0,
              sample_size: 0,
            },
          });
          grids.push(f.properties.h3_7);
        }
        allValues.push(f.properties.avrent);
        if (!gridValues[f.properties.h3_7]) {
          gridValues[f.properties.h3_7] = [f.properties.avrent];
        } else {
          gridValues[f.properties.h3_7].push(f.properties.avrent);
        }
      });

      h3Grid.features.forEach((f) => {
        const avg =
          gridValues[f.properties.grid].reduce((i, v) => i + v) /
          gridValues[f.properties.grid].length;
        f.properties["avg_grid_rent"] = +avg.toFixed(2);
        f.properties["sample_size"] = gridValues[f.properties.grid].length / 21;
      });

      const limits = ckmeans(allValues, 6).map(function (cluster) {
        return cluster[0];
      });
      console.debug(limits);
      const colors = chroma
        .scale(["#009B9E", "#42B7B9", "#A7D3D4", "#D691C1", "#C75DAB"])
        .mode("lch")
        .colors(limits.length);
      map.getSource("h3-commercial-grid").setData(h3Grid);
      map.setPaintProperty("h3-commercial-grid", "fill-color", [
        "case",
        ["!", ["has", "avg_grid_rent"]],
        "rgba(0,0,0,0.2)",
        ["==", ["get", "avg_grid_rent"], limits[0]],
        colors[0],
        ["<", ["get", "avg_grid_rent"], limits[1]],
        colors[1],
        ["<", ["get", "avg_grid_rent"], limits[2]],
        colors[2],
        ["<", ["get", "avg_grid_rent"], limits[3]],
        colors[3],
        ["<", ["get", "avg_grid_rent"], limits[4]],
        colors[4],
        ["<", ["get", "avg_grid_rent"], limits[5]],
        colors[5],
        "whitesmoke",
      ]);
      setMapIsLoading(false);
    }
  }

  function toggleLayers() {
    if (!mapIsLoading && map.getStyle() && layers) {
      console.debug("toggle layers");
      setMapIsLoading(true);

      layers.forEach((l) => {
        //VANILLA JS LEGEND FOR PLACES CHOROPLETH
        if (l.id === "places-fill") {
          if (l.metadata.visibility === "visible") {
            placesLegend.show();
          } else {
            placesLegend.hide();
          }
        }

        //TEMP GRID FETCHING
        // console.debug(map.getSource("h3-commercial-grid"))
        if (
          l.id === "h3-commercial-grid" &&
          l.metadata.visibility === "visible" &&
          !map.getSource("h3-commercial-grid")._data.features.length
        ) {
          console.debug("fetching grid data");
          fetchGridData(map);
        }

        //CHILDFITLERS
        const childFilters = l.childFilters ? l.childFilters : [];

        //NOTE THIS ASSUMES THESE CHILD FILTERS ARE THE ONLY FILTER FOR THE PARENT LAYERS
        if (childFilters.length) {
          let active = false;
          const filterValues = [];

          childFilters.forEach((child) => {
            if (child.active) {
              //SET THE FILTER TO ACTIVE WHICH WILL APPLY THE FILTER LATER
              // console.debug(child, child.active)
              active = true;
            } else {
              //ADD ALL THE INACTIVE FILTERS TO THE FILTER = WHICH IS AN "IN" FILTER
              if (Number(child.value)) {
                filterValues.push(Number(child.value));
              } else {
                filterValues.push(child.value);
              }
            }
          });
          // console.debug(filterValues)

          const parents = [];
          childFilters.forEach((obj) => {
            obj.parents.forEach((p) => {
              if (!parents.includes(p)) parents.push(p);
            });
          });

          if (active) {
            const filter = ["in", ["get", childFilters[0].field], ["literal", filterValues]];
            const currentFilter = map.getFilter(childFilters[0].parents[0]);
            //NOTE HERE IS THE CODE THAT WOULD NEED CHANGED AS WE ARE SETTING A NEW FILTER ON THE WHOLE LAYER WHICH WOULD WIPE OUT ANY OTHER FILTER
            //WE WOULD NEED TO SAVE THIS IN SOME LAYER STATE LIKE A React.useContext() AND THEN BASE THE FILTER ON VALUES STORED THERE
            const mapFilter =
              currentFilter && currentFilter[0] === "all"
                ? [...map.getFilter(childFilters[0].parents[0]), filter]
                : filter;
            // console.debug(mapFilter);
            parents.forEach((l) => map.setFilter(l, mapFilter));
          } else {
            parents.forEach((l) => map.setFilter(l));
          }
          // getDaysOnMarket({target: map});
          // getListingsByYear({target: map});
          // dataState.setDataStore(localDataStore)
        }

        const currentZoom = map.getZoom();
        let minZoom = 0;
        let visible = false;

        //VISIBILITY TOGGLE FOR PARENT LAYERS
        l.metadata.layers.forEach((layer) => {
          if (map.getLayer(layer)) {
            const layerVisibility = map.getLayoutProperty(layer, "visibility");
            // console.debug(layer, layerVisibility, l.metadata.visibility);
            if (layerVisibility !== l.metadata.visibility) {
              map.setLayoutProperty(layer, "visibility", l.metadata.visibility);
            }

            if (layerVisibility !== l.metadata.visibility && l.metadata.visibility === "visible") {
              visible = true;
              minZoom = map.getLayer(layer).minzoom || 0;
            }

            if (l.metadata.visibility === "none") {
              clearHighlight(map, l.id);
              if (l.id === popupContentId.id) {
                // clearPopupContent();
                popupContentId.id = null;
              }
            }
          }
        });

        //VISIBILITY TOGGLE FOR CHILD LAYERS
        const _children = l.children ? l.children : [];

        //CHILDREN
        _children.forEach((child) => {
          if (map.getLayer(child.id)) {
            map.setLayoutProperty(child.id, "visibility", child.visibility);
            if (child.visibility === "visible") {
              visible = true;
              minZoom = map.getLayer(child.id).minzoom || 0;
            }
          }
        });

        if (visible && minZoom > currentZoom) {
          toast({
            title: "Layer not visible at current zoom level.",
            description: "Zoom in further to view the layer.",
            position: "bottom-right",
            status: "info",
            duration: 2000,
            isClosable: true,
            variant: "top-accent",
          });
        }
      });
      setMapIsLoading(false);
    }
    localStorage.setItem("configLayers", JSON.stringify(layers));
  }

  /**
   * LOGIC IS COMPLICATED BY LAYERS SOMETIMES ALREADY HAVING FILTERS IN THE STYLE AND SOME LAYERS HAVING MULTIPLE FILTERS
   */
  function buttonFiltersInit() {
    if (debug) console.debug({ buttonFiltersState });
    try {
      //loaded
      if (!mapIsLoading && map.getStyle() && layers) {
        buttonFiltersState.forEach((f, i) => {
          // console.debug(f.layer);
          //loaded
          if (!mapIsLoading && map.getLayer(f.layer)) {
            const filter = map.getFilter(f.layer);
            // console.debug({ filter });

            //GET ORIGINAL FILTER AND SAVE FOR LATER - DONT ACTUALLY NEED THIS
            if (!originalFilter[f.layer])
              setOriginalFilter((state) => {
                if (!state[f.layer]) {
                  const _state = Object.assign(state);
                  if (!_state[f.layer]) {
                    _state[f.layer] = filter;
                  }
                  return _state;
                }
                return state;
              });

            // console.debug({originalFilter})
            const layerFilter = ["all", ["==", ["get", f.field], f.value]];

            //CHECK IF CURRENT FILTER = LAYER FILTER - MEANING OUR FILTER WAS APPLIED TO A NON-FILTERED LAYER
            if (
              filter &&
              layerFilter.toString().replace(/ /g, "") === filter.toString().replace(/ /g, "")
            ) {
              // console.debug("current filter === original filter");
              map.setFilter(f.layer);
              return;
            }

            //IF THERE IS NO FILTER, THEN APPLY THE LAYER FILTER
            if (!filter) {
              map.setFilter(f.layer, layerFilter);
              return;
            }

            //IF THERE IS A FILTER THE CURRENT FILTER DOES NOT MATCH THE LAYER FILTER
            if (
              filter &&
              layerFilter.toString().replace(/ /g, "") !== filter.toString().replace(/ /g, "")
            ) {
              // console.debug("current filter != layer filter");

              //CONSTRUCT NEW FILTER
              const newFilter = ["all"];

              if (originalFilter[f.layer]) {
                // console.debug("pushing original filter");
                newFilter.push(originalFilter[f.layer]);
              } else {
                // console.debug("new filter is just filter");
                newFilter.push(filter);
              }
              newFilter.push(["==", ["get", f.field], f.value]);

              // console.debug(newFilter)
              //IF THE NEW FILTER MATCHED THE CURRENT FILTER OUR FILTER IS APPLIED AND WE NEED TO REVERT TO THE ORIGINAL FILTER
              if (newFilter.toString().replace(/ /g, "") === filter.toString().replace(/ /g, "")) {
                // console.debug("new filter = filter");
                map.setFilter(f.layer, originalFilter[f.layer]);
                return;
              }

              // console.debug(f)

              //IF THE NEW FILTER DOES NOT MATCH THE CURRENT FILTER WE NEED TO APPLY THE FILTER IF THE FILTER IS SET TO "ON"
              if (
                newFilter.toString().replace(/ /g, "") !== filter.toString().replace(/ /g, "") &&
                f.on === true
              ) {
                // console.debug("new filter != filter and on = true");
                map.setFilter(f.layer, newFilter);
              }
            }
          }
        });
      }
    } catch (err) {
      console.debug(err);
    }
  }

  //NOTE - does not cause an initial map refresh
  useEffect(() => {
    toggleLayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, colorMode, statField, mapIsLoaded]);

  //NOTE - does not cause an initial map refresh
  useEffect(() => {
    buttonFiltersInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonFiltersState]);

  useEffect(() => {
    if (!apiToken) return;
    console.debug("[map] -- inFocusMetro changed", inFocusMetro);
    (async () => {
      try {
        let localBounds;
        if (!placesHPABounds.length) {
          const placesHPAMinMax = await fetch(
            `${apiGateway}/functions/postgisftw.get_places_hpa_minmax/items.json?access_token=${apiToken}&limit=10000`
          ).then((res) => res.json());
          console.debug(placesHPAMinMax);
          setPlacesHPABounds(placesHPAMinMax);
          localBounds = placesHPAMinMax;
        } else {
          localBounds = placesHPABounds;
        }
        console.debug(localBounds);
        //          hpaColorRamp = getHPACBGColorRamp(placesHPAMinMax, inFocusMetro, "2023");
        const years = [2024, 2025, 2026, 2027, 2028];
        years.forEach((year) => {
          if (map && map.getLayer(`places-hpa-fill-${year}`)) {
            const normalizedMetroName = fuzzySearch(localBounds, inFocusMetro, "area_name");
            if (!normalizedMetroName) return;
            console.debug(`[map] -- setting places-hpa-fill-${year} color ramp`);
            const colorRamp = getHPACBGColorRamp(localBounds, normalizedMetroName.area_name, year);
            map.setPaintProperty(`places-hpa-fill-${year}`, "fill-opacity", 0.4);
            map.setPaintProperty(`places-hpa-fill-${year}`, "fill-color", colorRamp);
          }
        });
      } catch (error) {
        console.debug("[demo-map] inFocus fetch call error", error);
      }
    })();
    //eslint-disable-next-line
  }, [inFocusMetro]);

  return (
    // <Box h={"100%"} w={"calc(100% * 1)"} minH="calc(50vh - 60px)" borderRadius={10} ref={mapContainerRef}>
    //   <Box zIndex={3} position="absolute" display={"flex"} flexDir="column">
    //     <MapLayerControlModal
    //     //  layers={layers}
    //     //  setLayers={setLayers}
    //     />
    //     <PropertyFilterFormModal />
    //   </Box>
    //   {mapIsLoading ? <Loader isOpen={mapIsLoading}></Loader> : <></>}
    // </Box>
    <>
      {apiToken ? (
        <Box
          h={"100%"}
          w={"100%"}
          minH="40vh"
          borderRadius={0}
          ref={mapContainerRef}
          position={"relative"}
        >
          <Box zIndex={3} position="absolute">
            <Flex>
              <MapLayerControlModal />
              <MapActiveHPAMetroTitle />
            </Flex>
            <PropertyFilterFormModal />
          </Box>
          <Box
            alignItems="center"
            flex={1}
            justifyContent={"center"}
            height={"100%"}
            display={showLoader ? "flex" : "none"}
          >
            <Box
              height={"100%"}
              width={"100%"}
              background="whiteAlpha.600"
              position={"absolute"}
              zIndex={1}
            >
              &nbsp;
            </Box>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              zIndex={2}
              display={showLoader ? "block" : "none"}
            />
          </Box>

          {/* {mapIsLoading ? <Loader isOpen={mapIsLoading}></Loader> : <></>} */}
          {/* {showLoader ? (
            <Loader isOpen={showLoader} id="map-loader"></Loader>
          ) : (
            <></>
          )} */}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

const MapActiveHPAMetroTitle = () => {
  const { activeHPAMetro } = useAppState();
  return (
    <>
      {activeHPAMetro && (
        <Heading
          as="h3"
          bg="white"
          size="sm"
          m={2}
          borderRadius={3}
          display={"flex"}
          alignItems={"center"}
          px={2}
          shadow={"sm"}
        >
          {activeHPAMetro || ""} {activeHPAMetro ? "MSA" : ""}
        </Heading>
      )}
    </>
  );
};

function getCenter(map) {
  const { lng, lat } = map.getCenter();
  return [lng, lat];
}

export default memo(Map);
