// const busIcon = `data:image/svg+xml,<svg viewBox="0 0 15 15" height="15" width="15"><title>bus.svg</title><rect fill="none" x="0" y="0" width="15" height="15"></rect><path fill="#0000FF" transform="translate(0 0)" d="M4,0C2.6364,0,1,0.7433,1,2.7461v5.4531V12c0,0,0,1,1,1v1c0,0,0,1,1,1s1-1,1-1v-1h7v1c0,0,0,1,1,1s1-1,1-1v-1
// c0,0,1,0,1-1V2.7461C14,0.7006,12.764,0,11.4004,0H4z M4.25,1.5h6.5C10.8885,1.5,11,1.6115,11,1.75S10.8885,2,10.75,2h-6.5
// C4.1115,2,4,1.8885,4,1.75S4.1115,1.5,4.25,1.5z M3,3h9c1,0,1,0.9668,1,0.9668V7c0,0,0,1-1,1H3C2,8,2,7,2,7V4C2,4,2,3,3,3z M3,10
// c0.5523,0,1,0.4477,1,1s-0.4477,1-1,1s-1-0.4477-1-1S2.4477,10,3,10z M12,10c0.5523,0,1,0.4477,1,1s-0.4477,1-1,1s-1-0.4477-1-1
// S11.4477,10,12,10z"></path></svg>`

const busIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' height='15' width='15' %3E%3Ctitle%3Ebus.svg%3C/title%3E%3Crect fill='none' x='0' y='0' width='15' height='15'%3E%3C/rect%3E%3Cpath fill='%230000FF' transform='translate(0 0)' d='M4,0C2.6364,0,1,0.7433,1,2.7461v5.4531V12c0,0,0,1,1,1v1c0,0,0,1,1,1s1-1,1-1v-1h7v1c0,0,0,1,1,1s1-1,1-1v-1
c0,0,1,0,1-1V2.7461C14,0.7006,12.764,0,11.4004,0H4z M4.25,1.5h6.5C10.8885,1.5,11,1.6115,11,1.75S10.8885,2,10.75,2h-6.5
C4.1115,2,4,1.8885,4,1.75S4.1115,1.5,4.25,1.5z M3,3h9c1,0,1,0.9668,1,0.9668V7c0,0,0,1-1,1H3C2,8,2,7,2,7V4C2,4,2,3,3,3z M3,10
c0.5523,0,1,0.4477,1,1s-0.4477,1-1,1s-1-0.4477-1-1S2.4477,10,3,10z M12,10c0.5523,0,1,0.4477,1,1s-0.4477,1-1,1s-1-0.4477-1-1
S11.4477,10,12,10z'%3E%3C/path%3E%3C/svg%3E`

// const fireIconOrig = `data:image/svg+xml,<svg viewBox="0 0 19 19" height="19" width="19"><title>fire-station.svg</title><rect fill="none" x="0" y="0" width="19" height="19"></rect><rect x="0" y="0" width="19" height="19" rx="4" ry="4" fill="#fff"></rect><path fill="#b22" transform="translate(2 2)" d="M7.5,0.5L5,4.5l-1.5-2
// C2.9452,3.4753,0.8036,5.7924,0.8036,8.3036C0.8036,12.002,3.8017,15,7.5,15s6.6964-2.998,6.6964-6.6964
// c0-2.5112-2.1416-4.8283-2.6964-5.8036l-1.5,2L7.5,0.5z M7.5,7c0,0,2.5,2.5618,2.5,4.5c0,0.8371-0.8259,2-2.5,2S5,12.3371,5,11.5
// C5,9.6283,7.5,7,7.5,7z"></path></svg>`

const fireIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19' height='24' width='24'%3E%3Ctitle%3Efire-station.svg%3C/title%3E%3Crect fill='none' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Crect x='0' y='0' width='19' height='19' rx='4' ry='4' fill='%23fff'%3E%3C/rect%3E%3Cpath fill='%23b22' transform='translate(2 2)' d='M7.5,0.5L5,4.5l-1.5-2
C2.9452,3.4753,0.8036,5.7924,0.8036,8.3036C0.8036,12.002,3.8017,15,7.5,15s6.6964-2.998,6.6964-6.6964
c0-2.5112-2.1416-4.8283-2.6964-5.8036l-1.5,2L7.5,0.5z M7.5,7c0,0,2.5,2.5618,2.5,4.5c0,0.8371-0.8259,2-2.5,2S5,12.3371,5,11.5
C5,9.6283,7.5,7,7.5,7z'%3E%3C/path%3E%3C/svg%3E`

/* <svg viewBox="0 0 15 15" height="15" width="15"><title>circle-stroked.svg</title><rect fill="none" x="0" y="0" width="15" height="15"></rect><path fill="#333" transform="translate(0 0)" d="M7.5,0C11.6422,0,15,3.3578,15,7.5S11.6422,15,7.5,15
	S0,11.6422,0,7.5S3.3578,0,7.5,0z M7.5,1.6666c-3.2217,0-5.8333,2.6117-5.8333,5.8334S4.2783,13.3334,7.5,13.3334
	s5.8333-2.6117,5.8333-5.8334S10.7217,1.6666,7.5,1.6666z"></path></svg> */

const circle = (fill, stroke) => {
  const svg = `<svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 18 18" height="18" width="18"><title>circle.svg</title><rect fill="none" x="0" y="0" width="18" height="18"></rect><path stroke="${stroke ? stroke : 'black'}" stroke-width="2" fill="${fill ? fill : 'white'}" transform="translate(0 0)" d="M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z"></path></svg>`
  return `data:image/svg+xml, ${svg.replace(/</g, "%3C").replace(/>/g,"%3E").replace(/#/g, "%23")}`
} 

const square = (fill, stroke) => {
  const svg = `<svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 15 15" height="15" width="15"><title>square.svg</title><rect fill="none" x="0" y="0" width="15" height="15"></rect><path stroke="${stroke ? stroke : 'white'}" stroke-width="2" fill="${fill ? fill : 'white'}" transform="translate(0 0)" d="M13,14H2c-0.5523,0-1-0.4477-1-1V2c0-0.5523,0.4477-1,1-1h11c0.5523,0,1,0.4477,1,1v11C14,13.5523,13.5523,14,13,14z"></path></svg>`
  return `data:image/svg+xml, ${svg.replace(/</g, "%3C").replace(/>/g,"%3E").replace(/#/g, "%23")}`
}

export {
  busIcon, fireIcon, circle, square
}