import { Box } from "@chakra-ui/react";
import { GPTTable } from "../Tables/GPTTable";
import { apiGateway } from "../../config";
import { useAccountStore } from "../../context/accountStore";
import { useEffect } from "react";
import { useState } from "react";

export const DeveloperDashboard = () => {
  const account = useAccountStore(state => state.account)
  const [backend, setBackend] = useState([]);

  useEffect(() => {
    if (!account) return
    if (!account.app_metadata) return
    if (!account.app_metadata.roles.length) return
    const roles = ["admin", "developer"];
    let authorized = false;
    account.app_metadata.roles.forEach(role => {
      if (roles.includes(role)) authorized = true
    })
    if (!authorized) return
    (async () => {
      const { features } = await fetch(`${apiGateway}/collections/public.vw_comments/items?limit=10000&&access_token=${account.gcpToken}`).then(res => res.json());
      setBackend(features.reduce((i,v) => [...i, v.properties], []))
    })()
  }, [account])

  return (
    <Box maxW="1800px" margin="0 auto">
      <GPTTable size="sm" sortable={false} data={backend.length ? backend : []} fields={[
        {
          alias: "Name",
          field: "name"
        },
        {
          alias: "type",
          field: "type"
        },
        {
          alias: "Comment",
          field: "comment"
        }
      ]} pageLength={400} sortField="name"></GPTTable>
    </Box>  
  )
}
