import { AddIcon, CloseIcon, DownloadIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Radio,
  RadioGroup,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Spacer,
  Stack,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { Buffer } from "buffer";
import { useAccountStore } from "../../context/accountStore";

export const PortfolioManager = ({ hideTitle }) => {
  const fileUploader = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  console.log("isReady", isReady);
  const [csvContent, setCsvContent] = useState(
    "32.7112672, -117.1652773, location 1\n32.7509677, -117.170624, location 2"
  );
  const [dateType, setDateType] = useState("yearly");
  const [hasLocationErrors, setHasLocationErrors] = useState(false);
  const [sliderValue, setSliderValue] = useState([
    2020,
    new Date().getFullYear(),
  ]);
  const { exportPortfolioStats } = useAccountStore((state) => state);
  const isValidLatitude = (value) =>
    typeof value === "number" && value <= 90 && value >= -90;
  const isValidLongitude = (value) =>
    typeof value === "number" && value <= 180 && value >= -180;

  // const queryPortfolioStats = async (date_type, min_date, max_date, locations) => {
  //   const data = locations

  //   // let url = `${apiGateway}/portfolio/places_stats?date_type=${date_type}&min_date=${min_date}&max_date=${max_date}&access_token=${apiToken["apiToken"]}`
  //   let apiEndpoint = `/portfolio/places_stats?date_type=${date_type}&min_date=${min_date}&max_date=${max_date}&access_token=${apiToken["apiToken"]}`

  //   const response = await fetch(url, {
  //     method: "POST", // *GET, POST, PUT, DELETE, etc.
  //     mode: 'cors',
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     },

  //     body: (!!data) ? JSON.stringify(data) : "" // body data type must match "Content-Type" header
  //   })
  //   if(response.status >= 400 && response.status < 600){
  //     const text = await response.text()
  //     throw text;
  //   }

  //   const json = await response.json()
  //   return json
  // }

  const getDataParts = async (mindate, maxdate, datetype, locations) => {
    const results = [];

    await locations.reduce(async (promise, location) => {
      await promise;
      if (!!location) {
        const latitude = location.split(",")[0].trim();
        const longitude = location.split(",")[1].trim();
        const name = location.split(",")[2].trim();

        const locations = {
          locations: [{ longitude: longitude, latitude: latitude, name: name }],
        };

        try {
          const data = await exportPortfolioStats(
            datetype,
            mindate,
            maxdate,
            locations
          );
          results.push(data);
        } catch (ex) {
          console.log`Error getting data ${ex}`;
        }
      }
    }, Promise.resolve());

    return results;
  };

  const downloadTemplate = () => {
    const csv = [
      "32.602778, -117.038131, Test 1",
      "32.853203, -116.981185, Example 2",
      "32.614758, -116.980056, That place over there",
      "32.652156, -116.977106, 1 Riverside Plaza",
      "32.611656, -116.971827, 1600 Pennsylvania Ave NW",
      "32.624469, -116.951255, location1",
      "32.618391, -116.460719, Other place",
    ].join("\r\n");

    const blob = new Blob([csv], { type: "text/csv" });
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = "portfolioStatsTemplate.csv";
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again  `
  };

  function* getChunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }

  const getData = async () => {
    const start = Date.now();
    setIsLoading(true);
    let mindate = sliderValue[0];
    let maxdate = sliderValue[1];

    if (dateType !== "yearly") {
      mindate += "01";
      maxdate += "12";
    }

    let results = [];
    const locations = csvContent.split("\n");
    const chunks = [...getChunks(locations, locations.length / 2)];

    try {
      const resultPromises = await Promise.allSettled(
        chunks.map(
          (chunk) =>
            new Promise((resolve) => {
              getDataParts(mindate, maxdate, dateType, chunk).then((values) =>
                resolve(values)
              );
            })
        )
      );

      resultPromises.forEach((values) => {
        results.push(values.value.flatMap((v) => v));
      });

      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const fresults = results.flatMap((r) => r);
      const header = Object.keys(fresults[0]);

      const csv = [
        header.join(","), // header row first
        ...fresults.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      const blob = new Blob([csv], { type: "text/csv" });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "portfolioStats.csv";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again  `
    } catch (ex) {
      console.log`Error getting data ${ex}`;
    }
    console.log(`Execution time: ${(Date.now() - start) / 1000} seconds`);
    setIsLoading(false);
  };

  const validateLocations = (locations) => {
    let newLocations = "";
    setHasLocationErrors(false);
    locations.split("\n").forEach((location) => {
      if (!!location) {
        try {
          const _lat = location.split(",")[0];
          const _long = location.split(",")[1];

          let name = location.replace(`${_lat},${_long}`, "");
          name = !!name ? name.substr(1).trim() : "";

          const latitude = parseFloat(_lat.trim());
          const longitude = parseFloat(_long.trim());
          // let name = (location.split(',').length > 2) ? location.split(',')[2].trim() : ""

          newLocations += latitude;
          if (!isValidLatitude(latitude)) {
            newLocations += " (invalid)";
            setHasLocationErrors(true);
          }

          newLocations += `, ${longitude}`;
          if (!isValidLongitude(longitude)) {
            newLocations += " (invalid)";
            setHasLocationErrors(true);
          }

          newLocations += `, ${name}`;
        } catch (e) {
          newLocations += e;
          setHasLocationErrors(true);
        }

        newLocations += "\n";
        setIsReady(!hasLocationErrors);
      }
    });

    setCsvContent(newLocations);
  };

  const readFile = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (!!file) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        const result = event.target.result;
        const decodedBase64String = Buffer.from(
          result.split(",")[1],
          "base64"
        ).toString();
        validateLocations(decodedBase64String);
        setIsLoading(false);
      });
      reader.readAsDataURL(file);
    }
  };

  const resetCsv = () => {
    fileUploader.current.value = null;
    setCsvContent("");
  };

  return (
    <SimpleGrid
      width="100%"
      columns={{ base: 1, md: 1, lg: 1, xl: 1 }}
      spacing="20px"
      p={4}
      border={"solid 1px #e6e6e6"}
      // borderRadius={10}
    >
      <Flex flexDirection={"column"} h="100%" mx={2}>
        {!hideTitle ? (
          <Heading as="h2" fontWeight={"bold"} mb={4} size="md">
            Download your portfolio stats &nbsp;
            <Tooltip
              label="Download the place stats for you properties.  Choose what years to include and if you want them aggregated by month or year."
              fontSize="sm"
            >
              <InfoIcon />
            </Tooltip>
          </Heading>
        ) : (
          ""
        )}
        <Box mb={2}>
          <Flex justifyContent={"left"} mb={2} fontWeight={600}>
            <RadioGroup
              isDisabled={isLoading}
              color={"gray.500"}
              value={dateType}
              onChange={(e) => setDateType(e)}
            >
              <Stack direction="row">
                <Radio value="yearly">Yearly</Radio>
                <Radio value="monthly">Monthly</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Box>

        <Box mb={8} mt={8}>
          <Flex justifyContent={"left"} mb={2} fontWeight={600}>
            <RangeSlider
              defaultValue={[sliderValue[0], sliderValue[1]]}
              min={2000}
              max={new Date().getFullYear()}
              step={1}
              onChange={(val) => setSliderValue(val)}
            >
              <RangeSliderTrack bg="red.100">
                <RangeSliderFilledTrack bg="tomato" />
              </RangeSliderTrack>
              <Tooltip
                hasArrow
                // isOpen
                placement="top"
                closeOnClick={false}
                label={`${sliderValue[0]}`}
              >
                <RangeSliderThumb boxSize={6} index={0} />
              </Tooltip>
              <Tooltip
                hasArrow
                // isOpen
                closeOnClick={false}
                label={`${sliderValue[1]}`}
              >
                <RangeSliderThumb boxSize={6} index={1} />
              </Tooltip>
            </RangeSlider>
          </Flex>
        </Box>

        <Box>
          <Input
            disabled={isLoading}
            style={{
              visibility: "hidden",
              display: "None",
            }}
            type="file"
            ref={fileUploader}
            w={350}
            p={[1, 0, 0, 1]}
            placeholder="Basic usage"
            onChange={readFile}
          />
          <Flex pos="right">
            Locations (Latitude, Longitude, Name)
            <Spacer />
            <Button
              variant="link"
              size="sm"
              disabled={isLoading}
              onClick={downloadTemplate}
            >
              Download Template
            </Button>
          </Flex>
          <Textarea
            disabled={isLoading}
            h={50}
            size="sm"
            placeholder="32.698284, -117.247597, sample location"
            value={csvContent}
            isInvalid={hasLocationErrors}
            readOnly={false}
            onChange={(e) => validateLocations(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Button
            isDisabled={!!!csvContent}
            variant={"outline"}
            colorScheme="blue"
            size="sm"
            mr={4}
            rightIcon={<CloseIcon />}
            onClick={resetCsv}
          >
            Reset
          </Button>
          <Button
            isDisabled={isLoading}
            variant={"solid"}
            colorScheme="blue"
            size="sm"
            mr={4}
            rightIcon={<AddIcon />}
            onClick={() => fileUploader.current.click()}
          >
            Load CSV
          </Button>
          <Button
            // isDisabled={!isReady}
            isLoading={isLoading}
            variant={"solid"}
            colorScheme="blue"
            size="sm"
            mr={4}
            rightIcon={<DownloadIcon />}
            onClick={getData}
          >
            Download Portfolio Stats
          </Button>
        </Box>
      </Flex>
    </SimpleGrid>
  );
};
