import regression from "regression";

/**
 *
 * @param {Array} data [{x: number, y: number}]
 */
export const getLineOfBestFit = (data, xmin, xmax, ymin, ymax) => {
  const linear = regression.linear(
    data.reduce((acc, val) => [...acc, [val?.x || 0, val?.y || 0]], [])
  );
  const linearPoints = linear.points.map((point) => ({ x: point[0], y: point[1] }));
  // const xMin = linear.predict(ymin)[1];
  // const xMax = linear.predict(ymax)[1];
  // const maxPoint = { x: xmax, y: xMax };
  // const minPoint = { x: xmin, y: xMin };
  // linearPoints.push(maxPoint);
  // linearPoints.push(minPoint);
  linearPoints.sort((a, b) => a.x - b.x);
  return linearPoints;
};

export const getRange = (data, key) => {
  const values = data.map((d) => d[key]);
  return [+Math.min(...values).toFixed(0) - 0.5, +Math.max(...values).toFixed(0) + 0.5];
};
