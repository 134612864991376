import { Layers } from "../components/Layers/Layers";
import { create } from "zustand";

const mapLayers = Layers();

//NORMALIZE LAYERS JSON
mapLayers.map((l) => {
  if (!l.source) l.source = l.id;
  if (l.sourceLayer && !l["source-layer"]) l["source-layer"] = l.sourceLayer;
  if (!l.metadata) l["metadata"] = {};
  if (!l.metadata.layers) l["metadata"]["layers"] = [l.id];
  if (l.layout && l.layout.visibility) l.metadata["visibility"] = l.layout.visibility;

  //MOVE FUNCTIONS OUT OF METADATA
  l["legend"] = l.metadata.legend ? l.metadata.legend : false;
  l["popup"] = l.metadata.popup ? l.metadata.popup : false;
  delete l.metadata.popup;
  delete l.metadata.legend;
  l["mapFilter"] = {};
  return l;
});

const useLayerStore = create((set) => ({
  layers: mapLayers,
  setLayers: (newLayers) => {
    set({ layers: newLayers });
  },
}));

export { useLayerStore };
