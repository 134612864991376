import {
  Box,
  FormLabel,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";

import React, { useState, useImperativeHandle, forwardRef } from "react";

const FormSlider = forwardRef(({ title, onChangeEndFn, min, max, initValues, tooltipFn }, ref) => {

  const [internalState, setInternalState] = useState(initValues);

  const resetValues = () => {
    setInternalState(initValues);
  };

  useImperativeHandle(ref, () => {
    return {
      resetValues: resetValues,
    };
  });

  // NOTE OTHER THINGS I TRIED THAT DID NOT WORK WHEN ATTEMPTING TO RESET THE STATE OF THE CHILD SLIDER ELEMENT
  // const [previousValue, setPreviousValue] = useState([]);

  // console.log(initValues, previousValue)

  // if (!previousValue.length || initValues[0] !== previousValue[0]) {
  //   setInternalState(initValues);
  //   setPreviousValue(initValues);
  // }

  // function updateState() {
  //   if (internalState[0] !== previousValue[0]) {
  //     setInternalState(initValues);
  //     setPreviousValue(initValues);
  //   }
  // }

  // const previousValueRef = useRef();
  // const previousValue = previousValueRef.current;

  // if (previousValue) {
  //   if (initValues[0] !== previousValue[0] && initValues[0] !== internalState[0]) {
  //     setInternalState(initValues);
  //   }
  // }

  // useEffect(() => {
  //   // updateState()
  // }, [initValues]);

  return (
    <>
      <FormLabel as="legend" fontWeight={600}>
        {title}
      </FormLabel>
      <Box>
        {tooltipFn(internalState)}
      </Box>
      <RangeSlider
        id={title.toLowerCase().replace(/ /g, "")}
        aria-label={"min, max"}
        defaultValue={initValues}
        value={internalState}
        min={min ? min : 0}
        max={max ? max : 100}
        onChange={(values) => {
          setInternalState(values)
        }}
        // step={100}
        focusThumbOnChange={false}
        minStepsBetweenThumbs={10}
        onChangeEnd={(values) => {
          onChangeEndFn(values);
        }}
        // step={1000}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
      {/* </Tooltip> */}
    </>
  );
});

export default FormSlider;
