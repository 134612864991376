import { Badge, Box } from "@chakra-ui/react";
import { FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis } from "react-vis";

import { placeTypeColors } from "../../assets/theme";
import { placeTypes } from "../../assets/theme";
import { useState } from "react";

/**
 * Processes the data object.
 *
 * @param {Object} params - The parameters object.
 * @param {Array<Object>} params.data - An array of data objects.
 * @param {number} params.data[].x - The x coordinate.
 * @param {number} params.data[].y - The y coordinate.
 * @param {number} params.data[].type - The type identifier.
 * @returns {void}
 */
export default function HPAScoreYearLineChart({ data, height, yFormat }) {
  const [tooltip, setTooltip] = useState({});
  const [qtr, setQtr] = useState("");

  const chartHandler = (d, e) => {
    // console.log(d);
    document.body.style.cursor = "crosshair";
    setQtr(d.x);
    setTooltip((current) => ({
      ...current,
      ["type" + d.type]: d.y
        ? yFormat
          ? Number(d.y).toFixed(2) + "%"
          : Number(d.y).toFixed(0)
        : "N/A",
    }));
  };

  const chartLeaveHandler = () => {
    document.body.style.cursor = "default";
    setTooltip({});
  };

  const formatTooltip = () => {
    if (!Object.values(tooltip).length) return "";
    return (
      <div>
        <div style={{ fontWeight: "bold", position: "absolute", right: 0 }}>{qtr}</div>
        <div style={{ columns: 2, textAlign: "left" }}>
          {placeTypes.map((t, i) => (
            <div
              key={i.toString()}
              style={{
                color: t.type !== 4 ? t.color : "#212121",
              }}
            >
              {t.label}: {tooltip["type" + t.type] !== "" ? tooltip["type" + t.type] : "N/A"}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Box h={height || 400} position={"relative"} pt={6} mb={6}>
      <Badge
        position={"absolute"}
        width={"100%"}
        top={"0px"}
        left={"0px"}
        zIndex={1}
        fontSize={"sm"}
      >
        {formatTooltip() || ""}
      </Badge>
      {data.length > 0 ? (
        <FlexibleWidthXYPlot
          height={height || 400}
          xType={"ordinal"}
          margin={{ left: 60, right: 20 }}
          yPadding={10}
          onMouseLeave={chartLeaveHandler}
        >
          <HorizontalGridLines />
          {new Array(4).fill(0).map((_, i) => (
            <LineSeries
              key={i.toString()}
              data={data.filter((h) => h.type === i + 1)}
              curve={"curveMonotoneX"}
              stroke={placeTypeColors["type_" + (i + 1)]}
              onNearestX={chartHandler}
            />
          ))}

          <XAxis
            tickLabelAngle={-45}
            tickTotal={data.length - 1}
            style={{
              text: { fontSize: "0.7rem" },
            }}
          />
          <YAxis
            orientation="left"
            style={{
              text: { fontSize: "0.7rem" },
            }}
            tickFormat={yFormat ? yFormat : (v) => v}
          />
        </FlexibleWidthXYPlot>
      ) : null}
    </Box>
  );
}
