export const dateObj = {
  xMonth: (x) => {
    let d = new Date();
    d.setMonth(d.getMonth() - x);
    return String(d.getMonth() + 1).padStart(2, "0");
  },
  xYear: (x) => {
    let d = new Date();
    d.setMonth(d.getMonth() - x);
    return String(d.getFullYear());
  },
};
