import { placeTypeColorsMuted, placeTypes } from "../assets/theme";

function getPlaceTypeValue(data, type, metricName, year) {
  if (!data || !data.premByPlaceType) return null;
  try {
    const placeType = data.premByPlaceType.find((p) => +p.groupby_id === type);
    const metric = placeType.stats.find((s) => s.metric_name === metricName);

    const timeseries = metric.timeseries.find((t) => t.x.slice(0, 4) === year);
    return timeseries?.y === 0
      ? 0
      : timeseries.y
      ? (timeseries.y * 100).toFixed(1)
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function getPlaceTypeValueChange(data, type, metricName, years) {
  if (!data || !data.premByPlaceType) return null;
  try {
    const placeType = data.premByPlaceType.find((p) => +p.groupby_id === type);
    const metric = placeType.stats.find((s) => s.metric_name === metricName);
    const year1 = metric.timeseries.find((t) => t.x.slice(0, 4) === years[0]);
    const year2 = metric.timeseries.find((t) => t.x.slice(0, 4) === years[1]);

    return year1?.y && year2?.y
      ? Number(((year2.y - year1.y) * 100).toFixed(1))
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function getPremiumByPlaceType(data, metricName, yr) {
  const year = yr || "2022";
  const array = [];
  if (data) {
    const premiums = data.premByPlaceType;
    const placeTypesArray = premiums.map((p) => p.groupby_id);
    placeTypesArray.sort();

    const typeAliases = placeTypes.map((p) => p.label);

    placeTypesArray.forEach((t) => {
      const placeType = premiums.find((p) => +p.groupby_id === +t);
      const metric = placeType.stats.find((s) => s.metric_name === metricName);
      if (!metric) return [];
      const value = metric.timeseries.find((t) => t.x.slice(0, 4) === year);
      array.push({
        x: typeAliases[+t - 1],
        y: +(value.y * 100).toFixed(2),
        color: placeTypeColorsMuted["type_" + t],
      });
    });
    // console.log(array);
  }
  return array;
}

function getPlaceTypeValueChangeArray(data, clusters, metrics, aliases, years) {
  const obj = {};

  clusters.forEach((t, i) => {
    obj[t] = [];
    metrics.forEach((metricName, index) => {
      obj[t].push({
        x: aliases[index],
        y: getPlaceTypeValueChange(data, i + 1, metricName, years),
      });
    });
  });
  // console.log(obj);
  return obj;
}

export {
  getPlaceTypeValue,
  getPlaceTypeValueChange,
  getPlaceTypeValueChangeArray,
  getPremiumByPlaceType,
};
