import "../../../node_modules/react-vis/dist/style.css";

import {
  Box,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  YAxis,
} from "react-vis";

const BasicMultiLineChart = ({
  height,
  hideTitle,
  inputStats,
  inputTypes,
  title,
  subtitle,
  xValueType,
  xTickFormat,
  yTickFormat,
  xType,
  xValues,
  yValues,
  ...rest
}) => {
  const { colorMode } = useColorMode();
  const types = inputTypes || [1, 2, 3, 4];
  //NOTE dont change the data, just change the types and hide charts if the type 1,2,3,4 is not in the types array

  //NOTE force descending order on x axis
  const data = inputStats.map((stat) => {
    if (!stat?.timeseries) return stat;
    const timeseries = stat?.timeseries?.sort((a, b) => (a.x > b.x ? 1 : -1));
    return {
      title: stat?.title || "Stat Title",
      color: stat?.color || "black",
      timeseries: timeseries.map((d) => {
        return { x: new Date(d.x), y: d.y };
      }),
    };
  });
  return (
    <Box
      w="100%"
      backgroundColor={useColorModeValue("white", "gray.800")}
      border={"solid thin lightgray"}
      borderColor={colorMode === "light" ? "gray.200" : "gray.700"}
      p={4}
      h="100%"
      {...rest}
    >
      {!hideTitle && (
        <>
          <Heading
            px={2}
            pt={3}
            as="h3"
            fontSize={"xl"}
            color={colorMode === "light" ? "gray.900" : "gray.100"}
          >
            {title ? title : "Basic MultiLine Chart"}
          </Heading>
          <Box px={2} pb={3}>
            {subtitle ? subtitle : ""}
          </Box>
        </>
      )}
      {data && data.length && data[0].timeseries ? (
        <Box borderWidth="0px" w="100%">
          <FlexibleWidthXYPlot
            height={height || 260}
            xType={xType ? xType : "time"}
            margin={{ left: 60, right: 20 }}
            yPadding={10}
          >
            <HorizontalGridLines />
            <XAxis
              tickLabelAngle={-45}
              tickTotal={data[0].timeseries.length - 1}
              style={{
                text: { fontSize: "0.7rem" },
              }}
            />
            <YAxis
              orientation="left"
              style={{
                text: { fontSize: "0.7rem" },
              }}
              tickFormat={
                yTickFormat
                  ? yTickFormat
                  : (v) => (Number(v) > 1000 ? Number(v) / 1000 + "k" : v)
              }
            />
            {/* {data && data.length
              ? data.map((stat, i) => (
                  <AreaSeries
                    key={i.toString()}
                    data={stat.timeseries}
                    color={chroma(stat.color).alpha(0.2)}
                    curve={"curveMonotoneX"}
                  />
                ))
              : ""} */}
            {data && data.length
              ? data.map((stat, i) => {
                  if (types.includes(i + 1)) {
                    return (
                      <LineSeries
                        key={i.toString() + (stat?.color || "_key")}
                        data={stat.timeseries}
                        curve={"curveMonotoneX"}
                        stroke={stat?.color}
                        // strokeStyle={!i ? "dashed" : "solid"}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              : ""}
          </FlexibleWidthXYPlot>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export { BasicMultiLineChart };
