import "./style.css";
import "@fontsource/noto-sans";
import "@fontsource/lexend-deca";

import App from "./views/demo/App";
import { createRoot } from "react-dom/client";
import netlifyIdentity from "netlify-identity-widget";

// import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

// import Routes from "./routes";

// import { placesTheme } from "./assets/theme";

/**
 * NETLIFY IDENTITY VANILLA JS SUPPORTED VANILLA JS WDIGET
 */

window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
netlifyIdentity.init();

const root = createRoot(document.getElementById("root"));

root.render(<App />);
// <>
//   <ColorModeScript initialColorMode={placesTheme.initialColorMode} />
//   <ChakraProvider theme={placesTheme}>
//     <Routes />
//   </ChakraProvider>
// </>
// );
