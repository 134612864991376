import "./property-filter-form.css"

import {
  Checkbox,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import {
  MdChairAlt,
  MdChildCare,
  MdCoffee,
  MdDirectionsBus,
  MdFestival,
  MdFitnessCenter,
  MdFormatPaint,
  MdHandyman,
  MdHealthAndSafety,
  MdLiquor,
  MdLocalFlorist,
  MdLocalGroceryStore,
  MdLocalHospital,
  MdLocalLibrary,
  MdLocalPharmacy,
  MdLocalPostOffice,
  MdMenuBook,
  MdRestaurant,
  MdSchool,
  MdShoppingBag,
  MdShoppingBasket,
  MdStore,
} from "react-icons/md";

import { FaChurch } from "react-icons/fa"
import React from "react";

// CONVERT TO CHECKBOX SO IT WILL TRIGGER A FORM CHANGE EVENT

const FormIconButton = (props) => {

  function getParent(el, className) {
    const parent = el.parentElement;
    if (parent.classList.contains(className)) {
      return parent
    }else{
      return getParent(parent, className)
    }
  }

  const onClickEvent = (e) => {
    const checkbox = getParent(e.target, "chakra-checkbox");
    checkbox.click()
  }

  let Icon;

  switch (props.icon) {
    case "ChairAlt":Icon = MdChairAlt; break;
    case "Liquor": Icon = MdLiquor; break;
    case "MenuBook":Icon = MdMenuBook; break;
    case "ChildCare":Icon = MdChildCare; break;
    case "ShoppingBag":Icon = MdShoppingBag; break;
    case "Coffee":Icon = MdCoffee; break;
    case "HealthAndSafety":Icon = MdHealthAndSafety; break;
    case "School":Icon = MdSchool; break;
    case "FitnessCenter":Icon = MdFitnessCenter; break;
    case "LocalFlorist":Icon = MdLocalFlorist; break;
    case "Store":Icon = MdStore; break;
    case "Handyman":Icon = MdHandyman; break;
    case "LocalHospital":Icon = MdLocalHospital; break;
    case "LocalLibrary":Icon = MdLocalLibrary; break;
    case "FormatPaint":Icon = MdFormatPaint; break;
    case "Festival":Icon = MdFestival; break;
    case "LocalPharmacy":Icon = MdLocalPharmacy; break;
    case "LocalPostOffice":Icon = MdLocalPostOffice; break;
    case "Church":Icon = FaChurch; break;
    case "Restaurant":Icon = MdRestaurant; break;
    case "ShoppingBasket":Icon = MdShoppingBasket; break;
    case "LocalGroceryStore":Icon = MdLocalGroceryStore; break;
    case "Transit":Icon = MdDirectionsBus; break;
    default: Icon = FaChurch
  }

  return <>
      <Checkbox defaultChecked={false} className="form-icon-checkbox" data-value={props.value} data-filter={props.filter} onChange={props.onChange}>
      <Tooltip label={props.name} placement="top">
        <IconButton
          color='black'
          colorScheme={props.colorScheme}
          borderRadius={'3px'}
          outline={'1px solid lightgray'}
          aria-label='Search database'
          icon={<Icon/>}
          size='sm'
          fontSize={'26px'}
          mb={2}
          onClick={onClickEvent}
          ></IconButton>
      </Tooltip>
      </Checkbox>
  </>
}

export {
  FormIconButton
}
