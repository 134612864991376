import { Box, Center, SimpleGrid } from "@chakra-ui/react";

import { TeamProfile } from "./TeamProfile";

export const Team = () => {
  return (
    <Box px="40px">
      <Center maxW={"1320px"} m="0 auto">
        <SimpleGrid minChildWidth="310px" spacing="0px" maxW={"100%"}>
          {team.map((t, i) => (
            <TeamProfile key={i.toString()} name={t.name} image={t.image} title={t.title} about={t.about}></TeamProfile>
          ))}
        </SimpleGrid>
      </Center>
    </Box>
  );
};

const team = [
  {
    name: "Jackson Horton",
    title: "Chief Executive Officer",
    image: "/images/splash/horton.png",
    about: "Horton is an information services executive who has led new market initiatives at IBM, Equifax, Booz Allen and CGI/AMS, and co-founder of Places Platform",
  },
  {
    name: "Stuart McFarland",
    title: "Strategic Advisor",
    image: "/images/splash/mcfarland.jpg",
    about: "McFarland is the former EVP Operations and CFO at Fannie Mae, EVP General Manager at GE Capital Mortgage Services, and CEO at GE Capital Asset Management; current public companies board member, and co-founder of Places Platform",
  },
  {
    name: "John Dolan",
    title: "Managing Director, Housing Futures",
    image: "/images/splash/dolan.png",
    about: "Dolan is a 40+ year veteran of financial markets.  His career includes 18 years managing Wall Street trading desks ( to include as Managing Director at Salomon Brothers). He spent 12 years in asset management where he was the Chief Investment Officer at Hyperion Capital."
  },
  {
    name: "Christopher Leinberger",
    title: "Co-Founding Partner & Managing Director",
    image: "/images/splash/leinberger.png",
    about: "Former owner and Managing Director of RCLCo, one of the largest real estate advisory firms in the US, and former Senior Fellow at Brookings. Currently Co-founder & Managing Director of Arcadia Land Co. and Endowed Professor and Chair, GWU Center for Real Estate and Urban Analysis",
  },
  {
    name: "Col Mark Mykleby, USMC (Ret)",
    title: "Chief Operating Officer",
    image: "/images/splash/puck.jpeg",
    about: "Mykleby is a retired Marine Corps fighter pilot, former strategic assistant to the Chairman of the Joint Chiefs of Staff and co-founder of Long Haul Capital Group (LHCG)",
  },
  {
    name: "William Kane",
    title: "Chief Licensing Officer",
    image: "/images/splash/kane.png",
    about: "Kane is a capital markets executive with experience at Warburg Paribas Becker, and co-founder of Places Platform",
  },
  {
    name: "Patrick Doherty",
    title: "Managing Director, ESG",
    image: "/images/splash/doherty.png",
    about: "Doherty is a macroeconomic strategist and co-founder of Long Haul Capital Group (LHCG).",
  },
  {
    name: "Saul Klein",
    title: "Exec. Producer – MPN™️",
    image: "/images/splash/klein.png",
    about: 'Klein is the father of Internet adoption in the residential real estate industry and is generally recognized within the industry, as a technology pioneer and its first Internet evangelist. He was a driving force in the migration of listings from the MLS Book to the Internet...from "Atoms to Bits."',
  },
  {
    name: "Nancy Allen",
    title: "Chief Products Officer",
    image: "/images/splash/allen.png",
    about: "Allen is former COO of Oversite Data Service and Director of Operations for Albertelli Law.  She is responsible for user experience, Platform integration and client delivery.",
  },
  {
    name: "Michael Rodriguez, AICP",
    title: "Chief Data Economist",
    image: "/images/splash/mrodriguez.jpeg",
    about: "Rodriguez is former Research Director at Smart Growth America, former head of Mid-Atlantic research for CBRE, PhD candidate at George Washington University with thesis regarding place-based agglomeration theory.",
  },
  {
    name: "Edward J. Pinto",
    title: "Industry Relations",
    image: "/images/splash/epinto.png",
    about:
      "Pinto a resident fellow and the director of the AEI Housing Center at the American Enterprise Institute (AEI). Before joining AEI, Mr. Pinto was an executive vice president and chief credit officer for Fannie Mae until the late 1980s. Today, he is frequently interviewed on radio and television and often testifies before Congress.",
  },
  {
    name: "Tobias Peter",
    title: "Analytics Research",
    image: "/images/splash/tpeter.png",
    about:
      "Peter is the director of research at the American Enterprise Institute’s Housing Center, where he focuses on housing risk and mortgage markets. He has coauthored a variety of reports on the impact of federal policy on housing demand and homeownership, housing finance risks, and first-time home buyers.",
  },
];
