import { createJSONStorage, persist } from "zustand/middleware";
import { del, get, set } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

import { create } from "zustand";

// Custom storage object
const storage = {
  getItem: async (name) => {
    console.log(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    console.log(name, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    console.log(name, "has been deleted");
    await del(name);
  }
};

export const useDBStore = create(
  persist(
    (set, get) => ({
      data: {},
      update: (update) => {
        console.log({ update });
        return set({ data: Object.assign({}, get().data, update) });
      },
      clear: () => {
        console.log("clearing db");
        storage.removeItem("ppl__db");
        return set({ data: {} });
      }
    }),
    {
      name: "ppl__db",
      // partialize: (state) => ({ trackedMetros: state.trackedMetros, trackedMetrosProps: state.trackedMetrosProps }),
      storage: createJSONStorage(() => storage)
    }
  )
);
