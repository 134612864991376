import { Box, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { WarningTwoIcon } from "@chakra-ui/icons";
import { useAccountStore } from "../context/accountStore";
import { useAppState } from "../context/useAppState";

export const Restricted = () => {
  const { setIsLoading } = useAppState();
  const { isLoggedIn } = useAccountStore();
  const [hideWarning, setHideWarning] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setHideWarning(false);
    }, 3000);
  }, [setIsLoading]);

  useEffect(() => {
    if (!isLoggedIn) return;
    setIsLoading(false);
    setHideWarning(true);
  }, [isLoggedIn, setIsLoading]);

  return (
    <Box
      height="calc(100vh - 60px)"
      justifyContent="center"
      alignItems="center"
      display={"flex"}
      flexDir="column"
      py={10}
      px={6}
    >
      {!hideWarning ? (
        <>
          <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Please Login
          </Heading>
          <Text color={"gray.500"}>
            Use the Login button on the Navbar to view this content.
          </Text>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};
