import netlifyIdentity from "netlify-identity-widget";

//TODO move to authStore or accountStore

export const NetlifyAuth = {
  // isAuthenticated: false,
  isLoggedIn() {
    if (this.user) return this.user;
    this.user = netlifyIdentity.currentUser();
    return this.user;
  },
  user: null,
  authenticate(callback) {
    // this.isAuthenticated = true;
    netlifyIdentity.open();
    netlifyIdentity.on("login", (user) => {
      this.user = user;
      callback(user);
      netlifyIdentity.close();
    });
  },
  signout(callback) {
    // this.isAuthenticated = false;
    netlifyIdentity.logout();
    netlifyIdentity.on("logout", () => {
      this.user = null;
      console.log("LOG NETLIFY AUTH -- user:", this.user);
      callback();
    });
  },
};

/**
 * TODO
 * netlifyIdentity.on("error", (e) => {
 *  console.log(e)
 * });
 * netlifyIdentity.on("logout", (e) => {
 *  console.log(e)
 * })
 */
