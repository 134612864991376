import { MetroSelector, PlaceTypeSelector } from "./SidebarSelectors";

import DeveloperView from "../DeveloperView";
import { Docs } from "./Docs";
import { HPA } from "./HPA";
import { Metrics } from "./Metrics";
import { Metros } from "./Metros";
import { PlacesScoreMatrix } from "./PlacesScoreMatrix";

// import { Places } from "./Places";

export const Pages = [
  {
    title: "Places Value Matrix",
    element: <PlacesScoreMatrix />,
    selectors: (
      <>
        <MetroSelector />
        <PlaceTypeSelector />
      </>
    ),
  },
  {
    title: "Market-Based Mapping",
    element: <Metros />,
    selectors: <MetroSelector />,
  },
  {
    title: "Submarket Performance",
    element: <Metrics />,
    selectors: (
      <>
        <MetroSelector />
        <PlaceTypeSelector />
      </>
    ),
  },
  {
    title: "Product Projections",
    element: <HPA />,
    selectors: <MetroSelector />,
  },

  // {
  //   title: "Places First Search",
  //   element: (
  //     <iframe
  //       title="Places Smart Search"
  //       src="https://aeihousingcenter.org/internal/ls360/sdar/"
  //       style={{ border: "none", width: "100%", flex: 1 }}
  //     />
  //   ),
  //   selectors: null,
  // },
  {
    title: "Docs",
    element: <Docs />,
    hide: true,
  },
  {
    title: "Tables",
    element: <DeveloperView />,
    hide: true,
  },
  // {
  //   title: "Places",
  //   element: <Places />,
  //   selectors: (
  //     <>
  //       <MetroSelector disabled={true} />
  //       <PlaceTypeSelector />
  //     </>
  //   ),
  // },
];
