import { defineStyleConfig, extendTheme } from "@chakra-ui/react";

// import { theme } from "@saas-ui/theme";

/* PLACES COLORS
type_I - 5D8847
II - 689ACB
III - CB6768
IV - D9D9D9
*/

/*CHAKRA UI COLORS 400*/
/*
#A0AEC0 - gray
#F56565 - red
#ED8936 - orange
#48BB7 - green
#38B2AC teal
#4299e1 blue
#9F7AEA purple
ED64A6 pink
#34B3E4 lknin
#2E90FF messenger
#3cdd78 whatsapp
#57BBF5 tw
#47A9DA tele
#ECC94B - yellow
*/

/* LISTING COLORS

  const types = ["Active", "Expired", "Off Market", "Pending", "Sold"]

  ACTIVE - '#e31a1c',
  EXPIRED - 'black',
  OFF MARKET - '#1f78b4',
  PENDING - 'darkorange',
  SOLD - 'gray',
  'black',
  '#a6cee3',
  '#fb9a99',
  '#b2df8a',
  '#33a02c'

*/

//TODO add Stat

const breakpoints = {
  sm: "600px",
  md: "1100px",
  lg: "1600px",
  xl: "1920px",
  "2xl": "2160px",
  letter: "816px",
};

const FormControl = defineStyleConfig({
  baseStyle: {
    margin: "10px 0",
  },
});

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: "2px",
  },
});

const Container = defineStyleConfig({
  baseStyle: {
    margin: "0 auto",
    padding: "2rem 0",
    width: "2000px",
    maxWidth: "calc(100% - 4rem)",
  },
});

const basePlacesTheme = {
  breakpoints,
  colors: {
    highlight: {
      500: "#ff0",
    },
    brand: {
      main: "#5885c2",
      light: "#9a9a9a",
      red: "#b5304b",
      green: "#84BE34",
    },
    places: {
      type_1: "#5D8847", //green
      type_2: "#689ACB", //blue
      type_3: "#CB6768", //red
      type_4: "#D9D9D9", //gray
    },
    placesMuted: {
      type_1: "#B3C2A7",
      type_2: "#B8CCE3",
      type_3: "#DFB6B4",
      type_4: "#D9D9D9",
    },
  },
  fonts: {
    heading: `'Lexend Deca', sans-serif`,
    body: `'Lexend Deca', 'Noto Sans', sans-serif`,
  },
  initialColorMode: "light",
  useSystemColorMode: false,
  components: {
    Container,
    FormControl,
    Button,
  },
};

export const placesTheme = extendTheme(basePlacesTheme);
export const placeTypeColors = {
  type_1: "#5D8847", //green
  type_2: "#689ACB", //blue
  type_3: "#CB6768", //red
  type_4: "#D9D9D9", //gray
};

export const placeTypeColorsMuted = {
  type_1: "#B3C2A7",
  type_2: "#B8CCE3",
  type_3: "#DFB6B4",
  type_4: "#D9D9D9",
};

export const placeTypes = [
  {
    type: 1,
    label: "WalkUPs",
    labelAlt: "Type I",
    color: "#5D8847",
    colorMuted: "#B3C2A7",
    icon: "walk",
  },
  {
    type: 2,
    label: "Walkable Neighborhoods",
    labelAlt: "Type II",
    color: "#689ACB",
    colorMuted: "#B8CCE3",
    icon: "walk",
  },
  {
    type: 3,
    label: 'Drivable "Edge-Cities"',
    labelAlt: "Type III",
    color: "#CB6768",
    colorMuted: "#DFB6B4",
    icon: "car",
  },
  {
    type: 4,
    label: "Drivable Communities",
    labelAlt: "Type IV",
    color: "#D9D9D9",
    colorMuted: "#D9D9D9",
    icon: "car",
  },
];
//https://chakra-ui.com/docs/styled-system/theme
