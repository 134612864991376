const icon = '<svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" style="display:inline-flex;margin:0" fill="currentColor"><path d="M1344 1344q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h465l135 136q58 56 136 56t136-56l136-136h464q40 0 68 28t28 68zm-325-569q17 41-14 70l-448 448q-18 19-45 19t-45-19l-448-448q-31-29-14-70 17-39 59-39h256v-448q0-26 19-45t45-19h256q26 0 45 19t19 45v448h256q42 0 59 39z"/></svg>'

function exportGeoJSONToCSV(features) {

  const csv = [];

  //GET ALL HEADERS
  const header = [];
  features.forEach(f => {
    for (let key in f.properties) {
      if (!header.includes(key)) header.push(key)
    }
  })
  csv.push(header.join(","));

  //GET ALL ROWS
  features.forEach(f => {
    const row = []
    header.forEach(key => {
      const rowValue = f.properties[key] ? f.properties[key] : null;
      const csvValue = (rowValue && typeof rowValue === "string" && rowValue.includes(",")) ? rowValue.replace(/,/g, " | ") : rowValue;
      row.push(csvValue)
    })
    csv.push(row.join(","))
  })

  downloadCSV(csv.join("\n"), "places-listings.csv");
}

function downloadCSV(csv, filename) {
  let csvFile;
  let downloadLink;

  csvFile = new Blob([csv], {
    type: "text/csv"
  });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(csvFile, filename);
    return
  }

  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove()
}

class ExportCSVControl {

  constructor(layers) {
    this._id = "exportCSVControl"
    this._layers = layers
  }

  onAdd(map) {
    const layers = this._layers
    this._map = map;
    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon mgl-custom-control tooltip tooltip-left';
    this._btn.type = 'button';
    this._btn['aria-label'] = "Export Listings";
    this._btn.dataset.tooltip = "Export Listings";
    this._btn.innerHTML = icon;
    this._btn.onclick = function () {
      const features = map.queryRenderedFeatures(null, {layers: layers});
      // console.log(features);
      
      //ERRORS
      if (features.length > 25) {
        alert("Too many features. Please zoom in to a smaller area or use the filter to limit the number of features.")
        return
      }
      if (!features.length) {
        alert("No features found!")
        return 
      }
      
      //DOWNLOAD CSV
      exportGeoJSONToCSV(features)
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group box-shadow';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

}

export {
  ExportCSVControl
}