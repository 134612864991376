// import { booleanWithin } from "@turf/turf";
// import { sortJSON } from "./helpers/geojsonHelpers.js";

import { apiGateway, apiKey } from "../../config.js";
import { busIcon, circle, fireIcon, square } from "./layerIcons";

import chroma from "chroma-js";
import { listingsPopup } from "./layerPopups";
import { placeTypes } from "../../assets/theme.js";

console.log(placeTypes);

// const metroScoreBounds = {};

const metroHPABounds = {
  year_2023: {
    min: -3.44,
    max: 12.06,
  },
  year_2024: {
    min: -3,
    max: 10,
  },
  year_2025: {
    min: -1.2,
    max: 11.3,
  },
  year_2026: {
    min: -0.2,
    max: 12.3,
  },
  year_2027: {
    min: 0.3,
    max: 12.8,
  },
  year_2028: {
    min: 0.3,
    max: 12.8,
  },
};
/*2023	-16.835050000000003	31.489620000000002
2024	-16.835050000000003	31.489620000000002
2025	-16.835050000000003	31.489620000000002
2026	-16.835050000000003	31.489620000000002
2027	-16.835050000000003	31.489620000000002
2028	-16.835050000000003	31.489620000000002*/
const placesHPABounds = {
  year_2023: {
    min: -16.83,
    max: 31.49,
  },
  year_2024: {
    min: -2.32,
    max: 21.2,
  },
  year_2025: {
    min: 2.25,
    max: 21.2,
  },
  year_2026: {
    min: 4.05,
    max: 21.2,
  },
  year_2027: {
    min: 5.51,
    max: 21.2,
  },
  year_2028: {
    min: -0.59,
    max: 21.2,
  },
};

const generateColorRamp = (bounds, year) => {
  const layerColorRamp = [];

  const { min: dataHPAMin, max: dataHPAMax } = bounds[year] ? bounds[year] : { min: 1, max: 100 };
  const colorRamp = chroma
    .scale(["#FE2E2A", "#FFFE37", "#1D8D23"])
    .domain([dataHPAMin - 0.01, dataHPAMax + 0.01])
    .colors(11);

  layerColorRamp.push("case");
  colorRamp.forEach((c, i) => {
    layerColorRamp.push(
      ["<=", ["get", year], dataHPAMin + i * ((dataHPAMax - dataHPAMin) / 10)],
      c
    );
  });

  layerColorRamp.push("black");
  return layerColorRamp;
};

// Example usage:
const year = "year_2028"; // Change this to the desired year
const metroHPARamp = generateColorRamp(metroHPABounds, year);
console.log(metroHPARamp);

const metroHPAPopup = (f) => {
  const props = f.properties;
  delete props.area_id;
  let html = Object.keys(props)
    .map((k) => {
      if (k.includes("year") || k.includes("hpa")) {
        return `<div><strong>${k
          .replace(/_/g, " ")
          .toUpperCase()
          .replace("YEAR", "Forward HPA Y/E")}</strong>: ${
          props[k] ? +props[k].toFixed(1) + "%" : "N/A"
        }</div>`;
      } else if (k === "area_name") {
        return `<div><strong>${props[k]
          .replace(/_/g, " ")
          .toUpperCase()} (YOY)</strong><p>&nbsp</p></div>`;
      } else {
        return "";
      }
    })
    .join("");
  html += "<br /><br /><em>Zoom in to see the HPA at the Place Level</em>";
  return { html };
};

const metroScorePopup = (f) => {
  const props = f.properties;
  delete props.area_id;
  let html = "";

  html += Object.keys(props)
    .map((k) => {
      if (k.includes("avg_score") && !k.includes("hpa")) {
        return `<div><strong>${k
          .replace(/_/g, " ")
          .toUpperCase()
          .replace("YEAR", "AVG Places Score")} (MSA)</strong>: ${
          props[k] ? +props[k].toFixed(1) : "N/A"
        }</div>`;
      } else if (k === "area_name") {
        return `<div><strong>${props[k]
          .replace(/_/g, " ")
          .toUpperCase()} (YOY)</strong><p>&nbsp</p></div>`;
      } else {
        return "";
      }
    })
    .join("");
  html += "<br />";

  html += Object.keys(props)
    .map((k) => {
      if (k.includes("year") || k.includes("hpa")) {
        return `<div><strong>${k
          .replace(/_/g, " ")
          .toUpperCase()
          .replace("YEAR", "AVG Forward HPA Y/E")}</strong>: ${
          props[k] ? +props[k].toFixed(1) + "%" : "N/A"
        }</div>`;
      } else {
        return "";
      }
    })
    .join("");
  html += "<br /><br /><em>Zoom in to see the Places Score at the Place Level</em>";
  return { html };
};

const placesScorePopup = (f) => {
  const props = f.properties;
  delete props.area_id;
  const type = +f?.properties?.place_type || null;
  let html = `<strong>Submarket: ${
    placeTypes.find((t) => t.type === +props.place_type)?.label
  }<br /><br />`;

  html += `Metro Avg Score: ${props?.avg_score ? props.avg_score.toFixed(0) : "TBD"}<br />
    Submarket Avg Score: ${type ? f.properties[`type${type}_avg_score`] : "N/A"}<br /><br />
  `;

  html += `Metro Avg HPA: ${props?.avg_hpa ? props.avg_hpa.toFixed(1) + "%" : "TBD"}<br />`;

  html += `Submarket Avg HPA: ${
    type
      ? props?.[`type${type}_avg_hpa`]
        ? props[`type${type}_avg_hpa`].toFixed(1) + "%"
        : ""
      : "N/A"
  }</strong><br />`;

  html += Object.keys(props)
    .map((k) => {
      if (k.includes("year") && !k.includes("hpa")) {
        return `<div><strong>${k
          .replace(/_/g, " ")
          .toUpperCase()
          .replace("YEAR", "Places Score")} (CBG)</strong>: ${
          props[k] ? +props[k].toFixed(1) : "N/A"
        }</div>`;
      } else {
        return "";
      }
    })
    .join("");
  html += "<br />";
  html += Object.keys(props)
    .map((k) => {
      if (k.includes("year") && k.includes("hpa")) {
        return `<div><strong>${k
          .replace("hpa_year", "Forward HPA Y/E")
          .replace(/_/g, " ")}</strong>: ${props[k] ? +props[k].toFixed(1) + "%" : "N/A"}</div>`;
      } else {
        return "";
      }
    })
    .join("");
  // html += "<br /><br /><em>Zoom in to see the HPA at the Place Level</em>";
  return { html };
};

const sd_sample = require("../../context/layer_d_sample.json");

const categories = [
  "Beauty Salon Or Barber",
  "Beer Or Wine Or Liquor Store",
  "Book Store",
  "Childcare",
  "Clothing Store",
  "Coffee Shop",
  "Convenience Store",
  "Doctor Or Dentist",
  "Elementary Or Secondary School",
  "Fitness",
  "Florists",
  "General Merchandise",
  "Hardware Store",
  "Hospitals And Outpatient Care",
  "Library",
  "Paint Or Wallpaper Store",
  "Parks And Museums",
  "Pet And Pet Supplies",
  "Pharmacy Or Drug Store",
  "Postal Service",
  "Religious Institution",
  "Resale Shops",
  "Restaurant Or Bar",
  "Specialty Food Store",
  "Super Market",
];

//from here - https://stackoverflow.com/questions/10014271/generate-random-color-distinguishable-to-humans/10014411
const colorsObject = {
  aqua: "#00ffff",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgrey: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkviolet: "#9400d3",
  fuchsia: "#ff00ff",
  gold: "#ffd700",
  green: "#008000",
  indigo: "#4b0082",
  khaki: "#f0e68c",
  lightblue: "#add8e6",
  lightcyan: "#e0ffff",
  lightgreen: "#90ee90",
  lightgrey: "#d3d3d3",
  lightpink: "#ffb6c1",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  magenta: "#ff00ff",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#ffa500",
  pink: "#ffc0cb",
  purple: "#800080",
  violet: "#800080",
  red: "#ff0000",
  silver: "#c0c0c0",
  white: "#ffffff",
  yellow: "#ffff00",
};

const listingColors = [
  "#e31a1c",
  "black",
  "#1f78b4",
  "darkorange",
  "gray",
  "black",
  "#a6cee3",
  "#fb9a99",
  "#b2df8a",
  "#33a02c",
];

const colors = [...Object.values(colorsObject)];
const colorMatchArray = ["case"];
const categoryColors = [];
categories.forEach((c, i) => {
  colorMatchArray.push(["==", ["get", "categry"], c.toLowerCase()], colors[i]);
  categoryColors.push({
    category: c,
    color: colors[i],
  });
});
colorMatchArray.push("black");

const genPoiChildFitlers = () => {
  const array = [];

  categoryColors.forEach((t, i) => {
    array.push({
      id: "pois_" + i,
      name: t.category,
      checked: true,
      icon: circle(t.color),
      field: "categry",
      value: t.category.toLocaleLowerCase(),
      parents: ["pois"],
      active: false,
    });
  });
  array.push({
    id: "pois_transit",
    name: "Transit",
    checked: true,
    icon: busIcon,
    field: "none",
    value: "none",
    parents: ["rapid-transit"],
    active: false,
  });
  return array;
};

const genCommercialChildFilters = () => {
  const sectors = ["Aff", "Apt", "Dis", "Flx", "Ind", "Lnd", "Off", "Sth"];

  const alias = [
    "Affordable Housing",
    "Apartments",
    "Dis",
    "Flx",
    "Industrial",
    "Lnd",
    "Office Building",
    "Sth",
  ];

  const colors = [
    "#ff00ff",
    "#800000",
    "#000080",
    "#808000",
    "#ffa500",
    "#ffc0cb",
    "#000000",
    "#ff0000",
  ];
  const array = [];

  sectors.forEach((t, i) => {
    array.push({
      id: "commercialProperties_" + i,
      name: alias[i],
      checked: true,
      icon: circle(colors[i]),
      field: "data_sector",
      value: t,
      parents: ["reis-sample"],
      active: false,
    });
  });
  // console.log(array)
  return array;
};

// const placesPopup2 = (feature) => {
//   let totalListings = 0;
//   // Listings.features.forEach((listing) => {
//   //   if (booleanWithin(listing, feature.geometry)) totalListings++;
//   // });
//   feature.properties["total_Listings"] = totalListings;
//   feature.properties["workers_within_30min_transit"] = feature.properties.stats_employment_access_index

//   const ignoreFields = [
//     "id", "ogc_fid", "objectid", "object_id", "fid", "data_id", "poi_types", "hh_pnt_white", "place_type", "stats_employment_access_index"
//   ];
//   const props = Object.keys(feature.properties);
//   props.sort();

//   let popupContent = "";

//   const moneyFields = [
//     "hh_avg_net_worth", "hh_med_gross_rent", "hh_med_income"
//   ]

//   for (const i in props) {
//     const p = props[i]
//     if (!ignoreFields.includes(p.toLocaleLowerCase())) {
//       popupContent += `<strong>${p.replace(/_/g, " ").replace(/stats/g, "").replace(/esi/g, "econ stab index").replace(/lor/g, "length of residence").toUpperCase()}</strong>:
//       ${moneyFields.includes(p) ? "$" + Number(feature.properties[p]).toLocaleString() :
//         Number(feature.properties[p]) ? Number(feature.properties[p]).toLocaleString() :
//         feature.properties[p]}${p === "stats_average_esi" ? " (1 = most stable; 30 = least stable)" :
//           p === "transit_connectivity_index" ? " (0=poor; 100=best)" :
//           p === "transit_performance_score" ? " (1=poor; 10=best)" :
//           p === "hh_pnt_single_family" ? "%" : ""}<br>`;
//     }
//   }

//   return {
//     html: popupContent
//   }
// }

const genListingsLegend = () => {
  // const types = ["ACTIVE", "BACK ON MARKET", "COMING SOON", "CONTINGENT/PENDING", "RENTED", "SOLD"];
  const types = ["Active", "Expired", "Off Market", "Pending", "Sold"];

  const colors = listingColors;
  const legend = [];
  types.forEach((t, i) => {
    legend.push({
      type: "circle",
      color: colors[i],
      strokeColor: "white",
      name: t.toLocaleLowerCase(),
      size: t === "Active" ? "lg" : "md",
    });
  });

  // console.log(legend)

  return legend;
};

// const genListingsPaintColor = () => {
//   // const types = ["ACTIVE", "BACK ON MARKET", "COMING SOON", "CONTINGENT", "PENDING", "RENTED", "SOLD"];
//   const types = ["Active", "Expired", "Off Market", "Pending", "Sold"]
//   const colors = listingColors.slice();
//   const paintColors = [];
//   types.forEach((t,i) => {
//     paintColors.push(t)
//     paintColors.push(colors[i])
//   })
//   const paintColorExpression = ["match", ["string", ["get", "listingstatus"]], ...paintColors, "#121212"];
//   // console.log(paintColorExpression)

//   return paintColorExpression
// }

// const genListingsChildFilters = () => {
//   const types = ["ACTIVE", "BACK ON MARKET", "COMING SOON", "CONTINGENT", "PENDING", "RENTED", "SOLD"];
//   const colors = listingColors.slice();
//   colors.splice(4,0,"darkorange");
//   const paintColors = [];
//   types.forEach((t,i) => {
//     paintColors.push(colors[i])
//   })
//   const filters = [];
//   types.forEach((t,i) => {
//     filters.push({
//       id: "listings_" + i,
//       name: t,
//       icon: `https://icongr.am/fontawesome/circle.svg?size=${t === 'ACTIVE' ? 24 : 18}&color=${paintColors[i].replace("#", "")}`,
//       checked: true,
//       field: "listingstatus",
//       value: t,
//       parents: ["listings"],
//       active: false
//     })
//   })
//   return filters
// }

const BaseLayers = () => {
  return [
    {
      id: "stats_areas",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.places_areas/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        generateId: "id",
        maxzoom: 6,
      },
      maxzoom: 6,
      "source-layer": "public.places_areas",
      type: "fill",
      paint: {
        "fill-color": "lightblue",
        "fill-opacity": 0.5,
      },
      metadata: {
        name: "Metro Boundaries",
        idField: "id",
        popup: true,
        hidePopup: true,
        highlight: true,
        hide: false,
        tooltip: "msa_name",
        visibility: "none",
      },
    },
    {
      id: "stats_areas_outline",
      source: "stats_areas",
      "source-layer": "public.places_areas",
      maxzoom: 6,
      type: "line",
      paint: {
        "line-color": "lightblue",
        "line-width": 3,
      },
      metadata: {
        name: "None",
        hide: true,
        visibility: "none",
        parent: "stats_areas",
      },
    },
    {
      id: "choropleth-fill",
      remove: true,
    },
    {
      id: "choropleth-stroke",
      remove: true,
    },
    {
      id: "places_grid",
      source: "places_grid",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.h3grid/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
      },
      "source-layer": "public.h3grid",
      type: "fill",
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "ppl_type3"], 1],
          "#5D8847",
          ["==", ["get", "ppl_type3"], 2],
          "#689ACB",
          ["==", ["get", "ppl_type3"], 3],
          "#CB6768",
          "#D9D9D9",
        ],
        "fill-opacity": 0.5,
      },
      metadata: {
        name: "Places Grid Med",
        idField: "id",
        popup: true,
        visibility: "none",
      },
    },
    {
      id: "places_grid2",
      source: "places_grid2",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.h3grid9/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
      },
      "source-layer": "public.h3grid9",
      type: "fill",
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "ppl_type3"], 1],
          "#5D8847",
          ["==", ["get", "ppl_type3"], 2],
          "#689ACB",
          ["==", ["get", "ppl_type3"], 3],
          "#CB6768",
          "#D9D9D9",
        ],
        "fill-opacity": 0.5,
      },
      metadata: {
        name: "Places Grid Small",
        idField: "index",
        popup: true,
        visibility: "none",
      },
    },
    {
      id: "h3-commercial-grid",
      _source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        generateId: true,
      },
      type: "fill",
      paint: {
        "fill-opacity": 0.7,
      },
      metadata: {
        name: "H3 Grids | Commercial",
        hidden: false,
        popup: true,
        idField: "id",
        visibility: "none",
      },
    },
    {
      id: "places_heatmap",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.places/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        minzoom: 6,
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": "transparent",
        "fill-opacity": 0,
      },
      metadata: {
        hide: true,
        visibility: "none",
      },
    },
    {
      id: "places-fill",
      childFilters: [
        {
          id: "1",
          name: "Type I",
          icon: square("#5D8847"),
          checked: true,
          field: "place_type",
          value: "1",
          parents: ["places-fill", "places-stroke"],
          active: false,
        },
        {
          id: "2",
          name: "Type II",
          icon: square("#689ACB"),
          checked: true,
          field: "place_type",
          value: "2",
          parents: ["places-fill", "places-stroke"],
          active: false,
        },
        {
          id: "3",
          name: "Type III",
          icon: square("#CB6768"),
          checked: true,
          field: "place_type",
          value: "3",
          parents: ["places-fill", "places-stroke"],
          active: false,
        },
        {
          id: "4",
          name: "Type IV",
          icon: square("#D9D9D9"),
          checked: true,
          field: "place_type",
          value: "4",
          parents: ["places-fill", "places-stroke"],
          active: false,
        },
      ],
      minzoom: 6,
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.places/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        minzoom: 6,
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": [
          "match",
          ["to-string", ["get", "place_type"]],
          "1",
          "#5d8847",
          "2",
          "#689acb",
          "3",
          "#cb6768",
          "4",
          "#d9d9d9",
          "hsla(0, 0%, 0%, 0)",
        ],
        "fill-opacity": 0.5,
      },
      metadata: {
        name: "Places Lens",
        hidden: false,
        popup: true,
        popupFields: [
          {
            name: "Places Type",
            field: "place_type",
          },
        ],
        hidePopup: true,
        highlight: true,
        ignoreFields: ["hh_pnt_white", "place_type", "_bbox"],
        idField: "id",
        layers: ["places-stroke", "places-fill"],
        filterField: "place_type",
        visibility: "visible",
        zoomto: true,
        //https://icongr.am/material/map-marker-circle.svg?size=36&color=currentColor
      },
    },
    {
      id: "places-stroke",
      hide: true,
      minzoom: 6,
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.places/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        minzoom: 6,
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "line",
      paint: {
        "line-color": [
          "case",
          ["==", ["feature-state", "highlighted"], true],
          "yellow",
          ["==", ["to-string", ["get", "place_type"]], "1"],
          "#5d8847",
          ["==", ["to-string", ["get", "place_type"]], "2"],
          "#689acb",
          ["==", ["to-string", ["get", "place_type"]], "3"],
          "#cb6768",
          "#d9d9d9",
        ],
        "line-width": ["case", ["==", ["feature-state", "highlighted"], true], 6, 1],
        "line-opacity": ["case", ["==", ["feature-state", "highlighted"], true], 1, 0.6],
      },
      metadata: {
        visibility: "visible",
        parent: "places-fill",
        popup: false,
      },
    },
    // {
    //   id: "listings_cluster",
    //   _source: {
    //     type: 'geojson',
    //     data: 'https://places.geoace.net/featureserv/collections/public.rets_listings/items.json?limit=-1&bbox=-65.9797728,31.41500855,-63.2672682,33.0285812&apiKey=' + apiKey,
    //     cluster: true,
    //     clusterMaxZoom: 16, // Max zoom to cluster points on
    //     clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    //   },
    //   type: 'circle',
    //   // filter: ['has', 'point_count'],
    //   paint: {
    //     // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    //     // with three steps to implement three types of circles:
    //     //   * Blue, 20px circles when point count is less than 100
    //     //   * Yellow, 30px circles when point count is between 100 and 750
    //     //   * Pink, 40px circles when point count is greater than or equal to 750
    //     'circle-color': [
    //       'step',
    //       ['get', 'point_count'],
    //       'black',
    //       0,
    //       '#51bbd6',
    //       5,
    //       '#f1f075',
    //       10,
    //       '#f28cb1',
    //       20,
    //       'firebrick'
    //     ],
    //     'circle-radius': [
    //       'step',
    //       ['get', 'point_count'],
    //       10, 2,
    //       20, 10,
    //       40
    //     ]
    //     // 'circle-radius': 10
    //   },
    //   minzoom: 12,
    //   metadata: {
    //     name: "Listings Clusters",
    //     popup: listingsPopup,
    //     hidden: false,
    //     idField: "listingid",
    //     visibility: "visible",
    //     filter: "listingsFitler",
    //     filterField: "listingid",
    //     tooltip: "address",
    //     legend: genListingsLegend()
    //   },
    // },
    {
      id: "metro-areas-scores-fill",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "avg_score_2024"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA Scores 2024",
        hidden: false,
        highlight: true,
        popup: metroScorePopup,
        idField: "area_id",
        visibility: "visible",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "metro-areas-scores-fill-25",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "avg_score_2025"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA Scores 2025",
        hidden: false,
        highlight: true,
        popup: metroScorePopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "metro-areas-scores-fill-26",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "avg_score_2026"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA Scores 2026",
        hidden: false,
        highlight: true,
        popup: metroScorePopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "metro-areas-scores-fill-27",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "avg_score_2027"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA Scores 2027",
        hidden: false,
        highlight: true,
        popup: metroScorePopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "metro-areas-scores-fill-28",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "avg_score_2028"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA Scores 2028",
        hidden: false,
        highlight: true,
        popup: metroScorePopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "cbg-place-score-fill-2024",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/postgisftw.ppl_places_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=2024`,
        ],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "year_2024"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      maxzoom: 24,
      metadata: {
        name: "Places Score 2024",
        parent: "metro-areas-scores-fill",
        hidden: true,
        highlight: true,
        popup: placesScorePopup,
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "cbg-place-score-fill-2025",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/postgisftw.ppl_places_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=2025`,
        ],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "year_2025"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      maxzoom: 24,
      metadata: {
        name: "Places Score 2025",
        parent: "metro-areas-scores-fill-25",
        hidden: true,
        highlight: true,
        popup: placesScorePopup,
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "cbg-place-score-fill-2026",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/postgisftw.ppl_places_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=2026`,
        ],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "year_2026"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      maxzoom: 24,
      metadata: {
        name: "Places Score 2026",
        parent: "metro-areas-scores-fill-26",
        hidden: true,
        highlight: true,
        popup: placesScorePopup,
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "cbg-place-score-fill-2027",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/postgisftw.ppl_places_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=2027`,
        ],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "year_2027"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      maxzoom: 24,
      metadata: {
        name: "Places Score 2027",
        parent: "metro-areas-scores-fill-27",
        hidden: true,
        highlight: true,
        popup: placesScorePopup,
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    {
      id: "cbg-place-score-fill-2028",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/postgisftw.ppl_places_scores/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=2028`,
        ],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp({ min: 1, max: 100 }, "year_2028"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      maxzoom: 24,
      metadata: {
        name: "Places Score 2028",
        parent: "metro-areas-scores-fill-28",
        hidden: true,
        highlight: true,
        popup: placesScorePopup,
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth",
            valueSuffix: "",
          },
        ],
      },
    },
    /** layer called MSA HPA which has two layers - fill and stroke, with the source being postgisftw.ppl_metro_hpa */
    {
      id: "metro-hpa-fill-2024",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp(metroHPABounds, "year_2024"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA HPA 2024",
        hidden: false,
        highlight: true,
        popup: metroHPAPopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "metro-hpa-fill-2025",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp(metroHPABounds, "year_2025"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,

      metadata: {
        name: "MSA HPA 2025",
        hidden: false,
        highlight: true,
        popup: metroHPAPopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "metro-hpa-fill-2026",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp(metroHPABounds, "year_2026"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,

      metadata: {
        name: "MSA HPA 2026",
        hidden: false,
        highlight: true,
        popup: metroHPAPopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "metro-hpa-fill-2027",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp(metroHPABounds, "year_2027"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,

      metadata: {
        name: "MSA HPA 2027",
        hidden: false,
        highlight: true,
        popup: metroHPAPopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "metro-hpa-fill-2028",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "area_id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        "fill-color": generateColorRamp(metroHPABounds, "year_2028"),
        "fill-opacity": 0.5,
      },
      maxzoom: 10,
      metadata: {
        name: "MSA HPA 2028",
        hidden: false,
        highlight: true,
        popup: metroHPAPopup,
        idField: "area_id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "metro-hpa-stroke",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_areas_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
      },
      sourceLayer: "default",
      type: "line",
      paint: {
        "line-color": "white",
        "line-width": 2,
      },
      metadata: {
        name: "MSA HPA",
        hidden: true,
        popup: false,
        idField: "id",
        visibility: "none",
        parent: [
          "metro-hpa-fill",
          "metro-hpa-fill-2024",
          "metro-hpa-fill-2025",
          "metro-hpa-fill-2026",
          "metro-hpa-fill-2027",
          "metro-hpa-fill-2028",
        ],
      },
    },
    {
      id: "places-hpa-fill",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/postgisftw.ppl_places_hpa/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        promoteId: "id",
      },
      sourceLayer: "default",
      type: "fill",
      paint: {
        // "fill-color": "black",
        "fill-color": generateColorRamp(placesHPABounds, "year_2024"),
        "fill-opacity": 0.5,
      },
      minzoom: 10,
      metadata: {
        name: "Places Forward HPA Y/E 2024",
        hidden: true,
        highlight: true,
        parent: "metro-hpa-fill",
        //public.mview_hpa_annualized_${year}
        popup: (f) => {
          return {
            html: `<div><strong>HPA 2024</strong>: ${+(f?.properties?.year_2024).toFixed(
              2
            )}%</div>`,
          };
        },
        idField: "id",
        visibility: "none",
        legend: [
          {
            type: "choropleth-list",
            valueSuffix: "%",
          },
        ],
      },
    },
    {
      id: "listings",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.rets_listings/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
      },
      sourceLayer: "public.rets_listings",
      // source: "listings",
      // sourceType: "geojson",
      // sourceData: Listings,
      type: "symbol",
      minzoom: 13,
      layout: {
        "icon-image": [
          "case",
          ["==", ["get", "listingstatus"], "Active"],
          "house-circle-outline-red",
          ["==", ["get", "listingstatus"], "Expired"],
          "house-circle-outline-black",
          ["==", ["get", "listingstatus"], "Off Market"],
          "house-circle-outline-blue",
          ["==", ["get", "listingstatus"], "Pending"],
          "house-circle-outline-orange",
          "house-circle-outline-gray",
        ],
        "icon-size": ["interpolate", ["linear"], ["zoom"], 6, 0.2, 16, 0.8],
        "icon-offset": [0, -18],
        "icon-allow-overlap": true,
      },
      // type: "circle",
      // paint: {
      //   "circle-color": genListingsPaintColor(),
      //   "circle-radius": ["case",
      //     ["==", ["get", "listingstatus"], "Active"],7,4],
      //   "circle-stroke-color": "white",
      //   "circle-stroke-width": 2,
      //   "circle-blur": 0.2,
      // },
      // childFilters: genListingsChildFilters(),
      filter: [
        "all",
        ["!=", ["get", "listingstatus"], "Sold"],
        ["!=", ["get", "listingstatus"], "Closed"],
      ],
      metadata: {
        name: "Listings",
        popup: listingsPopup,
        hidden: false,
        idField: "listingid",
        visibility: "visible",
        filter: "listingsFitler",
        filterField: "listingid",
        // tooltip: "address",
        legend: genListingsLegend(),
      },
    },
    {
      id: "properties",
      hide: false,
      source: "properties",
      "source-layer": "public.mview_prod_g73_properties_sm",
      minzoom: 14,
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/public.mview_prod_g73_properties_sm/{z}/{x}/{y}.pbf?apiKey=${apiKey}`,
        ],
        minzoom: 14,
        maxzoom: 22,
      },
      type: "circle",
      paint: {
        "circle-color": "whitesmoke",
        "circle-radius": {
          stops: [
            [15, 2.6],
            [18, 6],
          ],
        },
        "circle-stroke-color": "#121212",
        "circle-stroke-width": {
          stops: [
            [15, 1.6],
            [18, 2.6],
          ],
        },
        "circle-blur": 0.2,
      },
      // popup: listingsPopup,
      popup: true,

      metadata: {
        name: "Residential Properties",
        hidden: false,
        popup: true, //TODO move popup from popup function to this library
        idField: "id",
        visibility: "none",
        legend: [
          {
            name: "All Residential Properties",
            type: "circle",
            color: "white",
            strokeColor: "black",
          },
        ],
      },
    },
    // {
    //   id: "g73_firstscore",
    //   minzoom: 0,
    //   hide: true,
    //   source: "g73_firstscore",
    //   _source: {
    //     type: "vector",
    //     tiles: ["https://tiles.zerospatial.com/public.g73_firstscore/{z}/{x}/{y}.pbf"],
    //     minzoom: 13,
    //     maxzoom: 17,
    //   },
    //   "source-layer": "g73_firstscore",
    //   type: "circle",
    //   paint: {
    //     "circle-color": "white",
    //     "circle-radius": [
    //       "interpolate",
    //       ["linear"],
    //       ["zoom"],
    //       13,
    //       3,
    //       18,
    //       6
    //     ],
    //     "circle-stroke-color": [
    //       "case",
    //       ["==", ["get", "f"], 99],
    //       "#93003a",
    //       "#93003a"
    //     ],
    //     "circle-stroke-width":  [
    //       "interpolate",
    //       ["linear"],
    //       ["zoom"],
    //       13,
    //       1,
    //       18,
    //       3
    //     ],
    //     "circle-blur": 0.2,
    //   },
    //   // popup: listingsPopup,
    //   popup: true,
    //   metadata: {
    //     idField: "id",
    //     name: "Places Highest Probability of Listing",
    //     layers: ["g73_firstscore"],
    //     tooltip: "address",
    //     popup: true,
    //     visibility: "none",
    //     // legend: /*html*/ `
    //     //   <ul>
    //     //     <li style="list-style:none;"><span class="legend-circle" style="background-color:white;border-color:#93003a"></span> 20% probability of listing in the next 12 months
    //     //     </li>
    //     //   </ul>
    //     // `,
    //   },
    // },
    {
      id: "reis-sample",
      source: "composite",
      "source-layer": "reis_sample-6xr9r6",
      minzoom: 0,
      childFilters: genCommercialChildFilters(),
      metadata: {
        name: "Commercial Properties",
        // legend: /*html*/ `
        //   <ul>
        //     <li style="list-style:none;"><span class="legend-circle" style="background-color:#000;border-color:#fff"></span> Commercial Properties
        //     </li>
        //   </ul>
        // `,
        // legend: commercialLegend(),
        popup: true,
        idField: "fid",
        visibility: "none",
      },
    },
    {
      id: "parcels-outline",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/public.mview_prod_ca_sd_parcels_unique/{z}/{x}/{y}.pbf?apiKey=${apiKey}`,
        ],
        minzoom: 15,
        maxzoom: 18,
      },
      sourceLayer: "public.mview_prod_ca_sd_parcels_unique",
      type: "line",
      paint: {
        "line-color": "#a6a6a6",
        "line-width": {
          stops: [
            [14, 1],
            [20, 2],
          ],
        },
        "line-opacity": {
          stops: [
            [0, 0],
            [14, 0.1],
            [22, 0.5],
          ],
        },
      },
      metadata: {
        name: "Parcels",
        visibility: "visible",
        hide: true,
      },
      beforeLayer: "places-fill",
    },
    {
      id: "pois",
      source: "pois",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.ca_pois/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        maxzoom: 15,
        minzoom: 12,
      },
      minzoom: 12,
      "source-layer": "public.ca_pois",
      type: "circle",
      paint: {
        "circle-radius": 4,
        "circle-color": colorMatchArray,
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
      filter: ["has", "categry"],
      childFilters: genPoiChildFitlers(),
      metadata: {
        name: "Points of Interest",
        layers: ["pois", "rapid-transit"],
        idField: "id",
        directory: "POIS",
        group: "POIS",
        hidden: false,
        popup: true,
        visibility: "none",
        filterField: "categry",
      },
      // tooltip: "name"
    },
    {
      id: "rapid-transit",
      metadata: {
        name: "Transit Stops",
        parent: "pois",
        hidden: true,
        popup: false,
        visibility: "none",
        legend: [
          {
            type: "icon",
            icon: busIcon,
            name: "Transit Stops",
          },
        ],
      },
    },
    {
      id: "sd-fire-stations",
      metadata: {
        name: "Fire Stations",
        hidden: false,
        popup: false,
        visibility: "none",
        legend: [
          {
            type: "icon",
            icon: `${fireIcon}`,
            name: "Fire Stations",
          },
        ],
      },
    },
    {
      id: "fire_risk",
      source: "fire_risk",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/public.usa_firehaz_demographics_by_block/{z}/{x}/{y}.pbf?apiKey=${apiKey}`,
        ],
      },
      "source-layer": "public.usa_firehaz_demographics_by_block",
      type: "fill",
      paint: {
        "fill-color": {
          property: "mean",
          stops: [
            // [0,"rgba(204, 204, 204, 0.5)"],
            // [1,"rgba(255, 244, 181, 0.5)"],
            // [2.5,"rgba(254, 153, 41, 0.5)"],
            // [3.75,"rgba(211, 84, 6, 0.5)"],
            // [5,"rgba(102, 37, 6, 0.5)"],
            [0, "#eee"],
            [1, "#ce7272"],
            [2.5, "#a11e1e"],
            [3.75, "#811818"],
            [5, "#500f0f"],
          ],
        },
        "fill-opacity": 0.7,
      },
      filter: [">", ["get", "mean"], -1],
      beforeLayer: "listings",
      metadata: {
        name: "Fire Risk Potential",
        hidden: false,
        popup: false,
        visibility: "none",
        legend: [
          {
            type: "choropleth",
          },
        ],
      },
    },
    // {
    //   id: "fire_risk2",
    //   source: "fire_risk2",
    //   _source: {
    //     type: "vector",
    //     tiles: [ `${apiGateway}/tiles/public.ca_fhsz_lra/{z}/{x}/{y}.pbf?apikey=${apiKey}`]
    //   },
    //   "source-layer": "public.ca_fhsz_lra",
    //   type: "fill",
    //   paint: {
    //     "fill-color": "red",
    //     "fill-opacity": 0.5
    //   },
    //   beforeLayer: "listings",
    //   metadata: {
    //     name: "Fire Risk Areas",
    //     hidden: false,
    //     popup: false,
    //     visibility: "none",
    //     legend: [{
    //       type: "fill",
    //       color: "red",
    //       name: "Areas of Very High Fire Risk"
    //     }]
    //   },
    // },
    {
      id: "nfhl",
      source: "nfhl",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.usa_fema_flood/{z}/{x}/{y}.pbf?apikey=${apiKey}`],
        minzoom: 12,
      },
      minzoom: 12,
      "source-layer": "public.usa_fema_flood",
      type: "fill",
      paint: {
        "fill-color": "blue",
        "fill-opacity": 0.5,
        "fill-outline-color": "transparent",
      },
      filter: ["==", ["get", "fld_zone"], "AE"],
      metadata: {
        name: "Flood Zones",
        hidden: false,
        idField: "id",
        popup: false,
        visibility: "none",
        legend: [
          {
            type: "fill",
            color: "blue",
            strokeColor: "darkblue",
            name: "High Risk Flood Areas",
          },
        ],
      },
    },
    {
      id: "sd_zoning",
      source: "sd_zoning",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.ca_sd_zoning/{z}/{x}/{y}.pbf?apikey=${apiKey}`],
        maxzoom: 15,
        minzoom: 12,
      },
      minzoom: 12,
      sourceLayer: "public.ca_sd_zoning",
      type: "fill",
      paint: {
        // "fill-color": 'rgba(78,198,207,0.5)',
        "fill-color": ["get", "_vt_fillcolor"],
        "fill-opacity": 0.7,
        "fill-outline-color": "black",
      },
      filter: ["has", "_vt_fillcolor"],
      metadata: {
        name: "San Diego Zoning",
        idField: "id",
        hidden: false,
        popup: true,
        popupFields: [
          {
            name: "Ordinance",
            field: "ordnum",
          },
          {
            name: "Zoning",
            field: "zone_name",
          },
          {
            name: "Zoning Ordinance Info",
            field: "zoning_ord_link",
            type: "link",
          },
        ],
        visibility: "none",
        legend: [
          {
            type: "fill",
            color: "gray",
            strokeColor: "black",
            name: "Various Zoning",
          },
        ],
      },
    },
    {
      id: "public.ca_census_opportunity_zones",
      source: "public.ca_census_opportunity_zoness",
      _source: {
        type: "vector",
        tiles: [
          `${apiGateway}/tiles/public.usa_opportunity_zones/{z}/{x}/{y}.pbf?apiKey=${apiKey}`,
        ],
      },
      "source-layer": "public.usa_opportunity_zones",
      type: "fill",
      paint: {
        "fill-color": "black",
        "fill-opacity": 0.7,
      },
      metadata: {
        name: "Opportunity Zones",
        idField: "id",
        popup: true,
        popupFields: [
          {
            name: "Census Tract",
            field: "censustrac",
          },
        ],
        legend: [
          {
            type: "fill",
            color: "rgba(0,0,0,0.8)",
            strokeColor: "white",
          },
        ],
        visibility: "none",
      },
    },
    {
      id: "ht_costs",
      source: "composite",
      "source-layer": "ca_places_1-cgg8ip",
      type: "fill",
      minzoom: 8,
      paint: {
        "fill-color": {
          property: "ht_80ami",
          stops: [
            [45, "#d3f2a3"],
            [65, "#4c9b82"],
            [100, "#074050"], //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
          ],
        },
        "fill-opacity": 0.8,
      },
      layout: {
        visibility: "none",
      },
      filter: [">", ["get", "ht_80ami"], 0],
      metadata: {
        name: "H+T Costs %",
        directory: "Places",
        group: "Places",
        legend: [
          {
            type: "choropleth",
          },
        ],
        hidden: false,
        popup: true,
        popupFields: [
          {
            name: "H+T Costs %",
            field: "ht_80ami",
          },
        ],
        idField: "GEOID10",
        visibility: "none",
      },
    },
    {
      id: "home_price_appreciation",
      minzoom: 8,

      source: "composite",
      "source-layer": "ca_places_1-cgg8ip",
      type: "fill",

      paint: {
        "fill-color": {
          property: "hpa",
          stops: [
            [0.6, "#d3f2a3"],
            [0.88, "#4c9b82"],
            [1.15, "#074050"], //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
          ],
        },
        "fill-opacity": 0.8,
      },
      layout: {
        visibility: "none",
      },
      filter: [">", ["get", "hpa"], 0],
      metadata: {
        name: "Home Price Appreciation ('12-'19)",
        directory: "Places",
        group: "Places",
        legend: [
          {
            type: "choropleth",
          },
        ],
        hidden: true,
        popup: true,
        popupFields: [
          {
            name: "HPA",
            field: "hpa",
          },
        ],
        idField: "GEOID10",
        visibility: "none",
      },
    },
    // {
    //   "id": "mortgage_default_rate",
    //   "source": "composite",
    //   "source-layer": "ca_places_1-cgg8ip",
    //   "type": "fill",
    //   "paint": {
    //     "fill-color": {
    //       'property': 'default',
    //         'stops': [
    //         [0.23, '#d3f2a3'],
    //         [0.367, '#4c9b82'],
    //         [0.51, '#074050'] //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
    //       ]
    //     },
    //     "fill-opacity": 0.8
    //   },
    //   "layout": {
    //     "visibility": "none"
    //   },
    //   "filter": [">", ["get", "default"], 0],
    //   "metadata": {
    //     "name": "Mortgage Rate Missing Data",
    //     "directory": "Places",
    //     "group": "Places",
    //     "hidden": false,
    //     "popup": true,
    //     "idfield: "id",
    //     "visible": false
    //   }
    // },
    // {
    //   "id": "pnt_minority_borrow",
    //   "source": "composite",
    //   "source-layer": "ca_places_1-cgg8ip",
    //   "type": "fill",
    //   "paint": {
    //     "fill-color": {
    //       'property': 'minority',
    //         'stops': [
    //         [0, '#d3f2a3'],
    //         [0.25, '#4c9b82'],
    //         [0.45, '#074050'] //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
    //       ]
    //     },
    //     "fill-opacity": 0.8
    //   },
    //   "filter": [">", ["get", "minority"], 0],

    //   "layout": {
    //     "visibility": "none"
    //   },
    //   "metadata": {
    //     "name": "Minority Borrowers (%) - Missing Data",
    //     "directory": "Places",
    //     "group": "Places",
    //     "hidden": false,
    //     "popup": true,
    //     "idfield: "id",
    //     "visible": false
    //   }
    // },
    {
      id: "borrow_med_income",
      minzoom: 8,

      source: "composite",
      "source-layer": "ca_places_1-cgg8ip",
      type: "fill",
      paint: {
        "fill-color": {
          property: "income",
          stops: [
            [68000, "#d3f2a3"],
            [130000, "#4c9b82"],
            [190000, "#074050"], //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
          ],
        },
        "fill-opacity": 0.8,
      },
      layout: {
        visibility: "none",
      },
      filter: [">", ["get", "income"], 0],

      metadata: {
        name: "Borrower Median Income",
        directory: "Places",
        group: "Places",
        legend: [
          {
            type: "choropleth",
          },
        ],
        hidden: true,
        popup: true,
        popupFields: [
          {
            name: "Med Income",
            field: "income",
            type: "number",
          },
        ],
        idField: "GEOID10",
        visibility: "none",
      },
    },
    {
      id: "med_ltv_ratios_19",
      minzoom: 8,

      source: "composite",
      "source-layer": "ca_places_1-cgg8ip",
      type: "fill",
      paint: {
        "fill-color": {
          property: "median_ltv",
          stops: [
            [0.45, "#d3f2a3"],
            [0.745, "#4c9b82"],
            [1.05, "#074050"], //#d3f2a3,#97e196,#6cc08b,#4c9b82,#217a79,#105965,#074050
          ],
        },
        "fill-opacity": 0.8,
      },
      filter: [">", ["get", "median_ltv"], 0],

      layout: {
        visibility: "none",
      },
      metadata: {
        name: "Median LTV Ratio ('19)",
        directory: "Places",
        group: "Places",
        hidden: true,
        popup: true,
        legend: [
          {
            type: "choropleth",
          },
        ],
        popupFields: [
          {
            name: "Med LTV Ratio",
            field: "median_ltv",
            type: "number",
          },
        ],
        idField: "GEOID10",
        visibility: "none",
      },
    },
    {
      id: "med_sold_price_19",
      minzoom: 8,

      source: "composite",
      "source-layer": "ca_places_1-cgg8ip",
      type: "fill",
      paint: {
        "fill-color": {
          property: "median_sol",
          stops: [
            [190000, "#ede5cf"],
            [650000, "#d39c83"],
            [1125000, "#541f3f"],
          ],
        },
        "fill-opacity": 0.8,
      },
      layout: {
        visibility: "none",
      },
      filter: [">", ["get", "median_sol"], 0],
      metadata: {
        name: "Median Sold Price ('19)",
        directory: "Places",
        group: "Places",
        legend: [
          {
            type: "choropleth",
          },
        ],
        hidden: true,
        popup: true,
        popupFields: [
          {
            field: "median_sol",
            name: "Median Sold Price",
            type: "number",
          },
        ],
        idField: "GEOID10",
        visibility: "none",
        _legend: "#ede5cf,#e0c2a2,#d39c83,#c1766f,#a65461,#813753,#541f3f",
      },
    },
    // {
    //   id: "raw_places",
    //   hide: false,
    //   source: "composite",
    //   "source-layer": "places-9tbejv",
    //   minzoom: 11,
    //   type: "fill",
    //   paint: {
    //     "fill-color": "lightgray",
    //     "fill-opacity": 0.5
    //   },
    //   metadata: {
    //     name: "Raw Data | Places",
    //     hidden: false,
    //     ignoreFields: ["hh_pnt_white", "place_type"],
    //     popup: true, //TODO move popup from popup function to this library
    //     idField: "id",
    //     visibility: "none",
    //   },
    // },
    {
      id: "sd_sample",
      hide: false,
      source: "sd_sample",
      sourceType: "geojson",
      sourceData: sd_sample,
      type: "circle",
      paint: {
        "circle-color": "black",
        "circle-radius": {
          stops: [
            [6, 3],
            [16, 7],
          ],
        },
        "circle-stroke-color": "white",
        "circle-stroke-width": {
          stops: [
            [6, 0],
            [18, 3],
          ],
        },
        "circle-blur": 0.2,
      },
      minzoom: 8,
      metadata: {
        name: "CRE Performance Data",
        hide: false,
        popup: true,
        idField: "Id",
        visibility: "none",
        legend: [
          {
            name: "Commercial | REIS",
            type: "circle",
            color: "black",
            strokeColor: "gray",
          },
        ],
      },
    },
    {
      id: "raw_properties",
      hide: true,
      source: "raw_properties",
      "source-layer": "g73_properties",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.g73_properties/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        minzoom: 13,
        maxzoom: 15,
      },
      minzoom: 12,
      type: "circle",
      paint: {
        "circle-color": "whitesmoke",
        "circle-radius": {
          stops: [
            [6, 1],
            [16, 3],
          ],
        },
        "circle-stroke-color": "#121212",
        "circle-stroke-width": {
          stops: [
            [6, 0],
            [18, 2],
          ],
        },
        "circle-blur": 0.2,
      },
      metadata: {
        name: "Raw Data | Properties",
        layers: ["raw_properties"],
        hide: true,
        popup: true, //TODO move popup from popup function to this library
        idField: "id",
        visibility: "none",
      },
    },
    {
      id: "home",
      hide: false,
      source: "home",
      minzoom: 14,
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.g73_homes/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
        minzoom: 14,
        maxzoom: 18,
      },
      "source-layer": "public.g73_homes",
      type: "circle",
      paint: {
        "circle-color": "skyblue",
        "circle-radius": {
          stops: [
            [6, 1],
            [16, 3],
          ],
        },
        "circle-stroke-color": "#121212",
        "circle-stroke-width": {
          stops: [
            [6, 0],
            [18, 2],
          ],
        },
        "circle-blur": 0.2,
      },
      metadata: {
        name: "Raw Data | Homes",
        hide: true,
        ignoreFields: ["geo_geo_precision", "geo_addr_confidence", "geo_firstvintage"],
        popup: true,
        idField: "fid",
        visibility: "none",
        legend: [
          {
            name: "Homes",
            type: "circle",
            color: "skyblue",
            strokeColor: "#121212",
          },
        ],
      },
    },
    {
      id: "zipcodes-fill",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.usa_zipcodes_tiger_2019/{z}/{x}/{y}.pbf?limit=1000000`],
        minzoom: 8,
      },
      "source-layer": "public.usa_zipcodes_tiger_2019",
      type: "fill",
      paint: {
        "fill-color": "firebrick",
        "fill-opacity": 0.2,
      },
      metadata: {
        name: "ZIP Codes",
        popup: true,
        popupFields: [
          {
            field: "geoid10",
            name: "ZIP Code",
          },
        ],
        idField: "fid",
        visibility: "none",
        legend: [
          {
            name: "Zipcodes",
            type: "line",
            color: "firebrick",
            strokeColor: "firebrick",
          },
        ],
      },
    },
    {
      id: "zipcodes",
      _source: {
        type: "vector",
        tiles: [`${apiGateway}/tiles/public.usa_zipcodes_tiger_2019/{z}/{x}/{y}.pbf?limit=1000000`],
        minzoom: 8,
      },
      "source-layer": "public.usa_zipcodes_tiger_2019",
      type: "line",
      paint: {
        "line-color": "firebrick",
        "line-width": 3,
      },
      metadata: {
        hide: true,
        parent: "zipcodes-fill",
        visibility: "none",
      },
    },

    // {
    //   id: "ppl_places",
    //   type: "fill",
    //   _source: {
    //     type: "vector",
    //     tiles: [`${apiGateway}/api/v1/tile-cache/ppl_places/tiles/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
    //     minzoom: 7,
    //     maxzoom: 14
    //   },
    //   sourceLayer: "ppl_places",
    //   paint: {
    //     "fill-color": [
    //       "step",
    //       ["get", "pptype_bet"],
    //       "hsla(0, 0%, 0%, 0)",
    //       1,
    //       "#5d8847",
    //       2,
    //       "#689acb",
    //       3,
    //       "#cb6768",
    //       4,
    //       "#d9d9d9"
    //     ],
    //     "fill-opacity": 0.5
    //   },
    //   minzoom: 5,
    //   childFilters: [
    //     {
    //       id: "1",
    //       name: "Type I",
    //       icon: "square(5D8847",
    //       checked: true,
    //       field: "pptype_bet",
    //       value: "1",
    //       parents: ["ppl_places"],
    //       active: false
    //     },
    //     {
    //       id: "2",
    //       name: "Type II",
    //       icon: "square(689ACB",
    //       checked: true,
    //       field: "pptype_bet",
    //       value: "2",
    //       parents: ["ppl_places"],
    //       active: false
    //     },
    //     {
    //       id: "3",
    //       name: "Type III",
    //       icon: "square(CB6768",
    //       checked: true,
    //       field: "pptype_bet",
    //       value: "3",
    //       parents: ["ppl_places"],
    //       active: false
    //     },
    //     {
    //       id: "4",
    //       name: "Type IV",
    //       icon: "square(D9D9D9",
    //       checked: true,
    //       field: "pptype_bet",
    //       value: "4",
    //       parents: ["ppl_places"],
    //       active: false
    //     }
    //   ],
    //   metadata: {
    //     name: "Raw Data | Places Lens",
    //     hide: false,
    //     popup: true,
    //     // ignoreFields: ["hh_pnt_white", "place_type"],
    //     idField: "id",
    //     // layers: ["places-stroke", "places-fill"],
    //     // filterField: "place_type",
    //     visibility: "none",
    //     //https://icongr.am/material/map-marker-circle.svg?size=36&color=currentColor
    //   },
    // },
    // {
    //   id: "msa_ppl_places_large",
    //   type: "fill",
    //   _source: {
    //     type: "vector",
    //     tiles: [`${apiGateway}/tiles/public._tmp_msa_ppl_places/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
    //     minzoom: 7,
    //     maxzoom: 10
    //   },
    //   sourceLayer: "public._tmp_msa_ppl_places",
    //   paint: {
    //     "fill-color": [
    //       "step",
    //       ["get", "pptype"],
    //       "hsla(0, 0%, 0%, 0)",
    //       1,
    //       "#5d8847",
    //       2,
    //       "#689acb",
    //       3,
    //       "#cb6768",
    //       4,
    //       "#d9d9d9"
    //     ],
    //     "fill-outline-color": "transparent",
    //     "fill-opacity": {"stops":[
    //         [0,0.4],
    //         [12,0.4],
    //         [12.1,0]
    //       ]
    //     }
    //   },
    //   filter: ["!=", ["get", "ft22name"], "San Diego"],
    //   metadata: {
    //     name: "Raw Data | MSA Places Lens",
    //     hide: false,
    //     popup: false,
    //     idField: "id",
    //     layers: ["msa_ppl_places_large", "msa_ppl_places"],
    //     visibility: "visible",
    //     beforeLayer: "waterway-label"
    //   },
    // },
    // {
    //   id: "msa_ppl_places",
    //   hide: true,
    //   _source: {
    //     type: "vector",
    //     tiles: [`${apiGateway}/tiles/public._tmp_msa_ppl_places_ii/{z}/{x}/{y}.pbf?apiKey=${apiKey}`],
    //     minzoom: 8,
    //   },
    //   minzoom: 8,
    //   sourceLayer: "public._tmp_msa_ppl_places_ii",
    //   type: "fill",
    //   paint: {
    //     "fill-color": [
    //       "step",
    //       ["get", "pptype"],
    //       "hsla(0, 0%, 0%, 0)",
    //       1,
    //       "#5d8847",
    //       2,
    //       "#689acb",
    //       3,
    //       "#cb6768",
    //       4,
    //       "#d9d9d9",
    //     ],
    //     "fill-opacity": 0.5,
    //   },
    //   "line-width": 3,
    //   filter: ["!=", ["get", "ft22name"], "San Diego"],
    //   metadata: {
    //     visibility: "visible",
    //     name: "MSA Place Type",
    //     popup: true,
    //     popupFields: [
    //       {
    //         name: "Places Type",
    //         field: "pptype",
    //       },
    //     ],
    //     idField: "fid",
    //     beforeLayer: "listings",
    //   },
    // },
    {
      id: "satellite",
      source: "mapbox",
      metadata: {
        name: "Satellite",
        popup: false,
        visibility: "none",
        legend: false,
      },
    },
    // {
    //   id: "satellite",
    //   source: "mapbox",
    //   metadata: {
    //     name: "Satellite",
    //     popup: false,
    //     visibility: "none",
    //     legend: false
    //   },
    // },
  ];
};

const Layers = () => {
  const layers = BaseLayers();
  const pplHPA = (year) => ({
    id: `places-hpa-fill-${year}`,
    _source: {
      type: "vector",
      tiles: [
        `${apiGateway}/tiles/postgisftw.ppl_places_hpa_ii/{z}/{x}/{y}.pbf?apiKey=${apiKey}&yr=${year}`,
      ],
      promoteId: "id",
    },
    sourceLayer: "default",
    type: "fill",
    paint: {
      // "fill-color": "black",
      "fill-color": generateColorRamp(placesHPABounds, `year_${year}`),
      "fill-opacity": 0.5,
    },
    minzoom: 10,
    metadata: {
      name: `Places Forward HPA Y/E ${year}`,
      hidden: true,
      highlight: true,
      parent: `metro-hpa-fill-${year}`,
      popup: (f) => {
        const years = [2024, 2025, 2026, 2027, 2028];
        const type = f.properties.place_type || null;
        const _type = type ? placeTypes.find((t) => t.type === +type)?.label : null;
        return {
          html: `<div><h4 style="font-weight:bold;margin-bottom:12px">${
            _type ? `Submarket: ${_type}</h4>` : ""
          }
          <strong>Metro Avg HPA Y/E ${year}: ${f?.properties?.["metro_hpa_" + year]}<br />
          Submarket Avg HPA Y/E ${year}: ${
            type ? f?.properties?.[`type${type}_avg_hpa`] : "N/A"
          }</strong>
          <br>
          <br>
          ${years
            .map(
              (y) =>
                `<strong>Forward HPA Y/E ${y} (CBG): ${
                  f?.properties?.["year_" + y]
                    ? +f?.properties?.["year_" + y].toFixed(1) + "%"
                    : "N/A"
                }</strong><br />`
            )
            .join("")}</div>`,
        };
      },
      idField: "id",
      visibility: "none",
    },
  });
  // index of layers where id === listings
  const years = [2024, 2025, 2026, 2027, 2028];
  years.forEach((year) => {
    const listingsIndex = layers.findIndex((l) => l.id === "listings");
    layers.splice(listingsIndex, 0, pplHPA(year));
  });

  return layers;
};

export { Layers };
