import {
  Avatar,
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export const TeamProfile = ({ image, name, title, about }) => {
  return (
    <Flex py={6}>
      <Box
        maxW={"320px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        // boxShadow={'xl'}
        rounded={"md"}
        p={6}
        textAlign={"center"}
      >
        <Avatar
          size={"xl"}
          src={image}
          alt={"Avatar Alt"}
          mb={4}
          pos={"relative"}
          style={{ filter: "grayscale(1)" }}
          // _after={{
          //   content: '""',
          //   w: 4,
          //   h: 4,
          //   bg: 'green.300',
          //   border: '2px solid white',
          //   rounded: 'full',
          //   pos: 'absolute',
          //   bottom: 0,
          //   right: 3,
          // }}
        />
        <Heading
          fontSize={"2xl"}
          fontFamily={"body"}
          color={useColorModeValue("gray.700", "gray.200")}
        >
          {name}
        </Heading>
        <Text
          fontWeight={300}
          mb={4}
          color={useColorModeValue("gray.600", "gray.500")}
        >
          {title}
        </Text>
        <Text
          textAlign={"center"}
          color={useColorModeValue("gray.500", "gray.400")}
          px={3}
          style={{ textAlign: "justify" }}
        >
          {about}
        </Text>
      </Box>
    </Flex>
  );
};
