import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { BsLayersHalf } from "react-icons/bs";
import LayerControl from "../Layers/MapLayerControl"
import React from "react";

function MapLayerControl({ layers, setLayers }) {
  return (
    <Box
      // position="absolute"             
      // top={2}
      // left={2}
    >
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen} 
            as={IconButton}
            aria-label="Layer Control"
            title="Layer Control"
            icon={<BsLayersHalf />}
            variant="solid"
            color="black"
            backgroundColor={"white"}
            shadow="sm"
            mb={0}
            // borderRadius="3px"
          ></MenuButton>
          <MenuList minWidth="240px" pointerEvents={isOpen ? "all" : "none"} display={isOpen ? "block" : "none"} zIndex={2}>
  
            <LayerControl layers={layers} setLayers={setLayers} />
  
            {/* <MenuOptionGroup defaultValue="asc" title="Order" type="radio">
              <MenuItemOption value="asc">Ascending</MenuItemOption>
              <MenuItemOption value="desc">Descending</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup title="Country" type="checkbox">
              <MenuItemOption value="email">Email</MenuItemOption>
              <MenuItemOption value="phone">Phone</MenuItemOption>
              <MenuItemOption value="country">Country</MenuItemOption>
            </MenuOptionGroup> */}
          </MenuList>
        </>
      )}
    </Menu>
    </Box>
  );
}

function DrawerExample({ layers, setLayers }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <>
      <IconButton aria-label='Layer Selector'
        onClick={onOpen}
        variant="solid"
        color="black"
        backgroundColor={"white"}
        shadow="sm"
        ref={btnRef}
        icon={<BsLayersHalf />} />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Layer Control</DrawerHeader>

          <DrawerBody>
            <LayerControl layers={layers} setLayers={setLayers} />
          </DrawerBody>

          <DrawerFooter>
            {/* <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function MapLayerControlModal ({layers, setLayers}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <IconButton aria-label='Layer Selector'
        borderRadius={3}
        onClick={onOpen}
        variant="solid"
        color="black"
        backgroundColor={"white"}
        shadow="sm"
        icon={<BsLayersHalf />}
        m={2} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Map Layer Selector</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <LayerControl layers={layers} setLayers={setLayers} />

          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export { MapLayerControl, MapLayerControlModal, DrawerExample };
