import { Box, Center, Flex, Heading, Table, Tbody, Td, Tr } from "@chakra-ui/react";

import chroma from "chroma-js";

export default function PlacesScoreHeatGrid({ data }) {
  // console.log("[PlacesScoreHeatGrid] data: ", data[0]);
  //values from 0-100, at 5 point intervals
  const chartValues = new Array(21).fill(0).map((_, i) => i * 5);
  // console.log("[PlacesScoreHeatGrid] chartValues: ", chartValues);
  const riskBreaks = chartValues.slice(0, 11);
  // const returnBreaks = new Array(11).fill(0);
  riskBreaks.sort((a, b) => b - a);

  //i need the bottom left of the chart to be red, the top right to be green, and the diagonal to be yellow

  const ytr = chroma.scale(["yellow", "red"]).mode("lch").colors(11); //row start values
  const ytg = chroma.scale(["green", "yellow"]).mode("lch").colors(11); //row end values
  const colormap = ytr.map((r, i) => {
    const g = ytg[i];
    return chroma.scale([r, g]).mode("hsv").colors(11);
  });

  // data.forEach((d) => {
  //   if (!riskBreaks.includes(d.risk)) {
  //     riskBreaks.push(d.risk);
  //   }
  //   if (!returnBreaks.includes(d.return)) {
  //     returnBreaks.push(d.return);
  //   }
  // });
  // riskBreaks.sort((a, b) => a - b);
  // returnBreaks.sort((a, b) => a - b);

  return (
    <Center bg="white" flexDirection={"column"} mx={2}>
      <Flex>
        <Flex
          flexDir={"column"}
          flex={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          mr={2}
          fontSize={"sm"}
        >
          <Box mt={32}>
            <Box>lowest risk</Box>
          </Box>
          {/* svg with line and arrow pointing down */}
          <svg
            width="50"
            height="100%"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <line x1="50" y1="2" x2="50" y2="98" stroke="black" strokeWidth="2" />
            <polygon points="50,98 40,96 60,96" fill="black" />
          </svg>
          <Box>
            <Box>highest risk</Box>
          </Box>
        </Flex>
        <Box>
          <Box w="100%" maxW="560px">
            <Box>
              <Heading size="md" textAlign="center" mb={4} alignSelf={"center"}>
                Places Score Grid<sup style={{ fontSize: "0.6rem", top: "-10px" }}>TM</sup>
              </Heading>
            </Box>
            <Flex justifyContent="space-between" p={2} fontSize={"sm"}>
              <Box>lowest return</Box>
              <Box>highest return</Box>
            </Flex>
            {/* svg line with arrow at end */}
            <svg
              width="100%"
              height="50"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <line x1="2" y1="50" x2="98" y2="50" stroke="black" strokeWidth="2" />
              <polygon points="98,50 96,40 96,60" fill="black" />
            </svg>
          </Box>

          <Box border="solid thin lightgray" borderRadius={"sm"}>
            <Table size="sm">
              <Tbody>
                {riskBreaks.map((risk, i) => {
                  return (
                    <Tr key={i}>
                      {chartValues
                        .slice(
                          chartValues.findIndex((e) => e === risk),
                          chartValues.findIndex((e) => e === risk) + 11
                        )
                        .map((ret, ix) => {
                          return (
                            <Td
                              key={ix}
                              bg={colormap[i][ix]}
                              data-risk={risk}
                              data-ret={ret}
                              isNumeric
                              borderColor={"transparent"}
                              p={"0.45rem 0.9rem"}
                            >
                              {ret}
                            </Td>
                          );
                        })}
                    </Tr>
                  );
                })}
                {/* {riskBreaks.map((risk, i) => {
              return (
                <Tr key={i}>
                  {returnBreaks.map((ret, ix) => {
                    const avgReturn = data.find((d) => d.risk === risk && d.return === ret);
                    const value = avgReturn?.avgReturn || null;
                    return (
                      <Td key={ix} data-risk={risk.toFixed(2)} data-ret={ret.toFixed(2)}>
                        {value?.toFixed(0) || "--"}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })} */}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
    </Center>
  );
}
