// import { createJSONStorage, persist } from "zustand/middleware";

import { create } from "zustand";

// const useFishStore = create(
//   persist(
//     (set, get) => ({
//       fishes: 0,
//       addAFish: () => set({ fishes: get().fishes + 1 }),
//     }),
//     {
//       name: 'food-storage', // unique name
//       storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
//     }
//   )
// )

export const useMapStateStore = create(
  // persist(
  (set, get) => ({
    mapState: {
      zoom: 9,
      center: [-116.96902, 32.65399],
    },
    setMapState: (obj) => set({ mapState: obj }),

    inFocusMetro: "San Diego",
    setInFocusMetro: (value) => set({ inFocusMetro: value }),

    placesHPABounds: [],
    setPlacesHPABounds: (value) => set({ placesHPABounds: value }),
  })
  // {
  //   name: "places-map--map-state",
  //   storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
  // }
  // )
);

export const useMapStore = create((set) => ({
  map: null,
  setMap: (map) => set({ map: map }),
  clearMap: () => set({ map: {} }),

  placesHeatMapToggleActive: false,
  setPlacesHeatMapToggleActive: (boolean) => set({ placesHeatMapToggleActive: boolean }),

  placesHeatMapToggleValue: "",
  setPlacesHeatMapToggleValue: (value) => set({ placesHeatMapToggleValue: value }),

  placesArea: {},
  setPlacesArea: (area) => set({ placesArea: area }),

  activePlace: "",
  setActivePlace: (value) => set({ activePlace: value }),
}));
