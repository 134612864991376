export const DataCategory = {
  Rankings: 1,
  Equity: 2,
  CurrentPremiums: 3,
  Momentum: 4,
  PandemicForSale: 5,
  PandemicCommercial: 6,
  CBGS: 7,
  FTA: 478411,
  AVM: 478469,
  HPA: 478921,
  MSA_Details: 480377,
  ACS: 492645,
  RetailProperties: 495664,
  CommercialProperties: 495680,
  Residential: 495707,
  CGBS: 19044131,
  LENS_MATRIX: 19044152,
};
export const DataSource = {
  Rocktop: 1,
  AVM: 2,
  ACS: 3,
  FTA: 4,
  CGBS: 5,
  yardi_data_00926: 239180,
  rocktop_avm: 239181,
  placesplatform_ret_prop_dataset: 239182,
  metro_hpa: 239253,
  Census2020: 239264,
  places_hpa: 239287,
  national_hpa: 239288,
  us_census_bg_acs5yr2019: 239332,
  ntl_yardi_commercial: 239357,
  yardi_commercial: 239360,
  RETS: 239362,
  HPA: 239416,
  PPL: 239435,
};
export const GroupByType = {
  places_id: 1,
  places_type: 2,
  places_area: 3,
  places_national: 4,
  places_hpa: 5,
};

export const Metric = {
  PandemicCommercial: {
    pandemiccommercial_multi_prem_2019: {
      id: 19,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_multi_prem_2019",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_multi_prem_2021: {
      id: 20,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_multi_prem_2021",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_office_prem_2019: {
      id: 22,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_office_prem_2019",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_office_prem_2021: {
      id: 23,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_office_prem_2021",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_retail_prem_2019: {
      id: 25,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_retail_prem_2019",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_retail_prem_2021: {
      id: 26,
      label: "",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_retail_prem_2021",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_multi_pnt_chng: {
      id: 18,
      label: "Change Value",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_multi_pnt_chng",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_office_pnt_chng: {
      id: 21,
      label: "Change Value",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_office_pnt_chng",
      metric_type: "standard",
      description: "",
    },
    pandemiccommercial_retail_pnt_chng: {
      id: 24,
      label: "Change Value",
      category_id: 6,
      category_name: "PandemicCommercial",
      name: "pandemiccommercial_retail_pnt_chng",
      metric_type: "standard",
      description: "",
    },
  },
  CommercialProperties: {
    industrial_rent: {
      id: 17215,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "industrial_rent",
      metric_type: "standard",
      description: "",
    },
    office_rent: {
      id: 17216,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "office_rent",
      metric_type: "standard",
      description: "",
    },
    med_office_rent: {
      id: 17219,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "med_office_rent",
      metric_type: "standard",
      description: "",
    },
    med_industrial_rent: {
      id: 17224,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "med_industrial_rent",
      metric_type: "standard",
      description: "",
    },
    med_multifam_rent: {
      id: 17228,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "med_multifam_rent",
      metric_type: "standard",
      description: "",
    },
    multifam_rent: {
      id: 17232,
      label: "",
      category_id: 495680,
      category_name: "CommercialProperties",
      name: "multifam_rent",
      metric_type: "standard",
      description: "",
    },
  },
  Momentum: {
    future_momentum_index: {
      id: 16,
      label: "Momentum Index",
      category_id: 4,
      category_name: "Momentum",
      name: "future_momentum_index",
      metric_type: "standard",
      description: "",
    },
    future_momentum_rank: {
      id: 17,
      label: "Momentum Rank",
      category_id: 4,
      category_name: "Momentum",
      name: "future_momentum_rank",
      metric_type: "standard",
      description: "",
    },
  },
  FTA: {
    retail_med_avrent: {
      id: 62,
      label: "",
      category_id: 478411,
      category_name: "FTA",
      name: "retail_med_avrent",
      metric_type: "standard",
      description: "",
    },
    office_rent: {
      id: 63,
      label: "",
      category_id: 478411,
      category_name: "FTA",
      name: "office_rent",
      metric_type: "standard",
      description: "",
    },
    fs_avg_avm: {
      id: 64,
      label: "",
      category_id: 478411,
      category_name: "FTA",
      name: "fs_avg_avm",
      metric_type: "standard",
      description: "",
    },
    industrial_rent: {
      id: 65,
      label: "",
      category_id: 478411,
      category_name: "FTA",
      name: "industrial_rent",
      metric_type: "standard",
      description: "",
    },
    fs_med_avm: {
      id: 67,
      label: "",
      category_id: 478411,
      category_name: "FTA",
      name: "fs_med_avm",
      metric_type: "standard",
      description: "",
    },
    multifam_rent: {
      id: 66,
      label: "Multi-Family Rent",
      category_id: 478411,
      category_name: "FTA",
      name: "multifam_rent",
      metric_type: "standard",
      description: "",
    },
  },
  AVM: {
    med_avm_percFromMetro: {
      id: 19717,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "med_avm_percFromMetro",
      metric_type: "standard",
      description: "",
    },
    sum_la_sqft: {
      id: 95,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "sum_la_sqft",
      metric_type: "standard",
      description: "",
    },
    avg_avm: {
      id: 96,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "avg_avm",
      metric_type: "standard",
      description: "",
    },
    avg_avmlow: {
      id: 97,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "avg_avmlow",
      metric_type: "standard",
      description: "",
    },
    avg_avmhigh: {
      id: 100,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "avg_avmhigh",
      metric_type: "standard",
      description: "",
    },
    num_props: {
      id: 104,
      label: "",
      category_id: 478469,
      category_name: "AVM",
      name: "num_props",
      metric_type: "standard",
      description: "",
    },
    med_avm: {
      id: 103,
      label: "Median AVM",
      category_id: 478469,
      category_name: "AVM",
      name: "med_avm",
      metric_type: "standard",
      description: "",
    },
    avg_ppsqft: {
      id: 94,
      label: "Avg Price Per SQFT",
      category_id: 478469,
      category_name: "AVM",
      name: "avg_ppsqft",
      metric_type: "standard",
      description: "",
    },
  },
  HPA: {
    housing_unit: {
      id: 19718,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "housing_unit",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p10_hpa_year2: {
      id: 19720,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p10_hpa_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_hpa_year5: {
      id: 19721,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_hpa_year5",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p90_hpa_year2: {
      id: 19722,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p90_hpa_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p10_hpa_year5: {
      id: 19723,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p10_hpa_year5",
      metric_type: "standard",
      description: "",
    },
    hpa_year5: {
      id: 19724,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_year5",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p10_hpa_year3: {
      id: 19725,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p10_hpa_year3",
      metric_type: "standard",
      description: "",
    },
    metro_hpa_year2: {
      id: 19726,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_hpa_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p90_hpa_year1: {
      id: 19727,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p90_hpa_year1",
      metric_type: "standard",
      description: "",
    },
    hpa_year4: {
      id: 19728,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_year4",
      metric_type: "standard",
      description: "",
    },
    metro_hpa_year4: {
      id: 19729,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_hpa_year4",
      metric_type: "standard",
      description: "",
    },
    metro_hpa_year1: {
      id: 19730,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_hpa_year1",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p90_hpa_year4: {
      id: 19731,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p90_hpa_year4",
      metric_type: "standard",
      description: "",
    },
    metro_hpa_year5: {
      id: 19732,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_hpa_year5",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_hpa_year2: {
      id: 19733,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_hpa_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_hpa_year1: {
      id: 19734,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_hpa_year1",
      metric_type: "standard",
      description: "",
    },
    metro_hpa_year3: {
      id: 19735,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_hpa_year3",
      metric_type: "standard",
      description: "",
    },
    hpa_year2: {
      id: 19736,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p10_hpa_year4: {
      id: 19737,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p10_hpa_year4",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p90_hpa_year3: {
      id: 19738,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p90_hpa_year3",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p90_hpa_year5: {
      id: 19739,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p90_hpa_year5",
      metric_type: "standard",
      description: "",
    },
    hpa_year1: {
      id: 19740,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_year1",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_hpa_year3: {
      id: 19741,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_hpa_year3",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_p10_hpa_year1: {
      id: 19742,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_p10_hpa_year1",
      metric_type: "standard",
      description: "",
    },
    cbg_adj_hpa_year4: {
      id: 19743,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_adj_hpa_year4",
      metric_type: "standard",
      description: "",
    },
    hpa_year3: {
      id: 19744,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_year3",
      metric_type: "standard",
      description: "",
    },
    cbg_hpa_table_year1: {
      id: 16349700,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_hpa_table_year1",
      metric_type: "standard",
      description: "",
    },
    cbg_hpa_table_year2: {
      id: 16349701,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_hpa_table_year2",
      metric_type: "standard",
      description: "",
    },
    cbg_hpa_table_year3: {
      id: 16349702,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_hpa_table_year3",
      metric_type: "standard",
      description: "",
    },
    cbg_hpa_table_year4: {
      id: 16349703,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_hpa_table_year4",
      metric_type: "standard",
      description: "",
    },
    cbg_hpa_table_year5: {
      id: 16349704,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "cbg_hpa_table_year5",
      metric_type: "standard",
      description: "",
    },
    housing_unit_2020: {
      id: 18565602,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "housing_unit_2020",
      metric_type: "standard",
      description: "",
    },
    place_type_hpa_year1: {
      id: 18565613,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "place_type_hpa_year1",
      metric_type: "standard",
      description: "",
    },
    place_type_hpa_year2: {
      id: 18565614,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "place_type_hpa_year2",
      metric_type: "standard",
      description: "",
    },
    place_type_hpa_year3: {
      id: 18565615,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "place_type_hpa_year3",
      metric_type: "standard",
      description: "",
    },
    place_type_hpa_year4: {
      id: 18565616,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "place_type_hpa_year4",
      metric_type: "standard",
      description: "",
    },
    place_type_hpa_year5: {
      id: 18565617,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "place_type_hpa_year5",
      metric_type: "standard",
      description: "",
    },
    hpa: {
      id: 11304,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa",
      metric_type: "standard",
      description: "",
    },
    hpa_bust: {
      id: 11305,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_bust",
      metric_type: "standard",
      description: "",
    },
    hpa_normal: {
      id: 11306,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_normal",
      metric_type: "standard",
      description: "",
    },
    reversal_rank: {
      id: 11356,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "reversal_rank",
      metric_type: "standard",
      description: "",
    },
    hpa_price_quartile: {
      id: 11466,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_price_quartile",
      metric_type: "standard",
      description: "",
    },
    metro_rank: {
      id: 11490,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "metro_rank",
      metric_type: "standard",
      description: "",
    },
    hpa_ers_decile: {
      id: 11623,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "hpa_ers_decile",
      metric_type: "standard",
      description: "",
    },
    normal_rank: {
      id: 11625,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "normal_rank",
      metric_type: "standard",
      description: "",
    },
    current_trend: {
      id: 11672,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "current_trend",
      metric_type: "standard",
      description: "",
    },
    prior_trend: {
      id: 12027,
      label: "",
      category_id: 478921,
      category_name: "HPA",
      name: "prior_trend",
      metric_type: "standard",
      description: "",
    },
  },
  CGBS: {
    cbgs_walkable_prem: {
      id: 18565603,
      label: "",
      category_id: 19044131,
      category_name: "CGBS",
      name: "cbgs_walkable_prem",
      metric_type: "standard",
      description: "",
    },
    cbgs_walkable_prem_retail: {
      id: 18565605,
      label: "",
      category_id: 19044131,
      category_name: "CGBS",
      name: "cbgs_walkable_prem_retail",
      metric_type: "standard",
      description: "",
    },
    cbgs_walkable_prem_office: {
      id: 18565606,
      label: "",
      category_id: 19044131,
      category_name: "CGBS",
      name: "cbgs_walkable_prem_office",
      metric_type: "standard",
      description: "",
    },
    cbgs_walkable_prem_mf: {
      id: 18565607,
      label: "",
      category_id: 19044131,
      category_name: "CGBS",
      name: "cbgs_walkable_prem_mf",
      metric_type: "standard",
      description: "",
    },
  },
  PandemicForSale: {
    pandemic_for_sale_prem_2019: {
      id: 27,
      label: "",
      category_id: 5,
      category_name: "PandemicForSale",
      name: "pandemic_for_sale_prem_2019",
      metric_type: "standard",
      description: "",
    },
    pandemic_for_sale_prem_2021: {
      id: 28,
      label: "",
      category_id: 5,
      category_name: "PandemicForSale",
      name: "pandemic_for_sale_prem_2021",
      metric_type: "standard",
      description: "",
    },
    pandemic_for_sale_prem_pnt_chng: {
      id: 29,
      label: "Change Value",
      category_id: 5,
      category_name: "PandemicForSale",
      name: "pandemic_for_sale_prem_pnt_chng",
      metric_type: "standard",
      description: "",
    },
  },
  CurrentPremiums: {
    for_sale_housing_prem_2021: {
      id: 2,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "for_sale_housing_prem_2021",
      metric_type: "standard",
      description: "",
    },
    office_mf_prem_2021: {
      id: 6,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "office_mf_prem_2021",
      metric_type: "standard",
      description: "",
    },
    office_prem_2021: {
      id: 7,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "office_prem_2021",
      metric_type: "standard",
      description: "",
    },
    retail_prem_2021: {
      id: 9,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "retail_prem_2021",
      metric_type: "standard",
      description: "",
    },
    for_sale_housing_chg_2018_to_2021: {
      id: 1,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "for_sale_housing_chg_2018_to_2021",
      metric_type: "standard",
      description: "",
    },
    mf_prem_chg_2018_to_2021: {
      id: 4,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "mf_prem_chg_2018_to_2021",
      metric_type: "standard",
      description: "",
    },
    office_mf_chg_2018_to_2021: {
      id: 5,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "office_mf_chg_2018_to_2021",
      metric_type: "standard",
      description: "",
    },
    retail_prem_chg_2018_to_2021: {
      id: 10,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "retail_prem_chg_2018_to_2021",
      metric_type: "standard",
      description: "",
    },
    office_prem_chg_2018_to_2021: {
      id: 8,
      label: "Change Value",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "office_prem_chg_2018_to_2021",
      metric_type: "standard",
      description: "",
    },
    mf_prem_2021: {
      id: 3,
      label: "",
      category_id: 3,
      category_name: "CurrentPremiums",
      name: "mf_prem_2021",
      metric_type: "standard",
      description: "",
    },
  },
  MSA_Details: {
    total_30D_newlistings: {
      id: 2012,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "total_30D_newlistings",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_2: {
      id: 2114,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "perc_of_place_type_2",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_3: {
      id: 2174,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "perc_of_place_type_3",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_4: {
      id: 2207,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "perc_of_place_type_4",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_1: {
      id: 2234,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "perc_of_place_type_1",
      metric_type: "standard",
      description: "",
    },
    total_30D_activelistings: {
      id: 2248,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "total_30D_activelistings",
      metric_type: "standard",
      description: "",
    },
    total_30D_closedlistings: {
      id: 2300,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "total_30D_closedlistings",
      metric_type: "standard",
      description: "",
    },
    total_activelistings: {
      id: 2334,
      label: "",
      category_id: 480377,
      category_name: "MSA_Details",
      name: "total_activelistings",
      metric_type: "standard",
      description: "",
    },
  },
  Rankings: {
    combined_share: {
      id: 30,
      label: "",
      category_id: 1,
      category_name: "Rankings",
      name: "combined_share",
      metric_type: "standard",
      description: "",
    },
    for_sale_share: {
      id: 31,
      label: "",
      category_id: 1,
      category_name: "Rankings",
      name: "for_sale_share",
      metric_type: "standard",
      description: "",
    },
    mf_rental_share: {
      id: 33,
      label: "",
      category_id: 1,
      category_name: "Rankings",
      name: "mf_rental_share",
      metric_type: "standard",
      description: "",
    },
    office_share: {
      id: 34,
      label: "",
      category_id: 1,
      category_name: "Rankings",
      name: "office_share",
      metric_type: "standard",
      description: "",
    },
    retail_share: {
      id: 36,
      label: "",
      category_id: 1,
      category_name: "Rankings",
      name: "retail_share",
      metric_type: "standard",
      description: "",
    },
    fta_index: {
      id: 32,
      label: "SG Index",
      category_id: 1,
      category_name: "Rankings",
      name: "fta_index",
      metric_type: "standard",
      description: "",
    },
    rank: {
      id: 35,
      label: "SG Rank",
      category_id: 1,
      category_name: "Rankings",
      name: "rank",
      metric_type: "standard",
      description: "",
    },
  },
  Residential: {
    properties_sold_percOf_PlaceType_1: {
      id: 19707,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "properties_sold_percOf_PlaceType_1",
      metric_type: "standard",
      description: "",
    },
    properties_sold_percOf_PlaceType_3: {
      id: 19708,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "properties_sold_percOf_PlaceType_3",
      metric_type: "standard",
      description: "",
    },
    properties_sold_percOf_PlaceType_2: {
      id: 19709,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "properties_sold_percOf_PlaceType_2",
      metric_type: "standard",
      description: "",
    },
    properties_sold_percOf_PlaceType_4: {
      id: 19710,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "properties_sold_percOf_PlaceType_4",
      metric_type: "standard",
      description: "",
    },
    properties_sold: {
      id: 19711,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "properties_sold",
      metric_type: "standard",
      description: "",
    },
    pricepersqft: {
      id: 17239,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "pricepersqft",
      metric_type: "standard",
      description: "",
    },
    closeprice: {
      id: 17240,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "closeprice",
      metric_type: "standard",
      description: "",
    },
    cumulativedaysonmarket: {
      id: 17241,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "cumulativedaysonmarket",
      metric_type: "standard",
      description: "",
    },
    listprice: {
      id: 17242,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "listprice",
      metric_type: "standard",
      description: "",
    },
    list_over_sold: {
      id: 17243,
      label: "",
      category_id: 495707,
      category_name: "Residential",
      name: "list_over_sold",
      metric_type: "standard",
      description: "",
    },
  },
  ACS: {
    acs_hh_med_gross_rent: {
      id: 14200,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_hh_med_gross_rent",
      metric_type: "standard",
      description: "",
    },
    acs_hh_med_income: {
      id: 14201,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_hh_med_income",
      metric_type: "standard",
      description: "",
    },
    acs_hh_med_lor: {
      id: 14202,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_hh_med_lor",
      metric_type: "standard",
      description: "",
    },
    acs_hh_total: {
      id: 14203,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_hh_total",
      metric_type: "standard",
      description: "",
    },
    acs_total_pop: {
      id: 14204,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_total_pop",
      metric_type: "standard",
      description: "",
    },
    acs_transit_pnt_commute: {
      id: 14205,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "acs_transit_pnt_commute",
      metric_type: "standard",
      description: "",
    },
    n_places: {
      id: 14206,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_places",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type: {
      id: 15693,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_1: {
      id: 15694,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type_1",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_2: {
      id: 15695,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type_2",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_3: {
      id: 15696,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type_3",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_4: {
      id: 15697,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type_4",
      metric_type: "standard",
      description: "",
    },
    perc_of_place_type_Null: {
      id: 15698,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "perc_of_place_type_Null",
      metric_type: "standard",
      description: "",
    },
    n_place_type_1: {
      id: 15688,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_place_type_1",
      metric_type: "standard",
      description: "",
    },
    n_place_type_2: {
      id: 15689,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_place_type_2",
      metric_type: "standard",
      description: "",
    },
    n_place_type_3: {
      id: 15690,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_place_type_3",
      metric_type: "standard",
      description: "",
    },
    n_place_type_4: {
      id: 15691,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_place_type_4",
      metric_type: "standard",
      description: "",
    },
    n_place_type_Null: {
      id: 15692,
      label: "",
      category_id: 492645,
      category_name: "ACS",
      name: "n_place_type_Null",
      metric_type: "standard",
      description: "",
    },
  },
  LENS_MATRIX: {
    total_sqft: {
      id: 18565618,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "total_sqft",
      metric_type: "standard",
      description: "",
    },
    area_acres: {
      id: 18565619,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "area_acres",
      metric_type: "standard",
      description: "",
    },
    gdp_pnt_msa: {
      id: 18565620,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "gdp_pnt_msa",
      metric_type: "standard",
      description: "",
    },
    gdp: {
      id: 18565621,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "gdp",
      metric_type: "standard",
      description: "",
    },
    retail_sqft: {
      id: 18565622,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "retail_sqft",
      metric_type: "standard",
      description: "",
    },
    for_sale_sqft: {
      id: 18565623,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "for_sale_sqft",
      metric_type: "standard",
      description: "",
    },
    industrial_share: {
      id: 18565624,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "industrial_share",
      metric_type: "standard",
      description: "",
    },
    industrial_sqft: {
      id: 18565625,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "industrial_sqft",
      metric_type: "standard",
      description: "",
    },
    office_share: {
      id: 18565626,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "office_share",
      metric_type: "standard",
      description: "",
    },
    for_sale_share: {
      id: 18565627,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "for_sale_share",
      metric_type: "standard",
      description: "",
    },
    retail_share: {
      id: 18565628,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "retail_share",
      metric_type: "standard",
      description: "",
    },
    multifam_share: {
      id: 18565629,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "multifam_share",
      metric_type: "standard",
      description: "",
    },
    multifam_sqft: {
      id: 18565630,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "multifam_sqft",
      metric_type: "standard",
      description: "",
    },
    area_acres_pnt_msa: {
      id: 18565631,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "area_acres_pnt_msa",
      metric_type: "standard",
      description: "",
    },
    office_sqft: {
      id: 18565632,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "office_sqft",
      metric_type: "standard",
      description: "",
    },
    stock_pnt_msa: {
      id: 18565812,
      label: "",
      category_id: 19044152,
      category_name: "LENS_MATRIX",
      name: "stock_pnt_msa",
      metric_type: "standard",
      description: "",
    },
  },
  Equity: {
    affordability_index: {
      id: 11,
      label: "",
      category_id: 2,
      category_name: "Equity",
      name: "affordability_index",
      metric_type: "standard",
      description: "",
    },
    proximity_index: {
      id: 12,
      label: "",
      category_id: 2,
      category_name: "Equity",
      name: "proximity_index",
      metric_type: "standard",
      description: "",
    },
    transit_index: {
      id: 15,
      label: "",
      category_id: 2,
      category_name: "Equity",
      name: "transit_index",
      metric_type: "standard",
      description: "",
    },
    sei_index: {
      id: 13,
      label: "SEI Index",
      category_id: 2,
      category_name: "Equity",
      name: "sei_index",
      metric_type: "standard",
      description: "",
    },
    sei_rank: {
      id: 14,
      label: "SEI Rank",
      category_id: 2,
      category_name: "Equity",
      name: "sei_rank",
      metric_type: "standard",
      description: "",
    },
  },
  CBGS: {
    retail_cbgs_rent_percFromMetro: {
      id: 19704,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "retail_cbgs_rent_percFromMetro",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_rent_percFromMetro: {
      id: 19705,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_rent_percFromMetro",
      metric_type: "standard",
      description: "",
    },
    mf_cbgs_rent_percFromMetro: {
      id: 19706,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "mf_cbgs_rent_percFromMetro",
      metric_type: "standard",
      description: "",
    },
    retail_cbgs_rent: {
      id: 58,
      label: "Retail Rent",
      category_id: 7,
      category_name: "CBGS",
      name: "retail_cbgs_rent",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_rent_n: {
      id: 44,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_rent_n",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_rent_d: {
      id: 45,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_rent_d",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_occupancy_w: {
      id: 48,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_occupancy_w",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_occupiedsf: {
      id: 51,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_occupiedsf",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_netabs: {
      id: 52,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_netabs",
      metric_type: "standard",
      description: "",
    },
    mf_cbgs_netabs: {
      id: 56,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "mf_cbgs_netabs",
      metric_type: "standard",
      description: "",
    },
    retail_cbgs_inventory: {
      id: 57,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "retail_cbgs_inventory",
      metric_type: "standard",
      description: "",
    },
    retail_cbgs_vacancy: {
      id: 59,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "retail_cbgs_vacancy",
      metric_type: "standard",
      description: "",
    },
    retail_cbgs_netabs: {
      id: 60,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "retail_cbgs_netabs",
      metric_type: "standard",
      description: "",
    },
    industrial_cbgs_netabs: {
      id: 61,
      label: "",
      category_id: 7,
      category_name: "CBGS",
      name: "industrial_cbgs_netabs",
      metric_type: "standard",
      description: "",
    },
    industrial_cbgs_vacancy: {
      id: 43,
      label: "Industrial Vacancy",
      category_id: 7,
      category_name: "CBGS",
      name: "industrial_cbgs_vacancy",
      metric_type: "standard",
      description: "",
    },
    mf_cbgs_vacancy: {
      id: 55,
      label: "Multi-Family Vacancy",
      category_id: 7,
      category_name: "CBGS",
      name: "mf_cbgs_vacancy",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_inventory: {
      id: 46,
      label: "Office Inventory",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_inventory",
      metric_type: "standard",
      description: "",
    },
    industrial_cbgs_inventory: {
      id: 41,
      label: "Industrial Inventory",
      category_id: 7,
      category_name: "CBGS",
      name: "industrial_cbgs_inventory",
      metric_type: "standard",
      description: "",
    },
    mf_cbgs_inventory: {
      id: 53,
      label: "Multi-Family Inventory",
      category_id: 7,
      category_name: "CBGS",
      name: "mf_cbgs_inventory",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_inventory_w: {
      id: 47,
      label: "Office Inventory",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_inventory_w",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_occupancy: {
      id: 50,
      label: "Office Vacancy",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_occupancy",
      metric_type: "standard",
      description: "",
    },
    industrial_cbgs_rent: {
      id: 42,
      label: "Industrial Rent",
      category_id: 7,
      category_name: "CBGS",
      name: "industrial_cbgs_rent",
      metric_type: "standard",
      description: "",
    },
    mf_cbgs_rent: {
      id: 54,
      label: "Multi-Family Rent",
      category_id: 7,
      category_name: "CBGS",
      name: "mf_cbgs_rent",
      metric_type: "standard",
      description: "",
    },
    office_cbgs_rent: {
      id: 49,
      label: "Office Rent",
      category_id: 7,
      category_name: "CBGS",
      name: "office_cbgs_rent",
      metric_type: "standard",
      description: "",
    },
  },
  RetailProperties: {
    sum_anchavail: {
      id: 17203,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "sum_anchavail",
      metric_type: "standard",
      description: "",
    },
    avg_totsize: {
      id: 17204,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "avg_totsize",
      metric_type: "standard",
      description: "",
    },
    avg_nonanchsize: {
      id: 17205,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "avg_nonanchsize",
      metric_type: "standard",
      description: "",
    },
    avg_anchsize: {
      id: 17207,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "avg_anchsize",
      metric_type: "standard",
      description: "",
    },
    med_anchorrent: {
      id: 17208,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "med_anchorrent",
      metric_type: "standard",
      description: "",
    },
    sum_nonanchavail: {
      id: 17209,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "sum_nonanchavail",
      metric_type: "standard",
      description: "",
    },
    avg_yrblt: {
      id: 17210,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "avg_yrblt",
      metric_type: "standard",
      description: "",
    },
    tot_inventory: {
      id: 17211,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "tot_inventory",
      metric_type: "standard",
      description: "",
    },
    sum_avail: {
      id: 17212,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "sum_avail",
      metric_type: "standard",
      description: "",
    },
    med_nonanchorrent: {
      id: 17213,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "med_nonanchorrent",
      metric_type: "standard",
      description: "",
    },
    med_avrent: {
      id: 17214,
      label: "",
      category_id: 495664,
      category_name: "RetailProperties",
      name: "med_avrent",
      metric_type: "standard",
      description: "",
    },
  },
};
