import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { CustomModal as Modal } from "../Modals/Modals";
import { apiGateway } from "../../config";
import chroma from "chroma-js";
import { fuzzySearch } from "../../helpers/fuzzySearch";
import pqData from "../../data/hpa_boom_bust_pand.json";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";

export const RiskSpreadTable = ({ title, type, showButton }) => {
  const { activeMetro, setIsLoading } = useAppState();
  const [modalTitle, setModalTitle] = useState("Projected Year End");
  const [hpaData, setHPAData] = useState([]);
  const [metroHPA, setMetroHPA] = useState([]);
  const { apiToken } = useAccountStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [year, setYear] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const years = ["2024", "2025", "2026", "2027", "2028"];

  useEffect(() => {
    if (!apiToken || !apiGateway) return;
    console.log("getting hpa data");
    if (!year) return;
    (async () => {
      try {
        setIsLoading(true);
        const { features } = await fetch(
          `${apiGateway}/collections/public.mview_hpa_annualized_${year}/items/?limit=1000&access_token=${apiToken}`
        ).then((res) => res.json());
        // console.log("HPA 2024", features);
        setHPAData(features.map((p) => p.properties));
      } catch (error) {
        console.log("HPA 2024 error", error);
      }
    })();
    // eslint-disable-next-line
  }, [year, apiToken]);

  useEffect(() => {
    if (!hpaData.length || !activeMetro) return;
    const _metroHPA = fuzzySearch(hpaData, activeMetro, "metro");
    console.log(_metroHPA);
    const _data = _metroHPA?.data || [];
    _data.forEach((item, i) => {
      _data[i] = { ...item, ...item?.overall };
    });
    setMetroHPA(_data);
    setIsLoading(false);
    if (openModal) onOpen();
    // eslint-disable-next-line
  }, [hpaData, activeMetro]);

  return (
    <>
      <Box maxW="100%">
        <Heading as="h3" size="md" mb={4} textAlign={"center"}>
          {title}
          <br />
          {activeMetro} MSA
        </Heading>
        <RiskSpreadTableTable type={type} tableData={pqData} showButton={showButton} />
        {showButton && (
          <>
            <Table>
              <Tbody>
                <Tr>
                  <Td colSpan={3} style={{ borderRight: "none" }}></Td>
                  <Td colSpan={4} textAlign={"center"} style={{ border: "none" }}>
                    <Flex justifyContent={"space-between"}>
                      {years.map((y) => (
                        <Button
                          variant="outline"
                          key={y}
                          size="sm"
                          onClick={() => {
                            setModalTitle(`Forward HPA ${y}`);
                            setYear(y);
                            setOpenModal(true);
                          }}
                        >
                          {y === "2024" ? "Forward HPA 2024" : "HPA " + y}
                        </Button>
                      ))}
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Modal
              title={modalTitle}
              children={
                <>
                  <Heading as="h3" size="md" mb={4} textAlign={"center"}>
                    {activeMetro}
                  </Heading>
                  <Box textAlign={"center"} mx={8} my={6}>
                    <RiskSpreadTableTable
                      type="forward"
                      tableData={metroHPA}
                      debug={true}
                      year={year}
                    />
                  </Box>
                </>
              }
              isOpen={isOpen}
              onClose={onClose}
              size="3xl"
            />
            <Modal
              title={modalTitle}
              children={
                <div>
                  <p
                    style={{
                      position: "absolute",
                      top: "48%",
                      display: "flex",
                      justifyContent: "center",
                      width: "calc(100% - 48px)",
                      zIndex: 1,
                      fontSize: "1.5rem",
                    }}
                  >
                    Premium Subscription Required
                  </p>
                  <img
                    src="/images/modal-hpa-image.jpg"
                    alt="HPA"
                    style={{ filter: "blur(10px)" }}
                  />
                </div>
              }
              isOpen={false}
              onClose={() => {}}
            />
          </>
        )}
      </Box>
    </>
  );
};

const RiskSpreadTableTable = ({ tableData, type, year = "2024", debug, showButton }) => {
  // if (debug) console.log(tableData);
  const { activeMetro, hpaMatrixCurrent } = useAppState();
  const [data, setData] = useState([]);
  const [values, setValues] = useState({});
  const [breaks, setBreaks] = useState({});

  //NOTE red is highest, green is lowest
  const scale = chroma.scale(["red", "orange", "yellow", "green"]).mode("lch").colors(10);

  const pandFields = [
    "pq1_pand_2019_2021",
    "pq2_pand_2019_2021",
    "pq3_pand_2019_2021",
    "pq4_pand_2019_2021",
  ];
  const boomFields = [
    "pq1_boom_2014_2019",
    "pq2_boom_2014_2019",
    "pq3_boom_2014_2019",
    "pq4_boom_2014_2019",
  ];
  const bustFields = [
    "pq1_bust_2005_2011",
    "pq2_bust_2005_2011",
    "pq3_bust_2005_2011",
    "pq4_bust_2005_2011",
  ];
  const currentFields = ["pq1_bust_2023", "pq2_bust_2023", "pq3_bust_2023", "pq4_bust_2023"];
  const forwardFields = {
    2024: ["pq1_bust_2024", "pq2_bust_2024", "pq3_bust_2024", "pq4_bust_2024"],
    2025: ["pq1_bust_2025", "pq2_bust_2025", "pq3_bust_2025", "pq4_bust_2025"],
    2026: ["pq1_bust_2026", "pq2_bust_2026", "pq3_bust_2026", "pq4_bust_2026"],
    2027: ["pq1_bust_2027", "pq2_bust_2027", "pq3_bust_2027", "pq4_bust_2027"],
    2028: ["pq1_bust_2028", "pq2_bust_2028", "pq3_bust_2028", "pq4_bust_2028"],
  };

  useEffect(() => {
    if (!activeMetro || !hpaMatrixCurrent.length) return;
    const area = fuzzySearch(tableData, activeMetro, "metro");
    if (!area.metro) {
      setData([]);
      return;
    }

    const _historic = tableData.filter((d) => {
      if (area.metro === "Portland") {
        return d.cbsa === "Portland, OR";
      }
      return d.metro === area.metro;
    });
    _historic.sort((a, b) => {
      const decile = +a.ers_decile;
      const _decile = +b.ers_decile;
      if (decile > _decile) return 1;
      if (decile < _decile) return -1;
      return 0;
    });
    // if (debug) console.log({ _historic });

    // if (type === "pand") console.log({ _historic });

    if (type !== "current") {
      setData(_historic);
    } else {
      const _hpaCurrent = fuzzySearch(hpaMatrixCurrent, activeMetro, "metro");
      // console.log("[risk-spread-table] current data:", _hpaCurrent?.metro);
      // console.log("[risk-spread-table] current data:", _hpaCurrent?.metro ? true : false);
      if (!_hpaCurrent?.data?.length && type === "current") setData([]);

      const _current = _hpaCurrent.data.map((item) => {
        currentFields.forEach((field) => {
          item[field] = +(item.overall[field] * 100).toFixed(1);
        });
        return item;
      });

      _current.sort((a, b) => {
        const decile = +a.ers_decile;
        const _decile = +b.ers_decile;
        if (decile > _decile) return 1;
        if (decile < _decile) return -1;
        return 0;
      });

      // console.log({ _current });

      setData(_current);
    }
    // eslint-disable-next-line
  }, [activeMetro, hpaMatrixCurrent, type]);

  useEffect(() => {
    if (!data.length) return;

    // console.log({ data });

    if (type !== "current") {
      console.log({ data });
      const pandValues = data.reduce(
        (v, i) => [
          ...v,
          ...pandFields.map((f) =>
            +i[f] && Number.isInteger(+i[f]) ? +(+i[f]).toFixed(1) : +i[f]
          ),
        ],
        []
      );
      const boomValues = data.reduce(
        (v, i) => [
          ...v,
          ...boomFields.map((f) =>
            +i[f] && Number.isInteger(+i[f]) ? +(+i[f]).toFixed(1) : +i[f]
          ),
        ],
        []
      );
      const bustValues =
        type === "forward"
          ? data.reduce(
              (v, i) => [
                ...v,
                ...forwardFields[year].map((f) =>
                  +i[f] && Number.isInteger(+i[f]) ? +(+i[f]).toFixed(1) : +i[f]
                ),
              ],
              []
            )
          : data.reduce(
              (v, i) => [
                ...v,
                ...bustFields.map((f) =>
                  +i[f] && Number.isInteger(+i[f]) ? +(+i[f]).toFixed(1) : +i[f]
                ),
              ],
              []
            );
      const breaks = {
        pandBreaks: chroma.limits(pandValues, "q", 9),
        boomBreaks: chroma.limits(boomValues, "q", 9),
        bustBreaks: chroma.limits(bustValues, "q", 9),
      };
      setBreaks(breaks);
      const values = {
        pand: pandValues,
        boom: boomValues,
        bust: bustValues,
        forward: bustValues,
        current: [],
      };
      setValues(values);
    } else {
      const currentValues = data.reduce(
        (v, i) => [
          ...v,
          ...currentFields.map((f) =>
            +i[f] && Number.isInteger(+i[f]) ? +(+i[f]).toFixed(1) : +i[f]
          ),
        ],
        []
      );
      const currentBreaks = chroma.limits(currentValues, "q", 9);
      const breaks = {
        pandBreaks: [],
        boomBreaks: [],
        bustBreaks: [],
        currentBreaks,
      };
      setBreaks(breaks);
      const values = {
        pand: [],
        boom: [],
        bust: [],
        current: currentValues,
      };
      setValues(values);
    }
    // eslint-disable-next-line
  }, [data, type]);

  // if (type === "bust") {
  //   console.log({ data });
  //   console.log({ values });
  //   console.log({ breaks });
  // }

  // if (type === "forward") {
  //   console.log({ values });
  // }

  const hasValues = values[type]
    ? values[type].filter((v) => v !== undefined && v !== 0 && v !== null).length
    : false;

  return (
    <>
      {data.length && Object.keys(values).length && Object.keys(breaks).length && hasValues ? (
        <>
          <Table
            variant="simple"
            width={{ base: "100%", lg: "100%" }}
            size={{ base: "sm", lg: "sm" }}
            className="risk-spread-table"
          >
            <Thead>
              <Tr>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th colSpan="4" textAlign={"center"}>
                  Price Quartiles
                </Th>
              </Tr>
              <Tr>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th>Lowest</Th>
                <Th></Th>
                <Th></Th>
                <Th>Highest</Th>
              </Tr>
              <Tr>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th className="empty"></Th>
                <Th style={{ textAlign: "center" }}>1</Th>
                <Th style={{ textAlign: "center" }}>2</Th>
                <Th style={{ textAlign: "center" }}>3</Th>
                <Th style={{ textAlign: "center" }}>4</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => {
                return (
                  <Tr key={index}>
                    {!index ? (
                      <Td rowSpan={10} style={{ borderRight: "solid thin black" }}>
                        Risk
                      </Td>
                    ) : (
                      ""
                    )}
                    <Td
                      className="border-right"
                      style={{ borderRight: "solid thin black" }}
                      textAlign={"center"}
                    >
                      {!index ? "Highest" : index === data.length - 1 ? "Lowest" : ""}
                    </Td>
                    <Td textAlign={"center"} style={{ width: "10px" }}>
                      {10 - index}
                    </Td>
                    {type === "pand" ? (
                      <>
                        {pandFields.map((field, i) => {
                          //get the value from the breaks array that is the same or larger than the value
                          const value = row[field];
                          const breakValue = breaks.pandBreaks.find((b) => b >= value);
                          const indexValue = breaks.pandBreaks.indexOf(breakValue);
                          // console.log({ value, breakValue });
                          return (
                            <Td
                              isNumeric
                              fontWeight={"bold"}
                              key={i}
                              bg={breakValue ? scale[indexValue] : "transparent"}
                              className={index === data.length - 1 ? "border-bottom" : ""}
                            >
                              {row[field] || ""}
                              {"%"}
                            </Td>
                          );
                        })}
                      </>
                    ) : type === "boom" ? (
                      <>
                        {boomFields.map((field, i) => {
                          const value = row[field];
                          const breakValue = breaks.boomBreaks.find((b) => b >= value);
                          const indexValue = breaks.boomBreaks.indexOf(breakValue);
                          // console.log({ value, breakValue });
                          return (
                            <Td
                              isNumeric
                              fontWeight={"bold"}
                              key={i}
                              bg={scale[indexValue]}
                              className={index === data.length - 1 ? "border-bottom" : ""}
                            >
                              {row[field] || ""}
                              {"%"}
                            </Td>
                          );
                        })}
                      </>
                    ) : type === "bust" ? (
                      <>
                        {bustFields.map((field, i) => {
                          const value = row[field];
                          const breakValue = breaks.bustBreaks.find((b) => b >= value);
                          const indexValue = breaks.bustBreaks.indexOf(breakValue);
                          // console.log({ value, breakValue });
                          return (
                            <Td
                              isNumeric
                              fontWeight={"bold"}
                              key={i}
                              bg={breakValue ? scale[indexValue] : "transparent"}
                              className={index === data.length - 1 ? "border-bottom" : ""}
                            >
                              {breakValue ? row[field] : ""}
                              {breakValue ? "%" : ""}
                            </Td>
                          );
                        })}
                      </>
                    ) : type === "forward" ? (
                      <>
                        {forwardFields[year].map((field, i) => {
                          const value = row[field] === undefined ? "N/A" : row[field];
                          const breakValue = breaks.bustBreaks.find((b) => b >= value);
                          const indexValue = breaks.bustBreaks.indexOf(breakValue);
                          // console.log({ value, breakValue });
                          return (
                            <Td
                              isNumeric
                              fontWeight={"bold"}
                              key={i}
                              bg={breakValue ? scale[indexValue] : "transparent"}
                              className={index === data.length - 1 ? "border-bottom" : ""}
                            >
                              {breakValue ? +(row[field] * 100).toFixed(2) : ""}
                              {breakValue ? "%" : ""}
                            </Td>
                          );
                        })}
                      </>
                    ) : type === "current" ? (
                      <>
                        {currentFields.map((field, i) => {
                          const value = row[field];
                          const breakValue = breaks.currentBreaks.find((b) => b >= value);
                          const indexValue = breaks.currentBreaks.indexOf(breakValue);
                          // console.log({ value, breakValue });
                          return (
                            <Td
                              isNumeric
                              fontWeight={"bold"}
                              key={i}
                              bg={breakValue ? scale[indexValue] : "transparent"}
                              className={index === data.length - 1 ? "border-bottom" : ""}
                            >
                              {breakValue ? row[field] : ""}
                              {breakValue ? "%" : ""}
                            </Td>
                          );
                        })}
                      </>
                    ) : (
                      <Td></Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={3} style={{ borderRight: "none" }}></Td>
                <Td colSpan={4} textAlign={"center"} style={{ borderRight: "none" }}>
                  <Box textAlign={"center"} mb={1} color="gray.600">
                    Annualized Home Price Appreciation
                  </Box>
                  <Flex>
                    {scale.map((color, index) => (
                      <Box
                        as="span"
                        key={index.toString()}
                        h={4}
                        w={"10%"}
                        backgroundColor={color}
                        mr={0}
                        mb={0}
                      >
                        {""}
                      </Box>
                    ))}
                  </Flex>
                  <Flex>
                    {scale.map((color, index) => (
                      <Box
                        as="span"
                        key={index.toString()}
                        h={4}
                        w={"10%"}
                        mr={0}
                        mb={0}
                        color="gray.600"
                      >
                        {!index ? "Low" : index === scale.length - 1 ? "High" : ""}
                      </Box>
                    ))}
                  </Flex>
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" h="300px">
          Data not available.
        </Flex>
      )}
    </>
  );
};
