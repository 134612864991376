import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  VStack
} from "@chakra-ui/react";

import { default as logger } from "../../helpers/logger";
import { useAccountStore } from "../../context/accountStore";
import { useEffect } from "react";
import { useState } from "react";

const log = logger.getLogger("UserSettingsModal");
log.setLevel(log.levels.INFO);
export const UserSettingsModal = ({ isOpen, onClose }) => {
  // console.log("[user-settings-modal] render")
  const { account, settings, updateSetting, trackedMetros } = useAccountStore((state) => state);

  //FORM DATA
  const [defaultView, setDefaultView] = useState();
  const [displayName, setDisplayName] = useState();
  const [metros, setMetros] = useState([]);
  const [stagedMetros, setStagedMetros] = useState([]);
  const [singleMetro, setSingleMetro] = useState("1");
  const [roleType, setRoleType] = useState();

  //SET FORM DATA ON INITIAL LOAD
  useEffect(() => {
    if (!settings) return;
    // console.log("[user-settings-modal] -- init form data");
    // console.log({ settings });
    //Set Metros for dropdown from internal client side DB
    (async () => {
      if (metros.length) return;
      const data = settings?.msa_data || null;
      if (!data) return;
      setMetros(data.reduce((i, e) => [...i, e.name], []));
      const name = account.user_metadata && account.user_metadata.full_name ? account.user_metadata.full_name : "";
      setDisplayName(name);
    })();

    const appSettings = settings.app_settings ? settings.app_settings : {};

    const name = appSettings.displayName
      ? appSettings.displayName
      : account.user_metadata && account.user_metadata.full_name
      ? account.user_metadata.full_name
      : "";
    setDisplayName(name);

    if (appSettings.defaultView) {
      setDefaultView(appSettings.defaultView);
    } else {
      setDefaultView("commercial");
    }
    if (appSettings.singleMetro) {
      setSingleMetro(appSettings.singleMetro);
    } else {
      setSingleMetro("1");
    }
    if (trackedMetros.length) {
      setStagedMetros(trackedMetros);
    } else {
      setStagedMetros([]);
    }

    if (appSettings.roleType) {
      setRoleType(appSettings.roleType);
    } else {
      setRoleType("realtor");
    }

    if (appSettings.defaultView) {
      setDefaultView(appSettings.defaultView);
    } else {
      setDefaultView("commercial");
    }
    //eslint-disable-next-line
  }, [settings, account, isOpen]);

  // const updateUserSettings = async (key, value) => {
  //   const appSettings = settings.app_settings ? settings.app_settings : {};
  //   let obj = {};
  //   obj[key] = value;
  //   console.log(Object.assign(appSettings, obj))
  //   updateSetting("app_settings", Object.assign(appSettings, obj));
  // }

  return (
    <Modal isOpen={isOpen && metros.length} onClose={onClose} isCentered size="2xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {settings && !settings.app_settings
            ? "Setup Your Preferences to Get Started"
            : "Update Your Account Settings"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={"6"}>
            <FormControl>
              <FormLabel fontWeight={600}>Account Display Name</FormLabel>
              <Input
                placeholder="Full Name"
                value={displayName ? displayName : ""}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel fontWeight={600}>What is your Role?</FormLabel>
              <Select
                defaultValue={roleType}
                onChange={(e) => {
                  // updateUserSettings("roleType", e.target.value)
                  setRoleType(e.target.value);
                }}
              >
                <option value="realtor">Realtor</option>
                <option value="investor">Investor</option>
                <option value="developer">Developer</option>
                <option value="municipal">Municipal</option>
                <option value="insurer">Insurer</option>
                <option value="lender">Lender</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontWeight={600}>Default Analytic View</FormLabel>
              <RadioGroup value={defaultView} onChange={(e) => setDefaultView(e)}>
                <Stack spacing={5} direction="row">
                  <Radio value="commercial">Commercial</Radio>
                  <Radio value="residential">Residential</Radio>
                </Stack>
              </RadioGroup>
              <Box opacity={0.7} fontStyle={"italic"} mt={2}>
                The Commerical view shows analytics relating to commerical property inventory. The Residential view
                shows analytics relating to single and multi-family housing.
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel>Follow a single Metro or multiple?</FormLabel>
              <RadioGroup
                value={singleMetro}
                onChange={(e) => {
                  setSingleMetro(e);
                }}
              >
                <Stack spacing={5} direction="row">
                  <Radio value="1">Single Metro</Radio>
                  <Radio value="2">Multiple</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            {+singleMetro === 1 ? (
              <FormControl>
                <FormLabel fontWeight={600}>Choose a Metro</FormLabel>
                <Select
                  value={stagedMetros.length ? stagedMetros[0] : ""}
                  onChange={(e) => {
                    log.debug(e.target.value);
                    setSingleMetro("1");
                    setStagedMetros([e.target.value]);
                  }}
                >
                  <option value=""></option>
                  {metros.length
                    ? metros.map((metro) => (
                        <option value={metro} key={metro.replace(/ /g, "").replace(/-/, "")}>
                          {metro}
                        </option>
                      ))
                    : ""}
                </Select>
              </FormControl>
            ) : (
              <FormControl>
                <FormLabel fontWeight={600}>Add Metro Areas to Track</FormLabel>
                <SimpleGrid columns={3} direction="row">
                  {metros.length
                    ? metros.map((metro) => (
                        <Checkbox
                          key={metro.replace(/ /g, "").replace(/-/, "")}
                          isChecked={stagedMetros.includes(metro)}
                          onChange={() => {
                            setSingleMetro("2");
                            // console.log("[user-settings-modal]", stagedMetros, metro)
                            if (stagedMetros.includes(metro)) {
                              setStagedMetros(stagedMetros.filter((e) => e !== metro));
                            } else {
                              setStagedMetros([...stagedMetros, metro].sort());
                            }
                          }}
                        >
                          {metro}
                        </Checkbox>
                      ))
                    : ""}
                </SimpleGrid>
              </FormControl>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={"space-between"}>
          <Button
            colorScheme="red"
            fontWeight={"normal"}
            mr={3}
            onClick={async () => {
              await updateSetting("app_settings", null);
            }}
          >
            Reset Settings
          </Button>
          <Box>
            <Button
              colorScheme="blue"
              mr={3}
              fontWeight={"normal"}
              onClick={async () => {
                const appSettings = settings.app_settings ? settings.app_settings : {};

                await updateSetting(
                  "app_settings",
                  Object.assign(appSettings, {
                    defaultView: defaultView,
                    displayName: displayName,
                    singleMetro: singleMetro,
                    trackedMetros: +singleMetro === 1 ? [stagedMetros[0]] : stagedMetros,
                    roleType: roleType
                  })
                );
                return onClose();
              }}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
