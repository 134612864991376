import { apiGateway } from "../config";

/**
 *
 * @param {string} gateway
 * @param {string} token
 * @param {object} query
 * @param {('data'|'stat'|'metric')} type
 * @returns
 */
export const getMetrics = async (gateway = apiGateway, token, query, type) => {
  try {
    const _type = type || "data";
    const url = `${gateway}/functions/postgisftw.ppl_metrics_get_${_type}/items`; //data, stat, analytic
    // group by type places_type - get the id of this
    //i_places_area - Atlanta - id not the name - stored in the user settings
    // stat - median_avm - metric id - id not the stat name - function ppl_metrics_get_stat
    const queries = {
      i_groupby_type: query?.i_groupby_type || query?.groupByType || "{}", //places_area, places_id, places_type - ppL-groupby-type
      i_groupby_id: query?.i_groupby_id || query?.groupById || "{}", //whatever the id for the group by type - for ex geoid
      i_data_source: query?.i_data_source || query?.dataSource || "{}",
      i_data_category: query?.i_data_category || query?.dataCategory || "{}",
      i_places_area: query?.i_places_area || query?.placesArea || "{}",
      i_metric_ids: query?.i_metric_ids || query?.metricIds || "{}",
      i_date_type: query?.i_date_type || query?.dateType || "{}",
      i_date_start: query?.i_date_start || query?.dateStart || "", // integer,
      i_date_end: query?.i_date_end || query?.dateEnd || "", // integer,
      limit: 10000,
      access_token: token,
    };

    const request = `${url}/?${new URLSearchParams(queries).toString()}`;
    // console.log(request);
    const results = await fetch(request).then((res) => res.json());

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (result.data && result.data.length) {
        for (let x = 0; x < result.data.length; x++) {
          const metric = result.data[x];
          results[i][metric.metric_name] = metric.value;
        }
      }
    }

    if (type === "stat") {
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if (result.stats && result.stats.length) {
          for (let x = 0; x < result.stats.length; x++) {
            const stats = result.stats[x];
            if (stats.timeseries && stats.timeseries.length) {
              //sort by x where x is a date string
              stats.timeseries = stats.timeseries.sort((a, b) =>
                a.x < b.x ? -1 : a.x > b.x ? 1 : 0
              );
            }
          }
        }
      }
    }

    // for (let i = 0; i < results.length; i++) {
    //   const result = results[i];
    //   if (result.stats && result.stats.length) {
    //     for (let x = 0; x < result.stats.length; x++) {
    //       results[i][metric.metric_name] = result.stats[x].timeeries
    //     }
    //   }
    // }

    // const p = features[0].properties
    // console.log(p)
    // if (p.data && p.data.length) {
    //   for (let x = 0; x < p.data.length; x++) {
    //     p[x].data = p.data[x].value
    //   }
    // }

    return results;
  } catch (error) {
    console.log({ error });
    return { error: "Error fetching metrics" };
  }
};

// export const getNewListingsByArea = async (area, apiToken) => {
//   const data = await fetch(
//     `${apiGateway}/collections/public._tmp_n_listings_per_metro/items/?filter=area='${area}'&access_token=${apiToken}`
//   ).then((res) => res.json());
//   if (!data || !data.features.length) return 0;
//   return data.features[0].properties.new_listings;
// };

/**
 *
 * @param {Array} areas
 * @param {string} apiToken
 * @returns {Array}
 */
export const getMetroProps = async (areas, apiToken) => {
  //convert to for loop
  const areasProperties = [];
  for (let i = 0; i < areas.length; i++) {
    const area = areas[i];
    const { features } = await fetch(
      `${apiGateway}/collections/public.mview_stats_msa/items?area_name=${area}&access_token=${apiToken}`
    ).then((res) => res.json());
    if (!features || !features.length) {
      console.log("[requests-getMetroProperties] - No Features found!");
    } else {
      features[0].properties["area"] = area;
      // features[0].properties["new_listings"] = await getNewListingsByArea(area, apiToken);
      areasProperties.push(features[0].properties);
    }
  }

  // = await Promise.all(
  //   areas.map(async (area) => {
  //     const { features } = await fetch(`${apiGateway}/collections/public.mview_stats_msa/items?area_name=${area}&access_token=${apiToken}`).then((res) => res.json());
  //     if (!features || !features.length) {
  //       // throw new Error("No Features found!");
  //       console.log("[requests-getMetroProperties] - No Features found!");
  //       return null
  //     }
  //     features[0].properties["area"] = area;
  //     features[0].properties["new_listings"] = await getNewListingsByArea(area, apiToken);
  //     return features[0].properties;
  //   })
  // );
  return areasProperties;
};
