class GcpHelper {
  #account
  async setAccount(account){
    this.#settings.account = account
    const system_user = await this.signin(account)
    return system_user
  }

  #settings
  get settings(){
    return this.#settings;
  }

  async getToken(user) {
    const now = Date.now();
    try {
      const response = await callSystemApi("getAppToken", user);
      console.log("[gcpToken] --", Date.now() - now);
      return response
    } catch (error) {
      throw new Error(error);
    }
  }

  async signIn(account){
      const now = Date.now();
      try {
        this.#account = account
        const systemUser = await callSystemApi("userSignin", this.#account);
        console.log("[signin] --", Date.now() - now);
        if(!this.#settings){
          this.#settings = new Settings(this.#account, systemUser?.settings)
        }
        else{
          this.#settings.account = this.#account
        }

        return systemUser;
      } catch (error) {
        throw new Error(error); 
      }

      
  }

  async getUser() {
    return await callSystemApi("getUser", this.#account);
  }

  async getActivity() {
    return await callSystemApi("getUserActivity",  this.#account);
  }

  async saveActivity(type, msg, feature_flag, payload) {
    const data = {
      type: type,
      msg: msg,
      feature_flag: feature_flag,
      payload: payload,
    };

    return await callSystemApi("postUserActivity",  this.#account, data);
  }

  async exportPortfolioStats(dateType, minDate, maxDate, locations) {
    const data = {
      dateType: dateType,
      minDate: minDate,
      maxDate: maxDate,
      locations: locations,
    };
    const ac = this.#account
    
    return await callSystemApi("exportPortfolioStats",  ac, data);
  }
}

class Settings {
  #value
  #isDirty
  #account
  set account(value){
    this.#account = value;
    this.#isDirty = true;
  }
  
  constructor(account, value){
      if(!account){
          throw Error("Account is not set")
      }

      this.#account = account
      this.#value = value
  }
  async get() {
    console.log("get settings");

    if(this.#isDirty || !this.#value){
      console.log("value settings");
      this.#value = await callSystemApi("getUserSettings", this.#account);
    }
    
    console.log("[account store] - settings", this.#value)
    return this.#value  
  }
  async update(setting_key, value){
      console.log("update settings");
      
      const change = {}
      change[setting_key] = value

      await this.save(change);
      return this.#value
  }
  async save(settings){
    const new_settings = await callSystemApi(
      "patchUserSettings", 
      this.#account,
      {settings: settings}
    );
    
    this.#value = new_settings
    return this.#value
  }
}

const callApi = async (api, account, data) => {
  try {
    if (account) {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const token = await account.jwt();
      if (token) {
        headers["authorization"] = `Bearer ${token}`;
      } else {
        throw new Error("Unable to get Netlify token");
      }

      const response = await fetch(`/.netlify/functions/${api}`, {
        headers: headers,
        body: !!data ? JSON.stringify(data) : null,
        method: "POST",
      });

      if (!response.ok) {
        const result = await response.json();
        throw result.error;
      }
      let result;
      switch (response.headers.get("content-type")) {
        case "application/csv":
          result = await response.blob();
          break;
        default:
          result = await response.json();
          break;
      }
      return result;
    }
  } catch (error) {
    throw error;
  }
};
  
const callSystemApi = async (functionName, account, data = {}) => {
  try {
    data["function"] = functionName;

    const results = await callApi("system_api", account, data);
    return results;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export { GcpHelper }; 