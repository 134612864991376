import { Box } from "@chakra-ui/react";
import Documentation from "../../components/Demo/Documentation";
import { useAccountStore } from "../../context/accountStore";

export const Docs = () => {
  const { account } = useAccountStore();
  return (
    <Box>
      {account && account?.is_admin ? (
        <Documentation />
      ) : (
        <Box>Please request access to view this page.</Box>
      )}
    </Box>
  );
};
