import {
  VerticalBarSeries as BarSeries,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  YAxis,
} from "react-vis";

import { Badge } from "@chakra-ui/react";
import { fuzzySearch } from "../../helpers/fuzzySearch";
import { useAppState } from "../../context/useAppState";
import { useEffect } from "react";
import { useState } from "react";

//    "total_median": "62"

export const CustomBarChart = ({ boxplotData }) => {
  const { activeMetro } = useAppState();

  const [tooltip, setTooltip] = useState("");
  const [activeMetroTooltip, setActiveMetroTooltip] = useState("");

  const [data, setData] = useState(
    boxplotData
      ? boxplotData.map((bar) => ({ ...bar, color: "#12939A" })).sort((a, b) => a.median - b.median)
      : []
  );

  const lineData = data.map((d) => ({ x: d.x, y: d.median }));
  const offsetData = generateOffsetData(data);
  const offsetData2 = generateOffsetData2(data);
  // console.log("offsetData", offsetData);
  // console.log("lineData", chartData);

  const chartHandler = (d, e) => {
    document.body.style.cursor = "crosshair";
    const updatedData = data.map((bar) => {
      const color = bar.x === d.x ? "goldenrod" : bar.x === activeMetro ? "firebrick" : "#12939A";
      return {
        ...bar,
        color,
      };
    });

    const activeMetroData = fuzzySearch(updatedData, activeMetro, "x");
    setData(updatedData.sort((a, b) => a.median - b.median));
    if (d.x !== -9999) setTooltip(`${d.x}: ${d.start}% - ${d.y}% (Median: ${d.median}%)`);

    setActiveMetroTooltip(
      `${activeMetro}: ${activeMetroData?.start}% - ${activeMetroData?.y}% (Median: ${activeMetroData?.median}%)`
    );
    if (!activeMetroData?.y) setActiveMetroTooltip("");
  };

  const chartLeaveHandler = () => {
    const updatedData = data.map((bar) => {
      const color = bar.x === activeMetro ? "firebrick" : "#12939A";
      return { ...bar, color };
    });
    setData(updatedData.sort((a, b) => a.median - b.median));
    document.body.style.cursor = "default";
    setTooltip("");
  };

  const margin = 40;

  function generateOffsetData(input) {
    const offset = [];
    for (let i = 0; i < input.length; i++) {
      const d = input[i];
      offset.push({ x: d.x, y: d.start });
    }
    return offset;
  }

  function generateOffsetData2(input) {
    const offset = [];
    for (let i = 0; i < input.length; i++) {
      const d = input[i];
      offset.push({ x: d.x, y: 200 });
    }
    return offset;
  }

  useEffect(() => {
    if (!data || !data.length) return;
    chartHandler({ x: -9999 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMetro]);

  return (
    <>
      <Badge position={"absolute"} top={"40px"} left={"108px"} zIndex={1} fontSize={"lg"}>
        {tooltip || ""}
      </Badge>
      <Badge
        position={"absolute"}
        top={"70px"}
        left={"108px"}
        zIndex={1}
        fontSize={"lg"}
        backgroundColor={"#ff00006e"}
      >
        {activeMetroTooltip || ""}
      </Badge>
      <FlexibleWidthXYPlot
        height={600}
        xType="ordinal"
        yDomain={[0, 200]}
        margin={{ left: 60, right: margin, top: margin, bottom: 120 }}
        xDistance={0}
        colorType="literal"
        onMouseLeave={chartLeaveHandler}
      >
        <DiscreteColorLegend
          style={{ position: "absolute", left: "100px", top: "100px" }}
          orientation="horizontal"
          items={[
            {
              title: "10th-90th Percentile",
              color: "#12939A",
              strokeWidth: 40,
            },
            {
              title: "Metro Median",
              color: "brown",
              strokeWidth: 40,
              strokeStyle: "solid",
            },
            {
              title: "Median (100 Metros)",
              color: "orange",
              strokeWidth: 40,
              strokeStyle: "dashed",
            },
          ]}
        />
        {/* <VerticalGridLines /> */}
        <HorizontalGridLines />
        <BarSeries
          cluster="2015"
          color="white"
          // stroke={0}
          data={offsetData2}
          marginTop={margin}
          barWidth={0.8}
        />
        <BarSeries
          cluster="2015"
          // color={barColor}
          stroke={"transparent"}
          data={data}
          barWidth={0.8}
          onNearestX={chartHandler}
        />
        <BarSeries
          cluster="2015"
          color="white"
          // stroke={0}
          data={offsetData}
          marginTop={margin}
          barWidth={0.8}
        />
        <LineSeries data={data.map((d) => ({ x: d.x, y: 62 }))} strokeDasharray="6,4" />
        <LineSeries data={lineData} color="brown" />
        <XAxis tickLabelAngle={-90} style={{ text: { fontSize: "12px" } }} />
        <YAxis tickFormat={(v) => v + "%"} />
      </FlexibleWidthXYPlot>
    </>
  );
};
