import { Box, Flex, Grid, Heading, Stack } from "@chakra-ui/react";

import { CustomBarChart } from "../../components/Demo/BoxplotChart";
import { PortfolioManager } from "../../components/PortfolioManager/PortfolioManager";
import { RiskSpreadTable } from "../../components/Demo/RiskSpreadTable";
import { apiGateway } from "../../config";
import data from "../../data/hpa_tract_dist_100_cbsa.json";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";
import { useEffect } from "react";

export const HPA = () => {
  const { hpaMatrixCurrent, setHpaMatrixCurrent, activeMetro } = useAppState();
  const { apiToken: accessToken } = useAccountStore();

  const chartData = data.map((item) => ({
    x: item.metro,
    y: item.tract_pntl_90,
    start: item.tract_pntl_10,
    median: item.metro_median,
  }));

  useEffect(() => {
    if (!accessToken || hpaMatrixCurrent.length) return;
    (async () => {
      try {
        const matrix = await fetch(
          `${apiGateway}/collections/public.mview_hpa_annualized_2023/items.json?access_token=${accessToken}&limit=10000`
        ).then((res) => res.json());
        if (!matrix?.features?.length) throw new Error("No data");
        const matrixData = matrix.features.map((item) => item.properties);
        console.log(matrixData);
        setHpaMatrixCurrent(matrixData);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line
  }, [accessToken]);

  return (
    <Box margin={"0 auto"} flex={1} p={4}>
      <Heading textAlign={"center"} as="h2" size="xl" mb={8}>
        Product Projections
      </Heading>
      <Heading as="h3" size="lg" textAlign={"center"}>
        MSA For Sale Residential Housing Markets
        <br />
        Home Price Appreciation (2014-2020)
      </Heading>
      <Grid
        gridTemplateColumns={{
          base: "minmax(0, calc(100vw - 20px))",
          lg: "minmax(0, 1340px)",
          xl: "minmax(0, 1680px)",
        }}
        style={{ transform: "scale(1)" }}
        mb={40}
      >
        <CustomBarChart boxplotData={chartData} />
      </Grid>
      <Heading as="h3" size="lg" textAlign={"center"} mb={"14"}>
        Annualized For Sale Residential Price/Risk Spreads
        <br />
        Annualized Home Price Appreciation
        <br />
        {activeMetro}
      </Heading>
      <Stack spacing={40} flexDirection="column" alignItems={"center"}>
        <Grid
          templateColumns={{ base: "minmax(0,800px)", lg: "repeat(2, 1fr)" }}
          gap={6}
          width="calc(100% - 80px)"
          justifyContent={"center"}
        >
          <RiskSpreadTable type="current" title={`Projected Year End 2023`} showButton={true} />
          <RiskSpreadTable
            type="pand"
            title={`
      Pandemic (2019-2021)`}
          />
        </Grid>
        <Grid
          templateColumns={{ base: "minmax(0,800px)", lg: "repeat(2, 1fr)" }}
          gap={6}
          width="calc(100% - 80px)"
          justifyContent={"center"}
        >
          <RiskSpreadTable type="boom" title={`Boom (2014-2019)`} />
          <RiskSpreadTable type="bust" title={`Bust (2005-2011)`} />
        </Grid>
        <Box>
          <Heading as="h3" size="md" textAlign={"center"} mb={"14"}>
            Portfolio Stats
          </Heading>
          <Flex justifyContent="center" alignItems="center" flexDir={"column"}>
            <PortfolioManager hideTitle={true}></PortfolioManager>
          </Flex>
        </Box>
      </Stack>
      <Box h={100} />
    </Box>
  );
};
