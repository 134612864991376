import { mean, median, standardDeviation, variance } from "simple-statistics";

export const getAverages = (object) => {
  const averages = {};
  for (let key in object) {
    if (object[key].length && object[key].length > 1) {
      // averages[key] = object[key].reduce((sum, value) => sum + value, 0) / object[key].length;
      if (variance(object[key]) / standardDeviation(object[key]) > 100) {
        averages[key] = median(object[key]);

      }else{
        averages[key] = mean(object[key]);
      }
      // console.log(key, "variance", variance(object[key]) / standardDeviation(object[key]))
    } else {
      averages[key] = object[key][0];
    }
  }
  return averages;
};

/* Reference: https://stackoverflow.com/questions/71960808/calculate-average-of-an-array-with-objects-that-has-a-nested-object */
export const getReducedObject = (array) => {
  return array.reduce((acc, obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      acc[key] = acc[key] || [];
      if (Number(value)) {
        acc[key].push(value);
      } else {
        acc[key].push(0);
      }
    });
    return acc;
  }, {});
};

export const getBucket = (v, buckets) => {
  // if (debug) console.log(v, buckets)
  if (!v) return 0;
  const filtered = buckets.filter((bucket) => Number(v) >= bucket);
  // if (debug) console.log({filtered})
  const bucket = filtered.length > 0 ? filtered.length - 1 : 0;
  // if (debug) console.log(bucket)
  return bucket;
};

export const getListingStats = async (opts) => {
  const { gateway, area, dateRange, token, debug, areaType, placesType, placesAreaType } = opts;
  if (!area || !areaType) return;
  // setIsLoading(true);

  const areasArray = [
    null,
    "San Diego",
    "San Francisco"
  ]

  if (debug) console.log("[getListingsStats helper] -- getting average stats");
  const now = Date.now();
  
  let url = `${gateway}/functions/postgisftw.get_listing_stats_ii/items.json?${placesType ? `i_places=${placesType}`: ""}`
    url += `&i_places_area_id=${areasArray.indexOf(area)}`
    url += `&i_date_type=monthly`
    url += `&i_place_type=${placesType ? "place_type" : placesAreaType ? placesAreaType : "mls"}`
    url += `&i_min_date_value=${dateRange.range[0]}`
    url += `&i_max_date_value=${dateRange.range[1]}&limit=100000&access_token=${token}`;

  if (areaType === "Area") {
    const areaData = await fetch(url).then((res) => res.json());
    if (debug) console.log({areaData})
    return areaData
  }
  
  if (debug) console.log("[getListingsStatsFetch] - non area")

  const data = await fetch(url, {
    cache: "force-cache"
  }).then((res) => res.json());
  if (!data) throw new Error("Missing place data");

  const dl = Date.now() - now;

  const regionalPlacesData = data.map((d) => {
    d.places_id = +d.places_id;
    return d;
  });

  if (debug) console.log("[getListingsStats helper]", { regionalPlacesData });

  const array = [];
  regionalPlacesData.forEach((d) => {
    if (areaType === "Place") {
      if (!array.includes(d.places_id)) array.push(d.places_id);
    }
    if (areaType === "Area") {
      if (!array.includes(d.date_value)) array.push(d.date_value);
    }
  });

  const averageStats = [];
  let averaging;

  for (let i = 0; i < array.length; i++) {
    const p = array[i];
    if (!i) averaging = Date.now();

    if (i < 1 && debug) console.log("[getListingsStats helper] - sample id: ", p);
    const totalStats =
      areaType === "Place"
        ? regionalPlacesData.filter((d) => d.places_id && d.places_id === p)
        : regionalPlacesData.filter((d) => d.date_value && d.date_value === p);

    if (totalStats.length) {
      const reduced = getReducedObject(totalStats);
      const totalAverages = getAverages(reduced);
      totalAverages["__averaging"] = Date.now() - averaging;
      totalAverages["__download"] = dl;
      totalAverages["__dateRageValue"] = dateRange.value;
      averageStats.push(totalAverages);
    }
  }
  if (debug) console.log({averageStats});

  return averageStats;
};
