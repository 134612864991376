import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
} from "@chakra-ui/react";

const Loader = ({ isOpen, onClick, transparent, ...rest }) => {
  return (
    <Modal isOpen={isOpen} size={"full"} {...rest}>
      <ModalContent
        background={transparent ? "transparent" : "rgba(255,255,255,0.2)"}
        onClick={onClick}
      >
        <ModalBody h={"100%"} display="flex" justifyContent={"center"}>
          <Flex alignItems="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              zIndex={1}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Loader;
