import {
  Box,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MinusIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";

import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

// import { CSVLink } from "react-csv";

export const GPTTable = ({
  title,
  data,
  fields,
  sortField,
  padding,
  pageLength,
  size,
  variant,
  sortable,
  pagination,
  highlightRows,
  ...rest
}) => {
  const containerRef = useRef(null);
  const isSortable = sortable === false ? false : true;
  const isPaginated = pagination === false ? false : true;
  const [sortBy, setSortBy] = useState(sortField);
  const [sortDirection, setSortDirection] = useState("asc");
  // const [data, setData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [nItems] = useState(pageLength);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    if (!data.length) return;
    const n = Number((data.length / nItems).toFixed(0));
    const d = data.length / nItems - n > 0 ? n + 1 : n;
    setPages(d);
  }, [data, nItems]);

  const handleSort = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(newSortBy);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    const tempData = [];
    data.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
    data.forEach((d) => tempData.push(d));
    const sortedData = tempData.slice((page - 1) * nItems, page * nItems);
    setTableData(sortedData);
  }, [page, sortDirection, sortBy, nItems, data]);

  return (
    <Box
      id="gpt-table"
      ref={containerRef}
      bg={useColorModeValue("white", "gray.800")}
      // borderRadius={"lg"}
      p={padding === 0 ? padding : padding || 6}
      overflowX={"auto"}
      {...rest}
    >
      {title ? (
        <Heading as="h2" size="md">
          {title}
        </Heading>
      ) : (
        ""
      )}
      <Table size={size ? size : "md"} variant={variant ? variant : "simple"}>
        <Thead>
          <Tr>
            {fields.map((field, i) => (
              <Th key={i.toString()} isNumeric={field.isNumeric ? true : false}>
                {field.alias}
                {isSortable ? (
                  <IconButton
                    icon={
                      sortBy !== field.field ? (
                        <MinusIcon />
                      ) : sortBy === field.field && sortDirection === "asc" ? (
                        <TriangleUpIcon />
                      ) : (
                        <TriangleDownIcon />
                      )
                    }
                    onClick={() => handleSort(field.field)}
                    variant="ghost"
                  ></IconButton>
                ) : (
                  ""
                )}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData.length ? (
            tableData.map((row, i) => (
              <Tr
                key={i.toString()}
                fontWeight={highlightRows && highlightRows.includes(i) ? "bold" : "inherit"}
              >
                {fields.map((field, idx) => (
                  <Td
                    key={idx.toString()}
                    isNumeric={field.isNumeric ? true : false}
                    background={
                      highlightRows && highlightRows.includes(i)
                        ? "orange.200!important"
                        : "inherit"
                    }
                  >
                    {row[field.field]}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr></Tr>
          )}
        </Tbody>
      </Table>
      {isPaginated ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={4}>
          <Box fontSize={"sm"} opacity={0.8}>
            Page {page} of {pages}
          </Box>
          <Box>
            <IconButton
              icon={<ChevronLeftIcon />}
              mr={2}
              fontSize={"xl"}
              size={"sm"}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
              disabled={page === 1 ? true : false}
            >
              Prev
            </IconButton>
            <IconButton
              icon={<ChevronRightIcon />}
              fontSize={"xl"}
              size={"sm"}
              onClick={() => {
                if (page < Math.ceil(data.length / nItems)) {
                  setPage(page + 1);
                }
              }}
              disabled={page === pages ? true : false}
            >
              Next
            </IconButton>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
