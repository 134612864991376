import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseIcon, SettingsIcon, UnlockIcon } from "@chakra-ui/icons";

import { NetlifyAuth } from "../Auth/NetlifyAuth";
import { Pages } from "../../views/demo/Pages";
import md5 from "md5";
import { useAccountStore } from "../../context/accountStore";
import { useNavigate } from "react-router-dom";

export const AvatarNavButton = ({ openSettings }) => {
  const { account } = useAccountStore((state) => state);
  let navigate = useNavigate();

  function getGravatarURL(email) {
    const address = String(email).trim().toLowerCase();
    const hash = md5(address);
    return `https://www.gravatar.com/avatar/${hash}`;
  }

  const toggleLogin = () => {
    if (account) {
      console.log("[navbar] logout attempt");
      return NetlifyAuth.signout(() => {
        // setAccount(false);
        navigate("/");
      });
    } else {
      return NetlifyAuth.authenticate(async () => {
        const now = Date.now();
        console.log("[navbar] login attempt");
        // await setAccount(e);
        navigate("/" + Pages[0].title.toLowerCase().replace(/ /g, "-"));
        console.log("[navbar]--login", Date.now() - now);
      });
    }
  };

  return (
    <Flex alignItems={"center"}>
      <Menu>
        <MenuButton as={Button} rounded={"full"} variant={"link"} cursor={"pointer"} minW={0}>
          <Avatar
            bg={useColorModeValue("blue.200", "blue.200")}
            size={"sm"}
            fontSize="md"
            name={
              !account
                ? ""
                : account.user_metadata && account.user_metadata.full_name
                ? account.user_metadata.full_name
                : account.email
            }
            src={account && account.avatar ? getGravatarURL(account.email) : ""}
          />
        </MenuButton>
        <MenuList>
          {account ? (
            <>
              {Pages.map((page, i) => {
                if (!page?.hide) {
                  return (
                    <MenuItem
                      key={i}
                      justifyContent={"left"}
                      onClick={() => navigate("/" + page.title.toLowerCase().replace(/ /g, "-"))}
                    >
                      {page.title}
                    </MenuItem>
                  );
                } else return null;
              })}
              <MenuDivider />
              <MenuItem justifyContent={"left"} onClick={openSettings}>
                <SettingsIcon mr={2} />
                Settings
              </MenuItem>
              <MenuDivider />
            </>
          ) : (
            ""
          )}
          <MenuItem id="navbar-login" justifyContent={"left"} onClick={toggleLogin}>
            {account ? <CloseIcon boxSize={3} mr={2} /> : <UnlockIcon boxSize={3} mr={2} />}
            {account ? "Logout" : "Login"}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
