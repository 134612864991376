import { Box, Flex, Grid, Heading, Icon, Text } from "@chakra-ui/react";
import { FaCarSide, FaWalking } from "react-icons/fa";
import { useEffect, useState } from "react";

import { DataCategory } from "../../helpers/datamodels";
import { GroupByType } from "../../helpers/datamodels";
import { apiGateway } from "../../config";
import { getMetrics } from "../../services/requests";
// import { fuzzySearch } from "../../helpers/fuzzySearch";
// import lensdata from "../../data/demo_lens_matrix.json";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";

export const LensMatrix = ({ headingSize }) => {
  const { activeMetro } = useAppState();
  const [data, setData] = useState([]);
  const { apiToken, settings } = useAccountStore();

  //DEBUG
  // useEffect(() => {
  //   console.log({ lensdata });
  // }, []);
  console.debug(DataCategory);
  useEffect(() => {
    if (!settings?.msa_data || !settings?.msa_data?.length || !activeMetro) return;
    const taskedMetros = settings.msa_data.filter((m) => m.name === activeMetro);
    const taskedMetroIds = taskedMetros.reduce((i, v) => [...i, v.id], []);
    // console.log(area)
    (async () => {
      const lensdata = await getMetrics(
        apiGateway,
        apiToken,
        {
          placesArea: `{${taskedMetroIds.join(",")}}`,
          groupByType: GroupByType.places_type,
          dataCategory: `{${DataCategory.LENS_MATRIX}}`,
        },
        "data"
      );

      // const _data = lensdata.filter((d) => d.metro_area === area.metro_area);
      setData(lensdata);
      console.log({ lensdata });
    })();
  }, [apiToken, settings, activeMetro]);

  return (
    <Flex flexDirection="column" id="lens-matrix" alignItems={"center"}>
      {activeMetro ? (
        <Box flex={1} width="100%" maxW="1000px">
          <Heading size={headingSize || "lg"} mb={8} textAlign={"center"}>
            {activeMetro} Place-Based Sub-Markets
          </Heading>
          <Grid gridTemplateColumns={"repeat(3, calc(33.333% - 20px))"} gap={"20px"}>
            <Box
              gridColumn={"2 / -1"}
              textAlign={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
              mb={4}
            >
              Economic Function
            </Box>
          </Grid>

          <Grid
            gridTemplateColumns={"10px repeat(3, calc(33.333% - 20px))"}
            gap={"20px"}
            gridTemplateRows={"52px repeat(2,200px)"}
          >
            <Box
              gridRow={"span 3"}
              style={{ writingMode: "vertical-lr" }}
              transform="rotate(180deg)"
              fontSize={"xl"}
              fontWeight={"bold"}
              textAlign={"center"}
              ml={"30px"}
            >
              Development Form
            </Box>
            <Box></Box>
            <Box
              bg="gray.800"
              color="white"
              p={1}
              textTransform={"uppercase"}
              textAlign={"center"}
              fontSize={"md"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              Regionally Significant
            </Box>
            <Box
              bg="gray.500"
              color="white"
              p={1}
              textTransform={"uppercase"}
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              Local Serving
            </Box>
            <Flex
              flexDir="column"
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={"3xl"}
              color="green.600"
              textAlign={"center"}
            >
              <Box>
                <Icon as={FaWalking} fontSize={"4xl"} />
              </Box>
              Walkable <br></br>Urban
            </Flex>
            <MatrixBox title="WalkUPs" type={1} color={"places.type_1"} metroData={data} />
            <MatrixBox
              title="Walkable Neighborhoods"
              type={2}
              color={"places.type_2"}
              metroData={data}
            />
            <Flex
              flexDir="column"
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={"3xl"}
              color="yellow.800"
              textAlign={"center"}
            >
              <Box>
                <Icon as={FaCarSide} fontSize={"4xl"} />
              </Box>
              Drivable <br></br>Sub-urban
            </Flex>
            <MatrixBox
              title='Drivable "Edge-Cities"'
              type={3}
              color={"places.type_3"}
              metroData={data}
            />
            <MatrixBox
              title="Drivable Communities"
              type={4}
              color={"places.type_4"}
              metroData={data}
            />
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </Flex>
  );
};

const MatrixBox = ({ title, color, type, metroData }) => {
  const data = metroData.find((d) => +d.groupby_id === type) || {};
  let value = {
    value: "",
    name: "",
  };
  switch (color) {
    case "places.type_1":
      value.value = data?.office_share;
      value.name = "Office";
      break;
    case "places.type_2":
      value.value = data?.multifam_share;
      value.name = "Multi-Family";
      break;
    case "places.type_3":
      value.value = data?.retail_share;
      value.name = "Retail";
      break;
    default:
      value.value = data?.for_sale_share;
      value.name = "Residential";
  }
  const types = ["I", "II", "III", "IV"];

  return (
    <Box bg={color || "white"} p={4}>
      <Heading
        color={color === "places.type_4" ? "gray.800" : "white"}
        fontSize={"1x1"}
        fontWeight={"normal"}
        as="h2"
        textAlign={"center"}
        margin={"0 auto"}
        p="0"
        mb={2}
      >
        {title.split(" ").map((word, i) => (
          <Text key={i.toString()}>{word}</Text>
        ))}
        {type === 1 ? (
          <Text>
            <br />
          </Text>
        ) : (
          ""
        )}
        Type {types[type - 1]}
      </Heading>
      <Box bg="whiteAlpha.500" p={3} fontSize={"lg"} fontWeight={"bold"}>
        <Text>{data?.area_acres_pnt_msa}% Land</Text>
        <Text>{data?.stock_pnt_msa}% As-Built (sq ft)</Text>
        <Text>{Math.round(data?.gdp_pnt_msa ?? 0)}% GDP</Text>
      </Box>
    </Box>
  );
};
