const accessToken =
  "pk.eyJ1IjoicHBwZG9oZXJ0eSIsImEiOiJjbDJldmVkaXYwNG13M2NxejBsY29ybmJtIn0.u_gVqFCetSfF-a2wMD9eAw";
const apiGateway = process.env.REACT_APP_API_GATEWAY;
const apiKey = "Htz567UYz210BNk";
const mapStyles = {
  light_old: "mapbox://styles/pppdoherty/ckwlhf8og09rv15mah1tlxo1f",
  light: "mapbox://styles/pppdoherty/cl5v0mmhp001v14qnafjj8m66",
  dark: "mapbox://styles/pppdoherty/ckwlhf8og09rv15mah1tlxo1f",
};
const version = "0.1.0";

export { apiKey, accessToken, mapStyles, apiGateway, version };
