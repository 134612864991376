import { useAccountStore } from "../context/accountStore";
import { useAppState } from "../context/useAppState";
import { useEffect } from "react";

export const useInitAppState = () => {
  const { settings } = useAccountStore();
  const { metros, setMetros } = useAppState();
  // const [state, setState] = useState([]);

  useEffect(() => {
    if (!settings || !settings?.msa_data?.length) return;
    if (metros.length) return;
    const _metros = settings.msa_data.reduce((acc, m) => [...acc, m.name], []);
    console.log(_metros);
    setMetros(_metros);
    // setState(_metros);
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (!metros.length) return;
    console.log("[useInitAppState] total metros in db", metros.length);
  }, [metros]);

  return;
};
