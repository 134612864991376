import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const CustomModal = ({ title, isOpen, onClose, children, size, ...rest }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size || "xl"}>
      <ModalOverlay />
      <ModalContent {...rest}>
        <ModalHeader textAlign={"center"}>{title ? title : "Title"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* <Button variant="ghost">Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * Basic Modal
 * @returns
 */
function BasicModal({
  buttonText,
  buttonMargin,
  title,
  size,
  buttonAlign,
  buttonColorScheme,
  children,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box textAlign={buttonAlign || "center"} m={buttonMargin || 0}>
        <Button onClick={onOpen} colorScheme={buttonColorScheme || "gray"}>
          {buttonText || "Open Modal"}
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size={size || "lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>{title || "Title"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export { CustomModal, BasicModal };
