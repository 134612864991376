import {
  Box,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";

import { Pages } from "../../views/demo/Pages";

export const Sidebar = ({ isOpen, onClose, title, children, ...rest }) => {
  const { pathname } = useLocation();

  return (
    <Flex
      bg={useColorModeValue("white", "gray.900")}
      {...rest}
      id="entry-dashboard-sidebar"
      display={pathname === "/" || pathname === "/home" ? "none" : "flex"}
    >
      <SidebarContent
        id="desktop-sidebar"
        onClose={() => onClose}
        display={{ base: "none", lg: "flex" }}
        children={children}
        title={title}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={{ base: "full", sm: "sm" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <CloseButton position="absolute" right="16px" top="16px" onClick={onClose} />
          <SidebarContent pr={14} onClose={onClose} children={children} title={title} />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

function SidebarContent({ onClose, title, children, ...rest }) {
  return (
    <Flex
      position={"fixed"}
      height="100%"
      flexDir={"column"}
      width={{ base: "100%", lg: "260px" }}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      bg={useColorModeValue("white", "gray.800")}
      h="full"
      p={4}
      {...rest}
    >
      <Flex justifyContent="space-between">
        {/* <Text fontSize="2xl" as="h3" fontWeight="bold">
          {title}
        </Text> */}
      </Flex>
      <Box flex={1}>
        {Pages.map((page, i) => {
          if (!page?.hide) {
            return (
              <LinkButton
                key={i}
                link={"/" + page.title.toLocaleLowerCase().replace(/ /g, "-")}
                title={page.title}
              />
            );
          } else return null;
        })}
        <Divider my={4} />
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
}

const LinkButton = ({ link, title }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Button
      w="100%"
      p={4}
      my={2}
      colorScheme={pathname === link ? "facebook" : "gray"}
      fontWeight={400}
      onClick={() => navigate(link)}
    >
      {title}
    </Button>
  );
};
