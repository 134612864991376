import "./highlightjson.css";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { GPTTable } from "../Tables/GPTTable";
import { apiGateway } from "../../config";
import data from "../../docs/docs.json";
import { getMetrics } from "../../services/requests";
import hpaDATA from "../../data/hpa_tract_dist_100_cbsa.json";
import { syntaxHighlight } from "../../helpers/syntaxHighlight";
import { useAccountStore } from "../../context/accountStore";

const Documentation = () => {
  const { apiToken } = useAccountStore();
  const [docsConfig, setDocsConfig] = useState(data);
  const [sources, setSources] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [tables, setTables] = useState([]);

  const dataTypes = [
    {
      type: "data",
      description: "Static data points.",
    },
    {
      type: "stat",
      description: "Data with a time series component.",
    },
    {
      type: "analytic",
      description: "Aggregated data with a time series component.",
    },
    {
      type: "static",
      description:
        "Static data points that need migrated to the new API either on the front end, backend or both.",
    },
  ];

  useEffect(() => {
    if (!apiToken) return;
    (async () => {
      const config = await fetch(
        `${apiGateway}/collections/public.mview_ppl_metric_details/items/?access_token=${apiToken}&limit=1000`
      ).then((res) => res.json());
      const db = config.features.map((item) => item.properties);
      console.log(db);
      setSources(
        db
          .reduce(
            (acc, curr) => [
              ...acc,
              {
                name: curr.source_name,
                id: curr?.source_id || curr.source_name,
                description: curr.source_description,
                category: curr.category_name,
                category_id: curr.category_id,
                slug: curr.source_name + curr.category_name,
                updated: curr.source_last_update,
                max_metric_date: curr.max_metric_date,
                min_metric_date: curr.min_metric_date,
                metric_names: curr?.metrics?.map(
                  (metric) => metric?.metric_label || metric?.metric_name || "missing"
                ),
              },
            ],
            []
          )
          .sort((a, b) => a.slug.localeCompare(b.slug))
      );
      const _metrics = [];
      const metricTypes = [];
      db.forEach((item) => {
        if (item?.metrics?.length) {
          item.metrics.forEach((metric) => {
            if (!metricTypes.find((type) => type === metric.metric_type))
              metricTypes.push(metric.metric_type);
            if (!_metrics.find((metricItem) => metricItem.metric_id === metric.metric_id)) {
              let maxDate = null;
              let minDate = null;
              const metricSources = [];
              const metricSlices = [];
              if (metric?.usage?.length) {
                maxDate = metric.usage[0]?.analytic_max_date || metric.usage[0]?.stat_max_date;
                minDate = metric.usage[0]?.analytic_min_date || metric.usage[0]?.stat_min_date;
                metric.usage.forEach((usage) => {
                  if (!metricSources.includes(usage.source)) metricSources.push(usage.source);
                  if (!metricSlices.includes(usage.groupby)) metricSlices.push(usage.groupby);
                });
              }
              metric["maxDate"] = maxDate;
              metric["minDate"] = minDate;
              metric["sources"] = metricSources;
              metric["slices"] = metricSlices;
              _metrics.push(metric);
            }
          });
        }
      });
      console.log(metricTypes);
      setMetrics(_metrics);

      setDocsConfig((prev) => {
        const newDocs = prev.map((doc) => {
          const info = db.find((item) => item.category_id === doc.category);
          if (info) {
            doc = { ...doc, ...info };
          }
          return doc;
        });
        return newDocs;
      });
    })();

    (async () => {
      const { features } = await fetch(
        `${apiGateway}/collections/public.vw_comments/items?limit=10000&&access_token=${apiToken}`
      ).then((res) => res.json());
      if (!features.length) return;
      features.forEach((item) => {
        //alias r means table, m mean material view, v means view
        //loop through the properties and change the type value to the alias
        const keys = Object.keys(item.properties);
        keys.forEach((key) => {
          if (key === "type") {
            item.properties[key] = item.properties[key].replace("r", "table");
            item.properties[key] = item.properties[key].replace("v", "view");
            item.properties[key] = item.properties[key].replace("m", "material view");
          }
        });
      });
      setTables(features.reduce((i, v) => [...i, v.properties], []));
    })();
  }, [apiToken]);

  // useEffect(() => {
  //   if (!apiToken) return;
  //   (async () => {
  //     const dataSample = await getMetrics(
  //       apiGateway,
  //       apiToken,
  //       {
  //         placesArea: `{1}`,
  //         groupByType: `${data[0].groupByType}`,
  //         dataCategory: `{${data[0].category}}`,
  //       },
  //       data[0].apiType
  //     );
  //     console.log(dataSample);
  //     setSampleData((prev) => ({ ...prev, item1: dataSample[0]?.data }));
  //   })();
  // }, [apiToken, settings]);

  const [tabIndex, setTabIndex] = useState(
    window?.location?.hash ? +window.location.hash.replace("#", "") : 0
  );

  useEffect(() => {
    const allowed = [1, 2, 3, 4];
    if (!allowed.includes(tabIndex)) {
      setTabIndex(0);
      window.location.hash = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex alignItems={{ base: "flex-start", lg: "center" }} direction="column">
      <Box w="100%" maxW={{ base: "100%", lg: "1200px" }}>
        <Heading as="h1" size="xl" textAlign={"center"} mb={2}>
          Documentation & Data Sources
        </Heading>
        <Box fontStyle={"italic"} textAlign="center" mb={10}>
          All sample data is from San Diego.
        </Box>
        <Tabs
          onChange={(index) => {
            window.location.hash = index;
            setTabIndex(index);
          }}
          defaultIndex={Number(tabIndex) && tabIndex < 5 ? tabIndex : 0}
        >
          <TabList>
            <Tab>Charts & Data Visuals</Tab>
            <Tab>Sources & Categories</Tab>
            <Tab>Metrics</Tab>
            <Tab>Data Types</Tab>
            <Tab>Database Tables</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ChartsVisuals data={docsConfig} />
            </TabPanel>
            <TabPanel>
              <Sources sources={sources} />
            </TabPanel>
            <TabPanel>
              <Metrics metrics={metrics} />
            </TabPanel>
            <TabPanel>
              <DataTypes dataTypes={dataTypes} />
            </TabPanel>
            <TabPanel>
              <GPTTable
                size="sm"
                sortable={false}
                data={tables.length ? tables : []}
                fields={[
                  {
                    alias: "Name",
                    field: "name",
                  },
                  {
                    alias: "type",
                    field: "type",
                  },
                  {
                    alias: "Comment",
                    field: "comment",
                  },
                ]}
                pageLength={400}
                padding={0}
                sortField="name"
              ></GPTTable>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default Documentation;

const ChartsVisuals = ({ data }) => {
  const [sampleData, setSampleData] = useState({});
  const { apiToken } = useAccountStore();

  const getDataSample = (item) => {
    console.log(item);
    if (item.staticData) {
      const obj = {};
      obj[item.slug] = item.staticData === "hpaData" ? hpaDATA : item.staticData;
      setSampleData((prev) => ({ ...prev, ...obj }));
      return;
    } else if (item?.customAPI) {
      fetch(`${apiGateway}/${item.customAPI}&access_token=${apiToken}`)
        .then((res) => res.json())
        .then((data) => {
          const obj = {};
          obj[item.slug] = data;
          setSampleData((prev) => ({ ...prev, ...obj }));
        });
    } else {
      getMetrics(
        apiGateway,
        apiToken,
        {
          placesArea: `{1}`,
          groupByType: `${item.groupByType}`,
          dataCategory: `{${item?.category || ""}}`,
          metricIds: `{${item?.metricIds || ""}}`,
          dateType: `${item?.dateType || ""}`,
          dateStart: `${+item?.dateStart || ""}`,
          dateEnd: `${+item?.dateEnd || ""}`,
        },
        item.dataType
      ).then((dataSample) => {
        console.log(dataSample);
        const obj = {};
        obj[item.slug] =
          item.dataType === "data"
            ? dataSample[0]?.data
            : item.dataType === "stat"
            ? dataSample[0]?.stats
            : [];
        setSampleData((prev) => ({ ...prev, ...obj }));
      });
    }
  };

  const clearSampleData = (item) => {
    console.log(sampleData);
    if (sampleData[item.slug]) {
      const obj = { ...sampleData };
      delete obj[item.slug];
      setSampleData(obj);
    }
  };

  return (
    <Accordion allowToggle>
      {data.map((doc, i) => (
        <AccordionItem
          key={doc.title.toLowerCase().replace(/ /g, "-") + i}
          isDisabled={doc?.disabled}
        >
          <h2 id={doc.title.toLowerCase().replace(/ /g, "-")}>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {doc?.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Heading as="h3" size="md" textAlign={"left"} my={2}>
              <span style={{ textTransform: "capitalize" }}>{doc?.page}</span> | {doc?.title}
            </Heading>

            <p style={{ textTransform: "capitalize" }}>
              Query Unit: {doc?.timeframe || doc?.dateType || "Static"}
            </p>
            <p>Source: {doc?.source_name?.toUpperCase()?.replace(/_/g, " ")}</p>
            <p>Data Type: {doc?.dataType?.toUpperCase()}</p>
            <details style={{ margin: "0.5rem 0" }}>
              <summary>Metadata</summary>
              <pre
                dangerouslySetInnerHTML={{
                  __html: syntaxHighlight(JSON.stringify(doc, 0, 2)),
                }}
              />
            </details>
            <Flex my={4} alignItems={"center"} justifyContent={"space-between"}>
              {/* <Heading as="h3" size="md" textAlign={"left"} my={2}>
      Show Data Sample
    </Heading> */}
              <Flex>
                <Button onClick={() => getDataSample(doc)} mr={4}>
                  Get Sample Data
                </Button>
                <Button onClick={() => clearSampleData(doc)}>Clear Data</Button>
              </Flex>
            </Flex>
            {sampleData[doc.slug] ? (
              <pre
                dangerouslySetInnerHTML={{
                  __html: syntaxHighlight(JSON.stringify(sampleData[doc.slug], 0, 2)),
                }}
              ></pre>
            ) : (
              ""
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const Metrics = ({ metrics }) => (
  <Box>
    {metrics.map((metric, i) => (
      <Box key={i} my={2}>
        <Box fontWeight="bold" mb={2}>
          <p>
            Metric: {metric?.metric_id} - {metric?.metric_label}
          </p>
          <p>
            Date Range: {metric?.maxDate} - {metric?.minDate}
          </p>
          <p>
            Sources: {metric.sources.join(", ")}
            <br />
            Slices: {metric?.slices?.join(", ")}
          </p>
        </Box>
        <Divider />
      </Box>
    ))}
  </Box>
);

const Sources = ({ sources }) => (
  <Box>
    <SourceListing data={sources} />
  </Box>
);

const DataTypes = ({ dataTypes }) => (
  <>
    {dataTypes.map((type, i) => (
      <Box key={i}>
        <Box as="span" fontWeight="bold" textTransform={"uppercase"}>
          {type.type.replace(/_/g, " ")}
        </Box>
        <Box as="span"> - {type.description}</Box>
      </Box>
    ))}
  </>
);

const SourceListing = ({ data }) => {
  console.log({ data });

  return (
    <Grid gridTemplateColumns={"1fr"}>
      {data.map((item, i) => (
        <Stat key={i.toString()} mb={1}>
          <StatNumber fontSize={16}>
            {item?.name?.replace(/_/g, " ")} | {item?.category?.replace(/_/g, " ")}
          </StatNumber>
          <StatLabel>Updated in the Sytem: {item?.updated}</StatLabel>
          <StatHelpText>
            {item?.min_metric_date
              ? `Date Range: ${item?.min_metric_date} - ${item?.max_metric_date}`
              : ""}
          </StatHelpText>
          <StatHelpText>
            <details>
              <summary>Metrics</summary>
              {item?.metric_names?.length
                ? item.metric_names.sort().map((metric, i) => <p key={i.toString()}>{metric}</p>)
                : "No Metrics"}
            </details>
          </StatHelpText>
        </Stat>
      ))}
    </Grid>
  );
};
