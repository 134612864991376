import { Badge, Box, Heading } from "@chakra-ui/react";
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  YAxis,
} from "react-vis";

import { placeTypes } from "../../assets/theme";
import { useAppState } from "../../context/useAppState";
import { useState } from "react";

const MedianPriceChangeCharts = ({ data, title, metric }) => {
  const [tooltip, setTooltip] = useState({});
  const [qtr, setQtr] = useState("");
  const { placesTypes: activePlaceTypes } = useAppState();

  const chartHandler = (d, e) => {
    // console.log(d);
    document.body.style.cursor = "crosshair";
    setQtr(d.x);
    setTooltip((current) => ({
      ...current,
      ["type" + d.placeType]: d.y ? Number(d.y).toFixed(2) + "%" : "N/A",
    }));
  };

  const chartLeaveHandler = () => {
    document.body.style.cursor = "default";
    setTooltip({});
  };

  const formatTooltip = () => {
    if (!Object.values(tooltip).length) return "";
    return (
      <div>
        <div style={{ fontWeight: "bold", position: "absolute", right: 0 }}>{qtr}</div>
        <div style={{ columns: 2, textAlign: "left" }}>
          {placeTypes.map((t, i) => (
            <div
              key={i.toString()}
              style={{
                color: t.type !== 4 ? t.color : "#212121",
              }}
            >
              {t.label}: {tooltip["type" + t.type] !== "" ? tooltip["type" + t.type] : "N/A"}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {data.length ? (
        <Box width="1000px" maxW="100%" minH={"600px"} mb={8} mx="auto" position={"relative"}>
          <Heading as="h3" size="lg" textAlign={"center"} mb={6}>
            {title}
          </Heading>
          <Box
            backgroundColor={"white"}
            border={"solid thin lightgray"}
            borderColor={"gray.200"}
            px={4}
            py={8}
          >
            <Badge
              position={"absolute"}
              width={"100%"}
              top={"50px"}
              left={"0px"}
              zIndex={1}
              fontSize={"sm"}
            >
              {formatTooltip() || ""}
            </Badge>
            <FlexibleWidthXYPlot
              height={360}
              xType={"ordinal"}
              margin={{ left: 60, right: 20 }}
              yPadding={10}
              onMouseLeave={chartLeaveHandler}
            >
              <DiscreteColorLegend
                style={{ position: "absolute", left: "50px", bottom: "-100px" }}
                orientation="horizontal"
                items={placeTypes.map((m, i) => ({
                  title: m.label,
                  strokeWidth: 40,
                  strokeStyle: i === 1 || i === 3 ? "dashed" : "solid",
                  color: m.colorMuted,
                }))}
              />
              <HorizontalGridLines />
              <XAxis
                tickLabelAngle={-45}
                tickTotal={data.filter((d) => d.type === 1).length}
                style={{
                  text: { fontSize: "0.7rem" },
                }}
              />
              <YAxis
                orientation="left"
                style={{
                  text: { fontSize: "0.7rem" },
                }}
                tickFormat={(v) => `${(+v).toFixed(0)}%`}
              />
              {placeTypes.map(
                (t, i) =>
                  activePlaceTypes.includes(t.type) && (
                    <LineSeries
                      key={i.toString()}
                      data={data.filter((d) => d.placeType === t.type)}
                      curve={"curveMonotoneX"}
                      stroke={t.color}
                      strokeStyle={t.type === 2 || t.type === 4 ? "dashed" : "solid"}
                      onNearestX={chartHandler}
                    />
                  )
              )}
            </FlexibleWidthXYPlot>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default MedianPriceChangeCharts;
