import { useEffect, useRef } from "react";

// import netlifyIdentity from "netlify-identity-widget";
import { useAccountStore } from "../context/accountStore";
import { useAppState } from "../context/useAppState";

export const useAuth = () => {
  const { isLoggedIn, setAccount, logout, refreshToken } = useAccountStore();
  const { setIsLoading } = useAppState();
  let intervalVal = useRef(null);

  useEffect(() => {
    if (!window.netlifyIdentity) return;
    const netlifyIdentity = window.netlifyIdentity;
    console.log("[useAuth] init");
    netlifyIdentity.on("login", (user) => {
      console.log("[useAuth] netlify login");
      setAccount(user);
      netlifyIdentity.close();
    });

    netlifyIdentity.on("logout", () => {
      console.log("[useAuth] netlify logout");
      logout();
      console.log(intervalVal);
      clearInterval(intervalVal.current);
    });

    netlifyIdentity.on("error", (err) => {
      console.error("[useAuth] netlify error", err);
    });
    // eslint-disable-next-line
  }, [window.netlifyIdentity]);

  useEffect(() => {
    if (!window.netlifyIdentity) return;
    if(window.netlifyIdentity?.store?.modal?.page === "recovery") return;
    if (!isLoggedIn) {
      setIsLoading(true);
      const currentUser = window.netlifyIdentity.currentUser();
      console.log("[useAuth] set current user");
      console.log({ currentUser });      
      setAccount(currentUser);
      setIsLoading(false);
    } else {
      intervalVal.current = setInterval(() => {
        if (!isLoggedIn) return;
        console.log("[useAuth] new token");
        refreshToken();
      }, 1000 * 60 * 50);
    }

    // eslint-disable-next-line
  }, [isLoggedIn, window.netlifyIdentity]);

  return isLoggedIn;
};

// const NetlifyAuth = {
//   // isAuthenticated: false,
//   isLoggedIn() {
//     if (this.user) return this.user;
//     this.user = netlifyIdentity.currentUser();
//     return this.user;
//   },
//   user: null,
//   authenticate(callback) {
//     // this.isAuthenticated = true;
//     netlifyIdentity.open();
//     netlifyIdentity.on("login", (user) => {
//       this.user = user;
//       callback(user);
//       netlifyIdentity.close();
//     });
//   },
//   signout(callback) {
//     // this.isAuthenticated = false;
//     netlifyIdentity.logout();
//     netlifyIdentity.on("logout", () => {
//       this.user = null;
//       console.log("LOG NETLIFY AUTH -- user:", this.user);
//       callback();
//     });
//   },
// };
