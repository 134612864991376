import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

import React from "react";
import { useLayerStore } from "../../context/layerStore";

// import { FiCircle } from 'react-icons/fi'

/*
  LAYER DIRECTORY WHICH IS ACCORDION
  LAYER GROUP WHICH IS A CHECKBOX WITH OPTIONAL CHILDREN
  LAYERS WHICH ARE CHILDREN OF THE GROUP
*/

const LayerControl = () => {
  const layers = useLayerStore((state) => state.layers);
  const setLayers = useLayerStore((state) => state.setLayers);

  // const [checkedItems, setCheckedItems] = React.useState([false, false]);

  // const allChecked = checkedItems.every(Boolean);
  // const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const layerControlToggle = (e) => {
    const target = e.target;
    const updatedLayers = layers.map((l) => {
      if (!l.metadata.layers) l.metadata.layers = [l.id];
      //TODO add back children functionality
      if (l.childLayers && target.id.includes("_lc_child")) {
        l.childLayers.forEach((child) => {
          if (child.id === target.id.replace("_lc_child", "")) {
            if (child.visibility === "none") {
              child.visibility = "visible";
            } else {
              child.visibility = "none";
            }
          }
        });
      }

      //THIS JUST CHANGES THE LAYERS STATE - TOGGLE FUNCTIONALITY EXISTS IN THE MAP toggleLayers()
      if (l.childFilters && target.id.includes("_lc_childFilter")) {
        l.childFilters.forEach((child) => {
          if (child.id === target.id.replace("_lc_childFilter", "")) {
            if (child.active) {
              child.active = false;
            } else {
              child.active = true;
            }
          }
        });
      }

      //GET PARENT VISIBILITY
      l.metadata.layers.forEach((id) => {
        if (id === target.id || (l.metadata.parent && l.metadata.parent === target.id)) {
          if (l.metadata.visibility === "none") {
            l.metadata.visibility = "visible";
          } else {
            l.metadata.visibility = "none";
          }
        }
      });

      return l;
    });
    setLayers(updatedLayers);
  };

  //NOTE GROUP IDEA
  // const directories = {};
  // const groups = {};
  // layers.forEach((l) => {
  //   if (l.directory && !directories[l.directory]) {
  //     directories[l.directory] = {
  //       layers: [],
  //       groups: [],
  //     };
  //   }
  //   directories[l.directory].groups.push(l);
  // });

  // console.log(directories)
  // console.log(groups)

  const activeLayers = layers.filter((l) => {
    return !l.hide && !l.metadata.hide && !l.remove && !l.metadata.hidden;
  });

  return (
    <Accordion
      allowMultiple
      // defaultIndex={[2]} // use to expand a certain control
      key={0}
    >
      {activeLayers.map((layer, layerKey) => {
        const legend = layer.legend;
        return (
          <AccordionItem key={layerKey.toString() + layer?.id?.replace(/ /g, "-").toLowerCase()}>
            {({ isExpanded }) => (
              <>
                <AccordionButton p={0} pr={2}>
                  <Checkbox
                    size={"md"}
                    mr={2}
                    p={"8px 16px"}
                    flex="1"
                    id={layer.id}
                    name={layer.metadata.name}
                    key={"lc_checkbox_main_" + layerKey}
                    data-visible={layer.metadata.visibility === "visible" ? "visible" : "none"}
                    onChange={layerControlToggle}
                    isChecked={layer.metadata.visibility === "visible" ? true : false}
                  >
                    <Flex textAlign="left" fontWeight={400}>
                      {layer.metadata.name}
                    </Flex>
                  </Checkbox>
                  {/* <AccordionIcon /> */}
                  {!legend && !layer.childFilters && !layer.childLayers ? (
                    <></>
                  ) : isExpanded ? (
                    <ChevronDownIcon fontSize="18px" />
                  ) : (
                    <ChevronRightIcon fontSize="18px" />
                  )}
                </AccordionButton>

                <AccordionPanel
                  ml={2}
                  pb={4}
                  display={
                    legend && typeof legend !== "function" && !layer.childFilters
                      ? "block"
                      : !legend && !layer.childFilters && !layer.childLayers
                      ? "none"
                      : "block"
                  }
                >
                  {layer.children
                    ? layer.children.map((l, i) => {
                        return (
                          <Box p="0" key={i}>
                            <Checkbox
                              size="md"
                              id={l.id + "_lc_child"}
                              name={l.name}
                              key={"_lc_checkbox_" + i}
                              data-visible={
                                l.metadata.visibility === "visible" ? "visible" : "none"
                              }
                              onChange={layerControlToggle}
                              isChecked={l.metadata.visibility === "visible" ? true : false}
                              p={1}
                            >
                              {" "}
                              {l.name}
                            </Checkbox>
                          </Box>
                        );
                      })
                    : ""}
                  {legend && typeof legend !== "function"
                    ? //NOTE could change these to icons
                      legend.map((l, i) => {
                        const size = l.size && l.size === "lg" ? "26px" : "18px";
                        const mL = size === "26px" ? "-4px" : 0;
                        if (l.type === "circle") {
                          return (
                            <Flex key={i} textTransform={"capitalize"} alignItems="center">
                              <Box
                                borderRadius="50%"
                                border={`solid 2px ${l.strokeColor}`}
                                backgroundColor={l.color}
                                h={size}
                                w={size}
                                ml={mL}
                                mr={2}
                                mt={0.5}
                              ></Box>
                              {l.name ? l.name : layer.metadata.name}
                            </Flex>
                          );
                        } else if (l.type === "fill") {
                          return (
                            <Flex key={i} textTransform={"capitalize"} alignItems="center">
                              <Box
                                borderRadius={4}
                                border={`solid 2px ${l.strokeColor ? l.strokeColor : ""}`}
                                backgroundColor={l.color}
                                h={size}
                                w={size}
                                ml={mL}
                                mr={2}
                                mt={0.5}
                              ></Box>
                              {l.name ? l.name : layer.metadata.name}
                            </Flex>
                          );
                        } else if (l.type === "line") {
                          return (
                            <Flex key={i} textTransform={"capitalize"} alignItems="center">
                              <Box
                                border={`solid 2px ${l.strokeColor ? l.strokeColor : ""}`}
                                backgroundColor={l.color}
                                h={size}
                                w={size}
                                ml={mL}
                                mr={2}
                                mt={1}
                              ></Box>
                              {l.name ? l.name : layer.metadata.name}
                            </Flex>
                          );
                        } else if (l.type === "icon") {
                          return (
                            <Flex key={i} textTransform={"capitalize"} alignItems="center">
                              <Box h="20px" w="20px" ml={mL} mr={1} mt={0}>
                                <Image h="100%" src={l.icon} alt="Dan Abramov" />
                              </Box>
                              {l.name ? l.name : layer.metadata.name}
                            </Flex>
                          );
                        } else if (l.type === "choropleth" || l.type === "choropleth-list") {
                          const values = [];
                          const colors = [];
                          if (layer.paint["fill-color"]?.stops?.length > 0) {
                            layer.paint["fill-color"].stops.forEach((v) => values.push(v[0]));
                            layer.paint["fill-color"].stops.forEach((c) => colors.push(c[1]));
                          } else if (layer.paint["fill-color"][0] === "case") {
                            //a stop looks like this in this case - [">=", ["get", "value"], 0], "#fbb03b", // yellow
                            layer.paint["fill-color"].slice(1, -1).forEach((v) => {
                              let value = v?.toString()?.split("#")[0]?.split(",")[3];
                              if (value) values.push(+(+value).toFixed(2));
                            });
                            layer.paint["fill-color"].slice(1, -1).forEach((v) => {
                              let c = v.toString().split("#")[1];
                              if (c) colors.push("#" + c);
                            });
                          }
                          if (l.type === "choropleth-list") {
                            return (
                              <Box>
                                {colors.map((c, key) => (
                                  <Flex gap={2} key={key.toString()}>
                                    <Box w="20px" h="20px" backgroundColor={c}>
                                      &nbsp;
                                    </Box>
                                    <Box>
                                      {values[key] || values[key] === 0
                                        ? Number(values[key]).toLocaleString()
                                        : ""}
                                      {l.valueSuffix ? l.valueSuffix : ""}
                                    </Box>
                                  </Flex>
                                ))}
                              </Box>
                            );
                          } else {
                            return (
                              <Box key={i.toString()}>
                                <Flex justifyContent={"space-between"} w="100%" pl={1} pr={1}>
                                  {values.map((v, key) => (
                                    <Box key={key.toString()}>{Number(v).toLocaleString()}</Box>
                                  ))}
                                </Flex>
                                <Box
                                  background={`linear-gradient(90deg, ${colors.join(",")})`}
                                  w="100%"
                                  h={"20px"}
                                ></Box>
                              </Box>
                            );
                          }
                        } else {
                          return "";
                        }
                      })
                    : // : (legend && typeof legend === "function") ?
                      //   <React.Fragment>
                      //     {legend()}
                      //   </React.Fragment>
                      ""}

                  {layer.childFilters
                    ? layer.childFilters.map((l, i) => {
                        return (
                          <Box p="0" key={i}>
                            <Checkbox
                              defaultChecked
                              size="md"
                              // isChecked={true}
                              id={l.id + "_lc_childFilter"}
                              name={l.name}
                              key={"_lc_checkbox_" + i}
                              onChange={layerControlToggle}
                              p={0}
                            >
                              <span style={{ display: "inline-flex", alignItems: "center" }}>
                                {l.icon ? (
                                  <img
                                    src={l.icon}
                                    alt="Icon"
                                    style={{ margin: "2px 4px 0 0" }}
                                  ></img>
                                ) : (
                                  ""
                                )}
                                {l.name}
                              </span>
                            </Checkbox>
                          </Box>
                        );
                      })
                    : ""}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default React.memo(LayerControl);
