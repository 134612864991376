import { create } from "zustand";
const PLACETYPES = [
  "WalkUPs",
  "Walkable Neighborhoods",
  'Driveable "Edge Cities"',
  "Driveable Communities",
];
export const useAppState = create((set, get) => ({
  metros: [],
  setMetros: (e) => set(() => ({ metros: e })),
  getMetros: () => {
    return get().metros;
  },
  activeMetro: "San Diego",
  setActiveMetro: (e) => set(() => ({ activeMetro: e })),

  activeHPAMetro: null,
  setActiveHPAMetro: (e) => set(() => ({ activeHPAMetro: e })),

  isLoading: false,
  setIsLoading: (e) => set(() => ({ isLoading: e })),

  placesTypes: [1, 2, 3, 4],
  setPlacesTypes: (e) => set(() => ({ placesTypes: e })),

  activeMapPlaceType: null,
  setActiveMapPlaceType: (type) => {
    set(() => ({ activeMapPlaceType: !type ? null : PLACETYPES[+type - 1] }));
  },

  activeLayer: null,
  setActiveLayer: (e) => set(() => ({ activeLayer: e })),

  hpaMatrixCurrent: [],
  setHpaMatrixCurrent: (e) => set(() => ({ hpaMatrixCurrent: e })),
}));
