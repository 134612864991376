// import config from "../../env.js";

export const listingsPopup = async (feature) => {
  
  let imgErrorFixed = false;
  //Handle Image errors by replacing the image with a satellite image using the dataset properties
  document.addEventListener('error', (e) => {
    console.log(e.target.nodeName)
    if (e.target.nodeName === "IMG" && e.target.dataset.lng && !imgErrorFixed) {
      console.log(e.target.dataset.lng);
      const dataset = e.target.dataset;
      console.log(dataset.geojson)
      e.target.src = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${dataset.geojson})/${dataset.lng},${dataset.lat},17.2/300x300?access_token=pk.eyJ1IjoicHBwZG9oZXJ0eSIsImEiOiJjbDJldmVkaXYwNG13M2NxejBsY29ybmJtIn0.u_gVqFCetSfF-a2wMD9eAw`;
      imgErrorFixed = true
    }
  }, true)

  if (feature.properties.cluster) return {
    cluster: true
  }

  const categories = [
    "Beauty Salon Or Barber",
    "Beer Or Wine Or Liquor Store",
    "Book Store",
    "Childcare",
    "Clothing Store",
    "Coffee Shop",
    "Convenience Store",
    "Doctor Or Dentist",
    "Elementary Or Secondary School",
    "Fitness",
    "Florists",
    "General Merchandise",
    "Hardware Store",
    "Hospitals And Outpatient Care",
    "Library",
    "Paint Or Wallpaper Store",
    "Parks And Museums",
    "Pet And Pet Supplies",
    "Pharmacy Or Drug Store",
    "Postal Service",
    "Religious Institution",
    "Resale Shops",
    "Restaurant Or Bar",
    "Specialty Food Store",
    "Super Market",
  ];

  const icons = [
    "chair_alt",
    "liquor",
    "menu_book",
    "child_care",
    "shopping_bag",
    "coffee",
    "store",
    "health_and_safety",
    "school",
    "fitness_center",
    "local_florist",
    "store",
    "handyman",
    "local_hospital",
    "local_library",
    "format_paint",
    "festival",
    "pets",
    "local_pharmacy",
    "local_post_office",
    "church",
    "shopping_bag",
    "restaurant",
    "shopping_basket",
    "local_grocery_store"
  ]

  const categoryIcons = [];
  categories.forEach((c,i) => categoryIcons[c.toLowerCase()] = icons[i]);
  categoryIcons["other"] = "category";

  const layer = (feature.layer.id === "listings" || feature.layer.id === "listings_cluster") ? "listings" : "properties";

  const fields = {
    pois: {
      listings: "walkable_pois",
      properties: "walkable_pois"
    },
    totalpois: {
      listings: "total_walkable_pois",
      properties: "total_walkable_pois"
    },
    price: {
      listings: "listprice",
      properties: "prop_valcalc"
    },
    beds: {
      listings: "total_beds",
      properties: "prop_bedrms"
    },
    baths: {
      listings: "total_baths",
      properties: "prop_baths"
    },
    sqfeet: {
      listings: "livingarea",
      properties: "prop_bldsqft"
    },
    days_on_market: {
      listings: "cumulativedaysonmarket"
    },
    year: {
      listings: "yearbuilt",
      properties: "prop_yrbld"
    }
  }

  let HTML = "";
  const p = feature.properties;
  const coords = feature.geometry.coordinates;
  const geojson = {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": coords
    }
  };

  const walkable_pois = (p.total_walkable_pois && p.walkable_pois && p.walkable_pois.includes(",")) ? p.walkable_pois.split(",") :
  (p.total_walkable_pois && p.walkable_pois) ? [p.walkable_pois] : null;

  try {
    imgErrorFixed = false
    const img = p.media_url ? p.media_url : `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/geojson(${encodeURI(JSON.stringify(geojson))})/${coords[0]},${coords[1]},17.2/300x300?access_token=pk.eyJ1IjoicHBwZG9oZXJ0eSIsImEiOiJjbDJldmVkaXYwNG13M2NxejBsY29ybmJtIn0.u_gVqFCetSfF-a2wMD9eAw`;
    const imgHTML = `<figure style="margin:0"><img src="${img}" alt='Listing Preview' data-lat="${coords[1]}" data-lng="${coords[0]}" data-geojson="${encodeURI(JSON.stringify(geojson))}"/></figure>`;
    
    HTML += imgHTML;

    let report = "";
    
    // const address = (layer === "listings" && p.address) ? p.address : ((p.house) ? p.house : "") + " " + p.street + " " + p.strtype;
    const address = (layer === "listings") ? p.streetaddress : "" 

    const addressHTML = `${address}<br>${p.city}, ${p.stateorprovince} ${p.postalcode}`;

    HTML += `
      <h4>$${p[fields.price[layer]].toLocaleString()}</h4>
      <div class="text-muted">${addressHTML}</div>
      <div class="divider"></div>
      <div class="d-inline-flex text-bold" data-grid style="justify-content:space-between;width:100%;">
        <div class="d-inline-flex"><span class="material-icons-outlined">hotel</span> ${p[fields.beds[layer]] ? p[fields.beds[layer]] + " beds" : ""}</div>
        <div class="d-inline-flex"><span class="material-icons-outlined">shower</span> ${p[fields.baths[layer]] ? p[fields.baths[layer]] + " baths": ""}</div>
        <div class="d-inline-flex"><span class="material-icons-outlined">home</span> ${p[fields.sqfeet[layer]] ? Number(p[fields.sqfeet[layer]]).toLocaleString() + " ft²" : ""}</div>
      </div>
      ${(p.total_walkable_pois > 0) ? `
      <div class="text-bold">
        <div class="d-inline-flex"><span class="material-icons-outlined">category</span> ${p.total_walkable_pois > 100 ? "100+": p.total_walkable_pois } Walkable Point${p.total_walkable_pois > 1 ? "s" : ""} of Interest</div>
      </div>
      `: ""}
      <div class="divider"></div>
      <div class="d-inline-flex" data-grid style="width:100%;justify-content:space-between;">
      ${p[fields.year[layer]] ? `<div>Built: ${p[fields.year[layer]]}</div>` : ""}
      ${(p[fields.price[layer]] && p[fields.sqfeet[layer]]) ? `<div>Cost per Sq Ft: $${Number((Number(p[fields.price[layer]])/Number(p[fields.sqfeet[layer]])).toFixed(0)).toLocaleString()}</div>` : ""}
      </div>
      <br>
      ${p.mls_url ? `<a href="${p.mls_url}" target="_blank">View MLS Listing</a>` : ""}
      <br>
      <em class="text-muted" style="font-size: smaller">${layer === "listings" ? `Listed ${p.cumulativedaysonmarket} days ago.` : ""}</em>
      <br>
      `
    /************************************** */
    /*REPORT*/
    /************************************** */
    report += `
      <div class="columns"><div class="column col-sm-12">
    `
    report += imgHTML;
    
    report += ``;

    const poisSet = p.walkable_pois ? new Set(walkable_pois) : null
    const pois = poisSet ? [...poisSet] : null

    report += `</div></div><h4>$${p[fields.price[layer]].toLocaleString()}</h4>
      <div class="text-muted">
        ${p.address}<br>
        ${p.city}, ${p.state} ${p.zip}
      </div>
      <div class="divider"></div>
      <div class="d-inline-flex text-bold" data-grid style="justify-content:space-between;width:100%;">
        <div class="d-inline-flex"><span class="material-icons-outlined">hotel</span> ${p[fields.beds[layer]]} beds</div>
        <div class="d-inline-flex"><span class="material-icons-outlined">shower</span> ${p[fields.baths[layer]]} baths</div>
        <div class="d-inline-flex"><span class="material-icons-outlined">home</span> ${Number(p[fields.sqfeet[layer]]).toLocaleString()} ft²</div>
      </div>
      ${(p.total_walkable_pois > 0) ? `
      <div class="text-bold">
        <div class="d-inline-flex"><span class="material-icons-outlined">category</span> ${p.total_walkable_pois > 100 ? "100+": p.total_walkable_pois } Walkable Point${p.total_walkable_pois > 1 ? "s" : ""} of Interest</div>
      </div>
      <div style="display: inline-block;" data-html2canvas-ignore="true">${pois ?
        pois.map(p => `<span class="material-icons">${categoryIcons[p]}</span>`).join(" ") :
         ""}</div>
      `: ""}
      <div class="divider"></div>
      <div class="d-inline-flex" data-grid style="width:100%;justify-content:space-between;">
        ${p[fields.year[layer]] ? `<div>Built: ${p[fields.year[layer]]}</div>` : ""}
        ${(p[fields.price[layer]] && p[fields.sqfeet[layer]]) ? `<div>Cost per Sq Ft: $${Number((Number(p[fields.price[layer]])/Number(p[fields.sqfeet[layer]])).toFixed(0)).toLocaleString()}</div>` : ""}
      </div>
      <br>
      <em class="text-muted" style="font-size: smaller">${layer === "listings" ? `Listed ${p.cumulativedaysonmarket} days ago.` : ""}</em>
      <br>
      `;

      if (layer === "listings") HTML += `<a href="#listingsReport" class="btn btn-sm btn-outline text-center" style="width: 100%">Preview Report</a>`
    return {
      html: HTML,
      report: report
    }
  } catch (err) {
    console.log(err)
    // HTML = ""
    // const ignoreFields = ["id", "ogc_fid", "objectid", "object_id", "fid", "data_id", "poi_types"];
    // const props = Object.keys(feature.properties);
    // props.sort();
    // for (const i in props) {
    //   const p = props[i]
    //   if (!ignoreFields.includes(p.toLocaleLowerCase())) {
    //     HTML += `<strong>${p.replace(/_/g, " ").toUpperCase()}</strong>: ${feature.properties[p]}<br>`;
    //   }
    // }
    HTML += "<strong>No address information available.</strong>"
    return {
      html: HTML,
    }
  }
}

export const placesPopup = (feature) => {
  // let totalListings = 0;
  // listings.features.forEach((listing) => {
  //   if (booleanWithin(listing, feature.geometry)) totalListings++;
  // });
  // feature.properties["total_Listings"] = totalListings;
  feature.properties["workers_within_30min_transit"] = feature.properties.stats_employment_access_index

  const ignoreFields = [
    "_bbox", "geom", "id", "ogc_fid", "objectid", "object_id", "fid", "data_id", "poi_types", "hh_pnt_white", "pptype3", "stats_employment_access_index", "_bbox"
  ];
  const props = Object.keys(feature.properties);
  props.sort();
  
  let popupContent = "";

  const moneyFields = [
    "hh_avg_net_worth", "hh_med_gross_rent", "hh_med_income"
  ]

  for (const i in props) {
    const p = props[i]
    // console.log(p)
    if (!ignoreFields.includes(p.toLocaleLowerCase())) {
      popupContent += `<div><strong>${p.replace(/_/g, " ").replace(/stats/g, "").replace(/esi/g, "econ stab index").replace(/lor/g, "length of residence").toUpperCase()}</strong>:
      ${moneyFields.includes(p) ? "$" + Number(feature.properties[p]).toLocaleString() :
        Number(feature.properties[p]) ? Number(feature.properties[p]).toLocaleString() :
        feature.properties[p]}
      ${p === "stats_average_esi" ? " (1 = most stable; 30 = least stable)" :
          p === "transit_connectivity_index" ? " (0=poor; 100=best)" :
          p === "transit_performance_score" ? " (1=poor; 10=best)" : 
          p === "hh_pnt_single_family" ? "%" : ""}
      </div>`;
    }
  }

  return {
    html: popupContent
  }
}