import { Checkbox, Flex, Icon, Select, Stack } from "@chakra-ui/react";
import { FaCarSide, FaWalking } from "react-icons/fa";

import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";
import { useEffect } from "react";

export const MetroSelector = () => {
  const { activeMetro, setActiveMetro, setActiveLayer } = useAppState();
  const { settings } = useAccountStore();

  useEffect(() => {
    if (settings?.msa_data.length && !activeMetro) {
      setActiveMetro(settings.msa_data[0].name);
    }
  }, [settings, activeMetro, setActiveMetro]);

  return (
    <Select
      value={activeMetro || ""}
      mb={4}
      onChange={(e) => {
        setActiveMetro(e.target.value);
        setActiveLayer("metros");
      }}
    >
      {settings?.msa_data
        ? settings.msa_data.map((m, i) => (
            <option key={i.toString()} value={m.name}>
              {m.name}
            </option>
          ))
        : null}
    </Select>
  );
};

export const PlaceTypeSelector = () => {
  const { placesTypes: activePlaceTypes, setPlacesTypes } = useAppState();

  const updatePlacesTypes = (e) => {
    if (activePlaceTypes.includes(+e.target.value)) {
      setPlacesTypes(
        activePlaceTypes.filter((item) => item !== +e.target.value)
      );
    } else {
      setPlacesTypes([...activePlaceTypes, +e.target.value]);
    }
  };

  return (
    <Stack px={2} fontSize={"sm"}>
      <Checkbox
        key="1"
        value="1"
        isChecked={activePlaceTypes.includes(1)}
        data-id="Walkable Urban"
        onChange={updatePlacesTypes}
        colorScheme="green"
        fontSize={"sm"}
      >
        <Flex align={"center"}>
          <Icon as={FaWalking} mr={2} color={"green.500"} /> WalkUPs
        </Flex>{" "}
      </Checkbox>
      <Checkbox
        key="2"
        value="2"
        isChecked={activePlaceTypes.includes(2)}
        data-id="Walkable Neighborhoods"
        onChange={updatePlacesTypes}
        colorScheme="blue"
      >
        <Flex align={"center"}>
          <Icon as={FaWalking} mr={2} color={"blue.500"} /> Neighborhoods
        </Flex>{" "}
      </Checkbox>
      <Checkbox
        key="3"
        value="3"
        isChecked={activePlaceTypes.includes(3)}
        data-id="Drivable Commercial"
        onChange={updatePlacesTypes}
        colorScheme="red"
      >
        <Flex align={"center"}>
          <Icon as={FaCarSide} mr={2} color={"red.400"} /> "Edge-Cities"
        </Flex>
      </Checkbox>
      <Checkbox
        key="4"
        value="4"
        isChecked={activePlaceTypes.includes(4)}
        data-id="Drivable Sub-Urban"
        onChange={updatePlacesTypes}
        colorScheme="gray"
      >
        <Flex align={"center"}>
          <Icon as={FaCarSide} mr={2} color={"gray.600"} /> Communities
        </Flex>
      </Checkbox>
    </Stack>
  );
};
