import "./spectre-modal.css";
import "./mapbox-gl-popup.css";

// import { bbox } from "@turf/turf"

const store = {
  features: [],
  currentFeature: 0,
};

// function replaceHtml(el, html) {
// 	const oldEl = typeof el === "string" ? document.getElementById(el) : el;
// 	let newEl = oldEl.cloneNode(false);
// 	newEl.innerHTML = html;
// 	oldEl.parentNode.replaceChild(newEl, oldEl);
// 	return newEl;
// };

const ModalPopupTemplate = (id, content) => {
  if (!id || !content) return;
  if (document.getElementById(id)) return;

  const modal = document.createElement("div");
  modal.id = id;
  modal.classList = "modal modal-popup";
  modal.innerHTML = modalBody(content);
  if (document.querySelector(".mapboxgl-map")) {
    document.querySelector(".mapboxgl-map").appendChild(modal);
  } else {
    document.body.appendChild(modal);
  }

  function modalBody(content) {
    return `
    <!--a href="#close" class="modal-overlay" aria-label="Close"></a-->
    <div class="modal-container right">
      <div class="modal-header">
        <a class="modal-close" onclick={this.closest('.modal').classList.remove('active')} style='float:right;cursor:pointer;margin-top:10px' aria-label="Close"><img style="width:24px" class="modal-close" src='/x-circle.svg'></a>
        <div id="modal-popup-title" class="modal-title h5">${content.title}</div>
      </div>
      <div class="modal-body">
        <div class="content">
          ${content.body}
        </div>
      </div>
      <span class="divider"></span>
      <div class="modal-footer text-small" style="justify-content: space-between;align-items:center">${
        content.footer ? ` ${content.footer}` : ""
      }</div>
    </div>
  `;
  }

  // function update(html) {
  //   modal.querySelector(".content").innerHTML = html
  // }
};

/**
 * Clear any layers with the word highlightLayer in the id and reset the window has to the default mapbox hash
 * @param {Object} map
 */
export function clearHighlight(map, layer) {
  //GET HIGHLIGHT LAYERS
  if (!map) return;
  const style = map.getStyle();
  if (!style || !style.layers) return;
  const _layers = style.layers;
  const highlightLayers = _layers.filter((l) => l.id.includes("highlightLayer"));

  if (!highlightLayers.length) return;

  if (!layer) {
    highlightLayers.forEach((h) => {
      if (map.getLayer(h.id)) {
        map.setLayoutProperty(h.id, "visibility", "none");
        map.removeLayer(h.id);
      }
    });
  } else {
    highlightLayers.forEach((h) => {
      if (map.getLayer(h.id) && h.id === "highlightLayer_" + layer) {
        map.setLayoutProperty(h.id, "visibility", "none");
        map.removeLayer(h.id);
      }
    });
  }

  // if (window.location.hash !== "#popup") {
  //   //RESET HASH
  //   const zoom = map.getZoom();
  //   const center = map.getCenter();
  //   setTimeout(() => {
  //     window.location.hash =
  //       "#" + zoom + "/" + center.lat.toFixed(4) + "/" + center.lng.toFixed(4);
  //   }, 200);
  // }
}

export function mglPopup(map, layers, callback) {
  const _map = map;
  const params = new URLSearchParams(window.location.search);
  if (params.get("debug")) {
    localStorage.setItem("debug:maps", true);
  } else {
    localStorage.removeItem("debug:maps");
  }
  if (localStorage.getItem("debug:maps")) console.log({ layers });

  ModalPopupTemplate("popup", {
    title: "Feature Properties",
    body: "",
    footer: null,
  });

  //ADD SCOPED VARIABLE FOR MODAL
  const popupModal = document.querySelector("#popup");

  //CLEAR HIGHLIGHT WHEN CLOSING POPUP MODAL
  popupModal.addEventListener("click", (e) => {
    if (e.target.classList.contains("modal-close")) {
      clearHighlight(_map);
      if (callback) callback();
    }
  });

  // const reportModal = new Modal({
  //   id: "listingsReport",
  //   size: "modal-lg",
  //   pdf: true,
  // });
  // reportModal.create();

  const popupLayers = layers.filter((layer) => {
    return layer.metadata.popup || layer.popup;
  });

  const layerIds = popupLayers.reduce((i, layer) => {
    return [...i, layer.id];
  }, []);

  // document.body.addEventListener("click", (e) => {
  //   if (!e.target.classList.contains("modal-overlay")) return;
  //   clearHighlight(map);
  // });

  map.on("contextmenu", () => {
    clearHighlight(map);
    const modals = document.querySelectorAll(".modal-popup");
    modals.forEach((m) => {
      m.classList.remove("active");
    });
  });

  const genPopup = async (feature) => {
    if (localStorage.getItem("debug:maps")) {
      console.log(feature);
      console.log(`${feature.layer["id"]}: ${feature.properties["yr"]}`);
    }

    clearHighlight(map);

    //Get corresponding layer from the layer config to get access to the popup template
    const foundLayers = layers.filter((l) => {
      return l.id === feature.layer.id;
    });

    if (!foundLayers.length) return;

    //FEATURE: ZOOM TO FUNCTION - NEED ACCESS TO A BOUNDING BOX OR THE RAW GEOMETRY
    // if (feature.layer.metadata && feature.layer.metadata.zoomto) {
    //   const bounds = feature.properties._bbox ? JSON.parse(feature.properties._bbox) : null;
    //   if (bounds) {
    //     // map.fitBounds([ [bounds[0],bounds[1]], [bounds[2],bounds[3]] ], {
    //     //   maxZoom: map.getZoom(),
    //     //   padding: {top: 50, bottom: 50, left: 150, right: 150}
    //     // });
    //     map.flyTo({
    //       center: [(bounds[0] + bounds[2])/2,(bounds[1] + bounds[3])/2],
    //       zoom: map.getZoom()
    //     })
    //   }
    // }

    const layer = foundLayers[0];

    if (feature.layer.metadata.highlight) highlight(feature);

    popupModal.querySelector(".modal-footer").innerHTML = "";
    popupModal.classList.add("active");

    //TODO add modal.updateTitle()
    const popupTitle = document.querySelector("#modal-popup-title");
    popupTitle.innerText = layer.metadata.name;

    let popupContent = "";

    //BUG hardcoded listingspopup
    if (typeof layer.popup === "function") {
      const content = await layer.popup(feature);
      popupContent = content.html;
    } else if (layer.metadata.popupFields) {
      const props = feature.properties;

      layer.metadata.popupFields.forEach((p) => {
        popupContent += `${
          props[p.field]
            ? `
        <div>
          <strong>${
            p.type === "link"
              ? `<a href="${feature.properties[p.field]}" target="_blank">${p.name}</a>`
              : p.name + ": "
          } </strong>${
                p.type === "number"
                  ? Number(feature.properties[p.field]).toLocaleString()
                  : p.type === "link"
                  ? ""
                  : p.type === "currency"
                  ? "$" + Number(feature.properties[p.field]).toLocaleString()
                  : feature.properties[p.field]
              }
        </div>
        `
            : ""
        }
        `;
      });
    } else {
      const ignoreGlobal = [
        "_bbox",
        "geom",
        "id",
        "ogc_fid",
        "objectid",
        "object_id",
        "fid",
        "data_id",
        "poi_types",
        "firstscore",
        "geo_firstscore",
        "walkable_pois",
      ];
      const ignoreFields = layer.metadata.ignoreFields
        ? [...layer.metadata.ignoreFields, ...ignoreGlobal]
        : ignoreGlobal;
      const props = Object.keys(feature.properties);
      props.sort();
      for (const i in props) {
        const p = props[i];
        // let x = 0;
        if (!ignoreFields.includes(p.toLocaleLowerCase())) {
          // x = x+1;
          popupContent += `<div
            ><strong>${p.replace(/_/g, " ").toUpperCase()}:</strong> ${feature.properties[p]}${
            p === "stats_average_esi" ? " (1 = most stable; 30 = least stable)" : ""
          }</div>`;
        }
      }
    }

    //            style="display:flex;flex-direction:column;justify-content:space-between;${(x % 2 === 0) ? "background:whitesmoke": ""}"

    highlight(feature);

    return popupContent;
  };

  function highlight(feature) {
    const highlightId = "highlightLayer-" + feature.layer.id;
    // if (localStorage.getItem("debug:maps")) console.log(highlightId)
    if (!map.getLayer(highlightId)) {
      map.addLayer({
        id: highlightId,
        source: feature.source,
        "source-layer": feature.sourceLayer ? feature.sourceLayer : "",
        type: ["line", "fill"].includes(feature.layer.type) ? "line" : "circle",
        paint: ["line", "fill"].includes(feature.layer.type)
          ? {
              "line-color": "black",
              "line-width": 6,
            }
          : {
              "circle-color": "yellow",
              "circle-radius":
                feature.layer.paint && feature.layer.paint["circle-radius"]
                  ? feature.layer.paint["circle-radius"]
                  : 6,
              "circle-stroke-width": 4,
              "circle-stroke-color": "black",
              "circle-opacity": 1,
            },
        filter: [
          "==",
          //NOTE idField stored in metadata so we have access to it in other parts of the app like this function
          ["get", feature.layer.metadata.idField],
          feature.properties[feature.layer.metadata.idField],
        ],
        layout: {
          visibility: "none",
        },
      });
    } else {
      map.setFilter(highlightId, [
        "==",
        ["get", feature.layer.metadata.idField],
        feature.properties[feature.layer.metadata.idField],
      ]);
    }

    map.setLayoutProperty("highlightLayer-" + feature.layer.id, "visibility", "visible");
  }

  const genPager = () => {
    let pagerHTML = "";
    const n = store.currentFeature;
    if (store.features.length === 1) {
      popupModal.querySelector(".modal-footer").style.display = "none";
      return pagerHTML;
    } else {
      popupModal.querySelector(".modal-footer").style.display = "flex";
    }
    pagerHTML = `Feature ${!n ? 1 : n + 1} of ${store.features.length}
      <button class='btn btn-sm btn-outline prev' ${!n ? "disabled" : ""}>PREV</button>
      <button class='btn btn-sm btn-outline next' ${
        n === store.features.length - 1 ? "disabled" : ""
      }>NEXT</button>`;
    return pagerHTML;
  };

  //Add event listener for popup modal pager
  popupModal.querySelector(".modal-footer").addEventListener("click", async (e) => {
    if (e.target.classList.contains("btn") && store.features.length > 1) {
      if (e.target.classList.contains("next")) {
        store.currentFeature++;
      } else {
        store.currentFeature--;
      }
      // if (localStorage.getItem("debug:maps")) console.log(store)
      const feature = store.features[store.currentFeature];
      sessionStorage.setItem("ppl-currentFeature", JSON.stringify(feature));
      // const now = Date.now()
      popupModal.querySelector(".modal-body").innerHTML = await genPopup(feature);
      // popupModal.querySelector(".modal-body").innerHTML = replaceHtml(popupModal.querySelector(".modal-body"), genPopup(feature))
      // console.log(Date.now() - now)
      popupModal.querySelector(".modal-footer").innerHTML = genPager();
    }
  });

  map.on("click", async (e) => {
    // if (window.location.hash != "#popup") window.location.hash = "#popup";
    store.features = [];
    store.currentFeature = 0;
    clearHighlight(map);

    const isValid = e.lngLat ? true : e.point ? true : false;
    if (!isValid) return;

    const point = e.point ? e.point : [e.lng, e.lat];
    const features = map.queryRenderedFeatures(point, { layers: layerIds });

    if (features.length) {
      const feature = features[0];

      if (feature.properties.cluster) {
        map.flyTo({
          zoom: map.getZoom() + 1,
          center: e.lngLat,
        });
        return;
      }

      sessionStorage.setItem("ppl-currentFeature", JSON.stringify(feature));

      const filteredFeatures = [];
      const propsArray = [];
      features.forEach((f) => {
        if (!propsArray.includes(JSON.stringify(f.properties))) {
          filteredFeatures.push(f);
          propsArray.push(JSON.stringify(f.properties));
        }
      });
      store.features = filteredFeatures.filter((f) => !f.layer.metadata.hidePopup);
      if (feature.layer.metadata.highlight) highlight(feature);
      if (feature.layer.metadata.hidePopup) return;

      popupModal.querySelector(".modal-body").innerHTML = await genPopup(feature);
      popupModal.querySelector(".modal-footer").innerHTML = genPager();
    } else {
      store.features = [];
      return;
    }
  });
}
