import "../../../node_modules/react-vis/dist/style.css";

import { Badge, Box, Center, Flex, Text } from "@chakra-ui/react";
import {
  VerticalBarSeries as BarSeries,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import { useEffect, useState } from "react";

import { placeTypes } from "../../assets/theme";

// import { placeTypeColors } from "../../assets/theme";

export const BarChartCategory = ({
  data,
  rawStockData,
  appreciationData,
  title,
  legend,
  maxW,
  height,
  stock,
}) => {
  const [tooltip, setTooltip] = useState("");
  const [chartData, setChartData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const stockAppreciationAliases = {
    retail: "retail_cbgs_rent_appreciation_2018_2022",
    office: "office_cbgs_rent_appreciation_2018_2022",
    multifam: "mf_cbgs_rent_appreciation_2018_2022",
    forsale: "forsale_price_premium_appreciation_2018_2021",
  };

  // const [conversionFactor, setConversionFactor] = useState(1);

  // const getConversionFactor = (data1, data2) => {
  //   const max1 = Math.max(...data1.map((item) => item.y));
  //   const max2 = Math.max(...data2.map((item) => item.y));
  //   return max1 / max2;
  // };
  // console.log({ data });
  // console.log({ rawStockData });

  useEffect(() => {
    if (!data || !rawStockData) return;
    const _stockData = [];
    if (!stockData.length) {
      rawStockData.forEach((s) => {
        _stockData.push({
          x: placeTypes.find((p) => p.type === +s.groupby_id)?.label,
          y: +s["pnt_" + stock].toFixed(2),
          value: +s["pnt_" + stock].toFixed(2),
          color: "#f1f1f1",
          // color: placeTypes.find((c) => c.type === +s.groupby_id)?.colorMuted,
          type: +s.groupby_id,
        });
      });
      // const factor = getConversionFactor(data, _stockData);
      // _stockData.forEach((d) => {
      //   d.y = d.y * factor;
      // });
      // setConversionFactor(factor);
      setStockData(_stockData);
    }

    setChartData(
      data.map((d, i) => {
        d["type"] = i + 1;
        return d;
      })
    );

    //eslint-disable-next-line
  }, [data, rawStockData, stock]);

  const chartHandler = (d, e) => {
    document.body.style.cursor = "crosshair";
    const updatedData = chartData.map((bar) => {
      if (bar.x === d.x) {
        return { ...bar, color: "goldenrod" };
      } else {
        return { ...bar, color: placeTypes.find((c) => c.type === bar.type)?.colorMuted };
      }
    });
    setChartData(updatedData);

    const stockInfo = stockData.find((s) => s.x === d.x);

    let appreciationValue = null;
    console.log(appreciationData, d.type);
    if (appreciationData[0][stockAppreciationAliases[stock]]) {
      const appreciation = appreciationData.find((r) => r.type === d.type);
      appreciationValue = appreciation[stockAppreciationAliases[stock]];
      console.log({ appreciationValue });
    }

    setTooltip(
      `${d.x}: ${d.y}% | As-Built: ${stockInfo?.value}% ${
        appreciationValue ? `<br/> Appreciation: ${appreciationValue}%` : ""
      }`
    );
  };

  const chartLeaveHandler = () => {
    const updatedData = chartData.map((bar) => {
      return { ...bar, color: placeTypes.find((c) => c.type === bar.type)?.colorMuted };
    });
    setChartData(updatedData);
    document.body.style.cursor = "default";
    setTooltip("");
  };

  return (
    <Flex flexDirection="column" mb={10} mx="auto" position={"relative"}>
      {title ? (
        <Center mb={2}>
          <Text> {title}</Text>
        </Center>
      ) : null}

      <Badge
        position={"absolute"}
        top={"-20px"}
        left={"50px"}
        zIndex={10000}
        fontSize={"lg"}
        data-name="badge"
      >
        {/* allow html here */}
        <span dangerouslySetInnerHTML={{ __html: tooltip || "" }} />
        {/* {tooltip || ""} */}
      </Badge>
      {chartData ? (
        <Box flex={1}>
          <FlexibleWidthXYPlot
            xType="ordinal"
            stackBy="y"
            height={height || 500}
            yPadding={0}
            margin={{ left: 50, right: 50, top: 0, bottom: 50 }}
            colorType="literal"
            onMouseLeave={chartLeaveHandler}
          >
            <DiscreteColorLegend
              style={{
                position: "absolute",
                left: "20px",
                bottom: "-70px",
                fontSize: "8px",
                width: "500px",
                maxWidth: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
              }}
              orientation="horizontal"
              items={legend}
            />
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis hideTicks={true} />
            <YAxis orientation="left" tickFormat={(v) => `${v}%`} />
            <YAxis
              orientation="right"
              tickFormat={(v) => {
                // const tick = (v / conversionFactor).toFixed(0);
                return v + "%";
              }}
            />
            {/* {premiumsData.map((bar, i) => {
              return (
                <BarSeries
                  key={i.toString()}
                  data={[bar]}
                  color={bar.color}
                  // onNearestX={chartHandler}
                  // barWidth={bar.width}
                />
              );
            })} */}
            <BarSeries
              data={chartData}
              cluster="premiums"
              onNearestX={chartHandler}
              barWidth={0.75}
            />
            <BarSeries
              data={stockData}
              color="gray"
              cluster="stock"
              barWidth={0.75}
              yDomain={[
                Math.min(...chartData.map((item) => item.y)),
                Math.max(...stockData.map((item) => item.y)),
              ]} // Get the max value of the second data
            />
          </FlexibleWidthXYPlot>
        </Box>
      ) : null}
    </Flex>
  );
};
