import { apiGateway } from "../config";
import { create } from "zustand";
import { getListingStats } from "../helpers/getListingsStatsFetcher";
import { median } from "simple-statistics";
import { sortJSON } from "../helpers/helpers";

const useTimeSeriesStore = create((set) => ({
  data: {},
  setData: async (place, mode, dateRange, apiToken, area, placesType) => {
    set({
      data: await updateData(
        place,
        mode,
        dateRange,
        apiToken,
        area,
        placesType
      ),
    });
  },
  clearData: () => set({ data: {} }),

  dataByPlaceType: {},
  setDataByPlaceType: async (mode, dateRange, apiToken, area, dateType) => {
    const dataByPlaceType = await getDataByPlaceType(
      mode,
      dateRange,
      apiToken,
      area,
      dateType
    );
    set({ dataByPlaceType: dataByPlaceType });
    return dataByPlaceType;
  },
  clearDataByPlaceType: () => set({ dataByPlaceType: {} }),

  commercialData: sessionStorage.getItem("commercialData")
    ? JSON.parse(sessionStorage.getItem("commercialData"))
    : {},
  setCommercialData: async (areaName, token) =>
    set({ commercialData: await getCommercialData(areaName, token) }),

  metroAVMData: sessionStorage.getItem("metroAVMData")
    ? JSON.parse(sessionStorage.getItem("metroAVMData"))
    : [],
  setMetroAVMData: async (token) =>
    set({ metroAVMData: await getMetroAVMData(token) }),

  allCommercialData: {},
  setAllCommercialData: () =>
    set({
      allCommercialData: JSON.parse(sessionStorage.getItem("commercialData")),
    }),

  avgCommercialData: {},
  setAvgCommercialData: () =>
    set({ avgCommercialData: getAvgCommercialData() }),

  unifiedData: [],
  setUnifiedData: (data) => set({ unifiedData: data }),

  activeView: "residential",
  setActiveView: (view) => set({ activeView: view }),
}));

export { useTimeSeriesStore };

async function getDataByPlaceType(mode, dateRange, apiToken, area, dateType) {
  // console.log(mode, dateRange, area)
  const placeTypeData = {};
  const placesTypes = [1, 2, 3, 4];

  for (let i = 0; i < placesTypes.length; i++) {
    const { areaData, areaDataByQuarter } = await updateData(
      null,
      mode,
      dateRange,
      apiToken,
      area,
      placesTypes[i]
    );

    placeTypeData["type_" + placesTypes[i]] =
      dateType && dateType === "quarterly" ? areaDataByQuarter : areaData;
  }

  return placeTypeData;
}

function getAvgCommercialData() {
  if (!sessionStorage.getItem("commercialData")) return;
  const data = JSON.parse(sessionStorage.getItem("commercialData"));

  const averages = {};
  const keys = Object.keys(data[0]).filter(
    (k) => k.substring(0, 6) === "median"
  );

  keys.forEach((key) => {
    averages[key] = [];
  });

  keys.forEach((key) => {
    data.forEach((area) => {
      const valuesObject = JSON.parse(area[key]);
      Object.keys(valuesObject).forEach((v) => {
        if (!averages[key].filter((e) => e.x === v).length) {
          averages[key].push({
            x: v,
            y: [valuesObject[v]],
          });
        } else {
          averages[key].forEach((e) => {
            if (e.x === v) {
              e.y.push(valuesObject[v]);
            }
          });
        }
      });
    });
  });

  Object.keys(averages).forEach((key) => {
    averages[key].forEach((obj) => {
      obj.y = median(obj.y);
    });
  });
  // console.log(averages)
  return averages;
}

async function getMetroAVMData(apiToken) {
  //DONE 12/22/2022 check to see if we can delete this and use mview_stats_msa
  // const { features:features2 } = await fetch(
  //   `${apiGateway}/collections/public.view_msa_avm_stats/items/?access_token=${apiToken}&limit=5000`
  // ).then((res) => res.json());
  // console.log(features2)
  // const areas2 = features2.reduce((i,v) => [...i, v.properties.msa], []);

  const { features } = await fetch(
    `${apiGateway}/collections/public.mview_stats_msa/items?&access_token=${apiToken}&limit=5000`
  ).then((res) => res.json());
  // const avmData = features;
  // const areas = avmData.reduce((i,v) => [...i, v.properties.area_name], []);
  // console.log(areas.sort())
  // if (features.length) {
  //   sessionStorage.setItem("metroAVMData", JSON.stringify(features));
  // }
  return features.length ? features : [];
}

async function getCommercialData(areaName, apiToken) {
  if (!areaName || !apiToken) return {};
  let data;
  if (sessionStorage.getItem("commercialData")) {
    data = JSON.parse(sessionStorage.getItem("commercialData"));
  } else {
    data = await fetch(
      `${apiGateway}/functions/postgisftw.get_msa_stats/items.json?i_min_date_value=2015&i_max_date_value=2022&limit=100000&access_token=${apiToken}`,
      {
        // cache: "no-cache"
      }
    ).then((res) => res.json());

    data.forEach((obj) => {
      const officeVacant = JSON.parse(obj["median_office_cbgs_occupancy"]);
      Object.keys(officeVacant).forEach((key) => {
        officeVacant[key] =
          officeVacant[key] === 0
            ? 0
            : Number((100 - officeVacant[key]).toFixed(2));
      });
      obj["median_office_cbgs_vacancy"] = JSON.stringify(officeVacant);
    });
    sessionStorage.setItem("commercialData", JSON.stringify(data));
  }
  // console.log(data[0])
  const filtered = data.filter((d) => {
    return d.msa_name === areaName;
  });
  // console.log(filtered)
  return filtered.length ? filtered[0] : {};

  // const apts = sd_sample.features.filter(f => f.properties.HistoricalRent.length > 8 && f.properties.Sector === "Apt");
  // const office = sd_sample.features.filter(f => f.properties.HistoricalRent.length > 8 && f.properties.Sector === "Off");
  // const retail = sd_sample.features.filter(f => f.properties.HistoricalRent.length > 8 && f.properties.Sector === "Ret");

  // console.log({apts}, {office}, {retail})

  /* APT RENTS */
  // const aptsRentsArray = apts.reduce((i,f) => [...i, f.properties.HistoricalRent], []);
  // aptsRentsArray.forEach((f,i) => {
  //   f["sqft"] = apts[i].properties["UnitMix.0.Sqft"]
  // })
  // const rentYears = [];
  // const avgAptRentSqFt = []
  // aptsRentsArray.forEach(i => {
  //   i.forEach(f => {
  //     if (!rentYears.includes(f.Year)) rentYears.push(f.Year)
  //   })
  // });
  // rentYears.sort()
  // rentYears.forEach(year => {
  //   const values = [];
  //   aptsRentsArray.forEach(f => {
  //     f.forEach(v => {
  //       if (v.Year === year) {
  //         values.push(v.Value / f.sqft)
  //       }
  //     })
  //   });
  //   avgAptRentSqFt.push({
  //     x: year.toString(),
  //     y: Number((values.reduce((i,v) => i +v) / values.length).toFixed(2))
  //   })
  // });

  // function getAvgRent(data) {
  //   const array = data.reduce((i,f) => [...i, f.properties.HistoricalRent], []);
  //   const years = [];
  //   const avgRent = []
  //   array.forEach(i => {
  //     i.forEach(f => {
  //       if (!years.includes(f.Year)) years.push(f.Year)
  //     })
  //   });
  //   years.sort()
  //   years.forEach(year => {
  //     const values = [];
  //     array.forEach(f => {
  //       f.forEach(v => {
  //         if (v.Year === year) {
  //           values.push(v.Value)
  //         }
  //       })
  //     });
  //     avgRent.push({
  //       x: year.toString(),
  //       y: Number((values.reduce((i,v) => i +v) / values.length).toFixed(2))
  //     })
  //   });
  //   return avgRent
  // }

  // function getAvgVacancy(data) {
  //   const array = data.reduce((i,f) => [...i, f.properties.HistoricalVacancy], [])
  //   const years = [];
  //   const avgVacancy = [];
  //   array.forEach(i => {
  //     i.forEach(f => {
  //       if (!years.includes(f.Year)) years.push(f.Year)
  //     })
  //   });
  //   years.sort()
  //   years.forEach(year => {
  //     const values = [];
  //     array.forEach(i => {
  //       i.forEach(v => {
  //         if (v.Year === year) values.push(v.Value)
  //       })
  //     });
  //     avgVacancy.push({
  //       x: year.toString(),
  //       y: Number((values.reduce((i,v) => i +v) / values.length).toFixed(2))
  //     })
  //   });
  //   return avgVacancy
  // }

  // return {
  //   avgAptVacancy: getAvgVacancy(apts),
  //   avgAptRentSqFt: avgAptRentSqFt,
  //   avgOffVacancy: getAvgVacancy(office),
  //   avgOffRentSqFt: getAvgRent(office),
  //   avgRetVacancy: getAvgVacancy(retail),
  //   avgRetRentSqFt: getAvgRent(retail),
  // }
}

async function updateData(place, mode, dateRange, apiToken, area, placesType) {
  // console.log(place)
  // const decades = Array.from(Array(14).keys()).reduce((i, v) => [...i, 1890 + v * 10], []);
  // const yearsSeries = [];
  // decades.forEach((d) => {
  //   yearsSeries.push({
  //     x: d,
  //     y: 0,
  //   });
  // });

  // const today = new Date(Date.now());
  // const currentMonth = today.getMonth();
  // const currentYear = today.getFullYear();
  // const pastYearInMonths = new Array(12);

  // for (let i = 1; i < 13; i++) {
  //   let mo = currentMonth + i < 13 ? currentMonth + i : (12 - currentMonth - i) * -1;
  //   mo = mo.toString().padStart(2, "0");
  //   let yr = currentMonth + i < 13 ? (currentYear - 1).toString() : currentYear.toString();
  //   pastYearInMonths[i - 1] = yr + mo;
  // }

  /*
    (async () => {
      const avgStats = await getListingStats({
        gateway: apiGateway,
        area: area.name,
        dateRange: dateRange,
        token: apiToken,
        debug: debug,
        areaType: area.areaType
      });
      if (debug) console.log({avgStats})
      setPlacesStats(avgStats)
    })()
  */

  const placeRawData =
    place && place.properties && place.properties.geoid
      ? await fetch(
          `${apiGateway}/functions/postgisftw.get_listing_stats_ii/items.json?i_date_type=monthly&i_place_type=place&i_min_date_value=${dateRange.range[0]}&i_max_date_value=${dateRange.range[1]}&i_places=${place.properties.geoid}&limit=200&access_token=${apiToken}`
        ).then((res) => res.json())
      : [];

  // console.log({ placeRawData });
  // console.log({ placesType });
  const areasArray = [null, "San Diego", "San Francisco"];
  const areaRawData = await getListingStats({
    gateway: apiGateway,
    area: area.name,
    dateRange: dateRange,
    token: apiToken,
    debug: false,
    areaType: "Area",
    placesType: placesType,
  });

  // console.log({ areaRawData });

  const areaRawDataByQuarter = await fetch(
    `${apiGateway}/functions/postgisftw.get_listing_stats_iii/items.json?i_places_area_id=${areasArray.indexOf(
      area.name
    )}&i_date_type=quarterly&i_place_type=place_type&i_min_date_value=202101&i_max_date_value=202302&access_token=${apiToken}&i_places=${placesType}`
  ).then((res) => res.json());

  // console.log({ areaRawDataByQuarter });

  const placeRawDataSorted = sortJSON(placeRawData, "date_value");
  const areaRawDataSorted = sortJSON(areaRawData, "date_value");
  const areaRawDataByQuarterSorted = sortJSON(
    areaRawDataByQuarter,
    "date_value"
  );

  // console.log(placeRawDataSorted)

  function createTimeseriesChartData(data) {
    const avgDaysbyPlacebyMonth = [];
    const medSqFtPrice = [];
    const medListPricebyPlacebyMonth = [];
    const listToSold = [];
    const nSold = [];
    const avgSold = [];

    const timeSeriesArray = [];

    // if (!data || data.length === 0) return timeSeriesArray;

    data.forEach((value) => {
      const v = value;
      const statDate = new Date(
        v.date_value.toString().substr(4, 2) +
          "/01/" +
          v.date_value.toString().substr(0, 4)
      );
      if (mode) {
        avgDaysbyPlacebyMonth.push({
          x: statDate,
          y:
            v.avg_onmarket && v.prev_avg_onmarket && v.prev_avg_onmarket > 0
              ? (v.avg_onmarket / v.prev_avg_onmarket) * 100
              : 0,
          label: "avg_onmarket",
        });
        medSqFtPrice.push({
          x: statDate,
          y:
            v.median_square_foot_price &&
            v.prev_median_square_foot_price &&
            v.prev_median_square_foot_price > 0
              ? (v.median_square_foot_price / v.prev_median_square_foot_price) *
                100
              : 0,
          label: "median_square_foot_price",
        });
        medListPricebyPlacebyMonth.push({
          x: statDate,
          y:
            v.median_sold_price &&
            v.prev_median_sold_price &&
            v.prev_median_sold_price > 0
              ? (v.median_sold_price / v.prev_median_sold_price) * 100
              : 0,
          label: "median_sold_price",
        });
        listToSold.push({
          x: statDate,
          y:
            v.median_list_over_sold &&
            v.prev_median_list_over_sold &&
            v.prev_median_list_over_sold > 0
              ? (v.median_list_over_sold / v.prev_median_list_over_sold) * 100
              : 0,
          label: "median_list_over_sold",
        });
        nSold.push({
          x: statDate,
          y:
            v.properties_sold > 0 && v.prev_properties_sold > 0
              ? (v.properties_sold / v.prev_properties_sold) * 100
              : 0,
          label: "properties_sold",
        });
        avgSold.push({
          x: statDate,
          y:
            v.avg_places_properties_sold > 0 && v.avg_places_properties_sold > 0
              ? (v.avg_places_properties_sold /
                  v.prev_avg_places_properties_sold) *
                100
              : 0,
          label: "avg_places_properties_sold",
        });
      } else {
        avgDaysbyPlacebyMonth.push({
          x: statDate,
          y: v.avg_onmarket ? v.avg_onmarket : 0,
          type: v.place_type,
          label: "avg_onmarket",
        });
        medSqFtPrice.push({
          x: statDate,
          y: v.median_square_foot_price ? v.median_square_foot_price : 0,
          label: "median_square_foot_price",
        });
        medListPricebyPlacebyMonth.push({
          x: statDate,
          y: v.median_sold_price ? v.median_sold_price : 0,
          label: "median_sold_price",
        });
        listToSold.push({
          x: statDate,
          y: v.median_list_over_sold ? v.median_list_over_sold : 0,
          label: "median_list_over_sold",
        });
        nSold.push({
          x: statDate,
          y: v.properties_sold > 0 ? v.properties_sold : 0,
          label: "properties_sold",
        });
        avgSold.push({
          x: statDate,
          y:
            v.avg_places_properties_sold > 0 ? v.avg_places_properties_sold : 0,
          label: "avg_places_properties_sold",
        });
      }
    });

    timeSeriesArray[1] = data;
    timeSeriesArray[2] = avgDaysbyPlacebyMonth;
    timeSeriesArray[3] = medSqFtPrice;
    timeSeriesArray[4] = medListPricebyPlacebyMonth;
    timeSeriesArray[5] = listToSold;
    timeSeriesArray[6] = nSold;
    timeSeriesArray[7] = avgSold;
    timeSeriesArray[0] = mode ? 1 : 0;

    return timeSeriesArray;
  }

  function createTimeseriesChartDataByQuarter(data) {
    const avgDaysbyPlacebyMonth = [];
    const medSqFtPrice = [];
    const medListPricebyPlacebyMonth = [];
    const listToSold = [];
    const nSold = [];
    const avgSold = [];

    const timeSeriesArray = [];

    data.forEach((value) => {
      const v = value;
      const statDate = v.date_value;

      avgDaysbyPlacebyMonth.push({
        x: statDate,
        y: v.avg_onmarket ? v.avg_onmarket : 0,
        type: +v.places_id,
        label: "avg_onmarket",
      });
      medSqFtPrice.push({
        x: statDate,
        y: v.median_square_foot_price ? v.median_square_foot_price : 0,
        type: +v.places_id,

        label: "median_square_foot_price",
      });
      medListPricebyPlacebyMonth.push({
        x: statDate,
        y: v.median_sold_price ? v.median_sold_price : 0,
        type: +v.places_id,

        label: "median_sold_price",
      });
      listToSold.push({
        x: statDate,
        y: v.median_list_over_sold ? v.median_list_over_sold : 0,
        type: +v.places_id,

        label: "median_list_over_sold",
      });
      nSold.push({
        x: statDate,
        y: v.properties_sold > 0 ? v.properties_sold : 0,
        type: +v.places_id,

        label: "properties_sold",
      });
      avgSold.push({
        x: statDate,
        y: v.avg_places_properties_sold > 0 ? v.avg_places_properties_sold : 0,
        type: +v.places_id,

        label: "avg_places_properties_sold",
      });
    });

    timeSeriesArray[1] = data;
    timeSeriesArray[2] = avgDaysbyPlacebyMonth;
    timeSeriesArray[3] = medSqFtPrice;
    timeSeriesArray[4] = medListPricebyPlacebyMonth;
    timeSeriesArray[5] = listToSold;
    timeSeriesArray[6] = nSold;
    timeSeriesArray[7] = avgSold;
    timeSeriesArray[0] = mode ? 1 : 0;

    return timeSeriesArray;
  }

  return {
    placeData: createTimeseriesChartData(placeRawDataSorted),
    areaData: createTimeseriesChartData(areaRawDataSorted),
    areaDataByQuarter: !areaRawDataByQuarter.length
      ? null
      : createTimeseriesChartDataByQuarter(areaRawDataByQuarterSorted),
  };
}
