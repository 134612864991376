// import "./assets/root.css";
// import "@fontsource/noto-sans";
// import "@fontsource/lexend-deca";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import { Routes } from "../../Routes";
import { placesTheme } from "../../assets/theme";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { useInitAppState } from "../../hooks/useInitAppState";

const App = () => {
  const isLoggedIn = useAuth();

  useInitAppState();

  useEffect(() => {
    console.log("[app] logged in", isLoggedIn);
  }, [isLoggedIn]);

  return (
    <>
      <ColorModeScript initialColorMode={placesTheme.initialColorMode} />
      <ChakraProvider theme={placesTheme}>
        <Routes />
      </ChakraProvider>
    </>
  );
};

export default App;
