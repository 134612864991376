import {
  Box,
  Flex,
  IconButton,
  Image,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

import { BiMailSend } from "react-icons/bi";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"600"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.600", "gray.200")}
      p={10}
      w="100%"
      id="footer"
    >
      <SimpleGrid
        templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
        spacing={8}
        px={20}
        maxW="1320px"
        margin="0 auto"
      >
        <Stack spacing={6}>
          <Flex>
            <Image src="/logo-icon.png" h="30px"></Image>
            {/* TITLE */}
            <Text as="h1" fontSize="2xl" fontWeight="bold">
              <Box as="span" color={"brand.main"}>
                Places{" "}
              </Box>
              <Box as="span" color="brand.light">
                Platform
              </Box>
            </Text>
          </Flex>
          <Text fontSize={"sm"}>
            © 2022 Places Platform. All rights reserved
          </Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton label={"Twitter"} href={"#"}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={"YouTube"} href={"#"}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={"Instagram"} href={"#"}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Stack>
        <Stack align={"flex-start"}>
          <ListHeader>Company</ListHeader>
          <Link href={"#"}>About us</Link>
          <Link href={"#"}>Contact us</Link>
          <Link href={"#"}>Pricing</Link>
          <Link href={"#"}>Testimonials</Link>
        </Stack>
        <Stack align={"flex-start"}>
          <ListHeader>Support</ListHeader>
          <Link href={"#"}>Help Center</Link>
          <Link href={"#"}>Terms of Service</Link>
          <Link href={"#"}>Legal</Link>
          <Link href={"#"}>Privacy Policy</Link>
          <Link href={"#"}>Status</Link>
        </Stack>
        <Stack align={"flex-start"}>
          <ListHeader>Stay up to date</ListHeader>
          <Stack direction={"row"}>
            <Input
              placeholder={"Your email address"}
              bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
              border={0}
              _focus={{
                bg: "whiteAlpha.300",
              }}
            />
            <IconButton
              bg={useColorModeValue("brand.green", "green.700")}
              color={useColorModeValue("white", "gray.800")}
              _hover={{
                bg: "green.600",
              }}
              aria-label="Subscribe"
              icon={<BiMailSend />}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
