import chroma from "chroma-js";

// const aliases = [
//   { area: "Atlanta", alias: "Atlanta, GA" },
//   { area: "Austin", alias: "Austin, TX" },
//   { area: "Baltimore", alias: "Baltimore, MD" },
//   { area: "Boston", alias: "Boston, MA" },
//   { area: "Charlotte", alias: "Charlotte, NC" },
//   { area: "Chicago", alias: "Chicago, IL" },
//   { area: "Cincinnati", alias: "Cincinnati, OH" },
//   { area: "Cleveland", alias: "Cleveland, OH" },
//   { area: "Columbus", alias: "Columbus, OH" },
//   { area: "Dallas-Fort Worth", alias: "Dallas-Fort Worth, TX" },
//   { area: "Denver", alias: "Denver, CO" },
//   { area: "Detroit", alias: "Detroit, MI" },
//   { area: "Houston", alias: "Houston, TX" },
//   { area: "Indianapolis", alias: "Indianapolis, IN" },
//   { area: "Kansas City", alias: "Kansas City, MO" },
//   { area: "Las Vegas", alias: "Las Vegas, NV" },
//   { area: "Los Angeles", alias: "Los Angeles, CA" },
//   { area: "Miami", alias: "Miami, FL" },
//   { area: "Minneapolis - St. Paul", alias: "Minneapolis, MN" },
//   { area: "Nashville", alias: "Nashville, TN" },
//   { area: "New York", alias: "New York, NY" },
//   { area: "Orlando", alias: "Orlando, FL" },
//   { area: "Philadelphia", alias: "Philadelphia, PA" },
//   { area: "Phoenix", alias: "Phoenix, AZ" },
//   { area: "Pittsburgh", alias: "Pittsburgh, PA" },
//   { area: "Portland", alias: "Portland, OR" },
//   { area: "Sacramento", alias: "Sacramento, CA" },
//   { area: "San Antonio", alias: "San Antonio, TX" },
//   { area: "San Diego", alias: "San Diego, CA" },
//   { area: "San Francisco", alias: "San Francisco, CA" },
//   { area: "Seattle", alias: "Seattle, WA" },
//   { area: "St. Louis", alias: "St. Louis, MO" },
//   { area: "Tampa", alias: "Tampa, FL" },
//   { area: "Virginia Beach", alias: "Virginia Beach, VA" },
//   { area: "Washington DC", alias: "Washington DC" },
// ];

export const getHPACBGColorRamp = (bounds, metro, year) => {
  // let areaName = metro;
  // const areaAliases = aliases.find((d) => d.area === metro || d.alias === metro);
  // if (areaAliases) areaName = areaAliases.area;
  // console.log({ areaName });
  const data = bounds.find((d) => d.area_name === metro && d.year === +year);
  // console.log(data);
  if (!data) return;
  const { min_hpa: min, max_hpa: max } = data;
  const layerColorRamp = [];
  const colorRamp = chroma
    .scale(["#FE2E2A", "#FFFE37", "#1D8D23"])
    .domain([min - 0.01, max + 0.01])
    .colors(11);
  layerColorRamp.push("case");
  layerColorRamp.push(["!=", ["get", "area_name"], metro], "rgba(0,0,0,0.5)");
  colorRamp.forEach((c, i) => {
    layerColorRamp.push(["<=", ["get", `year_${year}`], min + i * ((max - min) / 10)], c);
  });

  layerColorRamp.push("rgba(0,0,0,0.5)");
  return layerColorRamp;
};
