const PoiFilterButtonIcons = [
  "ChairAlt",
  "Liquor",
  "MenuBook",
  "ChildCare",
  "ShoppingBag",
  "Coffee",
  "Store",
  "HealthAndSafety",
  "School",
  "FitnessCenter",
  "LocalFlorist",
  "Store",
  "Handyman",
  "LocalHospital",
  "LocalLibrary",
  "FormatPaint",
  "Festival",
  "LocalPharmacy",
  "LocalPostOffice",
  "Church",
  "ShoppingBag",
  "Restaurant",
  "ShoppingBasket",
  "LocalGroceryStore",
  "Transit"
];

const PoiFilterButtonIconValues = [
  "Beauty Salon or Barber",
  "Beer, Wine or Liquor Store",
  "Book Store",
  "Childcare",
  "Clothing Store",
  "Coffee Shop",
  "Convenience Store",
  "Doctor or Dentist",
  "Elementary or Secondary School",
  "Fitness",
  "Florists",
  "General Merchandise",
  "Hardware Store",
  "Hospitals and Outpatient Care",
  "Library",
  "Paint or Wallpaper store",
  "Parks and Museums",
  "Pharmacy or Drug Store",
  "Postal Service",
  "Religious Institution",
  "Resale shops",
  "Restaurant or Bar",
  "Specialty Food Store",
  "Super Market",
  "Transit"
];

export {
  PoiFilterButtonIconValues, PoiFilterButtonIcons
}
