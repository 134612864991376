import "../../../node_modules/react-vis/dist/style.css";

import { Badge, Box, useColorMode, useColorModeValue } from "@chakra-ui/react";
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  YAxis,
} from "react-vis";
import { placeTypeColors, placeTypes } from "../../assets/theme";
import { useRef, useState } from "react";

import { useTimeSeriesStore } from "../../context/timeSeriesStore";

export const DemoTimeSeriesChart = ({
  dataStoreIndex,
  xTickFormat,
  title,
  yTickFormat,
  xType,
  color,
  placesTypes,
  height,
  ...rest
}) => {
  const chartRef = useRef();
  const { colorMode } = useColorMode();
  const dataByPlaceType = useTimeSeriesStore((e) => e.dataByPlaceType);
  // console.log("dataByPlaceType", dataByPlaceType);
  // console.log(dataByPlaceType["type_1"]);

  const [obj, setObj] = useState({});
  const [qtr, setQtr] = useState("");

  const chartHandler = (d, e) => {
    const year = d.x.toString().substring(2, 4);
    const quarter = d.x.toString().substring(5, 6);
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    setQtr(quarters[quarter - 1] + "'" + year);
    document.body.style.cursor = "crosshair";
    setObj((current) => ({
      ...current,
      ["type" + d.type]: yTickFormat ? yTickFormat(d.y) : d.y,
    }));
  };

  const chartLeaveHandler = () => {
    // const updatedData = data.map((bar) => ({ ...bar, color: "#12939A" }));
    // setData(updatedData.sort((a, b) => a.median - b.median));
    document.body.style.cursor = "default";
    setObj({});
  };

  const formatTooltip = () => {
    if (!Object.values(obj).length) return "";
    return (
      <div>
        <div style={{ fontWeight: "bold", position: "absolute", right: 0 }}>{qtr}</div>
        <div style={{ columns: 2 }}>
          {placeTypes.map((t, i) => (
            <div
              key={i.toString()}
              style={{
                color: t.type !== 4 ? placeTypeColors["type_" + t.type] : "#212121",
              }}
            >
              {t.label}: {obj["type" + t.type] !== "" ? obj["type" + t.type] : "N/A"}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Box
      backgroundColor={useColorModeValue("white", "gray.800")}
      // borderRadius="lg"
      // m={2}
      position={"relative"}
      ref={chartRef}
      display="flex"
      flexDir={"column"}
      width="100%"
      border={"solid thin lightgray"}
      borderColor={colorMode === "light" ? "gray.200" : "gray.700"}
      {...rest}
    >
      <Badge
        position={"absolute"}
        width={"100%"}
        top={"0px"}
        left={"0px"}
        zIndex={1}
        fontSize={"sm"}
      >
        {formatTooltip() || ""}
      </Badge>
      <FlexibleWidthXYPlot
        height={height || 360}
        // width={chartRef.current ? chartRef.current.scrollWidth : "100%"}
        xType={"ordinal"}
        // color="#3960A7"
        // color="#D04C67"
        margin={{ left: 80, top: 40 }}
        // style={{boxShadow: "3px 3px 10px 5px rgb(0 0 0 / 5%)", borderRadius: "5px"}}
        onMouseLeave={chartLeaveHandler}
      >
        <DiscreteColorLegend
          style={{ position: "absolute", left: "50px", bottom: "-80px" }}
          orientation="horizontal"
          items={placeTypes.map((m, i) => ({
            title: m.label,
            strokeWidth: 40,
            strokeStyle: i === 1 || i === 3 ? "dashed" : "solid",
            color: m.colorMuted,
          }))}
        />
        <HorizontalGridLines
        // style={{stroke: '#BEE3F8'}}
        />
        <XAxis
          // title="Months"
          tickLabelAngle={-45}
          tickFormat={(v) => {
            const year = v.toString().substring(0, 4);
            const quarter = v.toString().substring(4, 6);
            const quarters = ["Q1", "Q2", "Q3", "Q4"];
            const months = ["01", "02", "03", "04"];
            return `${quarters[months.indexOf(quarter)]}'${year.toString().substring(2, 4)}`;
          }}
          tickTotal={10}
          // tickValues={['July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec','Jan', 'Feb', 'Mar', 'Apr', 'May', 'June']}
          style={{
            text: { fontSize: "0.9rem" },
          }}
        />
        <YAxis
          // title="Avg Days on Market"
          // tickLabelAngle={-45}
          orientation="left"
          style={{
            text: { fontSize: "0.9rem" },
          }}
          tickFormat={yTickFormat || ((v) => v)}
        />

        {/* <LineSeries
          data={data || []}
          curve={"curveMonotoneX"}
          stroke={color && color === "blue" ? "dodgerblue" : "#86BF34"} //red //green - #86BF34
        /> */}
        {!placesTypes || !placesTypes.length
          ? ""
          : placesTypes.map((t, i) => (
              <LineSeries
                key={i.toString()}
                data={
                  Object.keys(dataByPlaceType).length
                    ? dataByPlaceType["type_" + t][dataStoreIndex]
                    : []
                }
                curve={"curveMonotoneX"}
                stroke={placeTypeColors["type_" + t]}
                strokeStyle={t === 2 || t === 4 ? "dashed" : "solid"}
                onNearestX={chartHandler}
              />
            ))}
      </FlexibleWidthXYPlot>
    </Box>
  );
};
