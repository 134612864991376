// import * as loglevel from 'loglevel'
// const prefix = require('loglevel-plugin-prefix');
// // if (!__PROD__) {
// loglevel.setLevel('trace')
// // } else {
// //   loglevel.setLevel('error')
// // }

const log = require('loglevel');
const prefix = require('loglevel-plugin-prefix');
prefix.reg(log);
// const fn = (level, name) => `${level} (${name}):`;
prefix.apply(log, { template: '%l [%n]:' });
log.info('root');

export default log