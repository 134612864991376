import { useEffect, useState } from "react";

import { BasicMultiLineChart } from "./BasicMultiLineChart";
import { Metric } from "../../helpers/datamodels";
import { apiGateway } from "../../config";
import { getMetrics } from "../../services/requests";
import { placeTypeColors } from "../../assets/theme";
import { useAccountStore } from "../../context/accountStore";

//DONE change statN to stat name then generate the category in this function
//TODO create alias for metric names
export const PlaceTypeChart = ({
  hideTitle,
  placesTypes,
  metric,
  area,
  yTickFormat,
}) => {
  const { apiToken } = useAccountStore((state) => {
    if (state.account && state.account.gcpToken) {
      return {
        apiToken: state.account.gcpToken,
      };
    } else {
      return {
        apiToken: null,
      };
    }
  });
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("");

  const _avmPlaceData = data;
  console.log({ _avmPlaceData });

  function flattenMetrics(metrics) {
    const array = [];
    Object.keys(metrics).forEach((key) => {
      Object.keys(metrics[key]).forEach((metricName) => {
        array.push({
          id: metrics[key][metricName].id,
          label: metrics[key][metricName]?.label || metric || null,
          metric: metricName,
          category_id: metrics[key][metricName].category_id,
        });
      });
    });
    return array;
  }

  function getMetric(metricName) {
    const metrics = flattenMetrics(Metric);
    const _metric = metrics.find((e) => e.metric === metricName);
    // console.log({ _metric }); //PASSING
    return _metric;
  }

  useEffect(() => {
    if (!apiToken) return;
    const dbMetric = getMetric(metric);
    // console.log({ dbMetric }); //PASSING
    if (!dbMetric) return;
    (async () => {
      const stats = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 2, //placesType,
          // groupBy: [1,2,3,4], //placesId,
          dateType: "yearly",
          // dataCategory: `{${statCategory}}`,
          dateStart: 2015,
          dateEnd: 2022,
          placesArea: `{${area}}`,
          metricIds: `{${dbMetric.id}}`,
        },
        "stat"
      );
      const stats2 = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 2, //placesType,
          // groupBy: [1,2,3,4], //placesId,
          dateType: "quarterly",
          // dataCategory: `{${statCategory}}`,
          dateStart: 2021,
          dateEnd: 2022,
          placesArea: `{${area}}`,
          metricIds: `{${dbMetric.id}}`,
        },
        "metric"
      );
      console.log({ stats2 });
      console.log({ stats }); //PASSING
      console.log({ metric });
      if (metric === "office_cbgs_occupancy") {
        stats.forEach((placeTypeData) => {
          if (placeTypeData?.stats && placeTypeData?.stats[0]?.timeseries) {
            placeTypeData.stats[0].timeseries.forEach((data, i) => {
              if (data.y !== null)
                placeTypeData.stats[0].timeseries[i].y = Number(
                  (100 - data.y).toFixed(2)
                );
            });
          }
        });
      }

      if (!stats.length) return;

      //NOTE new method only returns one stat per chart
      const type1 = stats.find((e) => e.groupby_id === "1")?.stats[0] || {}; //stats[0].stats[stat];
      const type2 = stats.find((e) => e.groupby_id === "2")?.stats[0] || {}; //stats[0].stats[stat];
      const type3 = stats.find((e) => e.groupby_id === "3")?.stats[0] || {}; //stats[0].stats[stat];
      const type4 = stats.find((e) => e.groupby_id === "4")?.stats[0] || {};

      type1["color"] = placeTypeColors?.type_1 || "black";
      type2["color"] = placeTypeColors?.type_2 || "black";
      type3["color"] = placeTypeColors?.type_3 || "black";
      type4["color"] = placeTypeColors?.type_4 || "black";

      setData([type1, type2, type3, type4]);
      setLabel(dbMetric?.label || dbMetric?.metric_name);
    })();
    //eslint-disable-next-line
  }, [apiToken, area, metric]);

  return (
    <>
      {Object.keys(data).length ? (
        <BasicMultiLineChart
          hideTitle={hideTitle}
          inputStats={data}
          title={`${label} by Place Type`}
          inputTypes={placesTypes}
          yTickFormat={yTickFormat}
          height={400}
        />
      ) : null}
    </>
  );
};
