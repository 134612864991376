import {
  Box,
  Container,
  Heading,
  Image,
  Stack,
  useColorMode,
} from "@chakra-ui/react";

export default function Hero() {
  const { colorMode } = useColorMode();
  return (
    <Container w="100%" h="33%" as="header">
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 8, md: 8, lg: 16, xl: "14" }}
      >
        <Image
          margin="0 auto"
          maxW="400px"
          src="/logo.png"
          alt="logo"
          borderRadius="10px"
          padding="20px"
          background={colorMode === "light" ? "transparent" : "white"}
        ></Image>
        <Heading
          fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}
          fontWeight={600}
          mb={0}
          color={colorMode === "light" ? "gray.900" : "white"}
        >
          Measuring the{" "}
          <Box as="span" color={"brand.green"}>
            Value
          </Box>{" "}
          of{" "}
          <Box as="span" color={"brand.main"}>
            Place
          </Box>
        </Heading>
        <Box w="100%">
          <Box
            color="gray.500"
            fontSize={18}
            maxW="800px"
            textAlign={"justify"}
            margin={"0 auto"}
          >
            Places Platform, LLC is an information services company that
            provides a unified view of both commercial and residential real
            estate.
            <br></br>
            <br></br>
            Our proprietary suite of analytics provides a contextualized
            understanding of value, risk, and volatility in local real estate
            markets.
            <br></br>
            <br></br>
            <strong>
              Places Platform tells you what "location, location, location" is
              actually worth.
            </strong>
          </Box>{" "}
        </Box>
      </Stack>
    </Container>
  );
}

//        Real estate <Box as="span" color={'brand.main'}>analytics</Box> inside a context of <Box as="span" color={'brand.green'}>value</Box> and <Box as="span" color={'brand.red'}>risk</Box>.
