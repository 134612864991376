import { Box, Center, Grid, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { BarChartCategory } from "./DemoBarChartCategory";
import { DataCategory } from "../../helpers/datamodels";
import { GroupByType } from "../../helpers/datamodels";
// import MSSChart from "./MSSChart";
import { apiGateway } from "../../config";
import { getMetrics } from "../../services/requests";
import { getPremiumByPlaceType } from "../../helpers/getPlaceTypePremValues";
import { placeTypeColorsMuted } from "../../assets/theme";
import { placeTypes } from "../../assets/theme";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";

function getPricePremiumsByPlaceType(data, field = "pricePremiumsByPlaceType") {
  const types = placeTypes.map((p) => p.label);
  const colors = Object.values(placeTypeColorsMuted);

  const array = [];
  const metro = data || {};
  if (!metro[field]) return array;

  for (let key in metro[field]) {
    const q = metro[field][key];
    array.push({
      x: types[+key - 1],
      y: Number((q[q.length - 1].premium * 100).toFixed(2)),
      color: colors[+key - 1],
    });
  }
  return array;
}

const PremiumCharts = ({ height }) => {
  const { setIsLoading, activeMetro } = useAppState();
  const { apiToken, settings } = useAccountStore();
  const [metrics, setMetrics] = useState([]);
  // const [mssData, setMssData] = useState([]);
  const [appreciationData, setAppreciationData] = useState([]);

  const sectors = ["For Sale Residential", "Multi-Family", "Office", "Retail"];
  const titles = {
    "For Sale Residential": "Price Premiums",
    "Multi-Family": "Rent Premiums",
    Office: "Rent Premiums",
    Retail: "Rent Premiums",
  };
  const colors = Object.values(placeTypeColorsMuted);

  const barChartPlaceTypeLegend = [
    {
      title: "WalkUPs",
      color: colors[0],
      strokeWidth: 40,
    },
    {
      title: "Walkable Neighborhoods",
      color: colors[1],
      strokeWidth: 40,
    },
    {
      title: " ",
      color: "transparent",
      strokeWidth: 40,
    },
    {
      title: 'Drivable "Edge-Cities"',
      color: colors[2],
      strokeWidth: 40,
    },
    {
      title: "Drivable Communities",
      color: colors[3],
      strokeWidth: 40,
    },
    {
      title: "Sector As-Built Stock",
      color: "#f1f1f1",
      strokeWidth: 40,
    },
  ];

  useEffect(() => {
    if (!settings?.msa_data) return;
    (async () => {
      setIsLoading(true);
      const allMetroIds = settings.msa_data.reduce((i, v) => [...i, v.id], []);

      const metroMetrics = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 3,
          placesArea: `{${allMetroIds.join(",")}}`,
          // dataCategory: "{480377}"
        },
        "data"
      );
      console.log({ metroMetrics });

      const _appreciationData = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 2,
          placesArea: `{${allMetroIds.join(",")}}`,
        },
        "data"
      );
      console.log({ _appreciationData });
      const _appr = _appreciationData.map((m) => {
        const obj = {};
        obj["msa"] = m.msa;
        obj["groupby_id"] = m.groupby_id;
        obj["type"] = +m?.groupby_id;
        const keys = Object.keys(m).filter((k) => k.includes("appreciation"));
        keys.forEach((k) => {
          obj[k] = m[k];
        });
        return obj;
      });
      console.log({ _appr });
      setAppreciationData(_appr);

      // const mss = _appreciationData.map((m) => {
      //   const obj = {};
      //   obj["msa"] = m.msa;
      //   obj["groupby_id"] = m.groupby_id;
      //   const keys = Object.keys(m).filter((k) => k.includes("marketShift"));
      //   keys.forEach((k) => {
      //     obj[k] = m[k];
      //   });
      //   return obj;
      // });
      // console.log({ mss });
      // setMssData(mss);

      // NOTE this is the data that powers the price premium charts for the commercial sectors
      const metroRentPremByPlaceType = await getMetrics(
        apiGateway,
        apiToken,
        {
          placesArea: `{${allMetroIds.join(",")}}`,
          groupByType: 2,
          dateType: "yearly",
          dateStart: 2019,
          dateEnd: 2022,
          metricIds: "{19706,19705,19704,94}",
        },
        "stat"
      );
      console.log({ metroRentPremByPlaceType });

      //-------------------------------------
      /** TEMP VIEWS FOR FOR SALE PREMIUMS */
      //-------------------------------------

      // NOTE this is the data that powers the price premium charts for for sale
      const metroForSalePricePremByPlaceType = await fetch(
        `${apiGateway}/collections/public._tmp_mview_for_sale_prem_place_type/items?access_token=${apiToken}&limit=1000`
      ).then((res) => res.json());
      console.log({ metroForSalePricePremByPlaceType });

      // const metroPricePremiums = await fetch(
      //   `${apiGateway}/collections/public._tmp_mview_for_sale_premiums/items?access_token=${apiToken}&limit=1000`
      // ).then((res) => res.json());

      // const metroPricePremiumsByQuartile = await fetch(
      //   `${apiGateway}/collections/public._tmp_mview_for_sale_prem_hpa_quartile_t4/items?access_token=${apiToken}&limit=1000`
      // ).then((res) => res.json());

      metroMetrics.forEach((m, i) => {
        if (!Number(m.groupby_id)) m.msa = null;
        m["premByPlaceType"] = metroRentPremByPlaceType.filter((p) => p.msa === m.msa);

        m["pricePremiumsByPlaceType"] = metroForSalePricePremByPlaceType.features
          .filter((p) => p.properties.area === m.msa)
          .reduce((i, v) => ({ ...i, [v.properties.place_type]: v.properties.data }), {});

        // m["pricePremiums"] = metroPricePremiums.features.find(
        //   (p) => p.properties.area === m.msa
        // )?.properties.data;

        // m["pricePremiumsByQuartile"] = metroPricePremiumsByQuartile.features
        //   .filter((p) => p.properties.area === m.msa)
        //   .reduce(
        //     (i, v) => ({
        //       ...i,
        //       [v.properties.hpa_quartile]: v.properties.data,
        //     }),
        //     {}
        //   );]
      });
      //----------------------------------------
      /** END TEMP VIEWS FOR FOR SALE PREMIUMS */
      //----------------------------------------

      //----------------------------------------
      // NOTE No longer using this data
      //----------------------------------------
      // const metroRentPremiums = await getMetrics(
      //   apiGateway,
      //   apiToken,
      //   {
      //     groupByType: 3,
      //     placesArea: `{${allMetroIds.join(",")}}`,
      //     dateType: "yearly",
      //     dateStart: 2017,
      //     dateEnd: 2022,
      //     // dataCategory: "{480377}"
      //     metricIds: "{18565607,18565606,18565605,18565603}",
      //   },
      //   "stat"
      // );
      // console.log({ metroRentPremiums });

      // metroMetrics.forEach((m) => {
      //   m["stats"] = metroRentPremiums.find((p) => p.msa === m.msa)?.stats;
      // });
      //----------------------------------------
      //----------------------------------------

      // NOTE this is static from Places - direct CSV to App
      const lensdata = await getMetrics(
        apiGateway,
        apiToken,
        {
          // placesArea: `{${taskedMetroIds.join(",")}}`,
          groupByType: GroupByType.places_type,
          dataCategory: `{${DataCategory.LENS_MATRIX}}`,
        },
        "data"
      );
      console.log({ lensdata });

      metroMetrics.forEach((m) => {
        const array = [];
        const totalStockSQFT = {
          forsale: 0,
          retail: 0,
          office: 0,
          multifam: 0,
        };
        lensdata.forEach((l) => {
          if (l.msa === m.msa) {
            totalStockSQFT.forsale += l.for_sale_sqft;
            totalStockSQFT.retail += l.retail_sqft;
            totalStockSQFT.office += l.office_sqft;
            totalStockSQFT.multifam += l.multifam_sqft;
          }
        });
        lensdata.forEach((l) => {
          if (l.msa === m.msa) {
            l["pnt_forsale"] = +(l.for_sale_sqft / totalStockSQFT.forsale).toFixed(4) * 100;
            l["pnt_retail"] = +(l.retail_sqft / totalStockSQFT.retail).toFixed(4) * 100;
            l["pnt_office"] = +(l.office_sqft / totalStockSQFT.office).toFixed(4) * 100;
            l["pnt_multifam"] = +(l.multifam_sqft / totalStockSQFT.multifam).toFixed(4) * 100;
            array.push(l);
          }
        });
        m["lensmatrixdata"] = array;
      });

      // console.log({ metroRentPremiums });
      console.log(metroMetrics[0]);
      //----------------------------------------
      setMetrics(() => metroMetrics || []);
      setIsLoading(false);
    })();
    //eslint-disable-next-line
  }, [settings?.msa_data, apiToken, setIsLoading]);

  return (
    <Center>
      <Grid
        gridTemplateColumns={{ base: "repeat(1,minmax(0,1fr))", md: "repeat(2,minmax(0,1fr))" }}
        gap={10}
        columnGap={14}
        width="100%"
      >
        {sectors.map((sector, i) => {
          return (
            <React.Fragment key={i}>
              <Box key={i.toString()} mb={20}>
                <Heading as="h3" size="md" mb={2} textAlign={"center"}>
                  {sector} <br />
                  {titles[sector]}
                  {" ($/sq ft)"}
                  <br />
                </Heading>
                <Heading as="h3" size="md" mb={"12"} textAlign={"center"}>
                  {activeMetro || ""}
                </Heading>
                {sector === "For Sale Residential" ? (
                  <BarChartCategory
                    data={getPricePremiumsByPlaceType(metrics.find((m) => m.msa === activeMetro))}
                    rawStockData={metrics.find((m) => m.msa === activeMetro)?.lensmatrixdata}
                    legend={barChartPlaceTypeLegend}
                    title=""
                    height={height}
                    stock="forsale"
                    appreciationData={appreciationData.filter((m) => m.msa === activeMetro)}
                  />
                ) : sector === "Multi-Family" ? (
                  <BarChartCategory
                    title=""
                    data={getPremiumByPlaceType(
                      metrics.find((m) => m.msa === activeMetro),
                      "mf_cbgs_rent_percFromMetro",
                      "2021"
                    )}
                    rawStockData={metrics.find((m) => m.msa === activeMetro)?.lensmatrixdata}
                    appreciationData={appreciationData.filter((m) => m.msa === activeMetro)}
                    legend={barChartPlaceTypeLegend}
                    height={height}
                    stock="multifam"
                  />
                ) : sector === "Retail" ? (
                  <BarChartCategory
                    title=""
                    maxW="500px"
                    data={getPremiumByPlaceType(
                      metrics.find((m) => m.msa === activeMetro),
                      "retail_cbgs_rent_percFromMetro"
                    )}
                    rawStockData={metrics.find((m) => m.msa === activeMetro)?.lensmatrixdata}
                    appreciationData={appreciationData.filter((m) => m.msa === activeMetro)}
                    legend={barChartPlaceTypeLegend}
                    height={height}
                    stock="retail"
                  />
                ) : (
                  <>
                    <BarChartCategory
                      title=""
                      maxW="500px"
                      data={getPremiumByPlaceType(
                        metrics.find((m) => m.msa === activeMetro),
                        "office_cbgs_rent_percFromMetro"
                      )}
                      rawStockData={metrics.find((m) => m.msa === activeMetro)?.lensmatrixdata}
                      appreciationData={appreciationData.filter((m) => m.msa === activeMetro)}
                      legend={barChartPlaceTypeLegend}
                      height={height}
                      stock="office"
                    />
                  </>
                )}
              </Box>
              {/* <Box mb={20}>
                <MSSChart
                  sector={sector}
                  activeMetro={activeMetro}
                  rawChartData={mssData.filter((e) => e.msa === activeMetro)}
                  mb={20}
                  sectorMSS={
                    sector === "Multi-Family"
                      ? "multifam_inventory_marketShift_2018_2022"
                      : sector === "Office"
                      ? "office_inventory_marketShift_2018_2022"
                      : sector === "Retail"
                      ? "retail_rent_marketShift_2018_2022"
                      : sector === "For Sale Residential"
                      ? "forsale_inventory_marketShift_2018_2021"
                      : ""
                  }
                />
              </Box> */}
            </React.Fragment>
          );
        })}
      </Grid>
    </Center>
  );
};

export default PremiumCharts;
