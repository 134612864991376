import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MoonIcon, QuestionOutlineIcon, SunIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { AvatarNavButton } from "./AvatarNavButton";
import { FiMenu } from "react-icons/fi";
import { UserSettingsModal } from "../Auth/UserSettingsModal";
import { useAccountStore } from "../../context/accountStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { version } from "../../config";

const Navbar = ({ openSidebar, rest }) => {
  const navigate = useNavigate();
  const { account, settings } = useAccountStore();
  const { colorMode, toggleColorMode } = useColorMode();
  const { pathname } = useLocation();
  const {
    isOpen: isSettingsModalOpen,
    onOpen: settingsModalOpen,
    onClose: settingsModalClose,
  } = useDisclosure();

  /** ----------------------------- */
  /* EFFECTS
  /** ----------------------------- */

  //NOTE open settings window if no settings are found and they are logged in
  useEffect(() => {
    if (!settings) return;
    if (!settings.app_settings && account) {
      console.log("[app] no app settings found");
      settingsModalOpen();
    }
  }, [settings, settingsModalOpen, account]);

  const toast = useToast();

  const showCominSoon = () => {
    toast({
      title: "Coming Soon",
      description: "",
      status: "info",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <>
      <UserSettingsModal isOpen={isSettingsModalOpen} onClose={settingsModalClose} />
      {/* NAVBAR */}
      <Flex
        id="navbar"
        px={2}
        height="56px"
        py={4}
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor={colorMode === "light" ? "gray.200" : "gray.700"}
        justifyContent="space-between"
        position="fixed"
        width={"100%"}
        zIndex={10}
        background={colorMode === "light" ? "white" : "gray.900"}
        {...rest}
      >
        <Flex alignItems="center">
          {/* SIDEBAR TOGGLE BUTTON */}
          <Flex
            id="navbar-toggle"
            display={{ base: "flex", lg: "none" }}
            alignItems="center"
            bg={colorMode === "light" ? "white" : "gray.900"}
            justifyContent="flex-start"
            visibility={pathname === "/" || pathname === "/home" ? "hidden" : "visible"}
            {...rest}
          >
            <IconButton
              variant="outline"
              onClick={openSidebar}
              aria-label="open menu"
              icon={<FiMenu />}
            />
          </Flex>
          {/* LOGO */}
          <RouterLink to="/" style={{ display: "flex" }}>
            <Image src="/logo-icon.png" h="30px" ml={2} mr={2}></Image>
            {/* TITLE */}
            <Box
              as="h1"
              fontSize={{ base: "lg", lg: "2xl" }}
              fontWeight="bold"
              display={{ base: "none", lg: "block" }}
            >
              <Box as="span" color={"brand.main"}>
                Places{" "}
              </Box>
              <Box as="span" color="brand.light">
                Platform
              </Box>
            </Box>
          </RouterLink>
        </Flex>

        <Flex display={{ base: "none", md: "flex" }}>
          <HStack justifyContent={"center"}>
            <Button size="sm" onClick={showCominSoon}>
              Office
            </Button>
            <Button size="sm" onClick={showCominSoon}>
              Multi-Family
            </Button>
            <Button size="sm" onClick={showCominSoon}>
              Retail
            </Button>
            <Button size="sm" onClick={showCominSoon}>
              Industrial
            </Button>
          </HStack>
          <Flex m="2" fontSize={"sm"} color="gray.200" alignItems={"center"}>
            {version}
          </Flex>
          {/* <IconButton
            onClick={toggleColorMode}
            variant="ghost"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            mx={2}
          /> */}
          {account && account?.is_admin ? (
            <IconButton
              icon={<QuestionOutlineIcon />}
              variant={"ghost"}
              isRound={true}
              size="lg"
              fontSize={"3xl"}
              onClick={() => navigate("/docs")}
              color="gray.400"
            ></IconButton>
          ) : (
            ""
          )}
          <AvatarNavButton openSettings={settingsModalOpen}></AvatarNavButton>
        </Flex>
        <Flex display={{ base: "flex", md: "none" }}>
          <Flex m="2" fontSize={"sm"} color="gray.200" alignItems={"center"}>
            {version}
          </Flex>
          <IconButton
            onClick={toggleColorMode}
            variant="ghost"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            mx={2}
          />
          <AvatarNavButton openSettings={settingsModalOpen}></AvatarNavButton>
        </Flex>
      </Flex>
    </>
  );
};

export default Navbar;
