import { Box, Button, Center, Flex, Grid, HStack, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Map from "../../components/Demo/DemoMap";
import PlacesScoreHeatGrid from "../../components/Demo/PlacesScoreHeatGrid";
import { PlacesValueMatrixDotChart } from "../../components/Demo/PlacesValueMatrixDotChart";
import { apiGateway } from "../../config";
import chroma from "chroma-js";
import { placeTypes } from "../../assets/theme";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";

export const PlacesScoreMatrix = () => {
  const { activeMetro, setIsLoading, activePlacesTypes } = useAppState((state) => {
    return {
      activeMetro: state.activeMetro,
      setIsLoading: state.setIsLoading,
      activePlacesTypes: state.placesTypes,
    };
  });
  const { apiToken } = useAccountStore();
  const [data, setData] = useState([]);
  const [year, setYear] = useState("2024");
  const [matrixData, setMatrixData] = useState([]);

  //now we need to average all the values for each of the 9 groups using a function createMatrixData
  //we just need the hpa values as one value, then the two break values

  const createMatrixData = (data, yr) => {
    const riskBreaks = chroma.limits(
      data.map((d) => d.volatility_index),
      "q",
      9
    );
    const returnBreaks = chroma.limits(
      data.map((d) => d[`hpa_index_${yr}`]),
      "q",
      9
    );
    console.log(returnBreaks, riskBreaks);
    const matrix = [];
    riskBreaks.forEach((risk, i) => {
      returnBreaks.forEach((ret, ix) => {
        const filtered = data.filter((d) => {
          if (i === riskBreaks.length - 1 && ix === returnBreaks.length - 1) {
            return d.volatility_index >= risk && d[`hpa_index_${yr}`] >= ret;
          } else if (i === riskBreaks.length - 1) {
            return (
              d.volatility_index >= risk &&
              d[`hpa_index_${yr}`] >= ret &&
              d[`hpa_index_${yr}`] < returnBreaks[ix + 1]
            );
          } else if (ix === returnBreaks.length - 1) {
            return (
              d.volatility_index >= risk &&
              d.volatility_index < riskBreaks[i + 1] &&
              d[`hpa_index_${yr}`] >= ret
            );
          } else {
            return (
              d.volatility_index >= risk &&
              d.volatility_index < riskBreaks[i + 1] &&
              d[`hpa_index_${yr}`] >= ret &&
              d[`hpa_index_${yr}`] < returnBreaks[ix + 1]
            );
          }
        });
        // const avg = filtered.reduce((acc, curr) => {
        //   return acc + curr.volatility_index;
        // }, 0) / filtered.length;
        const avgReturn =
          filtered.reduce((acc, curr) => {
            return acc + curr[`places_score_${yr}`];
          }, 0) / filtered.length;
        matrix.push({
          risk: risk,
          return: ret,
          avgReturn: avgReturn,
          count: filtered.length,
        });
      });
    });
    return matrix;
  };

  useEffect(() => {
    (async () => {
      console.log("Active Metro", activeMetro);
      const _data = await fetch(
        `${apiGateway}/functions/postgisftw.get_places_volatility_iv/items/?limit=100000&access_token=${apiToken}&query_area_id=${activeMetro}`
      ).then((res) => {
        return res.json();
      });
      console.log({ _data });
      const matrix = createMatrixData(_data, year);
      setMatrixData(matrix);
      setData(
        _data.map((d, i) => {
          return {
            ...d,
            x: d[`hpa_index_${year}`],
            y: d.volatility_index,
            color: placeTypes.find((p) => p.type === d.place_type)?.color + "A0" || "black",
          };
        })
      );
    })();

    setIsLoading(false);
    // eslint-disable-next-line
  }, [activeMetro]);

  useEffect(() => {
    (async () => {
      const _data = await fetch(
        `${apiGateway}/functions/postgisftw.get_places_volatility_iv/items/?limit=100000&access_token=${apiToken}&query_area_id=${activeMetro}`
      ).then((res) => {
        return res.json();
      });
      console.log({ _data });
      const matrix = createMatrixData(_data, year);
      setMatrixData(matrix);
    })();
    setData(
      data.map((d, i) => {
        return {
          ...d,
          x: d[`hpa_index_${year}`],
          y: d.volatility_index,
          scoreKey: d[`places_score_${year}`],
          color: placeTypes.find((p) => p.type === d.place_type)?.color + "A0" || "black",
        };
      })
    );
    // eslint-disable-next-line
  }, [year]);

  return (
    <Flex
      justifyContent={"center"}
      id="score-matrix"
      p={4}
      flexDir={"column"}
      data-id="matrix-wrapper"
    >
      <Flex flexDir={"column"} w="100%" maxW={"1680px"} flex={1} margin="0 auto">
        <Heading textAlign={"center"} as="h2" size="xl" mb={8}>
          Places Value Matrix
        </Heading>
        <Grid
          gridTemplateColumns={{
            base: "minmax(0, 1fr)",
            lg: "minmax(0, 1fr) 50%",
          }}
          gap={10}
          justifyContent={"center"}
        >
          <Center flexDir={"column"}>
            <Heading as="h3" size={"md"} mb={2}>
              {activeMetro}
            </Heading>
            <Text fontSize="lg" fontWeight={"bold"}>
              For Sale Residential Housing
            </Text>
            <Text fontSize="lg" fontWeight={"bold"}>
              Year End {year} @ CBG Level
            </Text>
          </Center>
        </Grid>
        <Grid
          gridTemplateColumns={{
            base: "minmax(0, 1fr)",
            lg: "minmax(0, 1fr) 50%",
          }}
          gap={10}
          mb={20}
          justifyContent={"center"}
        >
          <Box textAlign={"center"}>
            <PlacesValueMatrixDotChart
              activeMetro={activeMetro}
              data={data.filter((d) => activePlacesTypes.includes(d.place_type))}
              placesType={[]}
            />
            <HStack spacing={4} justifyContent={"center"} mt={4}>
              {[2024, 2025, 2026, 2027, 2028].map((y) => (
                <Button
                  key={y}
                  onClick={() => setYear(y)}
                  variant={+year === +y ? "solid" : "outline"}
                  colorScheme="blue"
                  size="sm"
                >
                  {y}
                </Button>
              ))}
            </HStack>
          </Box>

          <Box background="lightgray" h="560px" maxH="690px" display={{ base: "none", lg: "flex" }}>
            <Map
              setPopupContent={() => {}}
              buttonFiltersState={null}
              analyticsChangeMode={false}
              placesType={1}
              setTabIndex={() => {}}
              activeLayers={["metro-areas-scores-fill", "cbg-place-score-fill-2024"]}
            />
          </Box>
        </Grid>
      </Flex>
      <Grid
        gridTemplateColumns={{
          base: "minmax(0, 1fr)",
          lg: "minmax(0, 1fr) 50%",
        }}
        gap={10}
        justifyContent={"center"}
      >
        <PlacesScoreHeatGrid data={matrixData} year={year} />
        <Box>&nbsp;</Box>
      </Grid>
    </Flex>
  );
};
