import { Box, Flex, Grid, Heading, SimpleGrid, Text } from "@chakra-ui/react";
// import { FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, XAxis, YAxis } from "react-vis";
import { placeTypeColorsMuted, placeTypes } from "../../assets/theme";
// import {
//   HPALineChartByPlaceType,
//   HPATableByPlaceType,
// } from "../../components/LineCharts/OverviewLineCharts";
import { useEffect, useState } from "react";

import { DemoTimeSeriesChart } from "../../components/Demo/DemoTimeSeriesChart";
import HPAScoreYearLineChart from "./HPAScoreYearLineChart";
import MedianPriceChangeCharts from "../../components/Demo/MedianPriceChangeCharts";
// import { ForwardHPASparklineChart } from "../../components/Demo/ForwardHPASparklineChart";
// import { MarketScapeChangeTable } from "../../components/Demo/MarketScapeChangeTable";
import { PlaceTypeChart } from "../../components/Charts/PlaceTypeChart";
import { apiGateway } from "../../config";
import { dateObj } from "../../utils/dateObj";
// import { getHPAPlaceTypeData } from "../../components/Dashboards/Overview";
import { getMetrics } from "../../services/requests";
import { useAccountStore } from "../../context/accountStore";
import { useAppState } from "../../context/useAppState";
import { useTimeSeriesStore } from "../../context/timeSeriesStore";

//TODO Demo - convert these to indiv components

export const Metrics = () => {
  const { activeMetro, setIsLoading, placesTypes } = useAppState();
  const { apiToken, settings } = useAccountStore();
  const { setDataByPlaceType, dataByPlaceType } = useTimeSeriesStore();
  const [medianChangeTableData, setMedianChangeTableData] = useState([]);

  const [priceChangeData, setPriceChangeData] = useState([]);

  // const [metroAVMNAtional, setMetroAVMNAtional] = useState([]);
  const metroAVMData = [];
  console.debug(medianChangeTableData);
  // console.debug({ metroAVMData });

  useEffect(() => {
    if (!settings?.msa_data || !settings?.msa_data?.length) return;
    (async () => {
      // if (!metroAVMData.length) await setMetroAVMData(apiToken);
      const allMetroIds = settings.msa_data.reduce((i, v) => [...i, v.id], []);
      const nationalMetrics = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 4,
          // dateType: "yearly",
          // dateStart: 2022,
          // dateEnd: 2022
        },
        "data"
      );
      console.debug({ nationalMetrics });
      const __hpaData = await getMetrics(
        apiGateway,
        apiToken,
        {
          placesArea: `{${allMetroIds.join(",")}}`,
          groupByType: 3,
          dateType: "yearly",
          dateStart: 2023,
          dateEnd: 2023,
          metricIds: "{11306}",
        },
        "stat"
      );
      console.debug({ __hpaData });
      const hpaData = __hpaData.map((v) => {
        return {
          metro: v.msa,
          hpa_2023: v?.stats?.[0]?.timeseries[0]?.y * 100,
        };
      });
      console.debug({ hpaData });
    })();
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (!apiToken) return;
    if (!settings?.msa_data || !settings?.msa_data?.length) return;
    (async () => {
      const metroId = settings.msa_data.find((e) => e.name === activeMetro)?.id || null;
      console.debug({ metroId });
      const data = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 2, //placesType,
          dateType: "quarterly",
          dateStart: 202101,
          dateEnd: 202302,
          placesArea: `{${metroId}}`,
          metricIds: "{18565839,18565838}",
        },
        "stat"
      );
      console.debug({ data });

      data.forEach((d) => {
        const _type = d.groupby_id;
        d.stats.forEach((s) => {
          s.placeType = _type;
          const chartData = [];
          s.timeseries.forEach((t) => {
            chartData.push({
              color: placeTypes.find((t) => t.ype === +_type)?.color || "gray",
              metric: s.metric_name,
              placeType: +_type,
              x: "Q" + t.x.toString().slice(6, 7) + "'" + t.x.toString().slice(2, 4),
              y: t.y,
            });
          });
          s["chartData"] = chartData;
        });
      });

      const medianPriceChangePlaceTypeData = data.reduce((i, v) => [...i, ...v.stats], []);
      const chartData = medianPriceChangePlaceTypeData.reduce((i, v) => {
        return [...i, ...v.chartData];
      }, []);
      console.debug({ chartData });
      setPriceChangeData(chartData);
    })();
    // eslint-disable-next-line
  }, [activeMetro, apiToken, settings]);

  //NOTE national avm stats
  // useEffect(() => {
  //   if (!metroAVMData.length || metroAVMNAtional.length) return;
  //   (async () => {
  //     setIsLoading(true);
  //     const data = await fetch(
  //       `${apiGateway}/collections/public.view_msa_avm_stats_nat/items?access_token=${apiToken}`
  //     ).then((res) => res.json());
  //     setIsLoading(false);
  //     if (data.features.length)
  //       setMetroAVMNAtional(data.features[0].properties.timeseries);
  //   })();
  //   //eslint-disable-next-line
  // }, [metroAVMData, apiToken, setMetroAVMNAtional]);

  // const sectors = ["industrial", "mf", "office", "retail"];

  // console.debug({ dataByPlaceType });

  const [metrics, setMetrics] = useState({
    metro: [],
  });
  const tableMetrics = [
    {
      name: "avg_places_properties_sold",
      change: "chg_avg_places_properties_sold",
      dataIndex: 6,
      round: 0,
      text: "2022 Median Properties Sold per Place",
    },
    {
      name: "avg_onmarket",
      change: "chg_avg_onmarket",
      dataIndex: 2,
      round: 0,
      text: "2022 Median Days On-Market per Place",
    },
    {
      name: "median_list_over_sold",
      change: "chg_median_list_over_sold",
      dataIndex: 5,
      round: 2,
      text: "2022 Median Sale-to-List Ratio per Place",
    },
    {
      name: "median_square_foot_price",
      change: "chg_median_square_foot_price",
      dataIndex: 4,
      round: 0,
      text: "2022 Median Square Foot Price per Place",
    },
  ];

  console.debug({ metrics });

  //TODO reduce this to a get HPA by place type custom hook
  useEffect(() => {
    if (!settings?.msa_data || !settings?.msa_data?.length) return;
    (async () => {
      setIsLoading(true);

      const allMetroIds = settings.msa_data.reduce((i, v) => [...i, v.id], []);

      const metroMetrics = await getMetrics(
        apiGateway,
        apiToken,
        {
          groupByType: 3,
          placesArea: `{${allMetroIds.join(",")}}`,
          // dataCategory: "{480377}"
        },
        "data"
      );

      // console.debug({ metroMetrics });

      const hpaByPlaceType = await getMetrics(apiGateway, apiToken, {
        groupByType: 2,
        metricIds: "{18565617,18565616,18565615,18565614,18565613}",
      });
      // console.debug({ hpaByPlaceType });

      metroMetrics.forEach((m, i) => {
        m["hpaByPlaceType"] = hpaByPlaceType.filter((p) => p.msa === m.msa);
      });

      setMetrics((state) =>
        Object.assign({}, state, {
          metro: [...state.metro, ...metroMetrics],
        })
      );
      setIsLoading(false);
    })();
    const areasArray = [null, "San Diego", "San Francisco"];
    (async () => {
      const _yearlyData = await fetch(
        `${apiGateway}/functions/postgisftw.get_listing_stats_ii/items.json?i_places_area_id=${areasArray.indexOf(
          activeMetro
        )}&i_date_type=yearly&i_place_type=place_type&i_min_date_value=2021&i_max_date_value=2022&access_token=${apiToken}`
      ).then((res) => res.json());
      if (!_yearlyData?.length) {
        return setMedianChangeTableData([]);
      }
      const filtered = _yearlyData.filter((d) => d.date_value !== 2023);
      // console.debug({ _yearlyData });
      console.debug({ filtered });
      const tableData = [];

      const types = [
        {
          type: "type_1",
          name: "Walkable WalkUPs",
        },
        {
          type: "type_2",
          name: "Walkable Neighborhoods",
        },
        {
          type: "type_3",
          name: 'Drivable "Edge-Cities"',
        },
        {
          type: "type_4",
          name: "Drivable Communities",
        },
      ];
      types.forEach((type) => {
        const typeData = _yearlyData.filter(
          (d) => d.places_id === type.type.replace("type_", "") && d.date_value === 2022
        );
        if (!typeData.length) return;
        tableMetrics.forEach((metric) => {
          tableData.push({
            metric: metric.name,
            typeName: type.name,
            color: placeTypeColorsMuted[type.type],
            value: Number(typeData[0][metric.name])
              ? Number(typeData[0][metric.name])?.toFixed(metric.round)
              : null,
            change: Number(typeData[0][metric.change])
              ? ((typeData[0][metric.change] - 1) * 100)?.toFixed(2)
              : "",
            text: metric.text,
          });
        });
      });

      setMedianChangeTableData(tableData);
    })();

    //eslint-disable-next-line
  }, [settings]);

  //TIMESERIES CHART DATA
  useEffect(() => {
    if (!apiToken || !activeMetro) return;
    console.debug("[map] -- metro changed");
    try {
      (async () => {
        await setDataByPlaceType(
          false,
          {
            value: 0,
            range: [
              `${dateObj.xYear(25)}${dateObj.xMonth(25)}`,
              `${dateObj.xYear(2)}${dateObj.xMonth(1)}`,
            ],
          },
          apiToken,
          {
            name: activeMetro,
            areaType: "Area",
          },
          "quarterly"
        );
      })();
    } catch (error) {
      console.debug(error);
    }

    // eslint-disable-next-line
  }, [apiToken, activeMetro]);

  const mlsCharts = [
    {
      title: "median_sale_price_per_sq_ft",
      index: 3,
      yTickFormat: (v) => {
        if (Number(v) >= 1000000) {
          return `$${(Number(v) / 1000000).toLocaleString()}m`;
        } else if (Number(v) > 1000) {
          return `$${(Number(v) / 1000).toLocaleString()}k`;
        } else {
          return `$${Number(v).toLocaleString()}`;
        }
      },
      // yTickFormat: (v) => v,
    },
    {
      title: "properties_sold",
      index: 6,
      yTickFormat: (v) => Number(v).toLocaleString(),
    },
    { title: "days_on_market", index: 2 },
    {
      title: "sale-to-list_ratio",
      index: 5,
      yTickFormat: (v) => `${(+v * 100).toFixed(0)}%`,
    },
  ];

  const [hpaTimeSeries, setHpaTimeSeries] = useState([]);
  const [scoreTimeSeries, setScoreTimeSeries] = useState([]);
  useEffect(() => {
    if (!apiToken || !settings) return;
    (async () => {
      const metroId = settings.msa_data.find((e) => e.name === activeMetro)?.id || null;
      if (!metroId) return;

      const data = await fetch(
        `${apiGateway}/collections/public.vw_metro_avg_hpa_score/items?access_token=${apiToken}&area_id=${metroId}`
      ).then((res) => res.json());
      if (!data.features.length) return;

      const validFeatures = data.features.filter((f) => f.properties.year);
      if (!validFeatures.length) return;

      console.log({ validFeatures });

      const timeseries = [];
      for (let i = 0; i < validFeatures.length; i++) {
        for (let t = 1; t < 5; t++) {
          timeseries.push({
            x: validFeatures[i].properties.year,
            y: validFeatures[i].properties["type" + t + "_avg_hpa"]
              ? +(validFeatures[i].properties["type" + t + "_avg_hpa"] * 1).toFixed(2)
              : null,
            type: t,
            color: placeTypeColorsMuted["type_" + t],
          });
        }
      }
      timeseries.sort((a, b) => a.x - b.x);
      console.log({ timeseries });

      setHpaTimeSeries([...timeseries]);

      const scoreTimeseries = [];
      for (let i = 0; i < validFeatures.length; i++) {
        for (let t = 1; t < 5; t++) {
          scoreTimeseries.push({
            x: validFeatures[i].properties.year,
            y: validFeatures[i].properties["type" + t + "_avg_score"]
              ? +validFeatures[i].properties["type" + t + "_avg_score"].toFixed(2)
              : null,
            type: t,
            color: placeTypeColorsMuted["type_" + t],
          });
        }
      }
      scoreTimeseries.sort((a, b) => a.x - b.x);
      console.log({ scoreTimeseries });
      setScoreTimeSeries([...scoreTimeseries]);
    })();
    //eslint-disable-next-line
  }, [activeMetro, apiToken, settings]);

  return (
    <Flex align="center" justify="space-between" mb={6} flexDir={"column"} p={4}>
      <Heading textAlign={"center"} as="h2" size="xl" mb={8}>
        MarketScape
      </Heading>
      <Heading textAlign={"center"} as="h2" size="lg" mb={8}>
        For Sale Residential Housing
      </Heading>
      <Heading textAlign={"center"} as="h2" size="lg" mb={10}>
        {activeMetro} MSA Place-Based Sub-Markets
      </Heading>
      <SimpleGrid
        columns={[1, 1, 1, 2]}
        width="100%"
        maxW={1680}
        align="center"
        justifyContent={"center"}
        mb={10}
      >
        <Box>
          <Heading as="h3" size="lg" mb={8} textAlign={"center"}>
            Forward Home Price Appreciation
          </Heading>
          <HPAScoreYearLineChart
            data={hpaTimeSeries.filter((e) => placesTypes.includes(e.type))}
            height={260}
            yFormat={(v) => v + "%"}
          />
        </Box>
        <Box>
          <Heading as="h3" size="lg" mb={8} textAlign={"center"}>
            Places Score
          </Heading>
          <HPAScoreYearLineChart
            data={scoreTimeSeries.filter((e) => placesTypes.includes(e.type))}
            height={260}
          />
        </Box>
      </SimpleGrid>
      <Flex
        align="center"
        justifyContent={"center"}
        // w={{
        //   base: "100vw",
        //   sm: "600px",
        //   lg: "900px",
        //   xl: "1210px",
        //   "2xl": "1920px",
        // }}
        flex={1}
        flexDir={"column"}
        mx={6}
        width="100%"
        maxW={1680}
      >
        {/* <Box w="100%" maxW={900} mb={20}>
          <Heading textAlign={"center"} as="h2" size="lg" mb={8}>
            Forward Home Price Appreciation –- {activeMetro}
          </Heading>
          <ForwardHPASparklineChart hideTitle={true} />
        </Box> */}
        {/* <Grid
          w="100%"
          gridGap={4}
          gridTemplateColumns={{
            base: "minmax(0,1fr)",
            xl: "repeat(1, minmax(0,1fr))",
          }}
          mb={32}
          minH={"800px"}
          maxW={1000}
        >
          {metrics.metro.length ? (
            <>
              <Heading textAlign={"center"} as="h2" size="lg" mb={0}>
                Forward Home Price Appreciation –- {activeMetro} Sub-Markets{" "}
              </Heading>
              <HPALineChartByPlaceType
                data={getHPAPlaceTypeData(
                  metrics.metro.find((m) => m.msa === activeMetro)
                )}
              />
              <HPATableByPlaceType
                data={getHPAPlaceTypeData(
                  metrics.metro.find((m) => m.msa === activeMetro)
                )}
              />
            </>
          ) : (
            ""
          )}
        </Grid> */}

        <Grid
          w="100%"
          gridGap={16}
          gridTemplateColumns={{
            base: "minmax(0,1fr)",
            lg: "repeat(2, minmax(0,1fr))",
          }}
          maxW={1600}
        >
          <MedianPriceChangeCharts
            data={priceChangeData.filter((d) => d.metric === "perc_median_price_chg")}
            title="Sale Price Change (Median)"
          />
          <MedianPriceChangeCharts
            data={priceChangeData.filter((d) => d.metric === "perc_median_pricepersqft_chg")}
            title="Sale Price Change SqFt (Median)"
          />
        </Grid>
        {metroAVMData?.length ? (
          <Grid
            w="100%"
            gridGap={4}
            gridTemplateColumns={{
              base: "minmax(0,1fr)",
              lg: "repeat(2, minmax(0,1fr))",
            }}
            maxW={1600}
            mb={52}
          >
            {/* <MarketScapeChangeTable
              metric={
                tableMetrics.find((m) => m.dataIndex === chart.index)?.name
              }
              data={medianChangeTableData}
            ></MarketScapeChangeTable> */}
            <Heading
              as="h3"
              size="lg"
              mb={8}
              textAlign={"center"}
              textTransform={"capitalize"}
              gridColumn={"1 / -1"}
              mt={52}
            >
              Home Value (Median AVM) for {activeMetro} Sub-Markets
            </Heading>
            <Box>Table</Box>
            <PlaceTypeChart
              hideTitle={true}
              placesTypes={placesTypes}
              metric={"med_avm"}
              area={
                settings?.msa_data
                  ? settings.msa_data.find((e) => e.name === activeMetro)?.id || null
                  : null
              }
              yTickFormat={(v) =>
                +v > 1000 ? `$${(+v / 1000).toLocaleString()}k` : `$${v.toLocaleString()}`
              }
            ></PlaceTypeChart>
            <Box mb={52}>{""}</Box>
            <Heading
              as="h3"
              size="lg"
              mb={8}
              textAlign={"center"}
              textTransform={"capitalize"}
              gridColumn={"1 / -1"}
            >
              For Sale Residential Price per SQ FT (Median) for {activeMetro} Sub-Markets
            </Heading>
            <Box>Table</Box>

            <PlaceTypeChart
              hideTitle={true}
              placesTypes={placesTypes}
              metric={"avg_ppsqft"}
              area={
                settings?.msa_data
                  ? settings.msa_data.find((e) => e.name === activeMetro)?.id || null
                  : null
              }
              yTickFormat={(v) =>
                +v > 1000 ? `$${(+v / 1000).toLocaleString()}k` : `$${v.toLocaleString()}`
              }
            ></PlaceTypeChart>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          gridTemplateColumns={{
            base: "minmax(0,1000px)",
            lg: "minmax(0,1fr) minmax(0,1fr)",
          }}
          gridGap={8}
          width={"100%"}
          justifyContent={"center"}
        >
          {dataByPlaceType?.type_1 ? (
            mlsCharts.map((chart, i) => (
              <Box width="100%" minH={"600px"} key={i.toString()} mb={8}>
                <Heading as="h3" size="lg" mb={6} textAlign={"center"} textTransform={"capitalize"}>
                  {chart.title.replace(/_/g, " ").replace(/median/g, "")}{" "}
                  {chart.title === "properties_sold" ? "" : "(Median)"}
                </Heading>
                {/* <Heading as="h4" size="md" mb={8} textAlign={"center"}>
                    {activeMetro} Sub-Markets
                  </Heading> */}

                {/* <Grid
                gridTemplateColumns={{
                  base: "minmax(0,1fr)",
                  md: "minmax(0,1fr) minmax(0,1fr)",
                }}
                gridGap={8}
              > */}
                {/* <Box>
                  <MarketScapeChangeTable
                    metric={
                      tableMetrics.find((m) => m.dataIndex === chart.index)
                        ?.name
                    }
                    data={medianChangeTableData}
                  ></MarketScapeChangeTable>
                </Box> */}
                <Box bg="white" p={4} borderRadius={0}>
                  <DemoTimeSeriesChart
                    dataStoreIndex={chart.index}
                    title={chart.title}
                    color="blue"
                    px={2}
                    py={4}
                    placesTypes={placesTypes}
                    xType="categorical"
                    yTickFormat={chart.yTickFormat || null}
                  />
                </Box>
                {/* </Grid> */}
              </Box>
            ))
          ) : (
            <>
              <Text textAlign={"center"}>Data Pending</Text>
              <Text textAlign={"center"}>Data Pending</Text>
            </>
          )}
        </Grid>

        {/* <Box p={10} border={"solid thin red"} mt={40}>
          <List>
            <Text>Pending</Text>
            <ListItem>Median New Listings</ListItem>
            <ListItem>Median Active Inventory</ListItem>
          </List>
        </Box> */}
      </Flex>
    </Flex>
  );
};

// const TopContent = () => {
//   const { placesTypes } = useAppState();
//   return (
//     <SimpleGrid
//       width="100%"
//       columns={{ base: 1, md: 1 }}
//       spacing="20px"
//       p={4}
//       borderRadius={10}
//     >
//       <DemoTimeSeriesChart
//         dataStoreIndex={6}
//         title="Properties Sold"
//         color="blue"
//         px={2}
//         py={4}
//         placesTypes={placesTypes}
//       ></DemoTimeSeriesChart>
//       <DemoTimeSeriesChart
//         dataStoreIndex={2}
//         title="Avg Days on Market"
//         color="blue"
//         px={2}
//         py={4}
//         placesTypes={placesTypes}
//       ></DemoTimeSeriesChart>
//       <DemoTimeSeriesChart
//         color="blue"
//         dataStoreIndex={3}
//         title="Avg Price per SQFT"
//         yTickFormat={(v) => "$" + Number(v).toLocaleString()}
//         px={2}
//         py={4}
//         placesTypes={placesTypes}
//       ></DemoTimeSeriesChart>
//       <DemoTimeSeriesChart
//         color="blue"
//         dataStoreIndex={4}
//         title="Median Sold Price"
//         yTickFormat={(v) =>
//           `$${(Number(v) / 1000).toLocaleString()}${Number(v) > 0 ? "k" : ""}`
//         }
//         px={2}
//         py={4}
//         placesTypes={placesTypes}
//       ></DemoTimeSeriesChart>
//       <DemoTimeSeriesChart
//         dataStoreIndex={5}
//         color="blue"
//         title="Sold to List Price Ratio"
//         px={2}
//         py={4}
//         placesTypes={placesTypes}
//       ></DemoTimeSeriesChart>
//     </SimpleGrid>
//   );
// };
