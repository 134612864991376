import { Badge, Center, Flex } from "@chakra-ui/react";
import { CgArrowLongDown, CgArrowLongLeft, CgArrowLongRight, CgArrowLongUp } from "react-icons/cg";
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import { getLineOfBestFit, getRange } from "../../utils/statistics";
import { useEffect, useState } from "react";

import { placeTypes } from "../../assets/theme";
import { useAppState } from "../../context/useAppState";
import { useMapStore } from "../../context/mapStore";

export const PlacesValueMatrixDotChart = ({ data }) => {
  const sample = data;
  const map = useMapStore((state) => state.map);
  const setIsLoading = useAppState((state) => state.setIsLoading);

  const xRange = getRange(sample, "x");
  const yRange = getRange(sample, "y");

  const linearPoints = getLineOfBestFit(sample, xRange[0], xRange[1], yRange[0], yRange[1]);

  const averageX = sample.reduce((acc, curr) => acc + curr.x, 0) / sample.length;
  const averageY = sample.reduce((acc, curr) => acc + curr.y, 0) / sample.length;
  const maxX = Math.max(...sample.map((s) => s.x));
  const minX = Math.min(...sample.map((s) => s.x));

  const [tooltip, setTooltip] = useState("");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    console.log("setting initial data");
    setChartData(data);
  }, [data]);

  const chartClickHandler = (d) => {
    const coords = d?.coordinates?.coordinates || null;
    if (coords) {
      setIsLoading(true);
      map.fire("contextmenu", {
        latlng: [0, 0],
        point: map.project([0, 0]),
        originalEvent: {},
      });

      map.flyTo({
        center: [+coords[0], +coords[1]],
        zoom: map.getZoom() < 10 ? 10 : map.getZoom(),
      });
      const fireClick = () => {
        setIsLoading(false);
        map.fire("click", {
          latlng: coords,
          point: map.project(coords),
          originalEvent: {},
        });
      };
      map.once("idle", fireClick);
    }
  };

  const [activeGeoID, setActiveGeoID] = useState(null);

  const activePlace = useMapStore((state) => state.activePlace);

  useEffect(() => {
    if (activePlace) {
      setActiveGeoID(activePlace);
    } else {
      setActiveGeoID(null);
    }
  }, [activePlace]);

  useEffect(() => {
    console.log("activeGeoID", activeGeoID);
    if (!activeGeoID) {
      const updatedData = data.map((d) => {
        return {
          ...d,
          color: placeTypes.find((p) => p.type === d.place_type)?.color + "A0" || "black",
        };
      });
      setChartData(updatedData);
      setTooltip("");
      return;
    }
    const updatedData = data.map((d) => {
      if (d.cbg_2020 === activeGeoID) {
        return { ...d, color: "red" };
      } else {
        return {
          ...d,
          color: placeTypes.find((p) => p.type === d.place_type)?.color + "A0" || "black",
        };
      }
    });
    setChartData(updatedData);
    const place = data.find((d) => d.cbg_2020 === activeGeoID);
    if (!place) return;
    const scoreKey =
      Object.keys(place)?.find((key) => key.includes("scoreKey")) ||
      Object.keys(place)?.find((key) => key.includes("places_score"));

    setTooltip(
      `Place Type: ${
        placeTypes.find((t) => t.type === place?.place_type)?.label || ""
      } | Places Score: ${place?.[scoreKey] || ""}`
    );
  }, [activeGeoID, data]);

  const chartHandler = (d) => {
    document.body.style.cursor = "crosshair";
    const updatedData = chartData.map((e) => {
      if (e.x === d.x && e.y === d.y) {
        return { ...e, color: "red" };
      } else {
        return {
          ...e,
          color: placeTypes.find((p) => p.type === e.place_type)?.color + "A0" || "black",
        };
      }
    });
    setChartData(updatedData);

    const scoreKey =
      Object.keys(d).find((key) => key.includes("scoreKey")) ||
      Object.keys(d)?.find((key) => key.includes("places_score"));

    setTooltip(
      `Place Type: ${placeTypes.find((t) => t.type === d.place_type)?.label} | Places Score: ${
        d[scoreKey]
      }`
    );
  };

  const chartLeaveHandler = () => {
    const updatedData = chartData.map((bar) => {
      return {
        ...bar,
        color: placeTypes.find((p) => p.type === bar.place_type)?.color + "A0" || "black",
      };
    });
    setChartData(updatedData);
    document.body.style.cursor = "default";
    setTooltip("");
  };

  return (
    <>
      {!chartData || chartData?.length === 0 ? (
        <Center flex={1} alignItems={"center"} h="100%">
          No data available.
        </Center>
      ) : (
        <Flex
          position={"relative"}
          flexDirection={"column"}
          ml={4}
          data-id="places-value-matrix-dot-chart"
        >
          <Badge
            position={"absolute"}
            width={"auto"}
            top={"20px"}
            left={"50px"}
            zIndex={1}
            fontSize={"sm"}
          >
            {tooltip || ""}
          </Badge>

          <Flex
            style={{ writingMode: "tb-rl" }}
            transform={"rotate(-180deg)"}
            position={"absolute"}
            justifyContent={"space-between"}
            top={"60px"}
            height={"400px"}
            zIndex={1}
            left={-4}
            alignItems={"center"}
            letterSpacing={4}
          >
            <Flex alignItems={"center"}>
              <CgArrowLongUp style={{ fontSize: "30px", marginBottom: "10px" }} />
              More
            </Flex>
            <div>Risk</div>
            <Flex alignItems={"center"}>
              Less
              <CgArrowLongDown style={{ fontSize: "30px", marginTop: "10px" }} />
            </Flex>
          </Flex>

          <FlexibleWidthXYPlot
            margin={{ left: 50, right: 50, top: 50, bottom: 50 }}
            height={500}
            // xDomain={[-10, 10]}
            yDomain={yRange}
            onMouseLeave={chartLeaveHandler}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <LineSeries
              strokeStyle="dashed"
              color="gray"
              data={[
                { x: maxX, y: averageY },
                { x: minX, y: averageY },
              ]}
            />
            <LineSeries
              strokeStyle="dashed"
              color="gray"
              data={[
                { x: averageX, y: yRange[0] },
                { x: averageX, y: yRange[1] },
              ]}
            />

            <MarkSeries
              data={chartData}
              colorType="literal"
              strokeType="literal"
              size={3}
              // onNearestXY={chartHandler}
              onValueMouseOver={chartHandler}
              onValueClick={chartClickHandler}
            />
            <MarkSeries
              data={chartData
                .filter((d) => d.cbg_2020 === activeGeoID)
                .map((d) => ({
                  ...d,
                  color: placeTypes.find((p) => p.type === d.place_type)?.color,
                }))}
              colorType="literal"
              strokeType="literal"
              stroke={"black"}
              strokeWidth={2}
              size={10}
              // onNearestXY={chartHandler}
              // onValueMouseOver={chartHandler}
              // onValueClick={chartClickHandler}
            />
            <LineSeries data={linearPoints} color="firebrick" />
            <XAxis />
            <YAxis />
          </FlexibleWidthXYPlot>
          <Flex
            alignItems="center"
            w="85%"
            ml="5%"
            justifyContent={"space-between"}
            letterSpacing={4}
            mt={-4}
          >
            <Flex alignItems={"center"}>
              <CgArrowLongLeft style={{ fontSize: "30px", marginRight: "10px" }} />
              Less
            </Flex>
            <div>Return</div>
            <Flex alignItems={"center"}>
              More
              <CgArrowLongRight style={{ fontSize: "30px", marginLeft: "10px" }} />
            </Flex>{" "}
          </Flex>
        </Flex>
      )}
    </>
  );
};
