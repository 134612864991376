import "./table.css";

import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FeaturesBlock,
  FeaturesBlockSingle,
} from "../ChakraTemplates/StatsGrid";
import { Fragment, useEffect, useState } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { NetlifyAuth } from "../Auth/NetlifyAuth";
import { Pages } from "../../views/demo/Pages";
import { useAccountStore } from "../../context/accountStore";
import { useNavigate } from "react-router";

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="lg"
      borderWidth="1px"
      bg="white"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.200")}
      rounded={"lg"}
      minWidth={{ base: "90%", md: "300px" }}
      width={"100%"}
    >
      {children}
    </Box>
  );
}

function ImageModal({ src, isOpen, onClose }) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex={2} background="white" />
          <ModalBody display={"flex"} flexDir="column" margin="0 auto">
            <Box
              position={"absolute"}
              top={"25%"}
              width={"85%"}
              transform="rotate(45deg)"
              style={{
                transform: "rotate(315deg)",
                zIndex: "1",
                letterSpacing: "40px",
                color: "lightgray",
                fontWeight: "900",
                opacity: "0.7",
                fontSize: "100px",
                left: "15%",
              }}
            >
              COMING SOON
            </Box>
            <Box>
              <Image alt="logo" src="/logo.png" height={"40px"}></Image>
            </Box>
            <Flex flex="1" flexDirection={"column"}>
              {src.map((img, i) => (
                <Box
                  display={"flex"}
                  alignItems="center"
                  key={i.toString()}
                  minH="100vh"
                >
                  <Image
                    objectFit={"contain"}
                    src={img}
                    alt="Example Image"
                    filter={"saturate(0.7) opacity(0.8)"}
                  ></Image>
                </Box>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function Features() {
  const { account } = useAccountStore();

  console.log({ account });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [src] = useState([]);
  const [page] = useState();
  let navigate = useNavigate();

  const toggleLogin = () => {
    if (account) {
      return NetlifyAuth.signout(() => {
        navigate("/");
      });
    } else {
      return NetlifyAuth.authenticate(async (user) => {
        if (user)
          navigate("/" + Pages[0].title.toLowerCase().replace(/ /g, "-"));
      });
    }
  };

  // const showModal = (e) => {
  //   const sources = {
  //     capital: ["/images/splash/cp-1.png", "/images/splash/cp-2.png"],
  //     govt: ["/images/splash/equity.png"],
  //     consumer: ["/images/splash/consumer-map.png"],
  //   };
  //   onOpen();
  //   console.log(sources[e.target.id]);
  //   setSrc(sources[e.target.id]);
  // };

  // const checkAccount = (e) => {
  //   if (!account) {
  //     e.preventDefault();
  //     setPage(e.target.dataset.link);
  //     document.querySelector("#login").click();
  //   } else {
  //     navigate(e.target.dataset.link);
  //   }
  // };

  useEffect(() => {
    if (account && account.gcpToken && page) {
      // console.log(page);
      navigate(page);
    }
    //eslint-disable-next-line
  }, [account]);

  return (
    <Box>
      <ImageModal
        src={src}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      ></ImageModal>
      <Box
        px={10}
        paddingBottom={40}
        flex="1"
        as="main"
        minHeight={"calc(100vh - 1000px)"}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, xl: 4 }}
          textAlign="center"
          spacing={{ base: 4, lg: 10 }}
          maxW={"1400px"}
          margin="0 auto"
        >
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text
                fontWeight="600"
                fontSize="3xl"
                minH={{ md: "90px" }}
                color={useColorModeValue("gray.900", "gray.900")}
              >
                Consumers
                <br></br>
              </Text>
              {/* <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  79
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack> */}
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List
                spacing={3}
                textAlign="start"
                px={12}
                minH={{ md: "120px" }}
              >
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Listings Data
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Lifestyle Search
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Value Analytics
                </ListItem>
              </List>
              <Box w="80%" pt={4}>
                {/* <Button w="full" colorScheme="red" variant="outline" onClick={showModal} id="consumer">
                  Coming Soon
                </Button> */}
              </Box>
            </VStack>
          </PriceWrapper>
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text
                fontWeight="600"
                fontSize="3xl"
                minH={{ md: "90px" }}
                color={useColorModeValue("gray.900", "gray.900")}
              >
                Real Estate Professionals
              </Text>
              {/* <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  79
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack> */}
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List
                spacing={3}
                textAlign="start"
                px={12}
                minH={{ md: "120px" }}
              >
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Metro Property Network
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Listings Data
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Market Analytics
                </ListItem>
              </List>
              <Box w="80%" pt={4}>
                {/* <Button w="full" colorScheme="red" variant="outline" onClick={checkAccount} data-link="/dashboard">
                  View Map
                </Button> */}
              </Box>
            </VStack>
          </PriceWrapper>
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text
                fontWeight="600"
                fontSize="3xl"
                minH={{ md: "90px" }}
                color={useColorModeValue("gray.900", "gray.900")}
              >
                Municipal Governments
              </Text>
              {/* <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  79
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack> */}
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List
                spacing={3}
                textAlign="start"
                px={12}
                minH={{ md: "120px" }}
              >
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Market Analytics
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Decision Support
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Fiscal Impact
                </ListItem>
              </List>
              <Box w="80%" pt={4}>
                {/* <Button w="full" colorScheme="red" variant="outline" onClick={showModal} id="govt">
                  Coming Soon
                </Button> */}
              </Box>
            </VStack>
          </PriceWrapper>
          <PriceWrapper>
            <Box py={4} px={12}>
              <Text
                fontWeight="600"
                fontSize="3xl"
                minH={{ md: "90px" }}
                color={useColorModeValue("gray.900", "gray.900")}
              >
                Capital Markets
              </Text>
              {/* <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  79
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack> */}
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List
                spacing={3}
                textAlign="start"
                px={12}
                minH={{ md: "120px" }}
              >
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  National Analytics
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Metro Analytics
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Place Analytics
                </ListItem>
              </List>
              <Box w="80%" pt={4}>
                {/* <Button w="full" colorScheme="red" variant="outline" onClick={showModal} id="capital">
                  Coming Soon
                </Button> */}
              </Box>
            </VStack>
          </PriceWrapper>
        </SimpleGrid>
        <Center my={20}>
          <Button
            size={"lg"}
            px={20}
            py={30}
            colorScheme="blue"
            onClick={toggleLogin}
          >
            {account ? "Logout" : "Login"}
          </Button>
        </Center>
      </Box>
      <Box textAlign={"center"}>{/* <ThreeColumnGrid /> */}</Box>
      {/* <StatsGrid /> */}
      <FeaturesBlockSingle
        variant={"solid"}
        title="A Unified View of Real Estate"
        text="Places applies a unique methodology proven across 40 metropolitan areas that classifies 100% of any given metro's real estate (commercial and residential) into place-based market areas using the Places Lens™, a categorization scheme that integrates economic function and land-use."
        align="left"
        image="/images/splash/city-wide.png"
      ></FeaturesBlockSingle>
      <FeaturesBlock
        topic={``}
        align="right"
        title="The Places Lens"
        image="/images/places-grid-trimmed.png"
        text={
          <Fragment>
            Two potential economic functions and two land use forms yield a
            four-cell matrix that categorizes 100% of metropolitan land.
            <br></br>
            <br></br>
            <strong>Type I WALKUP</strong>
            <br></br>
            Regionally Significant and Walkable Urban<br></br>
            <strong>Type II NEIGHBORHOOD</strong>
            <br></br>
            Local Serving and Walkable Urban<br></br>
            <strong>Type III DRIVE-IN</strong>
            <br></br>
            Regionally Significant and Drivable Sub-Urban<br></br>
            <strong>Type IV SUB-DIVISION</strong>
            <br></br>
            Local Serving and Drivable Sub-Urban & Rural areas
          </Fragment>
        }
      ></FeaturesBlock>
      <FeaturesBlockSingle
        variant="solid"
        title="Places Platform's Proprietary Analytics"
        className="splash-table"
        text={
          <Fragment>
            <table>
              <tbody>
                <tr>
                  <th>Places' Analytics</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>The Places Lens™</td>
                  <td>
                    Structures third-party and original content to distill the
                    actionable market signals produced by our suite of analytics
                    and metrics.
                  </td>
                </tr>
                <tr>
                  <td>Places Score™</td>
                  <td>
                    How much is this place worth? Calculates the contribution of
                    location to the strength of a local market (a “place”) and
                    value of an asset.
                  </td>
                </tr>
                <tr>
                  <td>Places Forward Score™</td>
                  <td>
                    Projects the relative market performance of a place in the
                    future.
                  </td>
                </tr>
                <tr>
                  <td>Places Halo Index™</td>
                  <td>
                    Measures the “knock-on” effect to asset values within a
                    given place due to close proximity to another strong
                    place-level RE market.
                  </td>
                </tr>
                <tr>
                  <td>Places Net Fiscal Impact™</td>
                  <td>
                    Quantifies the balance between tax income and expenses at
                    the place level to determine commercial and residential real
                    estate’s relative contribution and impact on municipal
                    budgets.
                  </td>
                </tr>
                <tr>
                  <td>Places Volatility Index™</td>
                  <td>
                    Measures and compares price volatility across metros and
                    within metros to support capital market portfolio bidding
                    and disposition strategies.
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        }
      ></FeaturesBlockSingle>
      <FeaturesBlockSingle
        align={"left"}
        title="About Us"
        text={`Places Platform LLC (Places) is a proptech company that connects global capital to local real estate markets through a proprietary method that creates first of a kind market understanding from user generated, first- and third-party data sources. Places' bottom-up, unified view of both commercial and residential real estate informs the entire real estate value chain, revealing actionable economic, social, and local market insights to create more sustainable, affordable, and equitable communities.
        `}
        style={{ marginBottom: "-100px" }}
      ></FeaturesBlockSingle>
      {/* <Testimonials /> */}
      {/* <Box id="https://thenewgrandstrategy.com"></Box> */}
    </Box>
  );
}
