import { Flex, Image } from "@chakra-ui/react";

import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import { Team } from "./Team";
import { useEffect } from "react";
import { useLocation } from "react-router";

//https://chakra-templates.dev/page-sections/hero
export const Splash = () => {
  const { pathname } = useLocation();
  console.log("[splash] pathname", pathname);
  useEffect(() => {
    if (pathname !== "/home") window.history.replaceState({}, "", "/");
    // eslint-disable-next-line
  }, []);

  return (
    <Flex flexDirection="column" id="splash" width="100%" flex={1}>
      <Hero />
      <Features />
      <Team></Team>
      <Flex>
        <Image
          src="/images/city-1.png"
          alt="Cityscape"
          filter="invert(0.1) opacity(0.3)"
          width="50%"
        ></Image>
        <Image
          src="/images/city.png"
          alt="Cityscape"
          filter="invert(0.1) opacity(0.3)"
          width="50%"
        ></Image>
      </Flex>
      <Footer />
    </Flex>
  );
};
