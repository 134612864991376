import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  useDisclosure
} from "@chakra-ui/react";
import { PoiFilterButtonIconValues, PoiFilterButtonIcons } from "./PoiFilterButtonIcons";
import React, { useEffect, useRef, useState } from "react";

import { BsFilterCircle } from "react-icons/bs";
import { FormIconButton } from "./PropertyFilterForm-IconButton";
import FormSlider from "./FormSlider";
import { useMapStore } from "../../context/mapStore";

/*********************
 * LOGIC
 * SELECT WHOLE FORM
 * SELECT EACH FORM FILTER AREA USING DATA-LISTINGS-FILTER FROM FORM
 * RUN THROUGH ALL FORM ITEMS - SINGLE OUT IF THE CURRENT FILTER
 * MATCHES DATA-FILTER AND DEAL WITH UPDATED CHANGED VALUE
 *
 *
 */

const PropertyFilterForm = () => {
  // if (localStorage.getItem("debug:maps")) console.log("DEBUG: render -- PropertyFilterForm")

  // const map = Object.keys(mapObj).length ? mapObj : null;
  const map = useMapStore((state) => state.map);

  // const statusTypes = ["ACTIVE", "BACK ON MARKET", "COMING SOON", "CONTINGENT", "PENDING", "RENTED", "SOLD"];
  const statusTypes = ["Active", "Expired", "Off Market", "Pending", "Sold"];
  const statusColors = ["#e31a1c", "black", "#1f78b4", "darkorange", "gray"];

  const checkedItemsInitialState = new Array(statusTypes.length);
  checkedItemsInitialState.fill(1);

  function getDateStrings(n) {
    const dates = [];
    for (let i = 0; i < n; i++) {
      const date = new Date(Date.now());
      date.setDate(date.getDate() - i);
      const datestring =
        date.getFullYear().toString() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
      dates.push(datestring);
    }
    return dates;
  }

  const filterKey = {
    filterLayers: ["listings"],
    fields: {
      status: {
        listings: "listingstatus",
        value: statusTypes.slice()
      },
      places_type: {
        listings: "places_type",
        g73_firstscore: null, //needs calculated,
        value: []
      },
      walkable_pois: {
        listings: "walkable_pois",
        g73_firstscore: "walkable_pois", //needs calculated
        value: []
      },
      transit: {
        listings: "total_walkable_rapidtransit",
        value: "0"
      },
      bedrooms: {
        value: "0",
        listings: "total_beds",
        g73_firstscore: "prop_bedrms" //nothing found!
      },
      bathrooms: {
        value: "0",
        listings: "total_baths",
        g73_firstscore: "prop_baths"
      },
      year: {
        value: [1850, 2030],
        listings: "yearbuilt",
        g73_firstscore: "prop_yrbld" //not in all properties
      },
      price: {
        value: [0, 5000000],
        listings: "listprice",
        g73_firstscore: "prop_valcalc"
      },
      sqft: {
        value: [0, 10000],
        listings: "livingarea",
        g73_firstscore: "prop_bldsqft"
      },
      date: {
        value: "0",
        listings: "listgdate"
      }
    }
  };

  const initialFormValues = Object.keys(filterKey.fields).reduce((i, k) => {
    let obj = {};
    obj[k] = filterKey.fields[k].value;
    return { ...i, ...obj };
  }, {});

  const [formValues, setFormValues] = useState(initialFormValues);
  const [checkedItems, setCheckedItems] = useState(checkedItemsInitialState);
  const sqftRef = useRef(null);
  const yearRef = useRef(null);
  const priceRef = useRef(null);

  useEffect(() => {
    if (!map) return;
    const array = new Array(statusTypes.length);
    array.fill(1);
    array[array.length - 1] = -1;
    setCheckedItems(array);
    setFormValues((state) => {
      // console.log(state)
      return { ...state, status: statusTypes.slice(0, statusTypes.length - 1) };
    });
    //eslint-disable-next-line
  }, []);

  //TODO move this into it's own function
  const FormIconButtons = () => {
    return (
      <>
        {PoiFilterButtonIcons.map((e, i) => {
          return (
            <FormIconButton
              key={i}
              filter="pois"
              icon={PoiFilterButtonIcons[i]}
              name={PoiFilterButtonIconValues[i]}
              value={PoiFilterButtonIconValues[i]}
              onChange={(e) => {
                const value = e.target.parentElement.dataset.value.toLowerCase();
                // console.log(value)
                if (value === "transit") {
                  setFormValues((state) => {
                    return { ...state, transit: Number(state.transit) * -1 + 1 };
                  });
                } else {
                  const pois = formValues.walkable_pois.includes(value)
                    ? formValues.walkable_pois.filter((v) => v !== value)
                    : [...formValues.walkable_pois, value];
                  setFormValues((state) => {
                    return { ...state, walkable_pois: pois };
                  });
                }
              }}
              colorScheme={
                (PoiFilterButtonIcons[i] === "Transit" && formValues.transit === 1) ||
                formValues.walkable_pois.includes(PoiFilterButtonIconValues[i].toLowerCase())
                  ? "highlight"
                  : "whiteAlpha"
              }
            />
          );
        })}
      </>
    );
  };

  const handleFormChange = () => {
    if (!map || map === undefined) return;

    // if (localStorage.getItem("debug:maps")) console.log("DEBUG: form onChange")

    let loaded = false;

    //NOTE DONT RUN THROUGH THE FORM CHANGE IF THE MAP IS NOT LOADED
    if (!Object.keys(map).length) return;

    //DO NOT RUN IF MAP LAYERS ARE NOT LOADED
    //BUG THIS IS THROWING ERRORS WHEN THE USER LOGS OUT AND LOGS BACK IN WITHOUT A PAGE REFRESH
    filterKey.filterLayers.forEach((l) => {
      if (map && map.getLayer(l)) loaded = true;
    });

    if (!loaded) return;

    const mapFilter = ["all"];

    let hasFilter = false;

    for (let key in formValues) {
      //POI FILTER
      if (key === "walkable_pois") {
        if (formValues[key].length) {
          hasFilter = true;
          formValues[key].forEach((v) => mapFilter.push(["!=", -1, ["index-of", v, ["get", "walkable_pois"]]]));
        }
      }

      //TRANSIT FILTER
      if (key === "transit") {
        hasFilter = true;
        mapFilter.push([">=", ["get", filterKey.fields[key]["listings"]], Number(formValues[key])]);
      }

      //STATUS FILTER
      if (key === "status" && formValues[key].length < 7) {
        hasFilter = true;
        mapFilter.push(["in", ["get", "listingstatus"], ["literal", formValues[key]]]);
      }

      //SLIDER FITLERS
      if (["sqft", "year", "price"].includes(key)) {
        if (formValues[key].toString() !== initialFormValues[key].toString()) {
          hasFilter = true;
          mapFilter.push([">=", ["get", filterKey.fields[key]["listings"]], Number(formValues[key][0])]);
          mapFilter.push(["<=", ["get", filterKey.fields[key]["listings"]], Number(formValues[key][1])]);
        }
      }

      //RADIO FILTERS
      if (["bedrooms", "bathrooms"].includes(key)) {
        if (formValues[key].toString() !== initialFormValues[key].toString()) {
          hasFilter = true;
          mapFilter.push([">=", ["get", filterKey.fields[key]["listings"]], Number(formValues[key])]);
        }
      }

      //DATE FILTER
      if (key === "date") {
        if (formValues[key].toString() !== initialFormValues[key].toString()) {
          hasFilter = true;
          const datestrings = formValues[key] === "1" ? getDateStrings(7) : getDateStrings(1);
          // console.log(datestrings)
          mapFilter.push(["in", ["get", "listdate"], ["literal", datestrings]]);
        }
      }
    }

    // if (localStorage.getItem("debug:maps")) console.log("DEBUG: ", mapFilter)

    if (hasFilter) {
      map.setFilter("listings", mapFilter);
    } else {
      map.setFilter("listings");
    }
  };

  useEffect(() => {
    if (map) handleFormChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <Box
    // top={14}
    // left={2}
    >
      {/* // <Menu closeOnSelect={false}>
  //   {({ isOpen }) => (
  //     <>
  //       <MenuButton
  //         isActive={isOpen} 
  //         as={IconButton}
  //         aria-label="Listings Filter"
  //         title="Listings Filter"
  //         icon={<BsFilterCircle />}
  //         variant="solid"
  //         color="black"
  //         backgroundColor={"white"}
  //         shadow="sm"
  //         // borderRadius="3px"
  //       ></MenuButton>
  //       <MenuList minWidth="240px" pointerEvents={isOpen ? "all" : "none"} display={isOpen ? "block" : "none"}> */}
      <FormControl
        data-form="propertyFilterForm"
        as="form"
        action="#"
        onChange={handleFormChange}
        p="0px 12px 0px 8px"
        w={380}
        maxW="100%"
      >
        <Box mb={2} fontWeight={600}>
          Listing Status (Listings Only)
        </Box>

        <Grid templateColumns={"repeat(2, 1fr)"} gap={1}>
          {statusTypes.map((t, i) => {
            return (
              <GridItem key={i}>
                <Checkbox
                  mr={4}
                  value={t}
                  isChecked={checkedItems[i] > 0}
                  textTransform={"capitalize"}
                  color={statusColors[i]}
                  onChange={(e) => {
                    const currentStatusList = formValues.status.slice();
                    let newStatusList;
                    if (e.target.checked && !currentStatusList.includes(e.target.value)) {
                      currentStatusList.push(e.target.value);
                    }
                    if (!e.target.checked && currentStatusList.includes(e.target.value)) {
                      newStatusList = currentStatusList.filter((f) => f !== e.target.value);
                    } else {
                      newStatusList = currentStatusList;
                    }

                    setFormValues((state) => {
                      return { ...state, status: newStatusList };
                    });

                    setCheckedItems((state) => {
                      const index = statusTypes.indexOf(e.target.value);
                      const newState = state.slice();
                      newState[index] = newState[index] * -1;
                      // if (localStorage.getItem("debug:maps")) console.log(state, newState)
                      return newState;
                    });
                  }}
                >
                  <Flex alignItems={"center"}>
                    <Box w={4} h={4} mr={2} borderRadius={2} bg={statusColors[i]}>
                      &nbsp;
                    </Box>
                    {t.toLocaleLowerCase()}
                  </Flex>
                </Checkbox>
              </GridItem>
            );
          })}
        </Grid>

        <Spacer h={4} />

        {/*//TODO move this label into the FormIconButtons component */}
        <Box mb={3} fontWeight={600}>
          Amenities within a Ten Minute Walk
        </Box>
        <FormIconButtons />

        <Spacer h={4} />

        <FormSlider
          ref={priceRef}
          title={"List Price"}
          initValues={initialFormValues.price}
          min={initialFormValues.price[0]}
          max={initialFormValues.price[1]}
          onChangeEndFn={(values) => {
            setFormValues((state) => {
              return { ...state, price: values };
            });
          }}
          tooltipFn={(e) => {
            return "$" + e[0].toLocaleString() + " - $" + e[1].toLocaleString();
          }}
        />

        <Spacer h={4} />

        {/* //TODO convert this to a function FormRadio */}
        <FormLabel as="legend" fontWeight={600}>
          Full Baths
        </FormLabel>
        <RadioGroup
          defaultValue="0"
          onChange={(value) => {
            setFormValues((state) => {
              return { ...state, bathrooms: value };
            });
          }}
          value={formValues.bathrooms}
        >
          <HStack spacing="24px">
            <Radio value="0">Any</Radio>
            <Radio value="1">1+</Radio>
            <Radio value="2">2+</Radio>
            <Radio value="3">3+</Radio>
            <Radio value="4">4+</Radio>
          </HStack>
        </RadioGroup>

        <Spacer h={4} />

        <FormLabel as="legend" fontWeight={600}>
          Number of Bedrooms
        </FormLabel>
        <RadioGroup
          defaultValue="0"
          onChange={(value) => {
            setFormValues((state) => {
              return { ...state, bedrooms: value };
            });
          }}
          value={formValues.bedrooms}
        >
          <HStack spacing="24px">
            <Radio value="0">Any</Radio>
            <Radio value="1">1+</Radio>
            <Radio value="2">2+</Radio>
            <Radio value="3">3+</Radio>
            <Radio value="4">4+</Radio>
          </HStack>
        </RadioGroup>

        <Spacer h={4} />

        <FormSlider
          ref={yearRef}
          title={"Year Built"}
          initValues={[1850, 2030]}
          min={1850}
          max={2030}
          onChangeEndFn={(values) => {
            setFormValues((state) => {
              return { ...state, year: values };
            });
          }}
          tooltipFn={(e) => {
            return e[0] + " - " + e[1];
          }}
        />

        <Spacer h={4} />

        <FormSlider
          ref={sqftRef}
          title={"Square Feet"}
          initValues={initialFormValues.sqft}
          min={0}
          max={10000}
          onChangeEndFn={(values) => {
            setFormValues((state) => {
              return { ...state, sqft: values };
            });
          }}
          tooltipFn={(e) => {
            return e[0].toLocaleString() + " - " + e[1].toLocaleString();
          }}
        />

        <Spacer h={4} />

        <FormLabel as="legend" fontWeight={600}>
          List Date
        </FormLabel>
        <RadioGroup
          defaultValue="0"
          onChange={(value) => {
            setFormValues((state) => {
              return { ...state, date: value };
            });
          }}
          value={formValues.date}
        >
          <HStack spacing="24px">
            <Radio value="0">All Dates</Radio>
            <Radio value="1">Past Week</Radio>
            <Radio value="2">Today</Radio>
          </HStack>
        </RadioGroup>

        <Spacer h={4} />

        <Button
          w="100%"
          onClick={() => {
            setFormValues((state) => {
              return { ...state, ...initialFormValues };
            });
            setCheckedItems(() => checkedItemsInitialState);
            sqftRef.current.resetValues();
            yearRef.current.resetValues();
            priceRef.current.resetValues();
          }}
        >
          Reset
        </Button>
      </FormControl>
      {/* )} */}
      {/* </Menu> */}
    </Box>
  );
};

// const PropertyFilterFormMemo = () => {
//   return React.memo(PropertyFilterForm)
// }

function PropertyFilterFormModal({ layers, setLayers }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        aria-label="Layer Selector"
        borderRadius={3}
        onClick={onOpen}
        variant="solid"
        color="black"
        backgroundColor={"white"}
        shadow="sm"
        icon={<BsFilterCircle />}
        m={2}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Map Property Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PropertyFilterForm />
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export { PropertyFilterFormModal };
