import { Box, Flex, useColorModeValue, useDisclosure } from "@chakra-ui/react";

import Loader from "./components/Loader/Loader";
import Navbar from "./components/Navbar/Navbar";
import { Pages } from "./views/demo/Pages";
import { Restricted } from "./components/Restricted";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { useAccountStore } from "./context/accountStore";
import { useAppState } from "./context/useAppState";
import { useDBStore } from "./context/dbStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const Layout = ({ children, selectors, sidebarTitle }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const { isLoading, setIsLoading } = useAppState();
  const { account, isLoggedIn } = useAccountStore();
  const db = useDBStore();

  useEffect(() => {
    setIsLoading(true);
    db.clear();
    setIsLoading(false);
    //eslint-disable-next-line
  }, []);

  // //NOTE set account on load - moved to useAuth() custom hook
  // useEffect(() => {
  //   console.log("[app] setting account");
  //   if (!account) {
  //     setAccount(NetlifyAuth.isLoggedIn());
  //     setInterval(() => {
  //       console.log("[app] new token");
  //       refreshToken();
  //     }, 1000 * 60 * 50);
  //   }
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (!account) return;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("debug")) console.log({ account });
    if (window.location.pathname === "/")
      window.location.replace(Pages[0].title.toLowerCase().replace(/ /g, "-"));
  }, [account]);

  return (
    <Flex flexDir="column" minH="100vh" bg={useColorModeValue("white", "gray.900")} id="layout">
      <Navbar openSidebar={onOpen} />
      <Box p={"28px"} id="navbar-spacer">
        {" "}
      </Box>
      <Flex id="main-shell" flex="1" flexDir={"column"}>
        <Sidebar children={selectors} isOpen={isOpen} onClose={onClose} title={sidebarTitle} />
        {pathname === "/" || pathname === "/home" ? (
          <Flex id="main" flexDir={"column"} flex={1}>
            {children}
          </Flex>
        ) : (
          <Flex
            id="main"
            flexDir={"column"}
            height="100%"
            ml={{ base: 0, lg: "240px" }}
            p={4}
            flex={1}
          >
            {isLoggedIn ? children : <Restricted />}
          </Flex>
        )}
      </Flex>

      <Loader isOpen={isLoading} />
    </Flex>
  );
};
