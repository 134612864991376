import {
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

import { Layout } from "./Layout";
// import { OverviewDashboard } from "./components/Dashboards/Overview";
import { Pages } from "./views/demo/Pages";
import { Splash } from "./components/Splash/Splash";

// import PlacesView from "./PlacesView";

//TODO the routes should be defined elsewhere so we can re-use them in the sidebar

export const Routes = () => {
  return (
    <Router>
      <ReactRoutes>
        <Route path="/" element={<Layout children={<Splash />} />} />
        <Route exact path="/home" element={<Layout children={<Splash />} />} />
        {/* <Route
          path="/overview"
          element={
            <Layout
              children={<OverviewDashboard isOpen={false} onClose={() => {}} />}
            />
          }
        /> */}
        {Pages.map((page, i) => (
          <Route
            key={i}
            path={page.title.toLocaleLowerCase().replace(/ /g, "-")}
            element={
              <Layout
                title={page.title}
                children={page.element}
                selectors={page.selectors}
              />
            }
          />
        ))}
        {/* <Route path="*" element={<Splash />} /> */}
      </ReactRoutes>
    </Router>
  );
};
