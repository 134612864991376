// Function to calculate string similarity using Levenshtein distance
function calculateStringSimilarity(a, b) {
  if (a === b) return 1;
  if (!a.length || !b.length) return 0;

  const lengthA = a.length;
  const lengthB = b.length;
  if (lengthA === 0) return lengthB;
  if (lengthB === 0) return lengthA;

  const matrix = Array(lengthA + 1);
  for (let i = 0; i <= lengthA; i++) {
    matrix[i] = Array(lengthB + 1);
    matrix[i][0] = i;
  }
  for (let j = 0; j <= lengthB; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= lengthA; i++) {
    for (let j = 1; j <= lengthB; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  return 1 - matrix[lengthA][lengthB] / Math.max(lengthA, lengthB);
}

// Function to perform fuzzy search on an object
// Function to perform fuzzy search on an array of objects
/**
 *
 * @param {Array} array
 * @param {String} searchTerm
 * @param {String} key
 * @returns
 */
export const fuzzySearch = (array, searchTerm, key) => {
  if (!array || !array.length || !searchTerm || !key) return {};
  let bestMatch = null;
  let bestSimilarity = 0;

  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const currentSimilarity = calculateStringSimilarity(item[key], searchTerm);

    if (currentSimilarity > bestSimilarity) {
      bestMatch = i;
      bestSimilarity = currentSimilarity;
    }
  }
  // console.log(bestSimilarity);
  console.log(array[bestMatch][key], bestSimilarity);
  if (bestSimilarity < 0.3) return {};
  return array[bestMatch];
};
