// import { apiGateway } from "../config";

import { arraysEqual, isEmpty } from "../helpers/helpers";
import { createJSONStorage, persist } from "zustand/middleware";

import { GcpHelper } from "../helpers/GcpHelper";
import { create } from "zustand";
import { getMetroProps } from "../services/requests";
import netlifyIdentity from "netlify-identity-widget";

// import { dateObj } from "../helpers/dateObj"

// import { BreadcrumbLink } from "@chakra-ui/breadcrumb";
// import { persist } from "zustand/middleware";

const gcpHelper = new GcpHelper();

//NOTE in order to set the tracked metros we need them to be in the same store as the account.

export const useAccountStore = create(
  persist(
    (set, get) => ({
      account: null,
      apiToken: null,
      isLoggedIn: false,
      settings: null,
      gcp: gcpHelper,
      setAccount: async (user) => {
        if (!!user) {
          try {
            const roles = user?.app_metadata?.roles;
            user["is_admin"] = roles?.includes("admin");
            const gcp = get().gcp;
            //check to see if gcpToken is expired, if not we can just return
            const gcpToken = await gcp.getToken(user);
            if (!gcpToken) throw new Error("gcpToken missing");
            set({ account: Object.assign(user, { gcpToken: gcpToken }) });
            set({ apiToken: gcpToken });
            set({ isLoggedIn: true });
            const systemUser = await gcp.signIn(get().account);
            //NOTE hydrate tracked metros
            get().initTrackedMetros(systemUser.settings, gcpToken);
            set({ settings: systemUser.settings }); //trigger settings refresh.
          } catch (error) {
            console.log(error);
            get().logout();
          }
        } else {
          get().logout();
        }
      },

      refreshToken: async () => {
        const token = get().apiToken;
        const user = get().account;
        if (!token || !user) return get().logout();
        console.log("[account-store] refreshing token");
        try {
          const gcp = get().gcp;
          //check to see if gcpToken is expired, if not we can just return
          const gcpToken = await gcp.getToken(user);
          if (!gcpToken) throw new Error("gcpToken missing");
          set({ apiToken: gcpToken });
        } catch (error) {
          console.log(error);
          get().logout();
        }
      },
      updateSetting: async (setting_key, value) => {
        const settings = await get().gcp.settings.update(setting_key, value);
        //NOTE rehydrate tracked metros
        get().initTrackedMetros(settings, get().account.gcpToken);
        set({ settings: settings });
      },
      exportPortfolioStats: async (dateType, minDate, maxDate, locations) => {
        const results = await get().gcp.exportPortfolioStats(dateType, minDate, maxDate, locations);
        return results;
      },
      logout: () => {
        console.log(netlifyIdentity?.store?.modal);
        netlifyIdentity.logout();
        set({
          account: null,
          apiToken: null,
          settings: null,
          isLoggedIn: false,
        });
      },

      //TRACKED METROS STORE
      trackedMetros: [],
      trackedMetrosProps: [],
      initTrackedMetros: async (settings, apiToken) => {
        if (isEmpty(settings, "app_settings")) return;

        const areas = !isEmpty(settings.app_settings, "trackedMetros")
          ? settings.app_settings.trackedMetros
          : [];
        // console.log(areas)
        const savedAreas = get().trackedMetros.sort();

        if (arraysEqual(savedAreas, areas)) return;

        // console.log(["tracked-stored] - hydrating metros"]);

        const areasProperties = await getMetroProps(areas, apiToken);

        return set({
          trackedMetros: areas,
          trackedMetrosProps: areasProperties,
        });
      },

      addTrackedMetro: (area) => {
        // console.log(area)
        const settings = get().settings;
        const app_settings = settings.app_settings;
        const trackedMetros =
          app_settings?.trackedMetros && app_settings.trackedMetros.length
            ? [...app_settings.trackedMetros, area]
            : [area];
        const update = Object.assign({}, app_settings, {
          trackedMetros: trackedMetros,
        });
        return get().updateSetting("app_settings", update);
      },

      removeTrackedMetro: async (area) => {
        const settings = get().settings;
        const app_settings = settings.app_settings;
        const trackedMetros = app_settings.trackedMetros.filter((p) => p !== area);
        const update = Object.assign(app_settings, {
          trackedMetros: trackedMetros,
        });
        return get().updateSetting("app_settings", update);
      },

      updateTrackedMetros: (area) => {
        const settings = get().settings;
        const app_settings = settings.app_settings;
        const trackedMetros = app_settings.trackedMetros.includes(area)
          ? app_settings.trackedMetros.filter((p) => p !== area)
          : [...app_settings.trackedMetros, area];
        const update = Object.assign(app_settings, {
          trackedMetros: trackedMetros,
        });
        return get().updateSetting("app_settings", update);
      },

      // set((state) => ({
      //   trackedMetros: state.trackedMetros.filter((p) => p.area !== area),
      //   trackedMetrosProps: state.trackedMetrosProps.filter((p) => p.area !== area),
      // })),
    }),
    {
      // ...
      name: "ppl__tracked_metros",
      partialize: (state) => ({
        trackedMetros: state.trackedMetros,
        trackedMetrosProps: state.trackedMetrosProps,
      }),
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

// const updatePlacesListings = async (places, apiToken) => {
//   for (let i = 0; i < places.length; i++) {
//     const p = places[i];
//     const data = await fetch(
//       `${apiGateway}/collections/public.rets_listings/items/?filter=places_id='${
//         p.geoid
//       }'%20AND%20listdate%20between%20${dateObj.xDayYear(30)}-${dateObj.xDayMonth(30)}-${dateObj.xDay(
//         30
//       )}%20and%20NOW()&access_token=${apiToken}`
//     ).then((res) => res.json());
//     console.log(data)
//     if (data && data.numberReturned) {
//       places[i]["new_listings"] = data.features.length;
//     } else {
//       places[i]["new_listings"] = 0;
//     }
//   }
//   return places
// };

// export const usedTrackedPlacesStore = create(
//   persist((set) => ({
//     //PLACES
//     places: [],
//     addPlace: (place) => {
//       place["places_score"] = Math.floor(Math.random() * 8) + 2;
//       place["future_score"] = (place["places_score"] + place.places_score * 0.09).toFixed(0);
//       return set((state) => ({ places: [...state.places, place] }));
//     },
//     getPlaceInfo: (place) => {
//       place["places_score"] = Math.floor(Math.random() * 8) + 2;
//       place["future_score"] = (place["places_score"] + place.places_score * 0.09).toFixed(0);
//       return place;
//     },
//     setPlaces: (places) => set({ places: places }),
//     removePlace: (id) => set((state) => ({ places: state.places.filter((p) => p.id !== Number(id)) })),
//     clearPlaces: () => set({ places: [] }),
//   }))
// );

// const getPlaceInfo = async (geoid) => {
//   const data = await fetch(`${apiGateway}/functions/postgisftw.get_all_place_info/?geoid=${geoid}`).then(res => res.json())
//   return data
// }

//create a function in SQL that counts the number of points inside a polygon in postgis

// NOTE SET PLACE LISTINGS 2
// useEffect(() => {
//   if (!initPlacesListings && trackedMetros.length && apiToken) {
//     let n = myPlaces.length;
//     myPlaces.map(async (p, i) => {
//       const data = await fetch(
//         `${apiGateway}/collections/public.rets_listings/items/?filter=places_id='${p.geoid}'%20AND%20listdate%20between%20${dateObj.xDayYear(30)}-${dateObj.xDayMonth(
//           30
//         )}-${dateObj.xDay(30)}%20and%20NOW()&access_token=${apiToken}`
//       ).then((res) => res.json());
//       if (data && data.numberReturned) {
//         myPlaces[i]["new_listings"] = data.features.length;
//       } else {
//         myPlaces[i]["new_listings"] = 0;
//       }
//       n = n - 1;
//       return p;
//     });
//     if (!n) setMyPlaces(myPlaces);
//     setInitPlacesListings(true);
//   }
//   //eslint-disable-next-line
// }, [setInitPlacesListings, apiToken]);
