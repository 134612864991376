import "./mapbox-gl-legend.css";

export class MapboxGLLegend {
  constructor({ id = "", title = "", layer = "", coloramp = true, html = false }) {
    this._id = id || "mapbox-gl-legend";
    this._title = title || "";
    this._layer = layer || "";
    this._coloramp = coloramp;
    this._html = html || false;

    this.onAdd = function (map) {
      this._map = map;
      this._container = document.createElement("div");

      this._container.style.marginBottom = "10px";
      this._container.style.background = "white";
      this._container.style.borderRadius = "3px";
      this._container.style.padding = "8px 16px";
      this._container.style.boxShadow = "0 0 10px 2px #0000001a";
      this._container.style.fontSize = "16px";

      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl--legend";

      this._legendTitle = document.createElement("h3");
      this._legendTitle.style.fontWeight = "bold";
      this._legendTitle.style.textTransform = "capitalize";
      this._legendTitle.style.fontSize = "larger";
      this._legendTitle.style.marginBottom = "4px";

      this._legendTitles = document.createElement("div");
      this._legendTitles.className = "mapboxgl-ctrl--legend-titles";
      this._legendTitles.style.display = "flex";
      this._legendTitles.style.flexDirection = "column";
      this._legendTitles.style.gap = "4px";

      this._legendColors = document.createElement("div");

      if (this._coloramp) {
        this._legendTitles.style.display = "inline-flex";
        this._legendTitles.style.width = "100%";
        this._legendTitles.style.justifyContent = "space-between";
        this._legendTitles.innerHTML = "<span>Low</span><span>Med</span><span>High</span>";

        this._legendColors.style.width = "100%";
        this._legendColors.style.height = "20px";
      }

      if (this._html) {
        this._legendTitles.innerHTML = html;
      }

      this._container.appendChild(this._legendTitle);
      this._container.appendChild(this._legendTitles);
      this._container.appendChild(this._legendColors);

      return this._container;
    };

    /*
    <Box key={i.toString()}>
      <Flex justifyContent={"space-between"} w="100%" pl={1} pr={1}>
        {values.map((v,key) => <Box key={key.toString()}>{Number(v).toLocaleString()}</Box>)}
      </Flex>
      <Box background={`linear-gradient(90deg, ${colors.join(",")})`} w="100%" h={"20px"}>
      </Box>
    </Box>
    */

    this.onRemove = function () {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    };

    this.update = (newLayer, title) => {
      // const queryParams = new URLSearchParams(window.location.search);
      // // const debug = queryParams.get("debug");
      if (newLayer) this._layer = newLayer;

      if (!this._container) return;

      this._container.title = title || this._title;
      this._legendTitle.textContent = title || this._title;

      if (!this._coloramp) return;

      this._container.style.width =
        window.innerWidth < 400 ? window.innerWidth - 40 + "px" : "280px";

      const layers = this._map.getStyle().layers;
      const layer = layers.filter((l) => l.id === this._layer);
      if (!layer.length) return;

      // const paint = layer[0].paint["fill-color"];
      // if (debug) console.log(paint);
      // const colors = [];
      const colors = ["#fcde9c", "#f0746e", "#dc3977", "#7c1d6f"];
      // for (let i = 6; i < paint.length - 1; i++) {
      //   if (i % 2 === 0) {
      //     colors.push(paint[i])
      //   }
      // };
      this._legendColors.style.background = `linear-gradient(90deg, ${colors.join(",")})`;
    };

    this.show = (layer) => {
      if (!this._container) return;
      this.update(layer);
      this._container.style.display = "block";
    };

    this.hide = () => {
      if (!this._container) return;
      this._container.style.display = "none";
    };
  }
}
