import { Box, Flex, Grid, Heading } from "@chakra-ui/react";

import { BasicModal } from "../../components/Modals/Modals";
import { LensMatrix } from "../../components/Demo/LensMatrix";
import { LensMatrixTable } from "../../components/Demo/LensMatrixTable";
import Map from "../../components/Demo/DemoMap";
import PremiumCharts from "../../components/Demo/PremiumCharts";

// import { useAppState } from "../../context/useAppState";

export const Metros = () => {
  // const { activeMetro } = useAppState();
  return (
    <Flex justifyContent={"center"} id="lens" p={4}>
      <Flex flexDir={"column"} maxW={"1680px"} flex={1}>
        <Heading textAlign={"center"} as="h2" size="xl" mb={8}>
          Places Lens
        </Heading>
        {/* <Heading textAlign={"center"} as="h2" size="xl" mb={20}>
          {activeMetro} Place-based Sub-Markets
        </Heading> */}
        <Grid
          gridTemplateColumns={{
            base: "minmax(0, 1fr)",
            lg: "minmax(0, 1fr) 40%",
          }}
          gap={20}
          mb={20}
          justifyContent={"center"}
        >
          <Box>
            <LensMatrix />
            <Box width="100%" maxW="1000px" margin="0 auto">
              <BasicModal
                buttonText={"Product Type"}
                buttonAlign={"right"}
                buttonColorScheme={"blue"}
                buttonMargin={"40px 20px 80px 0"}
                title="Product Type (Sq Ft) by Place"
                children={<LensMatrixTable />}
                size="6xl"
              />
            </Box>
          </Box>

          <Box background="lightgray" maxH="690px" display={{ base: "none", lg: "flex" }}>
            <Map
              setPopupContent={() => {}}
              buttonFiltersState={null}
              analyticsChangeMode={false}
              // dateRange={dateRange}
              // setDateRange={setDateRange}
              placesType={1}
              setTabIndex={() => {}}
              activeLayers={["places-fill", "places-stroke", "listings", "parcels"]}
            />
          </Box>
        </Grid>
        <PremiumCharts />
      </Flex>
    </Flex>
  );
};
