import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { AiOutlineDotChart } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { FcBullish } from "react-icons/fc";
import { Fragment } from "react";
import { RiDonutChartFill } from "react-icons/ri";

export function FeaturesBlock({ variant, topic, title, text, image, align }) {
  return (
    <Box
      px="10"
      bg={variant && variant === "solid" ? "gray.800" : "white"}
      position={"relative"}
    >
      <Container width="100%" position={"relative"}>
        <Stack
          direction={{ base: "column", lg: "row" }}
          maxW="1400px"
          justifyContent="center"
          alignItems={"center"}
          margin="0 auto"
        >
          {align && align === "right" ? (
            <Flex flex={1}>
              {image ? (
                <Image
                  px={10}
                  src={image}
                  alt={image}
                  objectFit="contain"
                ></Image>
              ) : (
                ""
              )}
            </Flex>
          ) : (
            ""
          )}
          <Stack
            flex={1}
            color={variant && variant === "solid" ? "gray.400" : "gray.500"}
            justifyContent="center"
            alignItems={"center"}
            py={{ base: 4, md: 20, xl: 60 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Box
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.400" : "gray.500"}
              >
                <Fragment>{topic}</Fragment>
              </Box>
              <Heading
                color={variant && variant === "solid" ? "white" : "gray.700"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                {title}
              </Heading>
              <Box
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.400" : "gray.500"}
              >
                {text}
              </Box>
            </Box>
          </Stack>
          {!align || align === "left" ? (
            <Flex flex={1}>
              {image ? (
                <Image
                  src={image}
                  alt={image}
                  objectFit="contain"
                  px={10}
                ></Image>
              ) : (
                ""
              )}
            </Flex>
          ) : (
            ""
          )}
        </Stack>
      </Container>
    </Box>
  );
}

export function FeaturesBlockSingle({
  variant,
  topic,
  title,
  text,
  image,
  align,
  ...rest
}) {
  return (
    <Box
      px="10"
      bg={variant && variant === "solid" ? "gray.800" : "white"}
      position={"relative"}
      {...rest}
    >
      <Container width="100%" position={"relative"}>
        <Box>
          <Stack
            flex={1}
            color={variant && variant === "solid" ? "gray.200" : "gray.500"}
            justifyContent="center"
            alignItems={"center"}
            py={{ base: 4, md: 20, xl: 60 }}
          >
            <Box mb={{ base: 8, md: 20 }} maxW="1400px">
              <Box
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.400" : "gray.500"}
              >
                <Fragment>{topic}</Fragment>
              </Box>
              <Heading
                color={variant && variant === "solid" ? "white" : "gray.700"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                {title}
              </Heading>
              {image ? (
                <Image
                  px={10}
                  src={image}
                  alt={image}
                  objectFit="contain"
                  width="100%"
                  my="10"
                  mx={0}
                  borderRadius={2}
                  p={0}
                ></Image>
              ) : (
                ""
              )}
              <Box
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.200" : "gray.500"}
              >
                <Fragment>{text}</Fragment>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export function StatsGrid({ variant }) {
  return (
    <Box px="10" bg={"gray.800"} position={"relative"}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: "none", lg: "flex" }}
        backgroundSize={"cover"}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position={"absolute"}
        width={"50%"}
        insetY={0}
        right={0}
      >
        <Flex
          bgGradient={"linear(to-r, gray.800 10%, transparent)"}
          w={"full"}
          h={"full"}
        />
      </Flex>
      <Container width="100%" position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={variant && variant === "solid" ? "gray.400" : "gray.500"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20, xl: 60 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Box
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.400" : "gray.500"}
              >
                Technology
              </Box>
              <Heading
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                21st Century Real Estate Analytics
              </Heading>
              <Box
                fontSize={"xl"}
                color={variant && variant === "solid" ? "gray.400" : "gray.500"}
              ></Box>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat, i) => (
                <Box key={i.toString()}>
                  <Box
                    fontFamily={"heading"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title ? stat.title : ""}
                  </Box>
                  <Box
                    fontSize={"xl"}
                    color={
                      variant && variant === "solid" ? "gray.400" : "gray.500"
                    }
                  >
                    {stat.content}
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container>
    </Box>
  );
}

const StatsText = ({ children }) => (
  <Text as={"span"} fontWeight={700} color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    content: (
      <Flex flexDirection={"column"}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.100"}
          mb={2}
        >
          <Icon as={AiOutlineDotChart} w={10} h={10} color="firebrick" />
        </Flex>
        <StatsText>Real Estate Value Distribution</StatsText>
      </Flex>
    ),
  },
  {
    content: (
      <Flex flexDirection={"column"}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.100"}
          mb={2}
        >
          <Icon as={RiDonutChartFill} w={10} h={10} color="dodgerblue" />
        </Flex>
        <StatsText>Real Estate Value and Market Share Performance</StatsText>
      </Flex>
    ),
  },
  {
    content: (
      <Flex flexDirection={"column"}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.100"}
          mb={2}
        >
          <Icon as={BsBuilding} w={10} h={10} color="black" />
        </Flex>
        <StatsText>Land Use and Office Rents</StatsText>
      </Flex>
    ),
  },
  {
    content: (
      <Flex flexDirection={"column"}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.100"}
          mb={2}
        >
          <Icon as={FcBullish} w={10} h={10} color="black" />
        </Flex>
        <StatsText>Future Investment Opportunity and Social Equity</StatsText>
      </Flex>
    ),
  },
];
